import styled from "styled-components";

export const ConceptCardContainer = styled.div`
  background: linear-gradient(27deg, #6852ED 16.67%, #BA43FC 100%);
  border-radius: 11.32px;
  box-shadow: 0px 0px 22.6409px 0px rgba(104, 82, 237, 0.80);
  display: flex;
  gap: 1.5rem;
  padding: 1.5rem 2.5rem;

  > img {
    object-position: top;
    object-fit: contain;

    @media screen and (max-width: 720px) {
      display: none !important;
    }
  }
`

export const ConceptContent = styled.div`
  h1, p {
    color: var(--white);
    font-family: Montserrat, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
  }

  h1 {
    font-size: clamp(1.5rem, 1.3849rem + 0.5115vw, 2rem);
    font-weight: 900;
    line-height: clamp(2rem, 1.7698rem + 1.023vw, 3rem);
    text-transform: uppercase;
  }

  p {
    font-size: clamp(1rem, 0.8849rem + 0.5115vw, 1.5rem);
    line-height: clamp(1.25rem, 1.0198rem + 1.023vw, 2.25rem);
  }
`
