import { AxiosInstance } from 'axios'
import { LoadPolibrasProductCategories } from '../../domain/use-case/LoadPolibrasProductCategories'

export class ApiLoadPolibrasProductCategories implements LoadPolibrasProductCategories {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (): Promise<LoadPolibrasProductCategories.Result> {
    try {
      const response = await this.api.get('/polibras/categories/all')
      return response
    } catch (error: any) {
      return error.response
    }
  }
}
