import styled from "styled-components";

export const WavesSectionContainer = styled.section`
  align-items: center;
  display: flex;
  gap: 5rem;
  justify-content: center;

  @media screen and (max-width: 1366px) {
    flex-wrap: wrap;
  }
`

export const WavesTimelineContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const WavesBrandsContainer = styled.div`
  width: fit-content;
  > img {
    width: 100%;
  }
`

export const WavesTitleContainer = styled.div`
  display: flex;
  justify-content: center;

  h1 {
    color: #000;
    font-size: clamp(2rem, 1.4246rem + 2.5575vw, 4.5rem);
    font-family: MontserratStatic, sans-serif;
    font-weight: 900;
    line-height: clamp(1.875rem, 1.501rem + 1.6624vw, 3.5rem);
    text-transform: uppercase;
    white-space: nowrap;

    span {
      :first-child {
        color: transparent;
        font-size: clamp(2rem, 1.5396rem + 2.046vw, 4rem);
        line-height: clamp(1.875rem, 1.3859rem + 2.1739vw, 4rem);
        -webkit-text-stroke: 2px #000;
        -webkit-text-fill-color: transparent;
      }

      :last-child {
        color: #000;
        font-size: clamp(3rem, 1.4463rem + 6.9054vw, 9.75rem);
        font-weight: 900;
        line-height: clamp(1.5rem, 1.4712rem + 0.1279vw, 1.625rem);
        vertical-align: text-bottom;
      }
    }
  }
`
