import { ReactElement, useCallback } from 'react'
import AccordionPaymentProps from './AccordionPayment.props'
import * as S from './style'

export default function AccordionPayment ({ index, isOpen = false, onOpen, title }: AccordionPaymentProps): ReactElement {
  const handleOpen = useCallback(() => {
    if (!isOpen) {
      onOpen(index)
      return
    }

    onOpen(undefined)
  }, [isOpen, index, onOpen])

  return <S.PaymentAccordion onClick={handleOpen}>
    <S.PaymentAccordionLabel className="simple-accordion-header accordion-trigger">
      <S.PaymentAccordionInput
        className={isOpen ? 'test' : ''}
        checked={isOpen}
        type="radio"
        value={title}
      />
      {title}
    </S.PaymentAccordionLabel>

    <S.PaymentAccordionContent
      className={`simple-accordion-content simple-accordion-text simple-accordion-text ${isOpen ? 'accordion-open' : ''}`}
    >
      <S.PaymentAccordionDescriprion>
        Click "Confirm Method" to be redirected to login and confirm your account.
      </S.PaymentAccordionDescriprion>
    </S.PaymentAccordionContent>
  </S.PaymentAccordion>
}
