import styled from 'styled-components'

export const PasswordContainer = styled.section`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1.75rem;
  grid-template-columns: repeat(2, 1fr);

  > :first-child {
    grid-area: 1 / 1 / 2 / 3;
  }

  > :nth-child(2) {
    grid-area: 2 / 1 / 3 / 2;
  }

  > :nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
  }

  @media screen and (max-width: 960px) {
    grid-template-columns: 1fr;

    > :nth-child(2) {
      grid-area: 2 / 1 / 3 / 3;
    }

    > :nth-child(3) {
      grid-area: 3 / 1 / 4 / 3;
    }
  }
`

export const ButtonsContainer = styled.section`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-area: 3 / 1 / 4 / 3;

  button:last-child {
    background-color: var(--secondary) !important;
  }

  @media screen and (max-width: 960px) {
    grid-area: 4 / 1 / 5 / 3;
  }
`
