import { ReactElement } from 'react'
import Template from '../../template'
import Card from '../../components/Card'
import CardTreinamentos from '../../components/Card/CardTreinamentos'
import InputSelect from '../../components/Forms/InputSelect'

import * as S from './style'

import AvatarSocial from '../../assets/img/avatar/01-social.png'
import Avatar31 from '../../assets/img/avatar/31.jpg'
import Avatar04 from '../../assets/img/avatar/04.jpg'

export default function Treinamentos (): ReactElement {
  return (
    <Template>
      <S.FilterContainer onSubmit={() => {}}>
        <Card>
          <InputSelect label="Mostrar">
            <option value="0">Todos</option>
            <option value="1">Treinamento</option>
          </InputSelect>

          <InputSelect label="Filtrar por">
            <option value="0">Mais recente</option>
            <option value="1">Não realizados</option>
          </InputSelect>

          <InputSelect label="Mostrar">
            <option value="0">12 Por página</option>
          </InputSelect>

          <S.SearchButton type="submit">Buscar</S.SearchButton>
        </Card>
      </S.FilterContainer>

      <S.CardsContainer>
        <CardTreinamentos
          avatar={AvatarSocial}
          category='Vendas'
          categoryUrl='construtor-de-vendas'
          thumbnailTitle='Primeiro treinamento'
          thumbnailUrlSrc='https://img.youtube.com/vi/lQV5myhSqCs/hqdefault.jpg'
          title='Marketing & Vendas'
        />

        <CardTreinamentos
          avatar={Avatar31}
          category='Vendas'
          categoryUrl='aprenda-a-vender'
          thumbnailTitle='Primeiro treinamento'
          thumbnailUrlSrc='https://img.youtube.com/vi/qWSzguf5mZ4/hqdefault.jpg'
          title='Aprenda a vender'
        />

        <CardTreinamentos
          avatar={Avatar04}
          category='Estudos'
          categoryUrl='digital-2021'
          thumbnailTitle='Primeiro treinamento'
          thumbnailUrlSrc='https://img.youtube.com/vi/MQDUUodMfCQ/hqdefault.jpg'
          title='Marketing Digital: Guia COMPLETO'
        />

        <CardTreinamentos
          avatar={Avatar04}
          category='Case'
          categoryUrl='100-days'
          thumbnailTitle='Primeiro treinamento'
          thumbnailUrlSrc='https://img.youtube.com/vi/9qdHBXcwayo/hqdefault.jpg'
          title='O Processo de Marketing'
        />
      </S.CardsContainer>
    </Template>
  )
}
