import { ReactElement } from 'react'
import * as S from './style'

export default function Badges (): ReactElement {
  return <S.BadgesContainer>
    <S.BadgesContent>
        {/* get badges */}
        {Array.from(Array(22).keys()).map((el) => (<S.Badge key={el} />))}
      </S.BadgesContent>

      <S.BadgeInstructions>Escolha a ordem em que seus distintivos aparecem. Basta arrastá-los e colocá-los onde você desejar!</S.BadgeInstructions>
      {/* import badges */}
  </S.BadgesContainer>
}
