import styled from 'styled-components'

export const CardsContainer = styled.section`
  display: grid;
  gap: 1rem;

  & > div {
    gap: 2.25rem;
  }
`

export const SectionTitle = styled.p`
  font-weight: 700;
  line-height: 1rem;
  margin: 0;
`

export const EmailNotificationsContent = styled.section`
  display: grid;
  gap: 1.625rem;
`

export const PrivacySettingsContent = styled.section`
  display: grid;
  gap: 1.75rem;
`
