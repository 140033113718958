import { useCampaign } from '../../contexts/CampaignContext';
import * as S from './style';

// Component
import Loading from '../Loading';

export default function PageLoaderComponent () {
    const { campaignData } = useCampaign();
    return (
        <S.PageLoaderContainer>
            <div className="page-loader">
                <div className="page-loader-decoration">
                    <img src={campaignData.logo} alt="logo campanha" />
                </div>

                <div className="page-loader-info">
                    <p className="page-loader-info-title">{campaignData.title}</p>

                    <p className="page-loader-info-text">Carregando...</p>
                </div>

                <Loading />
            </div>
        </S.PageLoaderContainer>
    );
}
