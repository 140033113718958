import styled from 'styled-components'

export const Banner = styled.figure`
  aspect-ratio: 4/1;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 96px;
  margin: 0;
  overflow: hidden;
  width: 100%;

  img {
    object-fit: contain;
  }
`
