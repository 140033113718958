import styled from 'styled-components';
import { Link as StyledLink } from 'react-router-dom';

export const Pergunta = styled.div`
  text-align: center;
  h4{
    margin-bottom: 20px;
  }
  button{
    border-radius: 0;
    border: 1px solid #dedeea;
    color: #3e3f5e;
    background-color: #fff;
    margin-bottom: 10px;
    height: 50px;
    :hover{
      color: #fff;
      background-color: #5753e4;
    }
    :focus{
      background-color: #5753e4;
      color: #fff;
    }
  }
  a{
    font-family: Rajdhani,sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dedeea;
    color: #3e3f5e;
    background-color: #fff;
    margin-bottom: 10px;
    height: 50px;
    :hover{
      color: #fff;
      background-color: #5753e4;
    }
    :focus{
      background-color: #5753e4;
      color: #fff;
    }
  }   
`;
export const Link = styled(StyledLink)`

`;
