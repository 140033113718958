import ExpProps from './Exp.props'
import * as S from './style'

export default function Exp ({ exp }: ExpProps): JSX.Element {
  return <S.Exp>
    <p>
      <span>+ </span>
      {exp}
      {' '}
      EXP
    </p>
  </S.Exp>
}
