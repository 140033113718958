import { ReactElement, useState } from 'react'
import AccordionPayment from '../../../../../../components/Accordion/AccordionPayment'
import Card from '../../../../../../components/Card'
import * as S from './style'

export default function Payments (): ReactElement {
  const [activeAccordion, setActiveAccordion] = useState<number | undefined>(undefined)

  return <S.PaymentsContainer>
    <Card className="single-card">
      <S.SectionTitle>Histórico de Pagamento</S.SectionTitle>

      <S.PaymentsHistoryTable>
        <thead>
          <tr>
            <th>DATA</th>
            <th>MÉTODO</th>
            <th>QUANTIDADE</th>
            <th>STATUS</th>
          </tr>
        </thead>

        <tbody>
          {/* get data and map */}
          <tr>
            <td>Nov 15th, 2019</td>
            <td>marinav@payments.com</td>
            <td>$250.23</td>
            <td className="pending-payment">Pending</td>
          </tr>
          <tr>
            <td>Oct 15th, 2019</td>
            <td>marinav@payments.com</td>
            <td>$118.24</td>
            <td>Paid</td>
          </tr>
          <tr>
            <td>Sep 15th, 2019</td>
            <td>marinav@payments.com</td>
            <td>$337.20</td>
            <td>Paid</td>
          </tr>
          <tr>
            <td>Aug 15th, 2019</td>
            <td>marinav@payments.com</td>
            <td>$101.68</td>
            <td>Paid</td>
          </tr>
          <tr>
            <td>Jul 15th, 2019</td>
            <td>marinav@payments.com</td>
            <td>$89.44</td>
            <td>Paid</td>
          </tr>
          <tr>
            <td>Jun 15th, 2019</td>
            <td>marinav@payments.com</td>
            <td>$109.82</td>
            <td>Paid</td>
          </tr>
          <tr>
            <td>May 15th, 2019</td>
            <td>marinav@payments.com</td>
            <td>$52.17</td>
            <td>Paid</td>
          </tr>
        </tbody>
      </S.PaymentsHistoryTable>
    </Card>

    <Card className="single-card">
      <S.SectionTitle>Método de Pagamento</S.SectionTitle>

      <AccordionPayment
        index={0}
        onOpen={(newIndex) => setActiveAccordion(newIndex)}
        title='Pontos'
        isOpen={activeAccordion === 0}
      />
      <AccordionPayment
        index={1}
        onOpen={(newIndex) => setActiveAccordion(newIndex)}
        title='Cartão de Crédito'
        isOpen={activeAccordion === 1}
      />

      <S.ConfirmMethodButton>
        Confirmar Método
      </S.ConfirmMethodButton>
    </Card>

    {/* modal? */}
    <Card className="single-card">
      <S.SectionTitle>Confirmação de Método</S.SectionTitle>

      <S.MethodConfirmationContainer>
        <S.Icon>
          <svg className="icon-check">
            <use href="#svg-check"></use>
          </svg>
        </S.Icon>

        <S.StatusContainer>
          marinav@payments.com
          <p>PayPal</p>
        </S.StatusContainer>

        <S.ConfirmationDescription>
          Your selected payout method was confirmed on Jan 17, 2019
        </S.ConfirmationDescription>
      </S.MethodConfirmationContainer>
    </Card>
  </S.PaymentsContainer>
}
