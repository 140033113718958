import { ReactElement } from 'react'
import Card from '../../../../../components/Card'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'

import * as S from './style'

import imgFrame from '../../../../../assets/img/badge/badge-empty-02.png'
import imgCover from '../../../../../assets/img/marketplace/items/13.jpg'

export default function StoreManageItems (): ReactElement {
  const img = null

  return <>
    <SectionHeaderComponent pretitle="Minha Loja" title="Gerenciar Itens" />

    <S.ManageItemsContainer>
      <Card className="store-item">
        <S.ItemCover>
          <img src={imgFrame} className="item-icon-frame" />
          <svg className="icon-item">
            <use href="#svg-item"></use>
          </svg>
        </S.ItemCover>

        <S.ItemContentContainer>
          <S.ItemContent>
            <p>O nome do Seu Item Aqui</p>
            <p className="create-item-card">Categoria</p>
          </S.ItemContent>

          <button className="store-item-button" >Criar Novo Item!</button>
        </S.ItemContentContainer>
      </Card>

      <Card className="store-item">
        <S.ItemCover>
          {img !== null && <img src={imgCover} alt="" />}
          <img src={imgCover} alt="" />
        </S.ItemCover>

        <S.ItemContentContainer>
          <S.PriceContainer>
            <p><span>$</span>{' '}12.00</p>
          </S.PriceContainer>
          <S.ItemContent>
            <p>Twitch Stream UI Pack</p>
            <p className="create-item-card">Stream Packs</p>
          </S.ItemContent>

          <button>Editar Item</button>
        </S.ItemContentContainer>
      </Card>
    </S.ManageItemsContainer>
  </>
}
