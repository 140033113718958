import styled from 'styled-components'

export const Label = styled.label`
  display: inline-block;
  position: relative;
  min-width: 52px;
  height: 28px;

  span {
    background-color: #adafca;
    border-radius: 2.125rem;
    cursor: pointer;
    inset: 0;
    position: absolute;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;

    &:before {
      background-color: white;
      border-radius: 50%;
      bottom: 3px;
      content: "";
      height: 22px;
      left: 3px;
      position: absolute;
      transition: .4s;
      -webkit-transition: .4s;
      width: 22px;
    }
  }
`

export const Input = styled.input`
  height: 0;
  opacity: 0;
  width: 0;

  &:checked + span {
    background-color: #23d2e2;
  }

  &:focus + span {
    box-shadow: 0 0 1px #2196F3;
  }

  &:checked + span:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }
`
