import { ReactElement, useState } from 'react'
import InputText from '../InputText'
import * as S from './styled'

interface InputTextProps {
  name?: string
  id?: string
  label?: string
  value?: string
  onChange?: (value: string) => void
  placeholder?: string
  readonly?: boolean
  type?: string
  mask?: (value: string) => string
  error?: string
  large?: boolean
  maxLength?: number
  required?: boolean
  className?: string
}

function InputPassword ({ ...rest }: InputTextProps): ReactElement {
  const [passwordType, setPasswordType] = useState('password')

  return (
    <>
      <S.InputWrapper>
        <InputText label={''} {...rest} type={passwordType} />
        {passwordType === 'password'
          ? (
            <S.EyeButton
              type="button"
              className="button primary"
              onClick={() => setPasswordType('text')}
            >
              <i className="far fa-eye"></i>
            </S.EyeButton>)
          : (
            <S.EyeButton
              type="button"
              className="button primary"
              onClick={() => setPasswordType('password')}
            >
              <i className="far fa-eye-slash"></i>
            </S.EyeButton>)
        }
      </S.InputWrapper>
    </>
  )
}

export default InputPassword
