import React, { createContext, useContext, useEffect, useState } from 'react'
import Modal from 'react-modal'
import { Link } from 'react-router-dom'
import { io } from 'socket.io-client'
import * as S from './style'

interface QuestModalContextProps {
  children: React.ReactNode
}

interface QuestModalProviderProps {
  name: string
  image: string
}

type EffectReturnCallback = () => void

const nullWebSocket: QuestModalProviderProps = { name: '', image: '' }

export const QuestModalContext = createContext<undefined>(undefined)

export const QuestModalProvider: React.FC<QuestModalContextProps> = ({ children }) => {
  const token = localStorage.getItem('token')
  const [questInfos, setQuestInfos] = useState<QuestModalProviderProps>(nullWebSocket)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const customStyles: Modal.Styles = {
    content: {
      backgroundColor: '#FFFFFF',
      border: '1px solid rgba(225, 225, 225, 1)',
      borderRadius: '24px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      height: '576px',
      left: '50%',
      maxHeight: '90%',
      maxWidth: '90%',
      overflow: 'visible',
      padding: '0px',
      position: 'fixed',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      width: '460px'
    }
  }
  const closeModal = (): void => {
    setModalIsOpen(false)
    setQuestInfos(nullWebSocket)
  }
  const handleQuestCompleted = (chunk: QuestModalProviderProps): void => {
    setQuestInfos(chunk)
    console.log(chunk)
    setModalIsOpen(true)
  }

  useEffect((): EffectReturnCallback => {
    const ioServer = io(String(process.env.REACT_APP_BASE_URL), {
      query: { token },
      transports: ['websocket']
    })

    ioServer.on('quest-completed', (chunk) => handleQuestCompleted(chunk))

    return () => ioServer.close()
  }, [handleQuestCompleted])

  return (
    <QuestModalContext.Provider value={undefined}>
        {children}
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
        >
            <S.ModalHeader>
                <S.ButtonClose onClick={closeModal}>X</S.ButtonClose>
                <img src={questInfos.image} alt="Badge de ranking" />
            </S.ModalHeader>
            <S.ModalBody>
              <h2>Parabéns!</h2>
              <p>Você acaba de completar a quest {questInfos.name}</p>
              <span>Agora você é um</span>
              <h3>Gigante da execução</h3>
              <Link to="/quests" onClick={closeModal}>Ver todas as conquistas</Link>
            </S.ModalBody>
        </Modal>
    </QuestModalContext.Provider>
  )
}

export const useQuestModal = (): QuestModalProviderProps => {
  const context = useContext(QuestModalContext)
  if (context == null) {
    throw new Error('Use useQuestModal whitin a QuestModalProvider')
  }
  return context
}
