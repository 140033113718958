import { Link } from 'react-router-dom';
import { useCampaign } from "../../contexts/CampaignContext";
import * as S from './style';

export default function NotFound () {
    const { campaignData } = useCampaign()
    return (
        <S.ErrorPage>
            <S.ErrorSectionContainer className="error-section">
                <p className="error-section-title">404</p>

                <div className="error-section-info">

                    <p className="error-section-subtitle">Oops!!...</p>

                    <p className="error-section-text">Caso o erro continue, por favor, fale com nosso suporte
                        <Link to="/forums">&nbsp;{campaignData.email}</Link>
                    </p>

                    <Link className="button medium primary" to="/home">Voltar ao início</Link>
                </div>
            </S.ErrorSectionContainer>
            <S.BottomDeco />
        </S.ErrorPage>
    );
}
