
// Template
import Template from '../../template';

// Components
import BannerTitleComponent from '../../components/BannerTitle';

// Image
import BannerTitle from '../../assets/img/banner/overview-icon.png';

export default function Overview() {
  return (
    <Template>
      <BannerTitleComponent
        imgUrl={BannerTitle}
        title="Overview"
        subTitle="Seu resumo, sua visão geral e mais!"
        imgAlt="overview-icon"
      />

      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Overview</p>

          <h2 className="section-title">Meu perfil</h2>

        </div>

      </div>

      <div className="grid">

        <div className="grid grid-3-3-3-3 centered">

          <div className="stats-box small stat-profile-views">

            <div className="stats-box-value-wrap">

              <p className="stats-box-value">87.365</p>

              <div className="stats-box-diff">

                <div className="stats-box-diff-icon positive">

                  <svg className="icon-plus-small">
                    <use href="#svg-plus-small"></use>
                  </svg>

                </div>

                <p className="stats-box-diff-value">3.2%</p>

              </div>

            </div>

            <p className="stats-box-title">Visualizações de perfil</p>

            <p className="stats-box-text">No mês passado</p>

          </div>

          <div className="stats-box small stat-posts-created">

            <div className="stats-box-value-wrap">

              <p className="stats-box-value">294</p>

              <div className="stats-box-diff">

                <div className="stats-box-diff-icon positive">

                  <svg className="icon-plus-small">
                    <use href="#svg-plus-small"></use>
                  </svg>

                </div>

                <p className="stats-box-diff-value">0.4%</p>

              </div>

            </div>

            <p className="stats-box-title">Postagens</p>

            <p className="stats-box-text">No último mês</p>

          </div>

          <div className="stats-box small stat-reactions-received">

            <div className="stats-box-value-wrap">

              <p className="stats-box-value">2.560</p>

              <div className="stats-box-diff">
                <div className="stats-box-diff-icon negative">
                  <svg className="icon-minus-small">
                    <use href="#svg-minus-small"></use>
                  </svg>
                </div>

                <p className="stats-box-diff-value">1.8%</p>

              </div>

            </div>

            <p className="stats-box-title">Interações</p>

            <p className="stats-box-text">No último mês</p>

          </div>

          <div className="stats-box small stat-Comentários-received">

            <div className="stats-box-value-wrap">

              <p className="stats-box-value">947</p>

              <div className="stats-box-diff">

                <div className="stats-box-diff-icon positive">

                  <svg className="icon-plus-small">
                    <use href="#svg-plus-small"></use>
                  </svg>

                </div>

                <p className="stats-box-diff-value">4.5%</p>

              </div>

            </div>

            <p className="stats-box-title">Comentários Recebidos</p>

            <p className="stats-box-text">No mês passado</p>

          </div>

        </div>

        <div className="grid grid-layout-1">

          <div className="grid-sidebar">

            <div className="profile-stats fixed-height">

              <div className="profile-stats-cover">
                <p className="profile-stats-cover-title">Bem vindo de volta!</p>

                <p className="profile-stats-cover-text">Rogério Santos</p>
              </div>

              <div className="profile-stats-info">
                <div className="user-avatar medium">
                  <div className="user-avatar-border">
                    <div className="hexagon-120-132">
                      {/* <canvas style="position: absolute; top: 0px; left: 0px;" width="120" height="132"></canvas> */}
                    </div>
                  </div>

                  <div className="user-avatar-content">
                    <div className="hexagon-image-82-90" data-src="img/avatar/01.jpg">
                      {/* <canvas style="position: absolute; top: 0px; left: 0px;" width="82" height="90"></canvas> */}
                    </div>
                  </div>

                  <div className="user-avatar-progress">

                    {/* <div className="hexagon-progress-100-110" style="width: 100px; height: 110px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="100" height="110"></canvas></div> */}

                  </div>

                  <div className="user-avatar-progress-border">

                    {/* <div className="hexagon-border-100-110" style="width: 100px; height: 110px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="100" height="110"></canvas></div> */}

                  </div>

                  <div className="user-avatar-badge">

                    <div className="user-avatar-badge-border">

                      {/* <div className="hexagon-32-36" style="width: 32px; height: 36px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="32" height="36"></canvas></div> */}

                    </div>

                    <div className="user-avatar-badge-content">

                      {/* <div className="hexagon-dark-26-28" style="width: 26px; height: 28px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="26" height="28"></canvas></div> */}

                    </div>

                    <p className="user-avatar-badge-text">24</p>

                  </div>

                </div>

                <div className="featured-stat-list">

                  <div className="featured-stat">

                    <svg className="featured-stat-icon icon-status">
                      <use href="#svg-status"></use>
                    </svg>

                    <p className="featured-stat-title">28.4</p>

                    <p className="featured-stat-subtitle">Postagens</p>

                    <p className="featured-stat-text">média por mês</p>

                  </div>

                  <div className="featured-stat">

                    <svg className="featured-stat-icon icon-comment">
                      <use href="#svg-comment"></use>
                    </svg>

                    <p className="featured-stat-title">69.7</p>

                    <p className="featured-stat-subtitle">Comentários</p>

                    <p className="featured-stat-text">Média por mês</p>

                  </div>

                </div>

                {/* <div className="featured-stat-list">
                <div className="featured-stat">
                  <div className="progress-arc-wrap small">

                    <div className="progress-arc">
                        <div className="chartjs-size-monitor">
                            <div className="chartjs-size-monitor-expand">
                                <div className=""></div>
                            </div>
                            <div className="chartjs-size-monitor-shrink">
                                <div className=""></div>
                            </div>
                        </div>
                      <canvas id="posts-engagement-chart" style="display: block; height: 80px; width: 80px;" className="chartjs-render-monitor" width="100" height="100"></canvas>
                    </div>

                    <div className="progress-arc-info">

                      <p className="progress-arc-title">87%</p>

                    </div>

                  </div>

                  <p className="featured-stat-subtitle">Metas</p>

                  <p className="featured-stat-text">Engajamento</p>

                </div>

                <div className="featured-stat">

                  <div className="progress-arc-wrap small">

                    <div className="progress-arc">
                      <canvas id="posts-Compartilhard-chart"></canvas>
                    </div>

                    <div className="progress-arc-info">

                      <p className="progress-arc-title">42%</p>

                    </div>

                  </div>

                  <p className="featured-stat-subtitle">Missões</p>

                  <p className="featured-stat-text">Realizadas</p>

                </div>

              </div> */}

              </div>
            </div>
          </div>

          <div className="grid-header">
            <div className="slider-line small">
              <div id="user-stats-slider-controls" className="slider-controls" aria-label="Carousel Navigation" tabIndex="0">
                <div className="slider-control left" aria-controls="user-stats-slider" tabIndex="-1" data-controls="prev">
                  <svg className="slider-control-icon icon-small-arrow">
                    <use href="#svg-small-arrow"></use>
                  </svg>
                </div>

                <div className="slider-control right" aria-controls="user-stats-slider" tabIndex="-1" data-controls="next">
                  <svg className="slider-control-icon icon-small-arrow">
                    <use href="#svg-small-arrow"></use>
                  </svg>
                </div>
              </div>

              <div className="tns-outer" id="user-stats-slider-ow">
                <div className="tns-liveregion tns-visually-hidden">slide <span className="current">1 to 6</span>  of 6</div>
                <div id="user-stats-slider-mw" className="tns-ovh">
                  <div className="tns-inner" id="user-stats-slider-iw">
                    <div id="user-stats-slider" className="slider-slides with-separator  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal" style={{ transitionDuration: '0s', transform: 'translate3d(0px, 0px, 0px)' }}>
                      <div className="slider-slide tns-item tns-slide-active" id="user-stats-slider-item0">
                        <div className="user-stat big">
                          <p className="user-stat-title">930</p>

                          <p className="user-stat-text">postagens</p>

                        </div>

                      </div>

                      <div className="slider-slide tns-item tns-slide-active" id="user-stats-slider-item1">

                        <div className="user-stat big">

                          <p className="user-stat-title">82</p>

                          <p className="user-stat-text">comentários</p>

                        </div>

                      </div>

                      <div className="slider-slide tns-item tns-slide-active" id="user-stats-slider-item2">

                        <div className="user-stat big">

                          <p className="user-stat-title">5.7k</p>

                          <p className="user-stat-text">visitas</p>

                        </div>

                      </div>

                      <div className="slider-slide tns-item tns-slide-active" id="user-stats-slider-item3">

                        <div className="user-stat big">

                          <p className="user-stat-title">13</p>

                          <p className="user-stat-text">badges</p>

                        </div>

                      </div>

                      <div className="slider-slide tns-item tns-slide-active" id="user-stats-slider-item4">

                        <div className="user-stat big">

                          <p className="user-stat-title">74</p>

                          <p className="user-stat-text">missões</p>

                        </div>

                      </div>

                      <div className="slider-slide tns-item tns-slide-active" id="user-stats-slider-item5">

                        <div className="user-stat big">

                          <p className="user-stat-title">10.6k</p>

                          <p className="user-stat-text">reações</p>

                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid-content">

            <div className="widget-box no-padding">

              <p className="widget-box-title">Atividades de perfil</p>

              <div className="widget-box-content padded-for-scroll" data-simplebar="init">
                <div className="simplebar-wrapper" style={{ margin: '0px 0px -28px' }}>
                  <div className="simplebar-height-auto-observer-wrapper">
                    <div className="simplebar-height-auto-observer"></div>
                  </div>
                  <div className="simplebar-mask">
                    <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                      <div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden scroll' }}>
                        <div className="simplebar-content" style={{ padding: '0px 0px 28px' }}>

                          <div className="user-status-list scroll-content">
                            <div className="user-status notification">

                              <a className="user-status-avatar" href="profile-timeline.html">

                                <div className="user-avatar small no-outline">

                                  <div className="user-avatar-content">

                                    {/* <div className="hexagon-image-30-32" data-src="img/avatar/03.jpg" style="width: 30px; height: 32px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="30" height="32"></canvas></div> */}

                                  </div>
                                </div>
                              </a>

                              <p className="user-status-title">
                                <a className="bold" href="profile-timeline.html">Roberto Santos</a> postou um comentário na sua
                                <a className="highlighted" href="profile-timeline.html">atualização de status</a>
                              </p>

                              <p className="user-status-timestamp small-space">2 min atrás</p>

                              <div className="user-status-icon">

                                <svg className="icon-comment">
                                  <use href="#svg-comment"></use>
                                </svg>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simplebar-placeholder" style={{ width: 'auto', height: '402px' }}></div>
                </div>
                <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                  <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}></div>
                </div>
                <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                  <div className="simplebar-scrollbar" style={{ height: '318px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }}></div>
                </div>
              </div>

            </div>
          </div>

          <div className="grid-column grid-content-sidebar">
            <div className="stats-decoration v2 big secondary">
              <p className="stats-decoration-title">33</p>

              <p className="stats-decoration-subtitle">Engajamento</p>

              <p className="stats-decoration-text">Diário</p>

              <div className="percentage-diff">
                <div className="percentage-diff-icon-wrap positive">
                  <svg className="percentage-diff-icon icon-plus-small">
                    <use href="#svg-plus-small"></use>
                  </svg>
                </div>

                <p className="percentage-diff-text">5.3%</p>
              </div>
            </div>

            <div className="stats-decoration v2 big primary">
              <p className="stats-decoration-title">126</p>

              <p className="stats-decoration-subtitle">Interações</p>

              <p className="stats-decoration-text">Diário</p>

              <div className="percentage-diff">
                <div className="percentage-diff-icon-wrap negative">
                  <svg className="percentage-diff-icon icon-minus-small">
                    <use href="#svg-minus-small"></use>
                  </svg>
                </div>

                <p className="percentage-diff-text">4.7%</p>
              </div>
            </div>
          </div>
        </div>

        <div className="slider-line medium">
          <div id="reaction-stats-slider-controls" className="slider-controls" aria-label="Carousel Navigation" tabIndex="0">
            <div className="slider-control left" aria-controls="reaction-stats-slider" tabIndex="-1" data-controls="prev" aria-disabled="true">
              <svg className="slider-control-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>

            <div className="slider-control right" aria-controls="reaction-stats-slider" tabIndex="-1" data-controls="next">
              <svg className="slider-control-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>
          </div>

          <div className="tns-outer" id="reaction-stats-slider-ow">
            <div className="tns-liveregion tns-visually-hidden" aria-live="polite" aria-atomic="true">slide
              <span className="current">1 to 8</span>  of 8
            </div>
            <div id="reaction-stats-slider-mw" className="tns-ovh">
              <div className="tns-inner" id="reaction-stats-slider-iw">
                <div id="reaction-stats-slider" className="slider-slides with-separator  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal" style={{ transitionDuration: '0s', transform: 'translate3d(0px, 0px, 0px)' }}>
                  <div className="slider-slide tns-item tns-slide-active" id="reaction-stats-slider-item0">
                    <div className="reaction-stat">
                      <img className="reaction-stat-image" src="img/reaction/like.png" alt="reaction-like" />

                      <p className="reaction-stat-title">12.642</p>

                      <p className="reaction-stat-text">Likes</p>
                    </div>
                  </div>

                  <div className="slider-slide tns-item tns-slide-active" id="reaction-stats-slider-item1">
                    <div className="reaction-stat">
                      <img className="reaction-stat-image" src="img/reaction/love.png" alt="reaction-love" />

                      <p className="reaction-stat-title">8.913</p>

                      <p className="reaction-stat-text">Loves</p>
                    </div>
                  </div>

                  <div className="slider-slide tns-item tns-slide-active" id="reaction-stats-slider-item2">
                    <div className="reaction-stat">
                      <img className="reaction-stat-image" src="img/reaction/dislike.png" alt="reaction-dislike" />

                      <p className="reaction-stat-title">945</p>

                      <p className="reaction-stat-text">Dislikes</p>
                    </div>
                  </div>

                  <div className="slider-slide tns-item tns-slide-active" id="reaction-stats-slider-item3">
                    <div className="reaction-stat">
                      <img className="reaction-stat-image" src="img/reaction/happy.png" alt="reaction-happy" />

                      <p className="reaction-stat-title">7.034</p>

                      <p className="reaction-stat-text">Happy</p>
                    </div>
                  </div>

                  <div className="slider-slide tns-item tns-slide-active" id="reaction-stats-slider-item4">

                    <div className="reaction-stat">

                      <img className="reaction-stat-image" src="img/reaction/funny.png" alt="reaction-funny" />

                      <p className="reaction-stat-title">2.356</p>

                      <p className="reaction-stat-text">Funny</p>

                    </div>

                  </div>

                  <div className="slider-slide tns-item tns-slide-active" id="reaction-stats-slider-item5">

                    <div className="reaction-stat">

                      <img className="reaction-stat-image" src="img/reaction/wow.png" alt="reaction-wow" />

                      <p className="reaction-stat-title">5.944</p>

                      <p className="reaction-stat-text">Wow</p>

                    </div>

                  </div>

                  <div className="slider-slide tns-item tns-slide-active" id="reaction-stats-slider-item6">

                    <div className="reaction-stat">

                      <img className="reaction-stat-image" src="img/reaction/angry.png" alt="reaction-angry" />

                      <p className="reaction-stat-title">1.706</p>

                      <p className="reaction-stat-text">Angry</p>

                    </div>

                  </div>

                  <div className="slider-slide tns-item tns-slide-active" id="reaction-stats-slider-item7">

                    <div className="reaction-stat">

                      <img className="reaction-stat-image" src="img/reaction/sad.png" alt="reaction-sad" />

                      <p className="reaction-stat-title">801</p>

                      <p className="reaction-stat-text">Sad</p>

                    </div>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-3-3-3-3 centered">

          <div className="featured-stat-box reactioner">

            <div className="featured-stat-box-cover">

              <p className="featured-stat-box-cover-title">Top Reações</p>

              <p className="featured-stat-box-cover-text">no mês passado</p>

            </div>

            <div className="featured-stat-box-info">

              <div className="user-avatar small">

                <div className="user-avatar-border">

                  {/* <div className="hexagon-50-56" style="width: 50px; height: 56px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="50" height="56"></canvas></div> */}

                </div>
              </div>

              <p className="featured-stat-box-title">94</p>

              <p className="featured-stat-box-subtitle">Reações</p>

              <p className="featured-stat-box-text">Hudson de Jesus</p>
            </div>
          </div>

          <div className="featured-stat-box reactioner">
            <div className="featured-stat-box-cover">
              <p className="featured-stat-box-cover-title">Top Reações</p>

              <p className="featured-stat-box-cover-text">a todo momento</p>
            </div>

            <div className="featured-stat-box-info">
              <div className="user-avatar small">

                <div className="user-avatar-border">
                  {/* <div className="hexagon-50-56" style="width: 50px; height: 56px; position: relative;">
                    <canvas style="position: absolute; top: 0px; left: 0px;" width="50" height="56"></canvas>
                </div> */}
                </div>

                <div className="user-avatar-content">

                  {/* <div className="hexagon-image-30-32" data-src="img/avatar/03.jpg" style="width: 30px; height: 32px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="30" height="32"></canvas></div> */}

                </div>
              </div>

              <p className="featured-stat-box-title">1.569</p>

              <p className="featured-stat-box-subtitle">Reações</p>

              <p className="featured-stat-box-text">Roberto Santos</p>

            </div>

          </div>

          <div className="featured-stat-box commenter">

            <div className="featured-stat-box-cover">

              <p className="featured-stat-box-cover-title">Top Comentários</p>

              <p className="featured-stat-box-cover-text">no mês passado</p>

            </div>

            <div className="featured-stat-box-info">

              <div className="user-avatar small">

                <div className="user-avatar-border">

                  {/* <div className="hexagon-50-56" style="width: 50px; height: 56px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="50" height="56"></canvas></div> */}

                </div>
              </div>

              <p className="featured-stat-box-title">47</p>

              <p className="featured-stat-box-subtitle">Comentários</p>

              <p className="featured-stat-box-text">Daniel Galvão</p>
            </div>
          </div>

          <div className="featured-stat-box commenter">
            <div className="featured-stat-box-cover">
              <p className="featured-stat-box-cover-title">Top Comentários</p>

              <p className="featured-stat-box-cover-text">o todo momento</p>
            </div>

            <div className="featured-stat-box-info">
              <div className="user-avatar small">
                <div className="user-avatar-border">
                  {/* <div className="hexagon-50-56" style="width: 50px; height: 56px; position: relative;">
                    <canvas style="position: absolute; top: 0px; left: 0px;" width="50" height="56"></canvas>
                </div> */}
                </div>
              </div>

              <p className="featured-stat-box-title">803</p>

              <p className="featured-stat-box-subtitle">Comentários</p>

              <p className="featured-stat-box-text">Hudson de Jesus</p>
            </div>
          </div>
        </div>

        <div className="grid grid-half change-on-desktop">
          <div className="widget-box no-padding">
            <div className="widget-box-settings">
              <div className="post-settings-wrap" style={{ position: 'relative' }}>

                <div className="post-settings widget-box-post-settings-dropdown-trigger">
                  <svg className="post-settings-icon icon-more-dots">
                    <use href="#svg-more-dots"></use>
                  </svg>
                </div>

                <div className="simple-dropdown widget-box-post-settings-dropdown">
                  <p className="simple-dropdown-link">Editar Post</p>

                  <p className="simple-dropdown-link">Delete Post</p>

                  <p className="simple-dropdown-link">Make it Featured</p>

                  <p className="simple-dropdown-link">Report Post</p>

                  <p className="simple-dropdown-link">Report Author</p>
                </div>

              </div>
            </div>

            <div className="widget-box-status">
              <p className="text-sticker medium round">
                <svg className="text-sticker-icon icon-trophy">
                  <use href="#svg-trophy"></use>
                </svg>

                <span className="text-sticker-content">Most Popular Post</span>
              </p>

              <div className="widget-box-status-content">

                <div className="user-status">

                  <a className="user-status-avatar" href="profile-timeline.html">

                    <div className="user-avatar small no-outline">

                      <div className="user-avatar-content">

                        {/* <div className="hexagon-image-30-32" data-src="img/avatar/01.jpg" style="width: 30px; height: 32px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="30" height="32"></canvas></div> */}

                      </div>
                    </div>
                  </a>

                  <p className="user-status-title medium"><a className="bold" href="profile-timeline.html">Rogério Santos</a></p>

                  <p className="user-status-text small">17 hours ago</p>
                </div>

                <p className="widget-box-status-text">Tomorow I&apos;ll be livestreaming along with <a href="profile-timeline.html">@DestroyDex</a> on my Youtube channel. We are gonna do a spedrun of Super Mochi Bros 3!</p>

                <a className="video-status" href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                  <img className="video-status-image" src="img/cover/50.jpg" alt="cover-50" />

                  <div className="video-status-info">
                    <p className="video-status-meta">youtube.com</p>

                    <p className="video-status-title"><span className="bold">GameHuntress</span> on <span className="highlighted">Youtube</span></p>

                    <p className="video-status-text">Watch the GameHuntress play all the greatest games.</p>
                  </div>
                </a>

                <div className="tag-list">
                  <a className="tag-item secondary" href="newsfeed.html">Stream</a>

                  <a className="tag-item secondary" href="newsfeed.html">Youtube</a>

                  <a className="tag-item secondary" href="newsfeed.html">Gaming</a>

                  <a className="tag-item secondary" href="newsfeed.html">Retro</a>

                </div>

                {/* <div className="content-actions">
                <div className="content-action">
                  <div className="meta-line">
                    <div className="meta-line-list reaction-item-list">
                      <div className="reaction-item" style="position: relative;">
                        <img className="reaction-image reaction-item-dropdown-trigger" src="img/reaction/happy.png" alt="reaction-happy"/>

                        <div className="simple-dropdown padded reaction-item-dropdown" style="position: absolute; z-index: 9999; bottom: 38px; left: -16px; opacity: 0; visibility: hidden; transform: translate(0px, 20px); transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;">
                          <p className="simple-dropdown-text">
                              <img className="reaction" src="img/reaction/happy.png" alt="reaction-happy"/>
                              <span className="bold">Happy</span>
                            </p>

                          <p className="simple-dropdown-text">Matt Parker</p>

                          <p className="simple-dropdown-text">Destroy Dex</p>

                          <p className="simple-dropdown-text">The Green Goo</p>

                        </div>

                      </div>

                      <div className="reaction-item" style={{position: "relative"}}>

                        <img className="reaction-image reaction-item-dropdown-trigger" src="img/reaction/love.png" alt="reaction-love"/>

                        <div className="simple-dropdown padded reaction-item-dropdown">

                          <p className="simple-dropdown-text">
                              <img className="reaction" src="img/reaction/love.png" alt="reaction-love"/>
                              <span className="bold">Love</span>
                            </p>

                          <p className="simple-dropdown-text">Sandra Strange</p>

                          <p className="simple-dropdown-text">Jane Rodgers</p>

                        </div>

                      </div>

                      <div className="reaction-item" style={{position: "relative"}}>

                        <img className="reaction-image reaction-item-dropdown-trigger" src="img/reaction/funny.png" alt="reaction-funny"/>

                        <div className="simple-dropdown padded reaction-item-dropdown">
                          <p className="simple-dropdown-text">
                              <img className="reaction" src="img/reaction/funny.png" alt="reaction-funny"/>
                          <span className="bold">Funny</span>
                          </p>

                          <p className="simple-dropdown-text">Neko Bebop</p>

                          <p className="simple-dropdown-text">Nick Grissom</p>

                          <p className="simple-dropdown-text">Sarah Diamond</p>

                          <p className="simple-dropdown-text">Jett Spiegel</p>

                          <p className="simple-dropdown-text">Marcus Jhonson</p>

                          <p className="simple-dropdown-text"><span className="bold">and 12 more...</span></p>

                        </div>

                      </div>

                    </div>

                    <p className="meta-line-text">22</p>
                  </div>

                  <div className="meta-line">
                    <div className="meta-line-list user-avatar-list">

                      <div className="user-avatar micro no-stats">

                        <div className="user-avatar-border">

                        <div className="hexagon-22-24" style="width: 22px; height: 24px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="22" height="24"></canvas></div>

                        </div>

                        <div className="user-avatar-content">

                          <div className="hexagon-image-18-20" data-src="img/avatar/09.jpg" style="width: 18px; height: 20px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="18" height="20"></canvas></div>

                        </div>

                      </div>
                    </div>

                    <p className="meta-line-text">30 Participantes</p>
                  </div>
                </div>

                <div className="content-action">
                  <div className="meta-line">
                    <p className="meta-line-link">12 Comentários</p>
                  </div>

                  <div className="meta-line">
                    <p className="meta-line-text">0 Compartilhars</p>
                  </div>
                </div>
              </div> */}
              </div>
            </div>

            <div className="post-options">
              <div className="post-option-wrap" style={{ position: 'relative' }}>
                <div className="post-option reaction-options-dropdown-trigger">
                  <svg className="post-option-icon icon-thumbs-up">
                    <use href="#svg-thumbs-up"></use>
                  </svg>

                  <p className="post-option-text">Reações</p>
                </div>

                {/* <div className="reaction-options reaction-options-dropdown" style="position: absolute; z-index: 9999; bottom: 54px; left: -16px; opacity: 0; visibility: hidden; transform: translate(0px, 20px); transition: transform 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s, visibility 0.3s ease-in-out 0s;">
                <div className="reaction-option text-tooltip-tft" data-title="Like" style="position: relative;">
                  <img className="reaction-option-image" src="img/reaction/like.png" alt="reaction-like"/>
                <div className="xm-tooltip" style="white-space: nowrap; position: absolute; z-index: 99999; top: -28px; left: 50%; margin-left: -22px; opacity: 0; visibility: hidden; transform: translate(0px, 10px); transition: all 0.3s ease-in-out 0s;"><p className="xm-tooltip-text">Like</p></div></div>

                <div className="reaction-option text-tooltip-tft" data-title="Love" style="position: relative;">
                  <img className="reaction-option-image" src="img/reaction/love.png" alt="reaction-love"/>
                <div className="xm-tooltip" style="white-space: nowrap; position: absolute; z-index: 99999; top: -28px; left: 50%; margin-left: -23.5px; opacity: 0; visibility: hidden; transform: translate(0px, 10px); transition: all 0.3s ease-in-out 0s;"><p className="xm-tooltip-text">Love</p></div></div>

                <div className="reaction-option text-tooltip-tft" data-title="Dislike" style="position: relative;">
                  <img className="reaction-option-image" src="img/reaction/dislike.png" alt="reaction-dislike"/>
                <div className="xm-tooltip" style="white-space: nowrap; position: absolute; z-index: 99999; top: -28px; left: 50%; margin-left: -28px; opacity: 0; visibility: hidden; transform: translate(0px, 10px); transition: all 0.3s ease-in-out 0s;"><p className="xm-tooltip-text">Dislike</p></div></div>

                <div className="reaction-option text-tooltip-tft" data-title="Happy" style="position: relative;">

                  <img className="reaction-option-image" src="img/reaction/happy.png" alt="reaction-happy"/>

                <div className="xm-tooltip" style="white-space: nowrap; position: absolute; z-index: 99999; top: -28px; left: 50%; margin-left: -27.5px; opacity: 0; visibility: hidden; transform: translate(0px, 10px); transition: all 0.3s ease-in-out 0s;"><p className="xm-tooltip-text">Happy</p></div></div>

                <div className="reaction-option text-tooltip-tft" data-title="Funny" style="position: relative;">

                  <img className="reaction-option-image" src="img/reaction/funny.png" alt="reaction-funny"/>

                <div className="xm-tooltip" style="white-space: nowrap; position: absolute; z-index: 99999; top: -28px; left: 50%; margin-left: -27px; opacity: 0; visibility: hidden; transform: translate(0px, 10px); transition: all 0.3s ease-in-out 0s;"><p className="xm-tooltip-text">Funny</p></div></div>

                <div className="reaction-option text-tooltip-tft" data-title="Wow" style="position: relative;">

                  <img className="reaction-option-image" src="img/reaction/wow.png" alt="reaction-wow"/>

                <div className="xm-tooltip" style="white-space: nowrap; position: absolute; z-index: 99999; top: -28px; left: 50%; margin-left: -24px; opacity: 0; visibility: hidden; transform: translate(0px, 10px); transition: all 0.3s ease-in-out 0s;"><p className="xm-tooltip-text">Wow</p></div></div>

                <div className="reaction-option text-tooltip-tft" data-title="Angry" style="position: relative;">

                  <img className="reaction-option-image" src="img/reaction/angry.png" alt="reaction-angry"/>

                <div className="xm-tooltip" style="white-space: nowrap; position: absolute; z-index: 99999; top: -28px; left: 50%; margin-left: -26.5px; opacity: 0; visibility: hidden; transform: translate(0px, 10px); transition: all 0.3s ease-in-out 0s;"><p className="xm-tooltip-text">Angry</p></div></div>

                <div className="reaction-option text-tooltip-tft" data-title="Sad" style="position: relative;">

                  <img className="reaction-option-image" src="img/reaction/sad.png" alt="reaction-sad"/>

                <div className="xm-tooltip" style="white-space: nowrap; position: absolute; z-index: 99999; top: -28px; left: 50%; margin-left: -21.5px; opacity: 0; visibility: hidden; transform: translate(0px, 10px); transition: all 0.3s ease-in-out 0s;"><p className="xm-tooltip-text">Sad</p></div></div>

              </div> */}
              </div>

              <div className="post-option">

                <svg className="post-option-icon icon-comment">
                  <use href="#svg-comment"></use>
                </svg>

                <p className="post-option-text">Comentar</p>

              </div>

              <div className="post-option">

                <svg className="post-option-icon icon-Compartilhar">
                  <use href="#svg-Compartilhar"></use>
                </svg>

                <p className="post-option-text">Compartilhar</p>

              </div>

            </div>

          </div>

          <div className="widget-box no-padding">

            <p className="widget-box-title">Atividade</p>

            <div className="widget-box-content padded-for-scroll medium" data-simplebar="init">
              <div className="simplebar-wrapper" style={{ margin: '0px 0px -28px' }}>
                <div className="simplebar-height-auto-observer-wrapper">
                  <div className="simplebar-height-auto-observer"></div>
                </div>
                <div className="simplebar-mask">
                  <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                    <div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden scroll' }}>
                      <div className="simplebar-content" style={{ padding: '0px 0px 28px' }}>

                        <div className="user-status-list scroll-content">

                          <div className="user-status">
                            <div className="user-status-activity activity-reaction">
                              <svg className="user-status-activity-icon icon-thumbs-up">
                                <use href="#svg-thumbs-up"></use>
                              </svg>
                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> left a love
                              <img className="reaction" src="img/reaction/love.png" alt="reaction-love" /> reaction on
                              <a className="highlighted" href="profile-timeline.html">Destroy Dex</a>&apos;s
                              <a className="highlighted" href="profile-timeline.html">status update</a>
                            </p>

                            <p className="user-status-timestamp small-space">3 minutes ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-update">

                              <svg className="user-status-activity-icon icon-members">
                                <use href="#svg-members"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> updated her <a className="highlighted" href="profile-photos.html">profile picture</a></p>

                            <p className="user-status-timestamp small-space">11 minutes ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-reaction">

                              <svg className="user-status-activity-icon icon-thumbs-up">
                                <use href="#svg-thumbs-up"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> left a happy
                              <img className="reaction" src="img/reaction/happy.png" alt="reaction-happy" /> reaction on
                              <a className="highlighted" href="profile-timeline.html">Neko Bebop</a>&apos;s
                              <a className="highlighted" href="profile-timeline.html">status update</a>
                            </p>

                            <p className="user-status-timestamp small-space">3 minutes ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-comment">

                              <svg className="user-status-activity-icon icon-comment">
                                <use href="#svg-comment"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> commented on <a className="highlighted" href="profile-timeline.html">Sarah Diamond</a>&apos;s <a className="highlighted" href="profile-photos.html">photo</a></p>

                            <p className="user-status-timestamp small-space">35 minutes ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-update">

                              <svg className="user-status-activity-icon icon-status">
                                <use href="#svg-status"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> posted a new <a className="highlighted" href="profile-timeline.html">status update</a> &quot;Hi to all! Remember&quot;</p>

                            <p className="user-status-timestamp small-space">1 hour ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-comment">

                              <svg className="user-status-activity-icon icon-comment">
                                <use href="#svg-comment"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> replied to a <a className="highlighted" href="profile-timeline.html">Damian Greyson</a>&apos;s <a className="highlighted" href="profile-timeline.html">comment</a></p>

                            <p className="user-status-timestamp small-space">1 hour ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-Compartilhar">

                              <svg className="user-status-activity-icon icon-Compartilhar">
                                <use href="#svg-Compartilhar"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> Compartilhard <a className="highlighted" href="profile-timeline.html">Destroy Dex</a>&apos;s <a className="highlighted" href="profile-timeline.html">status update</a></p>

                            <p className="user-status-timestamp small-space">4 hours ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-reaction">

                              <svg className="user-status-activity-icon icon-thumbs-up">
                                <use href="#svg-thumbs-up"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> left a wow!
                              <img className="reaction" src="img/reaction/wow.png" alt="reaction-wow" /> reaction on
                              <a className="highlighted" href="profile-timeline.html">Destroy Dex</a>&apos;s
                              <a className="highlighted" href="profile-timeline.html">status update</a>
                            </p>

                            <p className="user-status-timestamp small-space">2 days ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-update">

                              <svg className="user-status-activity-icon icon-members">
                                <use href="#svg-members"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> updated her <a className="highlighted" href="profile-timeline.html">cover picture</a></p>

                            <p className="user-status-timestamp small-space">1 week ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-reaction">

                              <svg className="user-status-activity-icon icon-thumbs-up">
                                <use href="#svg-thumbs-up"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> left a like
                              <img className="reaction" src="img/reaction/like.png" alt="reaction-like" /> reaction on
                              <a className="highlighted" href="profile-timeline.html">Nick Grissom</a>&apos;s
                              <a className="highlighted" href="profile-timeline.html">status update</a>
                            </p>

                            <p className="user-status-timestamp small-space">1 week ago</p>

                          </div>

                          <div className="user-status">

                            <div className="user-status-activity activity-reaction">

                              <svg className="user-status-activity-icon icon-thumbs-up">
                                <use href="#svg-thumbs-up"></use>
                              </svg>

                            </div>

                            <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a> left a funny
                              <img className="reaction" src="img/reaction/funny.png" alt="reaction-funny" /> reaction on
                              <a className="highlighted" href="profile-timeline.html">Bearded Wonder</a>&apos;s
                              <a className="highlighted" href="profile-timeline.html">status update</a>
                            </p>

                            <p className="user-status-timestamp small-space">2 weeks ago</p>

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="simplebar-placeholder" style={{ width: 'auto', height: '732px' }}></div>
              </div>
              <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}></div>
              </div>
              <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                <div className="simplebar-scrollbar" style={{ height: '624px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }}></div>
              </div>
            </div>

          </div>
        </div>

        <div className="slider-line">
          <div id="stat-block-slider-controls" className="slider-controls" aria-label="Carousel Navigation" tabIndex="0">
            <div className="slider-control left" aria-controls="stat-block-slider" tabIndex="-1" data-controls="prev" aria-disabled="true">
              <svg className="slider-control-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>

            <div className="slider-control right" aria-controls="stat-block-slider" tabIndex="-1" data-controls="next">
              <svg className="slider-control-icon icon-small-arrow">
                <use href="#svg-small-arrow"></use>
              </svg>
            </div>
          </div>

          {/* <div className="tns-outer" id="stat-block-slider-ow">
            <div className="tns-liveregion tns-visually-hidden" aria-live="polite" aria-atomic="true">slide
            <span className="current">1 to 4</span>  of
            5</div><div id="stat-block-slider-mw" className="tns-ovh"><div className="tns-inner" id="stat-block-slider-iw"><div id="stat-block-slider" className="slider-slides  tns-slider tns-carousel tns-subpixel tns-calc tns-horizontal" style="transition-duration: 0s; transform: translate3d(0px, 0px, 0px);">
          <div className="slider-slide tns-item tns-slide-active" id="stat-block-slider-item0">
            <div className="stat-block">
              <div className="stat-block-decoration">
                <svg className="stat-block-decoration-icon icon-friend">
                  <use href="#svg-friend"></use>
                </svg>
              </div>

              <div className="stat-block-info">
                <p className="stat-block-title">Last friend added</p>

                <p className="stat-block-text">5 Days Ago</p>
              </div>
            </div>
          </div>

          <div className="slider-slide tns-item tns-slide-active" id="stat-block-slider-item1">
            <div className="stat-block">
              <div className="stat-block-decoration">
                <svg className="stat-block-decoration-icon icon-status">
                  <use href="#svg-status"></use>
                </svg>
              </div>

              <div className="stat-block-info">
                <p className="stat-block-title">Last post update</p>

                <p className="stat-block-text">1 Day Ago</p>
              </div>
            </div>
          </div>

          <div className="slider-slide tns-item tns-slide-active" id="stat-block-slider-item2">

            <div className="stat-block">

              <div className="stat-block-decoration">

                <svg className="stat-block-decoration-icon icon-comment">
                  <use href="#svg-comment"></use>
                </svg>

              </div>

              <div className="stat-block-info">

                <p className="stat-block-title">Most commented post</p>

                <p className="stat-block-text">56 Comentários</p>

              </div>

            </div>

          </div>

          <div className="slider-slide tns-item tns-slide-active" id="stat-block-slider-item3">

            <div className="stat-block">

              <div className="stat-block-decoration">

                <svg className="stat-block-decoration-icon icon-thumbs-up">
                  <use href="#svg-thumbs-up"></use>
                </svg>

              </div>

              <div className="stat-block-info">

                <p className="stat-block-title">Most liked post</p>

                <p className="stat-block-text">904 Likes</p>

              </div>

            </div>

          </div>

          <div className="slider-slide tns-item" id="stat-block-slider-item4" aria-hidden="true" tabindex="-1">

            <div className="stat-block">

              <div className="stat-block-decoration">

                <svg className="stat-block-decoration-icon icon-Compartilhar">
                  <use href="#svg-Compartilhar"></use>
                </svg>

              </div>

              <div className="stat-block-info">

                <p className="stat-block-title">Most Compartilhard post</p>

                <p className="stat-block-text">156 Compartilhars</p>

              </div>

            </div>

          </div>
        </div>
        </div>
        </div>
        </div> */}
        </div>
      </div>

      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Overview</p>

          <h2 className="section-title">Gamification</h2>

        </div>

      </div>

      <div className="grid">

        <div className="grid grid-half change-on-desktop">
          <div className="achievement-box secondary">
            <div className="achievement-box-info-wrap">
              <img className="achievement-box-image" src="img/badge/caffeinated-b.png" alt="badge-caffeinated-b" />

              <div className="achievement-box-info">
                <p className="achievement-box-title">&quot;Última medalha destravada&quot;</p>

                <p className="achievement-box-text"><span className="bold">Cafeinado</span> 2 dias atrás</p>
              </div>
            </div>

            <a className="button white-solid" href="badges.html">Ver todas</a>
          </div>

          <div className="achievement-box primary">
            <div className="achievement-box-info-wrap">
              <img className="achievement-box-image" src="img/quest/completedq-l.png" alt="quest-completedq-l" />

              <div className="achievement-box-info">
                <p className="achievement-box-title">Última missão completa</p>

                <p className="achievement-box-text"><span className="bold">Nada a temer</span> 7 horas atrás</p>
              </div>
            </div>

            <a className="button white-solid" href="quests.html">Ver todas</a>
          </div>
        </div>

        <div className="grid grid-3-9">

          <div className="grid-column">

            <div className="widget-box">
              {/* <div className="progress-arc-summary">

              <div className="progress-arc-wrap">

                <div className="progress-arc"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
                  <canvas id="profile-completion-chart" style="display: block; height: 140px; width: 140px;" className="chartjs-render-monitor" width="175" height="175"></canvas>
                </div>

                <div className="progress-arc-info">

                  <p className="progress-arc-title">59%</p>

                </div>

              </div>

              <div className="progress-arc-summary-info">
                <p className="progress-arc-summary-title">Profile Completion</p>

                <p className="progress-arc-summary-subtitle">Rogério Santos</p>

                <p className="progress-arc-summary-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Iusto tempora voluptas nesciunt fugiat, unde voluptatum quaerat voluptatem ad fugit ex!</p>
              </div>
            </div> */}

              <div className="achievement-status-list">
                <div className="achievement-status">
                  <p className="achievement-status-progress">11/30</p>

                  <div className="achievement-status-info">
                    <p className="achievement-status-title">Missões</p>

                    <p className="achievement-status-text">Completas</p>
                  </div>

                  <img className="achievement-status-image" src="img/badge/completedq-s.png" alt="bdage-completedq-s" />
                </div>

                <div className="achievement-status">
                  <p className="achievement-status-progress">22/46</p>

                  <div className="achievement-status-info">
                    <p className="achievement-status-title">Badges</p>

                    <p className="achievement-status-text">Desbloqueadas</p>
                  </div>

                  <img className="achievement-status-image" src="img/badge/unlocked-badge.png" alt="bdage-unlocked-badge" />
                </div>
              </div>
            </div>
          </div>

          <div className="grid-column">
            <div className="level-progress-box">
              <div className="level-progress-badge">
                <p className="level-progress-badge-title">Level</p>

                <p className="level-progress-badge-text">24</p>
              </div>

              <div className="progress-stat">
                <div className="bar-progress-wrap big">

                  <p className="bar-progress-info start negative progress-with-text">+<span className="bar-progress-text">38<span className="bar-progress-unit">exp</span></span><span className="light">faltam para o próximo nível</span></p>

                  <p className="progress-stat-info">13.625 Pontos de experiência recebidos</p>
                </div>

                <div id="exp-to-next-level" className="progress-stat-bar" style={{ width: '712px', height: '6px', position: 'relative' }}>
                  {/* <canvas style="position: absolute; top: 0px; left: 0px;" width="712" height="6">
                  </canvas><canvas style="position: absolute; top: 0px; left: 0px;" width="712" height="6"></canvas> */}
                </div>
              </div>
            </div>

            <div className="widget-box no-padding">

              <p className="widget-box-title">Histórico de experiência</p>

              <div className="widget-box-content small-margin-top padded-for-scroll small" data-simplebar="init">
                <div className="simplebar-wrapper" style={{ margin: '0px 0px -28px' }}>
                  <div className="simplebar-height-auto-observer-wrapper">
                    <div className="simplebar-height-auto-observer"></div>
                  </div>
                  <div className="simplebar-mask">
                    <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                      <div className="simplebar-content-wrapper" style={{ height: '100%', overflow: 'hidden scroll' }}>
                        <div className="simplebar-content" style={{ padding: '0px 0px 28px' }}>

                          <div className="exp-line-list scroll-content">
                            <div className="exp-line">
                              <svg className="exp-line-icon icon-badges">
                                <use href="#svg-badges"></use>
                              </svg>

                              <p className="text-sticker small-text">
                                <svg className="text-sticker-icon icon-plus-small">
                                  <use href="#svg-plus-small"></use>
                                </svg>
                                80 EXP
                              </p>

                              <p className="exp-line-text">Está no caminho certo! Você subiu para o nível 2 a melhada &quot;O Guerreiro&quot;</p>

                              <p className="exp-line-timestamp">29 minutos atrás</p>
                            </div>

                            <div className="exp-line">
                              <svg className="exp-line-icon icon-quests">
                                <use href="#svg-quests"></use>
                              </svg>

                              <p className="text-sticker small-text">
                                <svg className="text-sticker-icon icon-plus-small">
                                  <use href="#svg-plus-small"></use>
                                </svg>
                                65 EXP
                              </p>

                              <p className="exp-line-text">Parabéns! Você completou a missão &quot;Nada a perder&quot;</p>

                              <p className="exp-line-timestamp">7 horas atrás</p>
                            </div>

                            <div className="exp-line">
                              <svg className="exp-line-icon icon-badges">
                                <use href="#svg-badges"></use>
                              </svg>

                              <p className="text-sticker small-text">
                                <svg className="text-sticker-icon icon-plus-small">
                                  <use href="#svg-plus-small"></use>
                                </svg>
                                40 EXP
                              </p>

                              <p className="exp-line-text">Bom trabalho! Você desbloqueou a medalha &quot;Cafeína&quot;</p>

                              <p className="exp-line-timestamp">2 dias atrás</p>
                            </div>

                            <div className="exp-line">
                              <svg className="exp-line-icon icon-badges">
                                <use href="#svg-badges"></use>
                              </svg>

                              <p className="text-sticker small-text">
                                <svg className="text-sticker-icon icon-plus-small">
                                  <use href="#svg-plus-small"></use>
                                </svg>
                                100 EXP
                              </p>

                              <p className="exp-line-text">Impressionante! Você desbloqueou a medalha &quot;Gold User&quot;</p>

                              <p className="exp-line-timestamp">5 dias atrás</p>
                            </div>

                            <div className="exp-line">
                              <svg className="exp-line-icon icon-badges">
                                <use href="#svg-badges"></use>
                              </svg>

                              <p className="text-sticker small-text">
                                <svg className="text-sticker-icon icon-plus-small">
                                  <use href="#svg-plus-small"></use>
                                </svg>
                                40 EXP
                              </p>

                              <p className="exp-line-text">Bom trabalho! Você desbloqueou a medalha &quot;Cientista Maluco&quot;</p>

                              <p className="exp-line-timestamp">1 semana atrás</p>
                            </div>

                            <div className="exp-line">

                              <svg className="exp-line-icon icon-quests">
                                <use href="#svg-quests"></use>
                              </svg>

                              <p className="text-sticker small-text">
                                <svg className="text-sticker-icon icon-plus-small">
                                  <use href="#svg-plus-small"></use>
                                </svg>
                                20 EXP
                              </p>

                              <p className="exp-line-text">Parabéns! Você completou a missão &quot;Press Start&quot;</p>

                              <p className="exp-line-timestamp">2 semanas atrás</p>
                            </div>

                            <div className="exp-line">
                              <svg className="exp-line-icon icon-quests">
                                <use href="#svg-quests"></use>
                              </svg>

                              <p className="text-sticker small-text">
                                <svg className="text-sticker-icon icon-plus-small">
                                  <use href="#svg-plus-small"></use>
                                </svg>
                                60 EXP
                              </p>

                              <p className="exp-line-text">Parabéns! Você completou a missão &quot;Social King&quot;</p>

                              <p className="exp-line-timestamp">3 weeks ago</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simplebar-placeholder" style={{ width: 'auto', height: '392px' }}></div>
                </div>
                <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                  <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}></div>
                </div>
                <div className="simplebar-track simplebar-vertical" style={{ visibility: 'visible' }}>
                  <div className="simplebar-scrollbar" style={{ height: '261px', transform: 'translate3d(0px, 0px, 0px)', display: 'block' }}></div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Overview</p>

          <h2 className="section-title">Análise de desempenho</h2>
        </div>
      </div>

      <div className="grid">

        <div className="grid grid-3-3-3-3 centered">
          <div className="account-stat-box account-stat-active-users">
            <div className="account-stat-box-icon-wrap">

              <svg className="account-stat-box-icon icon-friend">
                <use href="#svg-friend"></use>
              </svg>
            </div>

            <p className="account-stat-box-title">71</p>

            <p className="account-stat-box-subtitle">Missões realizadas</p>

            <p className="account-stat-box-text">Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Veritatis reprehenderit asperiores laudantium id, possimus consequatur quidem eos voluptatem
              molestias aspernatur ducimus tempora? Laboriosam dolore perferendis saepe, eos dolores sit molestiae?
            </p>
          </div>

          <div className="account-stat-box account-stat-visits">
            <div className="percentage-diff">
              <div className="percentage-diff-icon-wrap positive">
                <svg className="percentage-diff-icon icon-plus-small">
                  <use href="#svg-plus-small"></use>
                </svg>
              </div>

              <p className="percentage-diff-text">3.6%</p>
            </div>

            <div className="account-stat-box-icon-wrap">
              <svg className="account-stat-box-icon icon-members">
                <use href="#svg-members"></use>
              </svg>
            </div>

            <p className="account-stat-box-title">262</p>

            <p className="account-stat-box-subtitle">Contribuições</p>

            <p className="account-stat-box-text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Velit nihil cumque ab officiis, eaque ea eius iure maiores, fuga pariatur odit veritatis. Architecto est facere fugit ratione dolorem nemo culpa?</p>
          </div>

          <div className="account-stat-box account-stat-session-duration">
            <div className="percentage-diff">
              <div className="percentage-diff-icon-wrap negative">
                <svg className="percentage-diff-icon icon-minus-small">
                  <use href="#svg-minus-small"></use>
                </svg>
              </div>

              <p className="percentage-diff-text">2.4%</p>
            </div>

            <div className="account-stat-box-icon-wrap">
              <svg className="account-stat-box-icon icon-clock">
                <use href="#svg-clock"></use>
              </svg>
            </div>

            <p className="account-stat-box-title">4:39</p>

            <p className="account-stat-box-subtitle">Tempo conectado</p>

            <p className="account-stat-box-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Obcaecati corrupti quia velit quisquam maxime tempore ex quis rerum recusandae eos architecto laboriosam debitis culpa voluptatem autem nulla, vel enim error.</p>
          </div>

          <div className="account-stat-box account-stat-returning-visitors">

            <div className="percentage-diff">
              <div className="percentage-diff-icon-wrap positive">
                <svg className="percentage-diff-icon icon-plus-small">
                  <use href="#svg-plus-small"></use>
                </svg>
              </div>

              <p className="percentage-diff-text">8.2%</p>
            </div>

            <div className="account-stat-box-icon-wrap">
              <svg className="account-stat-box-icon icon-return">
                <use href="#svg-return"></use>
              </svg>
            </div>

            <p className="account-stat-box-title">80%</p>

            <p className="account-stat-box-subtitle">Cursos Realizados</p>

            <p className="account-stat-box-text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi reiciendis doloribus saepe iste, sequi dolore ad fuga, tempora vel ex quidem ipsam, quae praesentium. Id, deserunt voluptatibus! Est, iusto animi?</p>
          </div>
        </div>

        <div className="widget-box">
          <div className="widget-box-actions">
            <div className="widget-box-action">

              <p className="widget-box-title">Report Mensal</p>

            </div>

            <div className="widget-box-action">
              <div className="reference-item-list">
                <div className="reference-item">
                  <div className="reference-bullet primary"></div>

                  <p className="reference-item-text">Realizado</p>
                </div>

                <div className="reference-item">
                  <div className="reference-bullet secondary"></div>

                  <p className="reference-item-text">Meta</p>
                </div>
              </div>

              <div className="form-select v2">
                <select id="ve-monthly-report-date" name="ve_monthly_report_date">
                  <option value="0">Setembro 2021</option>
                  <option value="1">Novembro 2021</option>
                </select>
                <svg className="form-select-icon icon-small-arrow">
                  <use href="#svg-small-arrow"></use>
                </svg>
              </div>
            </div>
          </div>

          <div className="widget-box-content">
            <div className="chart-wrap">
              <div className="chart">
                <div className="chartjs-size-monitor">
                  <div className="chartjs-size-monitor-expand">
                    <div className=""></div>
                  </div>
                  <div className="chartjs-size-monitor-shrink">
                    <div className=""></div>
                  </div>
                </div>
                {/* <canvas id="ve-monthly-report-chart" style="display: block; height: 360px; width: 1128px;" className="chartjs-render-monitor" width="1410" height="450"></canvas> */}
              </div>
            </div>
          </div>

          <div className="widget-box-footer">
            <div className="chart-info">

              <div className="progress-arc-wrap tiny">

                <div className="progress-arc">
                  <div className="chartjs-size-monitor">
                    <div className="chartjs-size-monitor-expand">
                      <div className=""></div>
                    </div>
                    <div className="chartjs-size-monitor-shrink">
                      <div className=""></div>
                    </div>
                  </div>
                  {/* <canvas id="ve-monthly-report-ratio-chart" style="display: block; height: 60px; width: 60px;" className="chartjs-render-monitor" width="75" height="75"></canvas> */}
                </div>

                <div className="progress-arc-info">

                  <p className="progress-arc-title">%</p>

                </div>

              </div>

              <div className="user-stats">
                <div className="user-stat big">
                  <p className="user-stat-title">1.067</p>

                  <p className="user-stat-text">t. realizado</p>
                </div>

                <div className="user-stat big">
                  <p className="user-stat-title">298</p>

                  <p className="user-stat-text">t. Meta</p>
                </div>

                <div className="user-stat big">
                  <p className="user-stat-title">34.4</p>

                  <p className="user-stat-text">avg Realizado</p>
                </div>

                <div className="user-stat big">
                  <p className="user-stat-title">9.6</p>

                  <p className="user-stat-text">avg Meta</p>
                </div>

                <div className="user-stat big">

                  <p className="user-stat-title">

                    <svg className="user-stat-title-icon positive icon-plus-small">
                      <use href="#svg-plus-small"></use>
                    </svg>

                    26.3%
                  </p>

                  <p className="user-stat-text">Realizado / jul 2021</p>

                </div>

                <div className="user-stat big">

                  <p className="user-stat-title">

                    <svg className="user-stat-title-icon negative icon-minus-small">
                      <use href="#svg-minus-small"></use>
                    </svg>

                    4.9%
                  </p>

                  <p className="user-stat-text">Meta / jul 2021</p>

                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-9-3 stretched">

          <div className="grid-column">

            <div className="widget-box">

              <p className="widget-box-title">Top Amigos</p>

              <div className="widget-box-content no-margin-top">

                <div className="table table-top-friends join-rows">
                  <div className="table-header">
                    <div className="table-header-column">
                      <p className="table-header-title">Friend</p>
                    </div>

                    <div className="table-header-column centered padded">
                      <p className="table-header-title">Produto 1</p>
                    </div>

                    <div className="table-header-column centered padded">
                      <p className="table-header-title">Produto 2</p>
                    </div>

                    <div className="table-header-column centered padded">
                      <p className="table-header-title">Produto 3</p>
                    </div>

                    <div className="table-header-column centered padded">
                      <p className="table-header-title">Produto 4</p>
                    </div>

                    <div className="table-header-column padded-left">
                      <p className="table-header-title">Desempenho X meu resultado</p>
                    </div>
                  </div>

                  <div className="table-body">
                    <div className="table-row tiny">
                      <div className="table-column">

                        <div className="user-status">

                          <a className="user-status-avatar" href="profile-timeline.html">

                            <div className="user-avatar small no-outline">

                              <div className="user-avatar-content">

                                {/* <div className="hexagon-image-30-32" data-src="img/avatar/03.jpg" style="width: 30px; height: 32px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="30" height="32"></canvas></div> */}

                              </div>
                            </div>

                          </a>

                          <p className="user-status-title"><a className="bold" href="profile-timeline.html">Hudson de Jesus</a></p>

                          <p className="user-status-text small">Amigo desde: Jan 14, 2021</p>
                        </div>

                      </div>

                      <div className="table-column centered padded">
                        <p className="table-title">1569</p>
                      </div>

                      <div className="table-column centered padded">
                        <p className="table-title">750</p>
                      </div>

                      <div className="table-column centered padded">
                        <p className="table-title">109</p>
                      </div>

                      <div className="table-column centered padded">
                        <p className="table-title">223</p>
                      </div>

                      <div className="table-column padded-left">
                        <div className="progress-stat-wrap">
                          <div className="progress-stat">
                            <div id="post-engagement-1" className="progress-stat-bar" style={{ width: '172px', height: '4px', position: 'relative' }}>
                              {/* <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas>
                          <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas> */}
                            </div>

                            <div className="bar-progress-wrap">

                              <p className="bar-progress-info medium negative">
                                <span className="bar-progress-text no-space">89
                                  <span className="bar-progress-unit">%</span>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="table-row tiny">
                      <div className="table-column">

                        <div className="user-status">

                          <a className="user-status-avatar" href="profile-timeline.html">

                            <div className="user-avatar small no-outline">

                              <div className="user-avatar-content">

                                {/* <div className="hexagon-image-30-32" data-src="img/avatar/02.jpg" style="width: 30px; height: 32px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="30" height="32"></canvas></div> */}

                              </div>
                            </div>

                          </a>

                          <p className="user-status-title"><a className="bold" href="profile-timeline.html">João das Neves</a></p>

                          <p className="user-status-text small">Amigo desde: Mai 22, 2021</p>
                        </div>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">1036</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">803</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">97</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">126</p>

                      </div>

                      <div className="table-column padded-left">

                        <div className="progress-stat-wrap">

                          <div className="progress-stat">

                            <div id="post-engagement-2" className="progress-stat-bar" style={{ width: '172px', height: '4px', position: 'relative' }}>
                              {/* <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas>
                          <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas> */}
                            </div>

                            <div className="bar-progress-wrap">

                              <p className="bar-progress-info medium negative"><span className="bar-progress-text no-space">74<span className="bar-progress-unit">%</span></span></p>

                            </div>

                          </div>

                        </div>

                      </div>

                    </div>

                    <div className="table-row tiny">
                      <div className="table-column">
                        <div className="user-status">
                          <a className="user-status-avatar" href="profile-timeline.html">
                            <div className="user-avatar small no-outline">

                              <div className="user-avatar-content">

                                {/* <div className="hexagon-image-30-32" data-src="img/avatar/05.jpg" style="width: 30px; height: 32px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="30" height="32"></canvas></div> */}

                              </div>
                            </div>
                          </a>

                          <p className="user-status-title"><a className="bold" href="profile-timeline.html">Rogério Santos</a></p>

                          <p className="user-status-text small">Amigo desde: Set 19, 2021</p>
                        </div>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">860</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">662</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">102</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">91</p>

                      </div>

                      <div className="table-column padded-left">

                        <div className="progress-stat-wrap">

                          <div className="progress-stat">

                            <div id="post-engagement-3" className="progress-stat-bar" style={{ width: '172px', height: '4px', position: 'relative' }}>
                              {/* <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas>
                          <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas> */}
                            </div>

                            <div className="bar-progress-wrap">

                              <p className="bar-progress-info medium negative"><span className="bar-progress-text no-space">53<span className="bar-progress-unit">%</span></span></p>

                            </div>

                          </div>

                        </div>

                      </div>

                    </div>

                    <div className="table-row tiny">

                      <div className="table-column">

                        <div className="user-status">

                          <a className="user-status-avatar" href="profile-timeline.html">

                            <div className="user-avatar small no-outline">

                              <div className="user-avatar-content">

                                {/* <div className="hexagon-image-30-32" data-src="img/avatar/07.jpg" style="width: 30px; height: 32px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="30" height="32"></canvas></div> */}

                              </div>
                            </div>
                          </a>

                          <p className="user-status-title"><a className="bold" href="profile-timeline.html">Sarah Rodrigues</a></p>

                          <p className="user-status-text small">Amigo desde: Ago 6, 2021</p>
                        </div>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">742</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">401</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">77</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">64</p>

                      </div>

                      <div className="table-column padded-left">

                        <div className="progress-stat-wrap">

                          <div className="progress-stat">

                            {/* <div id="post-engagement-4" className="progress-stat-bar" style="width: 172px; height: 4px; position: relative;">
                              <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas>
                          <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas>
                          </div> */}

                            <div className="bar-progress-wrap">

                              <p className="bar-progress-info medium negative"><span className="bar-progress-text no-space">48<span className="bar-progress-unit">%</span></span></p>

                            </div>

                          </div>

                        </div>

                      </div>

                    </div>

                    <div className="table-row tiny">

                      <div className="table-column">

                        <div className="user-status">

                          <a className="user-status-avatar" href="profile-timeline.html">

                            <div className="user-avatar small no-outline">

                              <div className="user-avatar-content">

                                {/* <div className="hexagon-image-30-32" data-src="img/avatar/10.jpg" style="width: 30px; height: 32px; position: relative;"><canvas style="position: absolute; top: 0px; left: 0px;" width="30" height="32"></canvas></div> */}

                              </div>
                            </div>

                          </a>

                          <p className="user-status-title"><a className="bold" href="profile-timeline.html">José da Silva</a></p>

                          <p className="user-status-text small">Amigo desde: Dez 27, 2021</p>
                        </div>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">421</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">200</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">34</p>

                      </div>

                      <div className="table-column centered padded">

                        <p className="table-title">22</p>

                      </div>

                      <div className="table-column padded-left">

                        <div className="progress-stat-wrap">

                          <div className="progress-stat">

                            {/* <div id="post-engagement-5" className="progress-stat-bar" style="width: 172px; height: 4px; position: relative;">
                              <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas>
                          <canvas style="position: absolute; top: 0px; left: 0px;" width="172" height="4"></canvas>
                          </div>
                           */}

                            <div className="bar-progress-wrap">

                              <p className="bar-progress-info medium negative"><span className="bar-progress-text no-space">21<span className="bar-progress-unit">%</span></span></p>

                            </div>

                          </div>

                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="grid-column">

            <div className="widget-box">
              <div className="widget-box-actions">
                <div className="widget-box-action">
                  <p className="widget-box-title">Vendas realizadas</p>
                </div>
              </div>

              <div className="widget-box-content">

                <div className="progress-arc-wrap">

                  <div className="progress-arc">
                    <div className="chartjs-size-monitor">
                      <div className="chartjs-size-monitor-expand">
                        <div className=""></div>
                      </div>
                      <div className="chartjs-size-monitor-shrink">
                        <div className=""></div>
                      </div>
                    </div>
                    {/* <canvas id="engagements-chart" style="display: block; height: 140px; width: 140px;" className="chartjs-render-monitor" width="175" height="175"></canvas> */}
                  </div>

                  <div className="progress-arc-info">
                    <p className="progress-arc-title">28.8K</p>

                    <p className="progress-arc-text">Vendas realizadas</p>
                  </div>
                </div>

                <div className="user-stats reference">

                  <div className="user-stat big">
                    <div className="reference-bullet secondary"></div>

                    <p className="user-stat-title">18.3K</p>

                    <p className="user-stat-text">Produto 1</p>
                  </div>

                  <div className="user-stat big">
                    <div className="reference-bullet primary"></div>

                    <p className="user-stat-title">5.2K</p>

                    <p className="user-stat-text">Produto 2</p>
                  </div>
                </div>

                <div className="user-stats reference">
                  <div className="user-stat big">
                    <div className="reference-bullet blue"></div>
                    <p className="user-stat-title">1.4K</p>

                    <p className="user-stat-text">Produto 3</p>
                  </div>

                  <div className="user-stat big">
                    <div className="reference-bullet light-blue"></div>

                    <p className="user-stat-title">3.9K</p>

                    <p className="user-stat-text">Produto 4</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-3-9 stretched">

          <div className="grid-column">

            <div className="widget-box">

              <p className="widget-box-title">Vendas por Top países</p>

              <div className="widget-box-content">
                <div className="country-stat-list">
                  <div className="country-stat with-progress">
                    <img className="country-stat-image" src="img/flag/usa.png" alt="flag-usa" />

                    <div className="progress-stat small">

                      <div className="bar-progress-wrap">

                        <p className="bar-progress-info medium negative regular">Estados Unidos<span className="bar-progress-text no-space">25362</span></p>

                      </div>
                    </div>

                  </div>

                  <div className="country-stat with-progress">
                    <img className="country-stat-image" src="img/flag/india.png" alt="flag-india" />

                    <div className="progress-stat small">

                      <div className="bar-progress-wrap">

                        <p className="bar-progress-info medium negative regular">India<span className="bar-progress-text no-space">18201</span></p>

                      </div>

                    </div>

                  </div>

                  <div className="country-stat with-progress">
                    <img className="country-stat-image" src="img/flag/brazil.png" alt="flag-brazil" />

                    <div className="progress-stat small">
                      <div className="bar-progress-wrap">
                        <p className="bar-progress-info medium negative regular">Brasil<span className="bar-progress-text no-space">9567</span></p>
                      </div>
                    </div>
                  </div>

                  <div className="country-stat">

                    <img className="country-stat-image" src="img/flag/canada.png" alt="flag-canada" />

                    <p className="country-stat-title">Canada</p>

                    <p className="country-stat-text">8.922</p>

                  </div>

                  <div className="country-stat">

                    <img className="country-stat-image" src="img/flag/russia.png" alt="flag-russia" />

                    <p className="country-stat-title">Russia</p>

                    <p className="country-stat-text">6.303</p>

                  </div>

                  <div className="country-stat">

                    <img className="country-stat-image" src="img/flag/turkey.png" alt="flag-turkey" />

                    <p className="country-stat-title">Turquia</p>

                    <p className="country-stat-text">6.278</p>

                  </div>

                  <div className="country-stat">

                    <img className="country-stat-image" src="img/flag/france.png" alt="flag-france" />

                    <p className="country-stat-title">França</p>

                    <p className="country-stat-text">4.520</p>

                  </div>

                  <div className="country-stat">

                    <img className="country-stat-image" src="img/flag/germany.png" alt="flag-germany" />

                    <p className="country-stat-title">Alemanha</p>

                    <p className="country-stat-text">3.225</p>

                  </div>

                  <div className="country-stat">

                    <img className="country-stat-image" src="img/flag/argentina.png" alt="flag-argentina" />

                    <p className="country-stat-title">Argentina</p>

                    <p className="country-stat-text">1.744</p>

                  </div>

                </div>
              </div>

            </div>
          </div>

          <div className="grid-column">
            <div className="widget-box">
              <div className="widget-box-actions">
                <div className="widget-box-action">

                  <p className="widget-box-title">Vendas Mapa mundo</p>

                </div>

                <div className="widget-box-action">
                  <div className="form-select v2">
                    <select id="visits-map-date" name="visits_map_date">
                      <option value="0">Setembro 2021</option>
                      <option value="1">Novembro 2021</option>
                    </select>
                    <svg className="form-select-icon icon-small-arrow">
                      <use href="#svg-small-arrow"></use>
                    </svg>
                  </div>
                </div>
              </div>

              <div className="widget-box-content">
                <img className="full-width-image" src="img/flag/map.png" alt="map" />
              </div>

            </div>
          </div>
        </div>

        <div className="widget-box">

          <div className="widget-box-actions">
            <div className="widget-box-action">

              <p className="widget-box-title">Anual Report</p>

            </div>

            <div className="widget-box-action">

              <div className="reference-item-list">

                <div className="reference-item">

                  <div className="reference-bullet primary"></div>

                  <p className="reference-item-text">Resultado</p>

                </div>

                <div className="reference-item">

                  <div className="reference-bullet blue"></div>

                  <p className="reference-item-text">Meta</p>

                </div>

              </div>

              <div className="form-select v2">
                <select id="rc-yearly-report-date" name="rc_yearly_report_date">
                  <option value="0">Jan - Dez 2020</option>
                  <option value="1">Jan - Dez 2021</option>
                </select>
                <svg className="form-select-icon icon-small-arrow">
                  <use href="#svg-small-arrow"></use>
                </svg>
              </div>
            </div>
          </div>

          <div className="widget-box-content">
            {/* <div className="chart-wrap">
            <div className="chart"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
              <canvas id="rc-yearly-report-chart" style="display: block; height: 360px; width: 1128px;" className="chartjs-render-monitor" width="1410" height="450"></canvas>
            </div>
          </div> */}
          </div>

        </div>
      </div>

    </Template>);
}
