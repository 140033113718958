import axios from 'axios'
import { useCallback, useMemo, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import InputText from '../../../../../components/Forms/Input'
import PageLoaderComponent from '../../../../../components/PageLoader'
import { useCart } from '../../../../../contexts/CartContext'
import { useModal } from '../../../../../contexts/ModalContext'
import api from '../../../../../services/api'
import Template from '../../../../../template'
import { currency } from '../../../../../utils/format'

export default function Checkout (props) {
  const [cartData, setCartData] = useState()
  const inputRef = useRef({ cep: '', referencia: '', numero: '' })
  const [address, setAddress] = useState()
  const { openModal } = useModal()
  const navigate = useNavigate()

  const { cart } = useCart()

  const valorCarrinho = useMemo(() => {
    // const valueTotal = cart.reduce((a, b) => a + b.item.skus[0].price * b.amount, 0)
    // return valueTotal
    return 0
  }, [cart])

  const frete = useMemo(() => {
    return 0
  }, [cart])

  const subtotal = useMemo(() => {
    return valorCarrinho + frete
  }, [valorCarrinho, frete])

  const handleSaveAddress = useCallback(async (e) => {
    e.preventDefault()
    try {
      await api.post('/me/address', { ...address })
      toast.success('Os dados foram atualizado com sucesso', { theme: 'colored' })
    } catch (e) {
      toast.error('Ocorreu um erro ao tentar conectar com o servidor', { theme: 'colored' })
    }
  }, [address])

  async function handleSearchCEP () {
    const cep = inputRef.current.cep.value
    try {
      const res = await axios.get(`http://viacep.com.br/ws/${cep}/json/ `)
      setAddress(res.data)
    } catch (e) {
      toast.error('Ocorreu um erro, tente novamente !', { theme: 'colored' })
    }
  }

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (!cart) return
  //       const response = await api.post('/order', cart)
  //       setCartData(response.data)
  //     } catch (err) {
  //       switch (err.response.status) {
  //         case 500:
  //           toast.error("Correu um erro interno, por favor aguarde e tente novamente", { theme: 'colored' })
  //           // navigate('/home')
  //           return
  //         case 403:
  //           toast.error("Sua sessão expirou", { theme: 'colored' })
  //           navigate('/')
  //           return
  //       }
  //     }
  //   })()
  // }, [cart])

  if (!cart) return <PageLoaderComponent />

  return (
    <Template>
      <div className="grid grid-8-4 small-space">
        <div className="grid-column">
          <div className="widget-box">
            <p className="widget-box-title">Detalhes do pagamento</p>

            <div className="widget-box-content">
              <form className="form">
                <div className="form-row split">
                  <div className="form-item">
                    <InputText label="Bairro" disabled defaultValue={address && address.bairro} />
                  </div>

                  <div className="form-item">
                    <InputText label="Cidade" disabled defaultValue={address && address.localidade} />
                  </div>
                </div>

                <div className="form-row split">
                  <div className="form-item">
                    <InputText label="Estado" disabled defaultValue={address && address.uf} />
                  </div>

                  <div className="form-item">
                    <InputText label="Cep" onBlur={handleSearchCEP} ref={el => inputRef.current.cep = el} />
                  </div>
                </div>

                <div className="form-row split">
                  <div className="form-item">
                    <InputText label="Logradouro" disabled defaultValue={address && address.logradouro} />
                  </div>

                  <div className="form-item">
                    <InputText label="Complemento" disabled defaultValue={address && address.complemento} />
                  </div>
                </div>

                <div className="form-row split">
                  <div className="form-item">
                    <InputText label="Numero" ref={el => inputRef.current.numero = el} />
                  </div>

                  <div className="form-item">
                    <InputText label="Referencia" ref={el => inputRef.current.referencia = el} />
                  </div>
                </div>

                <button className="button primary" style={{ marginTop: '16px' }} onClick={handleSaveAddress}>Savar endereco</button>
              </form>
            </div>
          </div>
        </div>

        <div className="grid-column">
          <div className="sidebar-box">
            <p className="sidebar-box-title">Resumo do pedido</p>

            <div className="sidebar-box-items">
              <div className="totals-line-list separator-bottom">
                {cart.items.map((item) => (
                  <div className="totals-line">
                    <div className="totals-line-info">
                      <p className="totals-line-title"><span className="bold">{item.sku.Product.name}</span></p>

                      <p className="totals-line-text">{currency(item.sku.price)} x {item.amount}</p>
                    </div>

                    <p className="price-title"><span className="currency"></span> {currency(item.total)}</p>
                  </div>
                ))}

              </div>

              <div className="totals-line-list separator-bottom">
                <div className="totals-line">
                  <p className="totals-line-title">Total do carrinho</p>

                  <p className="price-title"><span className="currency"></span> {currency(cart.subtotal)}</p>
                </div>

                <div className="totals-line">
                  <p className="totals-line-title">Frete</p>

                  <p className="price-title"><span className="currency"></span>{currency(cart.delivery)}</p>
                </div>

                <div className="totals-line">
                  <p className="totals-line-title">Total</p>

                  <p className="price-title"><span className="currency"></span> {currency(cart.total)}</p>
                </div>
              </div>

              <p className="price-title big separator-bottom"> {currency(cart.total)} <span className="currency">Moedas</span></p>
            </div>

            <div className="sidebar-box-items">
              <button type="button" className="button primary" onClick={(e) => {
                e.preventDefault()
                openModal({
                  header: 'Sem pontos',
                  body: (<form className="form">
                    <div className="form-row">
                      <p>Infelizmente sua pontuação não é suficiente</p>
                    </div>

                    <div className="popup-box-actions medium void">
                      <Link to="/marketplace" className="popup-box-action full button secondary">Continuar resgatando pontos</Link>
                    </div>
                  </form>)
                })
              }}>Fazer pedido!</button>
            </div>
          </div>
        </div>
      </div>
    </Template >
  )
}
