import styled from 'styled-components'
import Grid from './Grid'

export const Container = styled(Grid)`
  justify-content: flex-start;

  > :last-child {
    flex: 25%;
  }
`

export const Disclaimer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  gap: 8px;
  text-align: center;
  width: 100%;
`

export const Image = styled.img`
  filter: grayscale(1);
  max-width: 60px;
`

export const Graph = styled.div<{ value: string }>`
  --primary: #615dfa;
  --secondary: #23d2e2;
  aspect-ratio: 1;
  background: conic-gradient(var(--primary) 0%, var(--secondary) ${props => `${props.value}%`}, #e8e8ef ${props => `${props.value}.1%`}, #e8e8ef 100%);
  border-radius: 50%;
  margin: 0 auto;
  max-width: 140px;
  position: relative;
  width: 100%;

  &:after {
    align-items: center;
    aspect-ratio: 1;
    background-color: #ffffff;
    border-radius: 50%;
    content: "${props => `${props.value}%`}";
    display: flex;
    font-family: Rajdhani, sans-serif;
    font-size: 2rem;
    font-weight: bold;
    justify-content: center;
    margin: 8px;
    position: absolute;
    width: calc(100% - 16px);
  }
`

export const Break = styled.div`
  flex-basis: 100%;
  height: 0;
  padding: 0 !important;
`

export const Group = styled.div.attrs(() => ({ className: 'group' }))`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  height: 100%;
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow: 0 none !important;

  & > div {
    padding: 32px 28px 100px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 40px 0 rgba(94, 92, 154, .06);
    position: relative;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  flex: 1 100%;
  gap: 16px;
  padding: 0 !important;

  & > button {
    flex: 0 0;
    padding: 0 32px;
  }
`
