import { ReactElement, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import InputText from '../../../components/Forms/InputText'
import api from '../../../services/api'

function RecoveryPassForm (): ReactElement {
  const [email, setEmail] = useState('')
  const handleRedefinePassword = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault()
      try {
        const result = await api.post('session/recovery', {
          email
        })
        toast.success(result.data.message, {
          theme: 'colored'
        })

        setEmail('')
      } catch (err: any) {
        toast.error(err.response.data.message, {
          theme: 'colored'
        })
      }
    },
    [email]
  )
  return (
    <form className="form">
      <div className="form-row">
        <div className="form-item">
          <div className="form-input small mb-3">
            <InputText
              value={email}
              type="text"
              id="login-username"
              className="form__input__focus"
              label="Email ou CPF"
              onChange={(email: string) => setEmail(email)}
              required
            />
          </div>
          <div className="mb-3">
            <button
              className="popup-box-action full button secondary"
              onClick={async (e) => await handleRedefinePassword(e)}
            >
              Enviar
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default RecoveryPassForm
