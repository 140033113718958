import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { currency } from '../../../../utils/format'
import { CartHeaderItemProps } from './props'
import * as S from './styles'

function CartHeaderItem (props: CartHeaderItemProps): ReactElement {
  return <S.Container>
    <div className="dropdown-box-list-item">
      <div className="cart-item-preview">
        <Link className="cart-item-preview-image" to={`/product/${String(props.sku.code)}`}>
          <figure className="picture medium round liquid" style={{ background: `url(${String(props.sku.images[0].path)}) center center / cover no-repeat` }}>
            <img src={String(props.sku.images[0].path)} alt="item-01" style={{ display: 'none' }} />
          </figure>
        </Link>

        <p className="cart-item-preview-title"><Link to={`/product/${String(props.sku.Product.id)}`}>{props.sku.Product.name}</Link></p>

        <p className="cart-item-preview-price"><span className="highlighted">$</span> {currency(props.sku.price)} x {props.amount}</p>

      </div>
    </div>
  </S.Container>
}

export default CartHeaderItem
