const svgWrap = document.createElement('div');
const svgData = `
  <!-- SVG FACEBOOK -->
  <svg style="display: none;">
    <symbol id="svg-facebook" viewBox="0 0 18 18" preserveAspectRatio="xMinYMin meet">
      <path d="M16.313,18h-4.504v-6.192h3.087V8.671h-3.087V7.135V6.831V6.814l0,0c0.01-0.328,0.277-0.591,0.607-0.591h0.067h1.113h1.62V3.086h-2.733l0,0l-0.009,0.018h-0.092c-2.051,0-3.712,1.661-3.712,3.711v0.911v0.945H6.191v3.137h2.479V18H1.687C0.755,18,0,17.242,0,16.313V1.686C0,0.754,0.755,0,1.687,0h14.626C17.244,0,18,0.754,18,1.686v14.627C18,17.242,17.244,18,16.313,18z"/>
    </symbol>
  </svg>
  <!-- /SVG FACEBOOK -->

  <!-- SVG TWITTER -->
  <svg style="display: none;">
    <symbol id="svg-twitter" viewBox="0 0 18 18" preserveAspectRatio="xMinYMin meet">
      <path d="M18,3.617c-0.675,0.322-1.351,0.43-2.139,0.537c0.788-0.43,1.351-1.076,1.576-1.937c-0.675,0.43-1.463,0.646-2.362,0.861C14.399,2.431,13.388,2,12.375,2c-1.912,0-3.601,1.617-3.601,3.554c0,0.323,0,0.538,0.113,0.754C5.851,6.2,3.038,4.8,1.238,2.646C0.9,3.185,0.788,3.724,0.788,4.478c0,1.184,0.675,2.261,1.688,2.907c-0.563,0-1.125-0.216-1.688-0.431l0,0c0,1.723,1.237,3.122,2.925,3.446c-0.338,0.107-0.675,0.107-1.013,0.107c-0.225,0-0.45,0-0.675-0.107c0.45,1.399,1.8,2.476,3.487,2.476C4.274,13.846,2.7,14.384,0.9,14.384c-0.338,0-0.563,0-0.9,0C1.688,15.354,3.601,16,5.625,16c6.75,0,10.462-5.385,10.462-10.015c0-0.108,0-0.323,0-0.431C16.875,5.016,17.549,4.371,18,3.617z"/>
    </symbol>
  </svg>
  <!-- /SVG TWITTER -->

  <!-- SVG TWITCH -->
  <svg style="display: none;">
    <symbol id="svg-twitch" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
      <path d="M6.179,0L0,17.964V87.81h24.714V100h15.789L52.86,87.81h18.993L100,60.527V0H6.179z M90.39,57.645L74.828,72.504H49.885L37.299,84.703V72.504H15.561V9.094H90.39V57.645z M49.885,27.051h-9.382v27.27h9.382V27.051z M74.828,27.051h-9.382v27.27h9.382V27.051z"/>
    </symbol>
  </svg>
  <!-- /SVG TWITCH -->

  <!-- SVG YOUTUBE -->
  <svg style="display: none;">
    <symbol id="svg-youtube" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
      <path d="M97.911,24.557c-1.149-4.549-4.538-8.13-8.842-9.347C81.271,13,49.999,13,49.999,13s-31.271,0-39.07,2.21c-4.302,1.216-7.69,4.798-8.84,9.347C0,32.8,0,50.001,0,50.001s0,17.2,2.089,25.443c1.15,4.549,4.538,8.132,8.84,9.348C18.729,87,49.999,87,49.999,87s31.271,0,39.07-2.208c4.304-1.216,7.692-4.799,8.842-9.348C100,67.201,100,50.001,100,50.001S100,32.8,97.911,24.557z M39.999,65.858V34.144L65.98,50.001L39.999,65.858z"/>
    </symbol>
  </svg>
  <!-- /SVG YOUTUBE -->

  <!-- SVG INSTAGRAM -->
  <svg style="display: none;">
    <symbol id="svg-instagram" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
      <path d="M72.402,0H27.595C12.379,0,0,12.381,0,27.597v44.809C0,87.619,12.379,100,27.595,100h44.807C87.621,100,100,87.619,100,72.406V27.597C100,12.381,87.621,0,72.402,0z M88.691,71.078c0,9.711-7.902,17.613-17.615,17.613H28.923c-9.712,0-17.613-7.902-17.613-17.613V28.926c0-9.715,7.901-17.618,17.613-17.618h42.153c9.713,0,17.615,7.903,17.615,17.618V71.078z M50.001,26.779c-12.804,0-23.22,10.426-23.22,23.22c0,12.804,10.416,23.22,23.22,23.22c12.801,0,23.217-10.416,23.217-23.22C73.219,37.205,62.803,26.779,50.001,26.779z M50.001,63.689c-7.548,0-13.688-6.139-13.688-13.691c0-7.545,6.14-13.684,13.688-13.684c7.545,0,13.686,6.139,13.686,13.684C63.688,57.551,57.547,63.689,50.001,63.689z M75.658,17.864c-1.711,0-4.383,0.724-5.711,2.056c-1.332,1.328-2.098,3.178-2.098,5.065c0,1.882,0.766,3.727,2.098,5.063c1.326,1.325,3.172,2.098,5.051,2.098c1.885,0,3.723-0.772,5.055-2.098c1.332-1.336,2.094-3.184,2.094-5.063c0-1.888-0.762-3.737-2.094-5.065C78.729,18.587,77.373,17.864,75.658,17.864z"/>
    </symbol>
  </svg>
  <!-- /SVG INSTAGRAM -->

  <!-- SVG DISCORD -->
  <svg style="display: none;">
    <symbol id="svg-discord" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
      <path d="M87.671,23.75C75.993,15.223,64.855,15,63.707,15c-0.061,0-0.093,0-0.093,0l-1.2,1.332c14.556,4.332,21.32,10.582,21.32,10.582c-8.906-4.749-17.639-7.083-25.771-8c-2.7-0.291-5.353-0.422-7.926-0.422c-3.287,0-6.437,0.216-9.371,0.591c-0.512,0-0.941,0.084-1.456,0.165c-2.994,0.252-10.273,1.334-19.434,5.249c-3.167,1.417-5.052,2.417-5.052,2.417s7.107-6.583,22.518-10.915L36.387,15c0,0-0.032,0-0.098,0c-1.144,0-12.283,0.223-23.96,8.75C12.329,23.75,0,45.498,0,72.333c0,0,7.19,12.081,26.114,12.667c0,0,3.166-3.752,5.734-6.919c-10.873-3.168-14.981-9.831-14.981-9.831s0.854,0.582,2.397,1.414l0.343,0.25l0.769,0.419c2.14,1.163,4.282,2.084,6.251,2.833c3.511,1.334,7.706,2.663,12.584,3.585c3.51,0.633,7.349,1.047,11.451,1.047c3.408,0,6.999-0.284,10.728-0.964c4.021-0.67,8.129-1.836,12.415-3.585c2.991-1.083,6.333-2.665,9.845-4.916c0,0-4.287,6.831-15.5,9.915C70.717,81.414,73.805,85,73.805,85C92.722,84.414,100,72.333,100,72.333C100,45.498,87.671,23.75,87.671,23.75z M34.869,66.745c-5.046,0-9.136-4.056-9.136-9.059c0-5.002,4.09-9.059,9.136-9.059c5.045,0,9.137,4.057,9.137,9.059C44.006,62.689,39.914,66.745,34.869,66.745z M67.758,66.745c-5.045,0-9.135-4.056-9.135-9.059c0-5.002,4.09-9.059,9.135-9.059c5.046,0,9.137,4.057,9.137,9.059C76.895,62.689,72.804,66.745,67.758,66.745z"/>
    </symbol>
  </svg>
  <!-- /SVG DISCORD -->

  <!-- SVG PATREON -->
  <svg style="display: none;">
    <symbol id="svg-patreon" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
      <path d="M0,100h16.667V0H0V100z M62.5,0C41.79,0,25,16.788,25,37.5c0,20.71,16.789,37.503,37.5,37.503c20.711,0,37.5-16.793,37.5-37.503C100,16.788,83.211,0,62.5,0z"/>
    </symbol>
  </svg>
  <!-- /SVG PATREON -->

  <!-- SVG GOOGLE -->
  <svg style="display: none;">
    <symbol id="svg-google" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
      <path d="M100,47.82H89.963v9.785H79.927V47.82H69.89v-9.784h10.037v-9.784h10.036v9.784H100V47.82z M32.358,83C14.487,83,0,68.226,0,50c0-18.226,14.487-33,32.358-33c8.594,0,16.384,3.437,22.173,9.013l-0.6,0.612L45,35.734c-3.349-3.087-7.773-4.984-12.642-4.984c-10.426,0-18.877,8.619-18.877,19.25c0,10.632,8.451,19.25,18.877,19.25c8.046,0,14.898-5.143,17.614-12.375l-15.21,0.729V42.927l28.606,0.199v1.375v12.375v2.516C59.4,73.038,47.028,83,32.358,83z"/>
    </symbol>
  </svg>
  <!-- /SVG GOOGLE -->

  <!-- SVG BEHANCE -->
  <svg style="display: none;">
    <symbol id="svg-behance" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
      <path d="M99.827,61.223H68.064c0,3.648,0.755,10.949,10.776,10.949s9.643-6.916,9.643-6.916H99.26c0,18.249-20.986,16.713-20.986,16.713c-24.012,0-23.822-20.746-23.822-20.746
    c0-5.668-0.095-27.087,24.39-27.087S99.827,61.223,99.827,61.223z M78.273,43.356c-9.714,0-10.209,9.221-10.209,9.221h19.852C87.916,52.577,87.987,43.356,78.273,43.356z M64.661,21.458h26.091v8.067H64.661V21.458z M35.166,80.799v0.016H0V18h34.032c0,0,0.024,0,0.062,0h1.072v0.054c3.628,0.299,14.748,2.292,14.748,15.506c0,0,1.144,10.259-7.661,12.951
    c4.671,0.871,9.93,4.394,9.93,15.863C52.183,62.373,53.762,80.43,35.166,80.799z M33.465,29.525H14.748V42.78h18.15c0,0,3.97-1.057,3.97-6.915C36.868,30.006,33.465,29.525,33.465,29.525z M34.032,53.154H14.748V69.29h18.717c0,0,5.672-1.249,5.672-8.067C39.137,54.402,34.032,53.154,34.032,53.154z"/>
    </symbol>
  </svg>
  <!-- /SVG BEHANCE -->

  <!-- SVG DEVIANTART -->
  <svg style="display: none;">
    <symbol id="svg-deviantart" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
      <path d="M83,80.77H48.107L37.204,100H17V82.052l21.362-37.18H17V20.513h35.356L64.143,0H83v19.231L61.918,56.41H83V80.77z"/>
    </symbol>
  </svg>
  <!-- /SVG DEVIANTART -->

  <!-- SVG ARTSTATION -->
  <svg style="display: none;">
    <symbol id="svg-artstation" viewBox="0 0 100 100" preserveAspectRatio="xMinYMin meet">
      <path d="M0,77.114l8.412,16.545c1.7,3.754,5.102,6.341,8.981,6.341h56.038l-11.62-22.886H0z M98.391,71.002L65.502,6.122C63.797,2.47,60.491,0,56.617,0H39.224l50.755,99.889l8.035-15.79c1.515-3.001,1.986-4.29,1.986-6.877C100,74.967,99.432,72.819,98.391,71.002z M31,16.217L8.315,60.799h45.276L31,16.217z"/>
    </symbol>
  </svg>
  <!-- /SVG ARTSTATION -->

  <!-- SVG CROSS -->
  <svg style="display: none;">
    <symbol id="svg-cross" viewBox="0 0 12 12" preserveAspectRatio="xMinYMin meet">
      <path d="M12,9.6L9.6,12L6,8.399L2.4,12L0,9.6L3.6,6L0,2.4L2.4,0L6,3.6L9.6,0L12,2.4L8.399,6L12,9.6z"/>
    </symbol>
  </svg>
  <!-- /SVG CROSS -->

  <!-- SVG LOGO VIKINGER -->
  <svg style="display: none;">
    <symbol id="svg-logo-vikinger" viewBox="0 0 28 40" preserveAspectRatio="xMinYMin meet">
      <path d="M27.18,24.038c-0.141-0.095-0.244-0.146-0.244-0.146l-0.005-0.049C25.489,22.783,23.103,22,23.103,22c1.542,0,2.582-0.563,3.501-1.334c-0.049-0.055,0.7-0.666,0.7-0.666c0.146-8.251-4.477-12.745-7.001-14.667C18.15,9.564,16.802,14,16.802,14c-0.219-4.426,0.243-8.072,0.7-10.667c-0.85-0.452-1.956-0.698-2.296-1.537C14.865,0.957,14.015,0,14.015,0L14,0.014L13.985,0c0,0-0.85,0.957-1.19,1.796c-0.34,0.839-1.445,1.085-2.295,1.537C10.957,5.928,11.418,9.574,11.2,14c0,0-1.349-4.436-3.501-8.667C5.174,7.255,0.551,11.749,0.697,20c0,0,0.75,0.611,0.701,0.666C2.316,21.437,3.357,22,4.898,22c0,0-2.387,0.783-3.829,1.844l-0.005,0.049c0,0-0.104,0.051-0.244,0.146c-0.48,0.397-0.806,0.828-0.819,1.269c-0.023,0.521,0.263,1.181,1.233,1.973c0,0,0.136,9.259,9.69,11.29c0,0,0.212,0.815,0.975,1.431L14,38l2.102,2c0.763-0.615,0.975-1.431,0.975-1.431c9.555-2.031,9.689-11.29,9.689-11.29
      c0.971-0.792,1.256-1.451,1.233-1.973C27.986,24.866,27.659,24.436,27.18,24.038z M4.198,26c2.362,0.121,3.517,1.473,5.602,4c0.799,0.969,2.059,0.83,2.059,0.83L11.899,34C5.249,34,4.198,26,4.198,26z M14,28.162l-2.97-2.828l2.101-2.001l-2.101-2l2.101-2l-2.101-2L14,14.505l2.972,2.828l-2.102,2l2.102,2l-2.102,2l2.102,2.001L14,28.162z M16.102,34l0.041-3.17
      c0,0,1.26,0.139,2.059-0.83c2.085-2.527,3.239-3.879,5.602-4C23.803,26,22.752,34,16.102,34z M13.3,26h1.4v-1.333h-1.4V26z M13.3,22h1.4v-1.334h-1.4V22z M13.3,18h1.4v-1.333h-1.4V18z"/>
    </symbol>
  </svg>
  <!-- /SVG LOGO VIKINGER -->

  <!-- SVG GRID -->
  <svg style="display: none;">
    <symbol id="svg-grid" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M7,0H2C0.896,0,0,0.895,0,2v5c0,1.104,0.896,2,2,2h5c1.104,0,2-0.896,2-2V2C9,0.895,8.104,0,7,0z M7,7H2V2h5V7z M7,11H2c-1.104,0-2,0.896-2,1.999v5C0,19.104,0.896,20,2,20h5c1.104,0,2-0.896,2-2.001v-5C9,11.896,8.104,11,7,11z M7,17.999H2v-5h5V17.999z M18,0h-5c-1.104,0-2,0.895-2,2v5c0,1.104,0.896,2,2,2h5c1.104,0,2-0.896,2-2V2C20,0.895,19.104,0,18,0z M18,7h-5V2h5V7z M18,11h-5c-1.104,0-2,0.896-2,1.999v5C11,19.104,11.896,20,13,20h5c1.104,0,2-0.896,2-2.001v-5C20,11.896,19.104,11,18,11z M18,17.999h-5v-5h5V17.999z"/>
    </symbol>
  </svg>
  <!-- /SVG GRID -->

  <!-- SVG DOTS -->
  <svg style="display: none;">
    <symbol id="svg-dots" viewBox="0 0 10 2" preserveAspectRatio="xMinYMin meet">
      <path d="M0,2h2V0H0V2z M4,2h2V0H4V2z M8,0v2h2V0H8z"/>
    </symbol>
  </svg>
  <!-- /SVG DOTS -->

  <!-- SVG MAGNIFYING GLASS -->
  <svg style="display: none;">
    <symbol id="svg-magnifying-glass" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M8,2c3.309,0,6,2.691,6,6s-2.691,6-6,6s-6-2.691-6-6S4.691,2,8,2 M8,0C3.582,0,0,3.582,0,8c0,4.418,3.582,8,8,8c4.418,0,8-3.582,8-8C16,3.582,12.418,0,8,0L8,0z"/>
      <path d="M19.171,15.168l-3.134-3.134c-0.309,0.613-0.679,1.19-1.113,1.714l2.833,2.834c0.323,0.324,0.323,0.851,0,1.175C17.545,17.969,17.298,18,17.17,18c-0.129,0-0.376-0.031-0.588-0.243l-2.834-2.833c-0.523,0.435-1.101,0.805-1.714,1.113l3.134,3.134C15.721,19.724,16.445,20,17.169,20c0.725,0,1.449-0.276,2.002-0.829C20.276,18.065,20.276,16.273,19.171,15.168z"/>
    </symbol>
  </svg>
  <!-- /SVG MAGNIFYING GLASS -->

  <!-- SVG COMMENT -->
  <svg style="display: none;">
    <symbol id="svg-comment" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M15,2c1.654,0,3,1.257,3,2.803v6.404c0,1.546-1.346,2.803-3,2.803H7.98H7.508l-0.422,0.211L2,16.764v-5.557V10V4.803C2,3.257,3.346,2,5,2H15 M15,0H5C2.238,0,0,2.15,0,4.803V10v1.207V20l7.98-3.99H15c2.762,0,5-2.15,5-4.803V4.803C20,2.15,17.762,0,15,0L15,0z"/>
      <g>
        <path d="M14,5H6C5.447,5,5,5.448,5,6c0,0.553,0.447,1,1,1h8c0.553,0,1-0.447,1-1C15,5.448,14.553,5,14,5z M10,9H6c-0.553,0-1,0.447-1,1s0.447,1,1,1h4c0.553,0,1-0.447,1-1S10.553,9,10,9z"/>
      </g>
    </symbol>
  </svg>
  <!-- /SVG COMMENT -->

  <!-- SVG THUMBS UP -->
  <svg style="display: none;">
    <symbol id="svg-thumbs-up" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M15.896,19.999c-0.01,0-0.021,0-0.03,0H2.826C1.268,19.999,0,18.743,0,17.201v-6.302c0-1.543,1.269-2.797,2.826-2.797h2.096l3.391-7.515C8.473,0.229,8.83,0,9.223,0c2.063,0,3.742,1.659,3.742,3.698l0.026,2.3h4.996c0.152-0.003-0.149-0.023,0,0c0.753,0.111,1.962,0.245,1.999,1c0.037,0.757-0.011,2.143,0,3.003l-1.295,7.624C18.483,18.982,17.284,19.999,15.896,19.999z M6.995,18.005h8.881c0.003,0,0.007,0,0.01,0c0.418,0,0.77-0.292,0.83-0.684l1.262-8.106c0.031-0.205-0.02-0.412-0.145-0.583c-0.132-0.178-0.621-0.601-0.845-0.634c-0.045-0.008,0.045-0.006,0,0h-4.996c-0.553,0-1-1.451-1-2.001l-0.026-2.3c0-0.733-0.477-1.36-1.144-1.6l-2.828,6.9V18.005z M2.997,10c-0.456,0-0.999,0.457-0.999,0.899v6.302c0,0.442,0.371,0.804,0.827,0.804h2.17V10H2.997z"/>
    </symbol>
  </svg>
  <!-- /SVG THUMBS UP -->

  <!-- SVG FRIEND -->
  <svg style="display: none;">
    <symbol id="svg-friend" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,0C4.477,0,0,4.478,0,10c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10C20,4.478,15.522,0,10,0z M10,18c-4.418,0-8-3.582-8-8c0-4.418,3.582-8,8-8s8,3.582,8,8
        C18,14.418,14.418,18,10,18z M10,13c-1.305,0-2.403-0.838-2.816-2H5.101c0.464,2.282,2.48,4,4.899,4s4.436-1.718,4.899-4h-2.083C12.403,12.162,11.305,13,10,13z M7,7H5v2h2V7z M13,9h2V7h-2V9z"/>
    </symbol>
  </svg>
  <!-- /SVG FRIEND -->

  <!-- SVG GROUP -->
  <svg style="display: none;">
    <symbol id="svg-group" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,2c1.654,0,3,1.346,3,3s-1.346,3-3,3S7,6.654,7,5S8.346,2,10,2 M10,0C7.238,0,5,2.238,5,5s2.238,5,5,5s5-2.238,5-5S12.762,0,10,0L10,0z"/>
      <path d="M3,20v-2c0-2.205,1.794-4,4-4h6c2.206,0,4,1.795,4,4v2h2v-2c0-3.313-2.687-6-6-6H7c-3.313,0-6,2.687-6,6v2H3z"/>
      <path d="M20,8.05c-1.13,0-2.05-0.919-2.05-2.05S18.87,3.95,20,3.95v-2c-2.236,0-4.05,1.813-4.05,4.05s1.813,4.05,4.05,4.05V8.05z"/>
      <path d="M0,8.05C1,8.05,2.05,7.13,2.05,6S1,3.95,0,3.95v-2c2,0,4.05,1.813,4.05,4.05S2,10.05,0,10.05V8.05z"/>
    </symbol>
  </svg>
  <!-- /SVG GROUP -->

  <!-- SVG MARKETPLACE -->
  <svg style="display: none;">
    <symbol id="svg-marketplace" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M18,6h-0.5L16,0h-4v2h2.438l1,4H4.562l1-4H8V0H4L2.5,6H2C0.896,6,0,6.896,0,8v2c0,0.738,0.405,1.376,1,1.723v0.276C1,12,1,12,1,12l1,6c0,1.104,0.896,2,2,2h12c1.104,0,2-0.896,2-2l1-6v-0.277c0.596-0.347,1-0.984,1-1.723V8C20,6.896,19.104,6,18,6z M2,8h16v2h-0.999H17H3H2.999H2V8z M16.027,17.671L16,17.834V18H4v-0.165l-0.027-0.164L3.028,12h13.945L16.027,17.671z"/>
    </symbol>
  </svg>
  <!-- /SVG MARKETPLACE -->

  <!-- SVG ADD FRIEND -->
  <svg style="display: none;">
    <symbol id="svg-add-friend" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,13c-1.305,0-2.403-0.838-2.816-2H5.101c0.464,2.282,2.48,4,4.899,4s4.436-1.718,4.899-4h-2.083C12.403,12.162,11.305,13,10,13z M7,7H5v2h2V7z M13,7v2h2V7H13z"/>
      <polygon points="20,2 18,2 18,0 16,0 16,2 14,2 14,4 16,4 16,6 18,6 18,4 20,4"/>
      <path d="M17.738,8C17.903,8.64,18,9.308,18,10c0,4.418-3.582,8-8,8s-8-3.582-8-8c0-4.418,3.582-8,8-8c0.692,0,1.359,0.097,2,0.262V0.201C11.354,0.07,10.685,0,10,0C4.477,0,0,4.478,0,10
      c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10c0-0.685-0.069-1.354-0.201-2H17.738z"/>
    </symbol>
  </svg>
  <!-- /SVG ADD FRIEND -->

  <!-- SVG REMOVE FRIEND -->
  <svg style="display: none;">
    <symbol id="svg-remove-friend" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,13c-1.305,0-2.403-0.838-2.816-2H5.101c0.464,2.282,2.48,4,4.899,4s4.436-1.718,4.899-4h-2.083C12.403,12.162,11.305,13,10,13z M7,7H5v2h2V7z M13,7v2h2V7H13z"/>
      <path d="M17.738,8C17.903,8.64,18,9.308,18,10c0,4.418-3.582,8-8,8s-8-3.582-8-8c0-4.418,3.582-8,8-8c0.692,0,1.359,0.097,2,0.262V0.201C11.354,0.07,10.685,0,10,0C4.477,0,0,4.478,0,10
      c0,5.523,4.477,10,10,10c5.522,0,10-4.477,10-10c0-0.685-0.069-1.354-0.201-2H17.738z"/>
      <rect x="14" y="2" width="6" height="2"/>
    </symbol>
  </svg>
  <!-- /SVG REMOVE FRIEND -->

  <!-- SVG DELETE -->
  <svg style="display: none;">
    <symbol id="svg-delete" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,20C4.477,20,0,15.523,0,10C0,4.478,4.477,0,10,0C15.522,0,20,4.478,20,10C20,15.523,15.522,20,10,20z M10,2c-4.418,0-8,3.582-8,8c0,4.418,3.582,8,8,8s8-3.582,8-8
      C18,5.582,14.418,2,10,2z M12.662,13.994L10,11.332l-2.662,2.662l-1.331-1.33l2.662-2.663L6.007,7.339l1.331-1.331L10,8.67l2.662-2.662l1.331,1.331l-2.662,2.662l2.662,2.663L12.662,13.994z"/>
    </symbol>
  </svg>
  <!-- /SVG DELETE -->

  <!-- SVG MESSAGES -->
  <svg style="display: none;">
    <symbol id="svg-messages" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M12,2c1.103,0,2,0.897,2,2v5c0,1.103-0.897,2-2,2H8H7.754l-0.239,0.06L2,12.438V9V4c0-1.103,0.897-2,2-2H12M12,0H4C1.791,0,0,1.791,0,4v5v6l8-2h4c2.209,0,4-1.791,4-4V4C16,1.791,14.209,0,12,0L12,0z"/>
      <path d="M12,5.012C12,5.558,11.558,6,11.013,6H4.987C4.442,6,4,5.558,4,5.012V4.988C4,4.442,4.442,4,4.987,4h6.025C11.558,4,12,4.442,12,4.988V5.012z"/>
      <path d="M10,8.047C10,8.574,9.573,9,9.047,9H4.953C4.427,9,4,8.574,4,8.047V7.953C4,7.426,4.427,7,4.953,7h4.094C9.573,7,10,7.426,10,7.953V8.047z"/>
      <path d="M17,5.142v2.136C17.595,7.625,18,8.263,18,9v5v3.438l-5.516-1.379L12.246,16H12H8c-0.737,0-1.375-0.405-1.722-1H4.142C4.588,16.722,6.139,18,8,18h4l8,2v-6V9C20,7.139,18.722,5.588,17,5.142z"/>
    </symbol>
  </svg>
  <!-- /SVG MESSAGES -->

  <!-- SVG SEND MESSAGE -->
  <svg style="display: none;">
    <symbol id="svg-send-message" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M19.993,0.94c-0.005-0.091-0.022-0.179-0.052-0.265c-0.011-0.031-0.021-0.062-0.035-0.093  c-0.049-0.107-0.109-0.208-0.195-0.294s-0.188-0.147-0.294-0.195c-0.031-0.014-0.062-0.024-0.093-0.035c-0.086-0.03-0.173-0.046-0.263-0.052C19.034,0.005,19.009,0,18.981,0c-0.104,0.002-0.207,0.017-0.308,0.052L0.67,6.353C0.283,6.488,0.018,6.846,0.001,7.256C-0.016,7.666,0.22,8.044,0.594,8.21l7.75,3.446l3.444,7.75C11.948,19.769,12.308,20,12.702,20
      c0.014,0,0.027,0,0.041-0.002c0.409-0.016,0.768-0.28,0.903-0.668l6.302-18.003c0.035-0.101,0.05-0.206,0.052-0.309C20,0.991,19.994,0.967,19.993,0.94z M15.197,3.388L8.88,9.706L3.711,7.408L15.197,3.388z M12.592,16.288l-2.298-5.169l6.317-6.316L12.592,16.288z"/>
    </symbol>
  </svg>
  <!-- /SVG SEND MESSAGE -->

  <!-- SVG BACK ARROW -->
  <svg style="display: none;">
    <symbol id="svg-back-arrow" viewBox="0 0 14 10" preserveAspectRatio="xMinYMin meet">
      <path d="M14,6H3.364l2.644,2.75L4.806,10L1.202,6.25l0,0L0,5L4.806,0l1.201,1.25L3.364,4H14V6z"/>
    </symbol>
  </svg>
  <!-- /SVG BACK ARROW -->

  <!-- SVG SHOPPING BAG -->
  <svg style="display: none;">
    <symbol id="svg-shopping-bag" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M15.904,6c0.148,0.117,0.347,0.386,0.406,0.557l0.001,0.017l0.676,11.421c-0.005,0.003-0.01,0.005-0.013,0.006H3.029c-0.004-0.001-0.01-0.003-0.016-0.006L3.689,6.573L3.69,6.556C3.749,6.386,3.947,6.117,4.095,6H15.904 M16.075,4H3.924C2.806,4,1.692,5.563,1.692,6.455L1,18.154C1,19.048,1.906,20,3.024,20h13.95C18.093,20,19,19.048,19,18.154L18.308,6.455C18.308,5.563,17.193,4,16.075,4L16.075,4z"/>
      <path d="M8,8V3.893C8,2.849,8.849,2,9.893,2H10h0.054C11.098,2,12,2.849,12,3.893V8h2V3.893C14,1.743,12.257,0,10.107,0H9.893C7.743,0,6,1.743,6,3.893V8H8z"/>
    </symbol>
  </svg>
  <!-- /SVG SHOPPING BAG -->

  <!-- SVG NOTIFICATION -->
  <svg style="display: none;">
    <symbol id="svg-notification" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M18.583,14.127c-0.023-0.018-2.241-1.758-2.241-7.52C16.342,2.964,13.497,0,10.004,0C6.511,0,3.668,2.964,3.668,6.607c0,5.762-2.22,7.502-2.228,7.508c-0.365,0.254-0.525,0.725-0.397,1.158c0.129,0.434,0.517,0.73,0.957,0.73h16.007c0.433,0,0.81-0.293,0.943-0.719C19.086,14.861,18.932,14.389,18.583,14.127zM4.086,13.939C4.873,12.527,5.67,10.21,5.67,6.607c0-2.505,1.945-4.542,4.334-4.542c2.391,0,4.335,2.038,4.335,4.542c0,3.603,0.796,5.92,1.583,7.333H4.086z M12.418,17.146c-0.57-0.283-1.293-0.115-1.619,0.381c-0.126,0.191-0.327,0.326-0.567,0.383c-0.234,0.051-0.478,0.023-0.688-0.084c-0.138-0.07-0.255-0.174-0.336-0.297c-0.325-0.496-1.05-0.668-1.618-0.385c-0.568,0.283-0.766,0.914-0.44,1.408c0.286,0.438,0.7,0.803,1.194,1.055C8.854,19.867,9.421,20,9.997,20c0.281,0,0.564-0.031,0.843-0.096c0.856-0.197,1.573-0.676,2.016-1.348C13.182,18.061,12.984,17.432,12.418,17.146z"/>
    </symbol>
  </svg>
  <!-- /SVG NOTIFICATION -->

  <!-- SVG SETTINGS -->
  <svg style="display: none;">
    <symbol id="svg-settings" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,7c-1.657,0-3,1.343-3,3c0,1.655,1.343,2.999,3,2.999c1.656,0,3-1.344,3-2.999C13,8.343,11.656,7,10,7zM10,11c-0.552,0-1-0.449-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C11,10.551,10.552,11,10,11z M17.363,12.638C18.817,12.638,20,11.453,20,10s-1.183-2.637-2.637-2.637h-0.135c-0.106,0-0.205-0.049-0.27-0.129 c-0.014-0.054-0.032-0.106-0.055-0.158c-0.059-0.132-0.031-0.283,0.111-0.429c0.498-0.499,0.772-1.161,0.772-1.865c0-0.704-0.274-1.367-0.773-1.865c-0.997-0.997-2.693-1.035-3.769,0.042c-0.102,0.1-0.24,0.135-0.397,0.066c-0.129-0.055-0.212-0.18-0.212-0.391C12.637,1.182,11.454,0,10,0S7.363,1.182,7.363,2.636v0.135c0,0.106-0.049,0.206-0.129,0.271C7.181,3.056,7.128,3.073,7.077,3.096c-0.128,0.058-0.264,0.052-0.43-0.113c-0.996-0.994-2.731-0.995-3.73,0.003c-1.026,1.028-1.025,2.701,0.044,3.77C3.062,6.858,3.089,7.01,3.007,7.2c-0.05,0.136-0.176,0.227-0.371,0.229C1.183,7.429,0,8.612,0,10.065c0,1.454,1.183,2.637,2.636,2.637h0.138c0.138,0,0.263,0.083,0.322,0.222c0.058,0.132,0.03,0.284-0.113,0.429c-0.497,0.498-0.771,1.161-0.771,1.865c0.001,0.705,0.276,1.367,0.773,1.863c0.996,0.996,2.689,1.038,3.771-0.042c0.101-0.099,0.142-0.173,0.443-0.046c0.136,0.049,0.226,0.176,0.229,0.37
      c0,1.454,1.183,2.637,2.637,2.637s2.637-1.183,2.637-2.637v-0.137c0-0.139,0.083-0.263,0.222-0.322c0.129-0.058,0.263-0.051,0.428,0.112c0.999,0.995,2.735,0.996,3.73-0.002c0.498-0.499,0.771-1.162,0.771-1.866c-0.001-0.704-0.275-1.366-0.813-1.903c-0.101-0.104-0.129-0.256-0.064-0.398C17.029,12.72,17.154,12.638,17.363,12.638zM15.666,14.7c0.121,0.12,0.188,0.279,0.188,0.449s-0.067,0.33-0.188,0.453c-0.239,0.241-0.605,0.295-0.956-0.058c-0.677-0.662-1.742-0.845-2.582-0.475c-0.864,0.369-1.423,1.214-1.425,2.153v0.14c0,0.352-0.286,0.636-0.637,0.636s-0.637-0.284-0.637-0.733c-0.023-0.97-0.627-1.815-1.479-2.125C7.649,15.007,7.329,14.939,7,14.939c-0.618,0-1.202,0.238-1.7,0.728c-0.24,0.24-0.66,0.241-0.901-0.002c-0.248-0.246-0.248-0.65,0.058-0.957c0.675-0.69,0.859-1.709,0.474-2.58c-0.368-0.864-1.214-1.424-2.154-1.426h-0.14C2.285,10.702,2,10.416,2,10.065
      s0.285-0.637,0.733-0.637c0.971-0.023,1.815-0.626,2.127-1.48c0.39-0.884,0.205-1.9-0.527-2.648C4.085,5.052,4.085,4.647,4.334,4.398c0.24-0.24,0.603-0.298,0.957,0.058C5.92,5.07,6.871,5.282,7.694,5c0.084-0.013,0.167-0.036,0.245-0.07c0.861-0.37,1.42-1.214,1.424-2.155v-0.14C9.363,2.285,9.649,2,10,2s0.637,0.285,0.637,0.712c0.002,0.937,0.561,1.782,1.414,2.148c0.865,0.38,1.884,0.222,2.649-0.527c0.241-0.24,0.656-0.243,0.901,0.001c0.12,0.12,0.187,0.279,0.187,0.449c0,0.169-0.066,0.331-0.242,0.507c-0.629,0.642-0.834,1.566-0.545,2.405
      c0.013,0.083,0.035,0.165,0.069,0.244c0.369,0.862,1.213,1.421,2.154,1.425h0.139C17.715,9.364,18,9.649,18,10c0,0.351-0.285,0.637-0.712,0.637c-0.938,0.002-1.783,0.561-2.146,1.411C14.749,12.933,14.933,13.951,15.666,14.7z"/>
    </symbol>
  </svg>
  <!-- /SVG SETTINGS -->

  <!-- SVG CROSS THIN -->
  <svg style="display: none;">
    <symbol id="svg-cross-thin" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M18,16.547L16.545,18L10,11.455L3.455,18L2,16.547L8.545,10L2,3.455L3.455,2L10,8.546L16.545,2L18,3.455L11.455,10L18,16.547z"/>
    </symbol>
  </svg>
  <!-- /SVG CROSS THIN -->

  <!-- SVG NEWSFEED -->
  <svg style="display: none;">
    <symbol id="svg-newsfeed" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,0H4C1.791,0,0,1.791,0,4v8c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V4C20,1.791,18.209,0,16,0zM18,12c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2V4c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V12z"/>
      <path d="M0,10v2h20v-2H0z M5,20h10v-2H5V20z"/>
    </symbol>
  </svg>
  <!-- /SVG NEWSFEED -->

  <!-- SVG OVERVIEW -->
  <svg style="display: none;">
    <symbol id="svg-overview" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M18,8v10h-2V8H18 M18,6h-2c-1.104,0-2,0.896-2,2v10c0,1.104,0.896,2,2,2h2c1.104,0,2-0.896,2-2V8C20,6.896,19.104,6,18,6L18,6z"/>
      <path d="M11,2v16H9V2H11 M11,0H9C7.896,0,7,0.896,7,2v16c0,1.104,0.896,2,2,2h2c1.104,0,2-0.896,2-2V2C13,0.896,12.104,0,11,0L11,0z"/>
      <path d="M4,14v4H2v-4H4 M4,12H2c-1.104,0-2,0.896-2,2v4c0,1.104,0.896,2,2,2h2c1.104,0,2-0.896,2-2v-4C6,12.896,5.104,12,4,12L4,12z"/>
    </symbol>
  </svg>
  <!-- /SVG OVERVIEW -->

  <!-- SVG MEMBERS -->
  <svg style="display: none;">
    <symbol id="svg-members" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,10c2.762,0,5-2.238,5-5c0-2.762-2.238-5-5-5S5,2.238,5,5C5,7.761,7.238,10,10,10z M10,2c1.654,0,3,1.346,3,3s-1.346,3-3,3S7,6.654,7,5S8.346,2,10,2z M13,12H7c-3.313,0-6,2.686-6,6v2h2v-2c0-2.205,1.794-4,4-4h6c2.206,0,4,1.795,4,4v2h2v-2C19,14.686,16.313,12,13,12z"/>
    </symbol>
  </svg>
  <!-- /SVG MEMBERS -->

  <!-- SVG BADGES -->
  <svg style="display: none;">
    <symbol id="svg-badges" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,8c2.757,0,5,2.243,5,5s-2.243,5-5,5s-5-2.243-5-5S7.243,8,10,8 M10,6c-3.866,0-7,3.134-7,7s3.134,7,7,7c3.865,0,7-3.134,7-7S13.865,6,10,6L10,6z"/>
      <path d="M3.906,6.391L2.651,2h3.84l0.701,2.453c0.627-0.206,1.283-0.347,1.963-0.41L8,0H0l2.36,8.26C2.79,7.568,3.31,6.941,3.906,6.391z"/>
      <path d="M12.808,4.453L13.509,2h3.84l-1.255,4.391C16.689,6.94,17.21,7.568,17.64,8.26L20,0h-8l-1.155,4.043C11.524,4.106,12.181,4.247,12.808,4.453z"/>
    </symbol>
  </svg>
  <!-- /SVG BADGES -->

  <!-- SVG QUESTS -->
  <svg style="display: none;">
    <symbol id="svg-quests" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,2.708l1.774,3.813l0.452,0.97l1.058,0.163l4.138,0.637l-3.07,3.175l-0.697,0.721l0.16,0.989l0.7,4.326l-3.494-1.947l-0.965-0.538L10,15.048l-0.055-0.03L8.98,15.556l-3.494,1.947l0.7-4.326l0.16-0.989l-0.697-0.721L2.578,8.292l4.138-0.637l1.058-0.163l0.451-0.97L10,2.708 M10.029-0.004C10.019-0.004,10.01-0.002,10,0C9.99-0.002,9.981-0.004,9.971-0.004
      c-0.558,0-1.066,0.326-1.303,0.834L6.412,5.678L1.223,6.477c-0.534,0.082-0.977,0.46-1.148,0.976c-0.172,0.517-0.042,1.086,0.334,1.474l3.802,3.932l-0.882,5.453c-0.089,0.548,0.141,1.1,0.591,1.42c0.248,0.176,0.539,0.266,0.83,0.266c0.24,0,0.479-0.061,0.697-0.182l4.507-2.512L10,17.328l0.046-0.025l4.506,2.512c0.219,0.121,0.457,0.182,0.697,0.182c0.291,0,0.582-0.09,0.83-0.266c0.45-0.32,0.68-0.872,0.591-1.42l-0.882-5.453l3.802-3.932c0.376-0.387,0.507-0.957,0.335-1.474c-0.172-0.515-0.614-0.894-1.148-0.976l-5.189-0.798l-2.256-4.848C11.095,0.322,10.586-0.004,10.029-0.004L10.029-0.004z"/>
      <rect x="7" y="9" width="2" height="2"/>
      <rect x="11" y="9" width="2" height="2"/>
    </symbol>
  </svg>
  <!-- /SVG QUESTS -->

  <!-- SVG STREAMS -->
  <svg style="display: none;">
    <symbol id="svg-streams" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,3c1.103,0,2,0.897,2,2v10c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2V5c0-1.103,0.897-2,2-2H16 M16,1H4C1.791,1,0,2.791,0,5v10c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V5C20,2.791,18.209,1,16,1L16,1z"/>
      <path d="M8.405,7c0.102,0,0.219,0.037,0.347,0.115l3.908,2.4c0.439,0.267,0.462,0.704,0.021,0.971l-3.928,2.4C8.624,12.964,8.507,13,8.405,13C8.157,13,8,12.782,8,12.408V7.593C8,7.217,8.157,7,8.405,7 M8.405,5C7.034,5,6,6.115,6,7.593v4.815C6,13.886,7.034,15,8.405,15c0.477,0,0.943-0.137,1.386-0.405l3.932-2.402c0.796-0.481,1.276-1.297,1.277-2.177c0.001-0.888-0.486-1.715-1.304-2.21L9.798,5.411C9.348,5.137,8.881,5,8.405,5L8.405,5z"/>
    </symbol>
  </svg>
  <!-- /SVG STREAMS -->

  <!-- SVG EVENTS -->
  <svg style="display: none;">
    <symbol id="svg-events" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,2H4C1.791,2,0,3.791,0,6v10c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V6C20,3.791,18.209,2,16,2zM18,16c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2V6c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V16z"/>
      <path d="M0,8v2h20V8H0z M7,0H5v6h2V0z M15,0h-2v6h2V0z"/>
    </symbol>
  </svg>
  <!-- /SVG EVENTS -->

  <!-- SVG FORUMS -->
  <svg style="display: none;">
    <symbol id="svg-forums" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,0H4C1.791,0,0,1.592,0,3.556V6v2v4.444C0,14.408,1.791,16,4,16h2l4,4l4-4h2c2.209,0,4-1.592,4-3.556V8V6V3.556C20,1.592,18.209,0,16,0z M18,12.444C18,13.287,17.084,14,16,14h-2h-0.828l-0.586,0.586L10,17.172l-2.586-2.586L6.829,14H6H4c-1.084,0-2-0.713-2-1.556V8h16V12.444z M2,6V3.556C2,2.712,2.916,2,4,2h12c1.084,0,2,0.712,2,1.556V6H2z"/>
      <rect x="4" y="3" width="2" height="2"/>
      <rect x="10" y="3" width="2" height="2"/>
      <rect x="7" y="3" width="2" height="2"/>
    </symbol>
  </svg>
  <!-- /SVG FORUMS -->

  <!-- SVG SMALL ARROW -->
  <svg style="display: none;">
    <symbol id="svg-small-arrow" viewBox="0 0 6 8" preserveAspectRatio="xMinYMin meet">
      <path d="M6,4L1.5,8L0,6.665L3,4L0,1.333L1.5,0l3,2.666l0,0L6,4z"/>
    </symbol>
  </svg>
  <!-- /SVG SMALL ARROW -->

  <!-- SVG BIG ARROW -->
  <svg style="display: none;">
    <symbol id="svg-big-arrow" viewBox="0 0 8 12" preserveAspectRatio="xMinYMin meet">
      <path d="M6.399,7.5L6.399,7.5L1.6,12L0,10.5L4.8,6L0,1.5L1.6,0L8,6L6.399,7.5z"/>
    </symbol>
  </svg>
  <!-- /SVG BIG ARROW -->

  <!-- SVG PUBLIC -->
  <svg style="display: none;">
    <symbol id="svg-public" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M20,10c0-5.522-4.478-10-10-10C4.477,0,0,4.478,0,10c0,5.523,4.477,10,10,10
      c1.566,0,3.043-0.37,4.362-1.013l0.01,0.018c0.051-0.028,0.086-0.059,0.135-0.087C17.763,17.27,20,13.9,20,10z M16.155,15.108c-0.648-1.745-2.802-3.14-4.653-3.408c-0.751-0.109-0.829-0.433-0.858-0.555C10.497,10.532,11,9.499,11.679,9.021c1.482-1.044,4.529-0.504,6.252-0.025C17.972,9.325,18,9.659,18,10C18,11.942,17.307,13.723,16.155,15.108z M14.715,3.547c-0.012,0-0.022,0.003-0.034,0.003c-1.374,0-2.609-0.561-3.518-1.456C12.479,2.286,13.688,2.796,14.715,3.547z M2,10
      c0-2.277,0.956-4.328,2.483-5.785c1.26,0.272,2.644,0.845,2.87,1.36C7.373,5.62,7.425,5.74,7.222,6.051c-0.295,0.451-0.603,0.88-0.896,1.29c-1.389,1.937-2.963,4.131-0.647,6.169c0.537,0.472,0.805,1.243,0.624,1.793c-0.184,0.557-0.815,0.773-1.402,0.86C3.128,14.695,2,12.479,2,10z M7.065,17.437c0.671-0.479,0.993-1.077,1.135-1.507c0.432-1.308-0.063-2.92-1.201-3.922c-0.814-0.716-0.672-1.24,0.951-3.502c0.31-0.432,0.633-0.883,0.944-1.359c0.66-1.009,0.521-1.85,0.289-2.377c-0.428-0.972-1.522-1.606-2.57-2.01C7.251,2.46,7.934,2.244,8.65,2.122c1.236,2.047,3.469,3.428,6.03,3.428c0.618,0,1.206-0.106,1.776-0.259c0.339,0.463,0.631,0.961,0.865,1.492c-1.979-0.428-4.906-0.729-6.795,0.603c-1.195,0.84-2.204,2.651-1.828,4.224c0.268,1.122,1.185,1.876,2.516,2.068c1.409,0.205,2.963,1.352,3.137,2.314c0.028,0.156,0.1,0.618-0.781,1.158C12.495,17.689,11.285,18,10,18C8.963,18,7.975,17.796,7.065,17.437z"/>
    </symbol>
  </svg>
  <!-- /SVG PUBLIC -->

  <!-- SVG PRIVATE -->
  <svg style="display: none;">
    <symbol id="svg-private" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M11,11H9c-0.553,0-1,0.447-1,1s0.447,1,1,1v1c0,0.553,0.447,1,1,1s1-0.447,1-1v-1c0.553,0,1-0.447,1-1S11.553,11,11,11z"/>
      <path d="M16,6.142V6c0-3.313-2.687-6-6-6S4,2.687,4,6v0.142C2.278,6.588,1,8.139,1,10v6c0,2.209,1.791,4,4,4h10c2.209,0,4-1.791,4-4v-6C19,8.139,17.722,6.588,16,6.142z M10,2c2.206,0,4,1.794,4,4H6C6,3.794,7.794,2,10,2z M17,16c0,1.103-0.897,2-2,2H5c-1.103,0-2-0.897-2-2v-6c0-1.103,0.897-2,2-2h10c1.103,0,2,0.897,2,2V16z"/>
    </symbol>
  </svg>
  <!-- /SVG PRIVATE -->

  <!-- SVG JOIN GROUP -->
  <svg style="display: none;">
    <symbol id="svg-join-group" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,9.999c2.762,0,5-2.238,5-5s-2.238-5-5-5s-5,2.238-5,5S7.238,9.999,10,9.999z M10,1.999c1.654,0,3,1.346,3,3s-1.346,3-3,3s-3-1.346-3-3S8.346,1.999,10,1.999z M20,3.949v-2c-2.236,0-4.05,1.813-4.05,4.05c0,2.236,1.813,4.05,4.05,4.05v-2c-1.13,0-2.05-0.92-2.05-2.05C17.95,4.869,18.87,3.949,20,3.949z M4.05,5.999C4.05,3.762,2,1.949,0,1.949v2c1,0,2.05,0.919,2.05,2.05c0,1.13-1.05,2.05-2.05,2.05v2C2,10.049,4.05,8.235,4.05,5.999zM11,13.999H9v2H7v2h2v2h2v-2h2v-2h-2V13.999z M7,11.999c-3.313,0-6,2.687-6,6v2h2v-2c0-2.205,1.794-4,4-4V11.999z M13,11.999v2c2.206,0,4,1.795,4,4v2h2v-2C19,14.686,16.313,11.999,13,11.999z"/>
    </symbol>
  </svg>
  <!-- /SVG JOIN GROUP -->

  <!-- SVG LEAVE GROUP -->
  <svg style="display: none;">
    <symbol id="svg-leave-group" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,9.999c2.762,0,5-2.238,5-5s-2.238-5-5-5s-5,2.238-5,5S7.238,9.999,10,9.999z M10,1.999c1.654,0,3,1.346,3,3s-1.346,3-3,3s-3-1.346-3-3S8.346,1.999,10,1.999z M20,3.949v-2c-2.236,0-4.05,1.813-4.05,4.05c0,2.236,1.813,4.05,4.05,4.05v-2c-1.13,0-2.05-0.92-2.05-2.05C17.95,4.869,18.87,3.949,20,3.949z M4.05,5.999C4.05,3.762,2,1.949,0,1.949v2c1,0,2.05,0.919,2.05,2.05c0,1.13-1.05,2.05-2.05,2.05v2C2,10.049,4.05,8.235,4.05,5.999z M7,17.999h6v-2H7V17.999z M7,11.999c-3.313,0-6,2.687-6,6v2h2v-2c0-2.205,1.794-4,4-4V11.999z M13,11.999v2c2.206,0,4,1.795,4,4v2h2v-2C19,14.686,16.313,11.999,13,11.999z"/>
    </symbol>
  </svg>
  <!-- /SVG LEAVE GROUP -->

  <!-- SVG MORE DOTS -->
  <svg style="display: none;">
    <symbol id="svg-more-dots" viewBox="0 0 22 6" preserveAspectRatio="xMinYMin meet">
      <path d="M19,6c-1.657,0-3-1.344-3-3c0-1.656,1.343-3,3-3s3,1.344,3,3C22,4.656,20.657,6,19,6z M19,2c-0.553,0-1,0.447-1,1c0,0.552,0.447,1,1,1c0.552,0,1-0.449,1-1C20,2.447,19.552,2,19,2z M11,6C9.343,6,8,4.656,8,3c0-1.656,1.343-3,3-3s3,1.344,3,3C14,4.656,12.657,6,11,6z M11,2c-0.553,0-1,0.447-1,1c0,0.552,0.447,1,1,1c0.552,0,1-0.449,1-1C12,2.447,11.552,2,11,2z M3,6C1.343,6,0,4.656,0,3c0-1.656,1.343-3,3-3c1.656,0,3,1.344,3,3C6,4.656,4.656,6,3,6z M3,2C2.447,2,2,2.447,2,3c0,0.552,0.447,1,1,1c0.552,0,1-0.449,1-1C4,2.447,3.552,2,3,2z"/>
    </symbol>
  </svg>
  <!-- /SVG MORE DOTS -->

  <!-- SVG PROFILE -->
  <svg style="display: none;">
    <symbol id="svg-profile" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,2c4.418,0,8,3.582,8,8c0,4.418-3.582,8-8,8c-4.418,0-8-3.582-8-8C2,5.582,5.582,2,10,2 M10,0C4.486,0,0,4.486,0,10c0,5.514,4.486,10,10,10c5.514,0,10-4.486,10-10C20,4.486,15.514,0,10,0L10,0z"/>
      <path d="M10,7c0.551,0,1,0.449,1,1s-0.449,1-1,1S9,8.551,9,8S9.449,7,10,7 M10,5C8.343,5,7,6.343,7,8c0,1.656,1.343,3,3,3c1.656,0,3-1.344,3-3C13,6.343,11.656,5,10,5L10,5z"/>
      <path d="M14.866,15.025C14.43,13.29,12.871,12,11,12H9c-1.871,0-3.43,1.29-3.866,3.025C5.678,15.553,6.308,15.988,7,16.317V16c0-1.104,0.897-2,2-2h2c1.103,0,2,0.896,2,2v0.317C13.692,15.988,14.322,15.553,14.866,15.025z"/>
    </symbol>
  </svg>
  <!-- /SVG PROFILE -->

  <!-- SVG TIMELINE -->
  <svg style="display: none;">
    <symbol id="svg-timeline" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,0H4C1.791,0,0,1.791,0,4v12c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V4C20,1.791,18.209,0,16,0zM18,16c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2V4c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V16z"/>
      <polygon points="18,5 2,5 2,7 5,7 5,20 7,20 7,7 13,7 13,20 15,20 15,7 18,7"/>
    </symbol>
  </svg>
  <!-- /SVG TIMELINE -->

  <!-- SVG PHOTOS -->
  <svg style="display: none;">
    <symbol id="svg-photos" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,0H4C1.791,0,0,1.791,0,4v12c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V4C20,1.791,18.209,0,16,0zM4,2h12c1.103,0,2,0.897,2,2v6.407L16.703,9.11c-0.75-0.751-1.734-1.126-2.718-1.126c-0.984,0-1.968,0.375-2.719,1.126l-2.98,2.98l-0.259-0.259c-0.563-0.563-1.301-0.844-2.039-0.844s-1.476,0.282-2.04,0.844L2,13.782V4C2,2.897,2.897,2,4,2z M16,18H4c-0.918,0-1.686-0.625-1.919-1.47l3.282-3.283c0.226-0.226,0.489-0.259,0.626-0.259c0.137,0,0.399,0.033,0.625,0.258l0.259,0.259l1.414,1.414L9.7,13.505l2.981-2.981c0.348-0.348,0.811-0.54,1.304-0.54c0.492,0,0.955,0.192,1.304,0.541L18,13.234V16C18,17.103,17.103,18,16,18z"/>
      <path d="M6,9c1.657,0,3-1.344,3-3c0-1.657-1.343-3-3-3S3,4.343,3,6C3,7.656,4.343,9,6,9z M6,5c0.551,0,1,0.449,1,1c0,0.552-0.449,1-1,1S5,6.552,5,6C5,5.449,5.449,5,6,5z"/>
    </symbol>
  </svg>
  <!-- /SVG PHOTOS -->

  <!-- SVG VIDEOS -->
  <svg style="display: none;">
    <symbol id="svg-videos" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,0H4C1.791,0,0,1.791,0,4v12c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V4C20,1.791,18.209,0,16,0zM18,4v1h-2V2C17.103,2,18,2.897,18,4z M4,13H2V7h2V13z M16,7h2v6h-2V7z M14,2v16H6V2H14z M4,2v3H2V4C2,2.897,2.897,2,4,2z M2,16v-1h2v3C2.897,18,2,17.103,2,16z M16,18v-3h2v1C18,17.103,17.103,18,16,18z"/>
    </symbol>
  </svg>
  <!-- /SVG VIDEOS -->

  <!-- SVG BLOG POSTS -->
  <svg style="display: none;">
    <symbol id="svg-blog-posts" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M15,0H5C2.791,0,1,1.791,1,4v3v2v7c0,2.209,1.791,4,4,4h10c2.209,0,4-1.791,4-4V9V7V4C19,1.791,17.209,0,15,0z M17,16c0,1.103-0.897,2-2,2H5c-1.103,0-2-0.897-2-2V9h14V16z M3,7V4c0-1.103,0.897-2,2-2h10c1.103,0,2,0.897,2,2v3H3z"/>
      <rect x="5" y="11" width="10" height="2"/>
      <rect x="13" y="14" width="2" height="2"/>
      <rect x="5" y="14" width="6" height="2"/>
    </symbol>
  </svg>
  <!-- /SVG BLOG POSTS -->

  <!-- SVG FORUM -->
  <svg style="display: none;">
    <symbol id="svg-forum" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10.969,0H5C2.791,0,1,1.791,1,4v12c0,2.209,1.791,4,4,4h10c2.209,0,4-1.791,4-4V8.125L10.969,0z M11,2.877L16.064,8H13c-1.104,0-2-0.897-2-2V2.877z M15,18H5c-1.103,0-2-0.897-2-2V4c0-1.103,0.897-2,2-2h4v4c0,2.209,1.791,4,4,4h4v6C17,17.103,16.103,18,15,18z"/>
      <path d="M13,14H7c-0.553,0-1,0.447-1,1s0.447,1,1,1h6c0.552,0,1-0.447,1-1S13.552,14,13,14z"/>
    </symbol>
  </svg>
  <!-- /SVG FORUM -->

  <!-- SVG STORE -->
  <svg style="display: none;">
    <symbol id="svg-store" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M19.411,4.288l-1.894-3.301C17.174,0.378,16.537,0,15.855,0H4.047C3.37,0,2.734,0.376,2.39,0.981L0.492,4.288C-0.18,5.459-0.227,6.949,0.37,8.177C0.737,8.933,1.314,9.494,2,9.826V16c0,2.209,1.791,4,4,4h8c2.209,0,4-1.791,4-4V9.766c0.642-0.338,1.184-0.871,1.532-1.586C20.13,6.953,20.084,5.462,19.411,4.288z M2.227,5.289L4.047,2h11.732l1.896,3.289c0.343,0.598,0.365,1.388,0.059,2.018c-0.136,0.277-0.452,0.755-1.073,0.845c-0.567,0.088-1.203-0.159-1.624-0.674l-0.772-0.943L13.49,7.477c-0.725,0.883-2.046,0.883-2.767,0.001L9.95,6.532L9.176,7.477C8.452,8.361,7.13,8.359,6.41,7.478L5.636,6.531L4.862,7.478c-0.421,0.515-0.973,0.773-1.639,0.67C2.62,8.06,2.303,7.583,2.168,7.306C1.862,6.675,1.885,5.885,2.227,5.289zM4,10.11c0.582-0.086,1.148-0.295,1.636-0.641c1.263,0.9,3.046,0.9,4.313,0c1.265,0.9,3.046,0.901,4.315,0c0.518,0.368,1.113,0.592,1.736,0.665V14H4V10.11z M14,18H6c-1.103,0-2-0.897-2-2h12C16,17.103,15.103,18,14,18z"/>
    </symbol>
  </svg>
  <!-- /SVG STORE -->

  <!-- SVG STAR -->
  <svg style="display: none;">
    <symbol id="svg-star" viewBox="0 0 9 8" preserveAspectRatio="xMinYMin meet">
      <path d="M4.5,0l1.482,2.515L9,3.056L6.899,5.151L7.28,8L4.5,6.78L1.719,8L2.1,5.151L0,3.056l3.017-0.541L4.5,0"/>
    </symbol>
  </svg>
  <!-- /SVG STAR -->

  <!-- SVG PLAY -->
  <svg style="display: none;">
    <symbol id="svg-play" viewBox="0 0 12 14" preserveAspectRatio="xMinYMin meet">
      <path d="M11.182,5.868l-9.378-5.6C0.751-0.355,0,0.147,0,1.383v11.236c0,1.234,0.751,1.737,1.803,1.112l9.431-5.599C12.291,7.51,12.236,6.491,11.182,5.868z"/>
    </symbol>
  </svg>
  <!-- /SVG PLAY -->

  <!-- SVG SHARE -->
  <svg style="display: none;">
    <symbol id="svg-share" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M15.336,12.6c-0.984,0-1.876,0.399-2.536,1.04l-4.602-2.714c0.077-0.297,0.13-0.604,0.13-0.925s-0.054-0.628-0.13-0.925l4.598-2.717C13.457,7,14.35,7.4,15.336,7.4C17.356,7.4,19,5.741,19,3.7S17.356,0,15.336,0s-3.665,1.66-3.665,3.7c0,0.321,0.054,0.628,0.13,0.925L7.203,7.342C6.543,6.7,5.651,6.3,4.665,6.3C2.644,6.3,1,7.959,1,10c0,2.04,1.645,3.699,3.665,3.699c0.986,0,1.878-0.399,2.538-1.041l4.601,2.713c-0.078,0.299-0.132,0.606-0.132,0.93c0,2.04,1.645,3.699,3.665,3.699S19,18.341,19,16.301C19,14.26,17.356,12.6,15.336,12.6z M15.336,2c0.921,0,1.67,0.763,1.67,1.7s-0.749,1.7-1.67,1.7c-0.922,0-1.672-0.763-1.672-1.7S14.414,2,15.336,2z M4.665,11.699c-0.921,0-1.671-0.763-1.671-1.699c0-0.938,0.75-1.7,1.671-1.7s1.67,0.763,1.67,1.7C6.335,10.937,5.586,11.699,4.665,11.699z M15.336,18c-0.922,0-1.672-0.763-1.672-1.699c0-0.308,0.087-0.592,0.228-0.841c0.003-0.005,0.006-0.008,0.009-0.012c0.003-0.005,0.002-0.01,0.005-0.015c0.292-0.497,0.821-0.834,1.431-0.834c0.921,0,1.67,0.763,1.67,1.701C17.006,17.237,16.257,18,15.336,18z"/>
    </symbol>
  </svg>
  <!-- /SVG SHARE -->

  <!-- SVG PINNED -->
  <svg style="display: none;">
    <symbol id="svg-pinned" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M17,20c-0.205,0-0.408-0.063-0.581-0.188L10,15.229l-6.419,4.584c-0.305,0.218-0.706,0.249-1.039,0.077C2.209,19.717,2,19.375,2,19V3c0-1.654,1.346-3,3-3h10c1.654,0,3,1.346,3,3v16c0,0.375-0.209,0.717-0.542,0.89C17.313,19.963,17.156,20,17,20z M10,13c0.203,0,0.407,0.063,0.581,0.186L16,17.057V3c0-0.551-0.448-1-1-1H5C4.448,2,4,2.448,4,3v14.057l5.419-3.871C9.593,13.063,9.796,13,10,13z"/>
    </symbol>
  </svg>
  <!-- /SVG PINNED -->

  <!-- SVG STATUS -->
  <svg style="display: none;">
    <symbol id="svg-status" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,1H4C1.791,1,0,2.791,0,5v4v2v4c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4v-4V9V5C20,2.791,18.209,1,16,1z M18,15c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2v-4h16V15z M18,9H2V5c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V9z"/>
      <rect x="13" y="13" width="2" height="2"/>
      <rect x="9" y="13" width="2" height="2"/>
      <rect x="5" y="5" width="10" height="2"/>
      <rect x="5" y="13" width="2" height="2"/>
    </symbol>
  </svg>
  <!-- /SVG STATUS -->

  <!-- SVG BIG GRID VIEW -->
  <svg style="display: none;">
    <symbol id="svg-big-grid-view" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,20H4c-2.209,0-4-1.791-4-4V4c0-2.209,1.791-4,4-4h12c2.209,0,4,1.791,4,4v12C20,18.209,18.209,20,16,20z M2,16c0,1.102,0.896,2,2,2h5v-7H2V16z M4,2C2.896,2,2,2.896,2,4v5h7V2H4z M18,4c0-1.104-0.897-2-2-2h-5v7h7V4z M18,11h-7v7h5c1.103,0,2-0.898,2-2V11z"/>
    </symbol>
  </svg>
  <!-- /SVG BIG GRID VIEW -->

  <!-- SVG SMALL GRID VIEW -->
  <svg style="display: none;">
    <symbol id="svg-small-grid-view" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,20H4c-2.209,0-4-1.791-4-4V4c0-2.209,1.791-4,4-4h12c2.209,0,4,1.791,4,4v12C20,18.209,18.209,20,16,20z M12,18v-4H8v4H12z M2,16c0,1.102,0.896,2,2,2h2v-4H2V16z M6,2H4C2.896,2,2,2.896,2,4v2h4V2z M2,8v4h4V8H2z M12,2H8v4h4V2z M8,8v4h4V8H8z M18,4c0-1.103-0.897-2-2-2h-2v4h4V4z M18,8h-4v4h4V8zM18,14h-4v4h2c1.103,0,2-0.898,2-2V14z"/>
    </symbol>
  </svg>
  <!-- /SVG SMALL GRID VIEW -->

  <!-- SVG LIST GRID VIEW -->
  <svg style="display: none;">
    <symbol id="svg-list-grid-view" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,20H4c-2.209,0-4-1.791-4-4V4c0-2.209,1.791-4,4-4h12c2.209,0,4,1.791,4,4v12C20,18.209,18.209,20,16,20z M2,16c0,1.102,0.896,2,2,2h2v-4H2V16z M6,2H4C2.896,2,2,2.896,2,4v2h4V2z M2,8v4h4V8H2z M18,4c0-1.103-0.897-2-2-2H8v4h10V4z M18,8H8v4h10V8z M18,14H8v4h8c1.103,0,2-0.898,2-2V14z"/>
    </symbol>
  </svg>
  <!-- /SVG LIST GRID VIEW -->

  <!-- SVG PLUS -->
  <svg style="display: none;">
    <symbol id="svg-plus" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M20,11h-9v9H9v-9H0V9h9V0h2v9h9V11z"/>
    </symbol>
  </svg>
  <!-- /SVG PLUS -->

  <!-- SVG PLUS SMALL -->
  <svg style="display: none;">
    <symbol id="svg-plus-small" viewBox="0 0 8 8" preserveAspectRatio="xMinYMin meet">
      <path d="M8,5H5v3H3V5H0V3h3V0h2v3h3V5z"/>
    </symbol>
  </svg>
  <!-- /SVG PLUS SMALL -->

  <!-- SVG MINUS SMALL -->
  <svg style="display: none;">
    <symbol id="svg-minus-small" viewBox="0 0 8 8" preserveAspectRatio="xMinYMin meet">
      <rect y="3" width="8" height="2"/>
    </symbol>
  </svg>
  <!-- /SVG MINUS SMALL -->

  <!-- SVG POLL -->
  <svg style="display: none;">
    <symbol id="svg-poll" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M12,14H2C0.896,14,0,14.896,0,16v2C0,19.104,0.896,20,2,20h10c1.105,0,2-0.896,2-2v-2C14,14.896,13.105,14,12,14z M12,18H2v-2h10V18z M18,7H2C0.896,7,0,7.896,0,9v2C0,12.104,0.896,13,2,13h16c1.104,0,2-0.896,2-2V9C20,7.896,19.104,7,18,7z M18,11H2V9h16V11z M2,6h4C7.105,6,8,5.104,8,4V2C8,0.896,7.105,0,6,0H2C0.896,0,0,0.896,0,2v2C0,5.104,0.896,6,2,6z M2,2h4v2H2V2z"/>
    </symbol>
  </svg>
  <!-- /SVG POLL -->

  <!-- SVG CAMERA -->
  <svg style="display: none;">
    <symbol id="svg-camera" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M13.018,2C13.505,2,13.81,2.018,14,2.041v1.964h2c1.103,0,2,0.896,2,1.999v9.998C18,17.104,17.103,18,16,18H4c-1.103,0-2-0.896-2-1.999V6.003c0-1.102,0.897-1.999,2-1.999h2V2.041C6.19,2.018,6.496,2,6.983,2l0.473,0.002L8,2.005h4l0.544-0.003L13.018,2 M13.018,0C12.692,0,12.352,0.005,12,0.005H8C7.648,0.005,7.308,0,6.983,0C5.267,0,4,0.148,4,2.005c-2.209,0-4,1.791-4,3.999v9.998C0,18.21,1.791,20,4,20h12c2.209,0,4-1.79,4-3.999V6.003c0-2.208-1.791-3.999-4-3.999C16,0.148,14.733,0,13.018,0L13.018,0z"/>
      <path d="M10,10c1.103,0,2,0.896,2,2c0,1.103-0.897,2-2,2c-1.103,0-2-0.897-2-2C8,10.896,8.897,10,10,10 M10,8c-2.209,0-4,1.791-4,4s1.791,4,4,4s4-1.791,4-4S12.209,8,10,8L10,8z"/>
      <path d="M15,6c0.553,0,1,0.448,1,1c0,0.553-0.447,1-1,1s-1-0.447-1-1C14,6.448,14.447,6,15,6z"/>
    </symbol>
  </svg>
  <!-- /SVG CAMERA -->

  <!-- SVG GIF -->
  <svg style="display: none;">
    <symbol id="svg-gif" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M3.676,5.098L2.243,3.665C1.025,5.153,0.201,6.975,0,8.995h2.053C2.237,7.534,2.822,6.206,3.676,5.098z M8.995,2.028V0C6.974,0.201,5.152,1.025,3.664,2.243l1.429,1.428
        C6.199,2.807,7.527,2.212,8.995,2.028z M16.336,2.243C14.847,1.025,13.025,0.201,11.006,0v2.028c1.468,0.184,2.795,0.778,3.902,1.643L16.336,2.243z M17.947,8.995H20c-0.201-2.021-1.025-3.842-2.242-5.33l-1.435,1.433C17.179,6.206,17.764,7.534,17.947,8.995z M2.053,11.005H0c0.201,2.021,1.025,3.842,2.243,5.33l1.433-1.434
        C2.822,13.793,2.237,12.466,2.053,11.005z M11.006,17.971V20c2.02-0.2,3.841-1.025,5.33-2.243l-1.428-1.428C13.801,17.193,12.474,17.787,11.006,17.971z M3.664,17.757C5.152,18.975,6.974,19.8,8.995,20v-2.029c-1.468-0.184-2.796-0.777-3.902-1.642L3.664,17.757z M16.323,14.901l1.435,1.434c1.217-1.488,2.041-3.31,2.242-5.33h-2.053C17.764,12.466,17.179,13.793,16.323,14.901z M15.025,11.005v-2.01H10v2.01h2.831c-0.416,1.168-1.521,2.01-2.831,2.01c-1.666,0-3.016-1.35-3.016-3.016c0-1.665,1.35-3.015,3.016-3.015h3.998C13.08,5.771,11.64,4.974,10,4.974c-2.776,0-5.026,2.25-5.026,5.025c0,2.774,2.25,5.025,5.026,5.025C12.432,15.024,14.56,13.298,15.025,11.005z"/>
    </symbol>
  </svg>
  <!-- /SVG GIF -->

  <!-- SVG TAGS -->
  <svg style="display: none;">
    <symbol id="svg-tags" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M20,13.001v2h-6L13,20h-2l1-4.999H6L5,20H3l1-4.999H0v-2h4.4L5.6,7H0V5h6l1-5h2L8,5h6l1-5h2l-1,5h4v2h-4.4L14.4,13.001H20z M13.6,7h-6l-1.2,6.001h6L13.6,7z"/>
    </symbol>
  </svg>
  <!-- /SVG TAGS -->

  <!-- SVG QUOTE -->
  <svg style="display: none;">
    <symbol id="svg-quote" viewBox="0 0 32 22" preserveAspectRatio="xMinYMin meet">
      <path d="M12.998,5l-1-5C3.897,0.622,0,4.275,0,12.904V22h10.998V11H6.997C6.597,7.035,8.106,5.854,12.998,5z M32,5l-1.001-5c-8.1,0.622-11.998,4.275-11.998,12.904V22h10.998V11h-4C25.599,7.035,27.107,5.854,32,5z"/>
    </symbol>
  </svg>
  <!-- /SVG QUOTE -->

  <!-- SVG PIN -->
  <svg style="display: none;">
    <symbol id="svg-pin" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,2c3.309,0,6,2.773,6,6.182c0,4.78-4.517,9.104-5.994,9.768C8.557,17.272,4,12.836,4,8.182C4,4.773,6.691,2,10,2 M10,0C5.581,0,2,3.663,2,8.182C2,14.291,8.192,20,10,20c1.807,0,8-5.596,8-11.818C18,3.663,14.418,0,10,0L10,0z"/>
      <path d="M10,6c1.103,0,2,0.897,2,2c0,1.103-0.897,2-2,2c-1.103,0-2-0.897-2-2C8,6.897,8.897,6,10,6 M10,4C7.791,4,6,5.791,6,8s1.791,4,4,4s4-1.791,4-4S12.209,4,10,4L10,4z"/>
    </symbol>
  </svg>
  <!-- /SVG PIN -->

  <!-- SVG TICKET -->
  <svg style="display: none;">
    <symbol id="svg-ticket" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M15,2v16h-1.537c-0.692-1.194-1.985-2-3.463-2s-2.771,0.806-3.463,2H5V2h1.537C7.229,3.195,8.522,4,10,4s2.771-0.805,3.463-2H15 M17,0h-5c0,1.104-0.896,2-2,2S8,1.104,8,0H3v20h5c0-1.104,0.896-2,2-2s2,0.896,2,2h5V0L17,0z"/>
      <rect x="7" y="7" width="6" height="2"/>
      <rect x="7" y="11" width="4" height="2"/>
    </symbol>
  </svg>
  <!-- /SVG TICKET -->

  <!-- SVG EVENTS MONTHLY -->
  <svg style="display: none;">
    <symbol id="svg-events-monthly" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,20H4c-2.209,0-4-1.791-4-4.001V6c0-2.209,1.791-4,4-4h1V0h2v2h6V0h2v2h1c2.209,0,4,1.791,4,4v9.999C20,18.209,18.209,20,16,20z M18,6c0-1.103-0.897-2-2-2h-1v2h-2V4H7v2H5V4H4C2.896,4,2,4.897,2,6v9.999C2,17.103,2.896,18,4,18h12c1.103,0,2-0.897,2-2.001V6z M13,13.001h2v2h-2V13.001z M13,9.001h2v2h-2V9.001z M9,13.001h2v2H9V13.001z M9,9.001h2v2H9V9.001z M5,13.001h2v2H5V13.001z M5,9.001h2v2H5V9.001z"/>
    </symbol>
  </svg>
  <!-- /SVG EVENTS MONTHLY -->

  <!-- SVG EVENTS WEEKLY -->
  <svg style="display: none;">
    <symbol id="svg-events-weekly" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,20H4c-2.209,0-4-1.791-4-4.001V6c0-2.209,1.791-4,4-4h1V0h2v2h6V0h2v2h1c2.209,0,4,1.791,4,4v9.999C20,18.209,18.209,20,16,20z M18,6c0-1.103-0.897-2-2-2h-1v2h-2V4H7v2H5V4H4C2.896,4,2,4.897,2,6v9.999C2,17.103,2.896,18,4,18h12c1.103,0,2-0.897,2-2.001V6z M5,13.001h10v2H5V13.001z M5,9.001h10v2H5V9.001z"/>
    </symbol>
  </svg>
  <!-- /SVG EVENTS WEEKLY -->

  <!-- SVG EVENTS DAILY -->
  <svg style="display: none;">
    <symbol id="svg-events-daily" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,20H4c-2.209,0-4-1.791-4-4.001V6c0-2.209,1.791-4,4-4h1V0h2v2h6V0h2v2h1c2.209,0,4,1.791,4,4v9.999C20,18.209,18.209,20,16,20z M18,6c0-1.103-0.897-2-2-2h-1v2h-2V4H7v2H5V4H4C2.896,4,2,4.897,2,6v9.999C2,17.103,2.896,18,4,18h12c1.103,0,2-0.897,2-2.001V6z M7,15.001H5v-2v-2v-2h2h6h2v2v2v2h-2H7zM13,11.001H7v2h6V11.001z"/>
    </symbol>
  </svg>
  <!-- /SVG EVENTS DAILY -->

  <!-- SVG INFO -->
  <svg style="display: none;">
    <symbol id="svg-info" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M9,15h2V9H9V15z M9,7h2V5H9V7z M16-0.001H4c-2.209,0-4,1.791-4,4v12c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4v-12C20,1.79,18.209-0.001,16-0.001z M18,15.999C18,17.102,17.103,18,16,18H4c-1.103,0-2-0.898-2-2.001v-12c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V15.999z"/>
    </symbol>
  </svg>
  <!-- /SVG INFO -->

  <!-- SVG CHECK -->
  <svg style="display: none;">
    <symbol id="svg-check" viewBox="0 0 12 9" preserveAspectRatio="xMinYMin meet">
      <path d="M12,1.5L4.5,9L3,7.499l0,0L0,4.5L1.5,3l3,3l6-6L12,1.5z"/>
    </symbol>
  </svg>
  <!-- /SVG CHECK -->

  <!-- SVG TROPHY -->
  <svg style="display: none;">
    <symbol id="svg-trophy" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M19.968,4.031C19.941,5.666,19.579,11,15.99,11h-0.125c-0.426,2.357-2.111,2.999-5.858,2.999c-3.748,0-5.434-0.642-5.859-2.999H4.009c-3.588,0-3.951-5.333-3.977-6.969L0,2h2.028h1.98h0.015V0H15.99v2l0,0h1.981H20L19.968,4.031z M4.009,3.999L1.994,4c0,0,0.112,4.999,2.015,4.999V3.999z M13.993,2H6.02v7.6c0,2.385,0.741,2.399,3.987,2.399c3.245,0,3.986-0.014,3.986-2.399V2z M17.972,3.999H15.99v5C17.893,8.999,18.006,4,18.006,4L17.972,3.999z M11.005,15.999H13c2.206,0,3.993,1.789,3.993,4h-1.989h-0.006c0-1.104-0.896-2.001-1.998-2.001h-1.995H9.009H7.013c-1.102,0-1.996,0.896-1.996,2.001H4.996H3.02c0-2.211,1.788-4,3.993-4h1.996v-2.001h0.998h0.998V15.999z"/>
    </symbol>
  </svg>
  <!-- /SVG TROPHY -->

  <!-- SVG CLOCK -->
  <svg style="display: none;">
    <symbol id="svg-clock" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M10,0C4.486,0,0,4.485,0,9.999c0,5.515,4.486,10,10,10c5.514,0,10-4.485,10-10C20,4.485,15.514,0,10,0zM10,17.999c-4.414,0-8.005-3.589-8.005-8S5.586,2,10,2c4.414,0,8.005,3.588,8.005,7.999S14.414,17.999,10,17.999z M14.437,12.105l-3.44-1.724V4.999C10.997,4.447,10.551,4,10,4S9.002,4.447,9.002,4.999V11c0,0.379,0.213,0.725,0.551,0.894l3.99,2C13.687,13.966,13.838,14,13.988,14c0.367,0,0.719-0.203,0.894-0.554C15.128,12.953,14.929,12.352,14.437,12.105z"/>
    </symbol>
  </svg>
  <!-- /SVG CLOCK -->

  <!-- SVG RETURN -->
  <svg style="display: none;">
    <symbol id="svg-return" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M19.12,13.852c-1.047,2.187-2.896,3.834-5.203,4.638C12.922,18.835,11.905,19,10.906,19c-3.751,0-7.273-2.313-8.59-6.006c-0.186-0.52,0.086-1.092,0.606-1.277c0.52-0.189,1.092,0.085,1.277,0.605c1.301,3.646,5.367,5.563,9.06,4.278c1.801-0.627,3.241-1.909,4.057-3.614c0.802-1.675,0.9-3.56,0.277-5.308c-1.3-3.644-5.363-5.562-9.06-4.278C7.541,3.746,6.623,4.316,5.877,5.052l-2.33,2.163h2.852c0.553,0,1,0.448,1,1c0,0.552-0.447,1-1,1H1c-0.007,0-0.013-0.004-0.019-0.004C0.901,9.21,0.826,9.185,0.749,9.164C0.699,9.151,0.646,9.149,0.601,9.13C0.555,9.109,0.518,9.071,0.474,9.044c-0.066-0.043-0.137-0.08-0.191-0.138C0.277,8.901,0.271,8.9,0.267,8.896C0.231,8.857,0.218,8.811,0.189,8.769C0.147,8.707,0.101,8.649,0.073,8.579C0.045,8.507,0.039,8.432,0.028,8.355C0.021,8.307,0,8.264,0,8.214V2.882c0-0.552,0.447-1,1-1c0.552,0,1,0.448,1,1v3.041l2.495-2.315c0.935-0.922,2.111-1.653,3.382-2.096c4.728-1.646,9.933,0.819,11.6,5.495C20.281,9.261,20.154,11.69,19.12,13.852z"/>
    </symbol>
  </svg>
  <!-- /SVG RETURN -->

  <!-- SVG DRIBBBLE -->
  <svg style="display: none;">
    <symbol id="svg-dribbble" viewBox="0 0 12 12" preserveAspectRatio="xMinYMin meet">
      <path d="M6,11.999c-3.313,0-6-2.687-6-6c0-3.313,2.687-6,6-6s6,2.686,6,6C12,9.313,9.313,11.999,6,11.999z M7.434,4.992C7.585,5.295,7.728,5.599,7.86,5.896c0.814-0.135,1.696-0.139,2.634,0.046c-0.01-0.874-0.272-1.686-0.713-2.373C9.062,4.167,8.257,4.632,7.434,4.992z M9.104,9.244c0.599-0.573,1.036-1.31,1.247-2.138c-0.484-0.085-1.184-0.15-2.008-0.054C8.681,7.915,8.935,8.684,9.104,9.244z M5.994,10.495c0.708,0,1.375-0.167,1.972-0.459C7.812,9.095,7.532,8.167,7.188,7.285C6.014,7.624,4.701,8.333,3.44,9.7C4.166,10.2,5.045,10.495,5.994,10.495z M2.556,8.897c0.8-0.837,2.245-2.097,4.159-2.722C6.593,5.913,6.466,5.659,6.337,5.409C4.395,6.05,2.505,6.189,1.501,6.212C1.551,7.233,1.938,8.165,2.556,8.897z M1.593,5.048c1.733-0.13,3.096-0.387,4.16-0.7c-0.613-1.05-1.216-1.906-1.624-2.45C2.851,2.481,1.894,3.642,1.593,5.048z M5.994,1.49c-0.258,0-0.509,0.027-0.754,0.068C5.864,2.29,6.409,3.113,6.885,3.955c1.091-0.447,1.737-0.937,2.108-1.311C8.197,1.929,7.148,1.49,5.994,1.49z"/>
    </symbol>
  </svg>
  <!-- /SVG DRIBBBLE -->

  <!-- SVG ITEM -->
  <svg style="display: none;">
    <symbol id="svg-item" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M19,5h-1.952C17.209,4,17.3,3.943,17.3,3.5c0-1.792-1.458-3.375-3.25-3.375c-2.035,0-3.296,1.306-4.049,2.653C9.248,1.431,7.985,0.031,5.95,0.031c-1.792,0-3.25,1.692-3.25,3.484C2.7,3.958,2.791,4,2.952,5H1C0.448,5,0,4.948,0,5.5V10c0,0.552,0.448,1,1,1h1v5c0,2.209,1.791,4,4,4h3h2h3c2.209,0,4-1.791,4-4v-5h1c0.553,0,1-0.448,1-1V5.5C20,4.948,19.553,5,19,5z M14.05,2.25c0.689,0,1.25,0.686,1.25,1.375S14.739,5,14.05,5h-2.693C11.788,3,12.626,2.25,14.05,2.25zM5.95,2.375C7.375,2.375,8.212,3,8.644,5H5.95C5.261,5,4.7,4.189,4.7,3.5S5.261,2.375,5.95,2.375z M9,18H6c-1.103,0-2-0.897-2-2v-5h5V18z M16,16c0,1.103-0.897,2-2,2h-3v-7h5V16z M18,9h-4h-3H9H5H2V7h3h0.95H9h2h3h0.05H18V9z"/>
    </symbol>
  </svg>
  <!-- /SVG ITEM -->

  <!-- SVG WALLET -->
  <svg style="display: none;">
    <symbol id="svg-wallet" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M20,9h-2V8c0-1.999-1.471-3.641-3.387-3.938l-0.395-1.548C13.832,0.999,12.527,0,11.104,0c-0.276,0-0.557,0.038-0.836,0.116l-7.79,2.194C1.155,2.684,0.2,3.72,0.033,5H0v3v1v7c0,2.209,1.791,4,4,4h10c2.209,0,4-1.791,4-4v-1h2V9z M10.81,2.042C10.908,2.014,11.006,2,11.104,2c0.543,0,1.025,0.415,1.178,1.008L12.534,4H4C3.924,4,3.853,4.018,3.778,4.022L10.81,2.042z M16,16c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2V9V8c0-1.103,0.897-2,2-2h10c1.103,0,2,0.897,2,2v1h-1c-1.657,0-3,1.344-3,3s1.343,3,3,3h1V16z M18,13h-3c-0.552,0-1-0.448-1-1s0.448-1,1-1h3V13z"/>
    </symbol>
  </svg>
  <!-- /SVG WALLET -->

  <!-- SVG EARNINGS -->
  <svg style="display: none;">
    <symbol id="svg-earnings" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,20h-1v-2h1c1.103,0,2-0.897,2-2v-1h2v1C20,18.209,18.209,20,16,20z M18,11h2v2h-2V11z M18,7h2v2h-2V7z M18,4c0-1.103-0.897-2-2-2h-1V0h1c2.209,0,4,1.791,4,4v1h-2V4zM8.75,9h2.5C12.767,9,14,10.346,14,12s-1.233,3-2.75,3H11v1H9v-1H7c-0.553,0-1-0.447-1-1s0.447-1,1-1h2h2h0.25c0.406,0,0.75-0.458,0.75-1s-0.344-1-0.75-1h-2.5C7.233,11,6,9.654,6,8s1.233-3,2.75-3H9V4h2v1h1.5c0.553,0,1,0.448,1,1s-0.447,1-1,1H11H9H8.75C8.344,7,8,7.458,8,8S8.344,9,8.75,9z M11,0h2v2h-2V0z M7,0h2v2H7V0z M0,16v-1h2v1c0,1.103,0.896,2,2,2h1v2H4C1.791,20,0,18.209,0,16z M2,4v1H0V4c0-2.209,1.791-4,4-4h1v2H4C2.896,2,2,2.897,2,4z M2,7v2H0V7H2z M2,13H0v-2h2V13z M9,20H7v-2h2V20z M13,20h-2v-2h2V20z"/>
    </symbol>
  </svg>
  <!-- /SVG EARNINGS -->

  <!-- SVG REVENUE -->
  <svg style="display: none;">
    <symbol id="svg-revenue" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M16,0H4C1.791,0,0,1.791,0,4v12c0,2.209,1.791,4,4,4h12c2.209,0,4-1.791,4-4V4C20,1.791,18.209,0,16,0zM18,16c0,1.103-0.897,2-2,2H4c-1.103,0-2-0.897-2-2V4c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V16z"/>
      <path d="M11.25,9h-2.5C8.344,9,8,8.542,8,8s0.344-1,0.75-1h3.75c0.553,0,1-0.448,1-1s-0.447-1-1-1H11V4H9v1H8.75C7.233,5,6,6.346,6,8s1.233,3,2.75,3h2.5c0.406,0,0.75,0.458,0.75,1s-0.344,1-0.75,1H7c-0.553,0-1,0.447-1,1s0.447,1,1,1h2v1h2v-1h0.25c1.517,0,2.75-1.346,2.75-3S12.767,9,11.25,9z"/>
    </symbol>
  </svg>
  <!-- /SVG REVENUE -->

  <!-- SVG LOGIN -->
  <svg style="display: none;">
    <symbol id="svg-login" viewBox="0 0 20 20" preserveAspectRatio="xMinYMin meet">
      <path d="M13,2.523v2.176c2.358,1.126,4,3.513,4,6.301c0,3.866-3.134,7-7,7s-7-3.134-7-7c0-2.788,1.642-5.175,4-6.301V2.523C3.507,3.76,1,7.083,1,11c0,4.971,4.029,9,9,9s9-4.029,9-9
      C19,7.083,16.493,3.76,13,2.523z M11,0H9v10h2V0z"/>
    </symbol>
  </svg>
  <!-- /SVG LOGIN -->
  <!-- SVG UPLOAD XLSX -->
  <svg style="display: none;">
  <symbol id="svg-upload-xlsx" viewBox="0 0 512 512" preserveAspectRatio="xMinYMin meet">
    <path fill="#FFF" d="M20.076 150.42H361.584V491.928H20.076z"/>
    <path
      fill="#B4BBCC"
      d="M361.582 512H20.076C8.988 512 0 503.012 0 491.924V150.419c0-11.088 8.988-20.076 20.076-20.076h341.505c11.088 0 20.076 8.988 20.076 20.076v341.505c.001 11.088-8.988 20.076-20.075 20.076zm-321.43-40.152h301.354V170.495H40.152v301.353z"
    />
    <path
      fill="#7DD2F0"
      d="M285.091 250.501c-12.672 0-22.944-10.272-22.944-22.944v-18.474L160.473 310.756l-32.292-32.292-29.629 134.772 134.772-29.629-32.292-32.291 100.814-100.814H285.09v-.001z"
    />
    <path
      d="M262.147 227.557v-18.474l-83.981 83.981c4.651 20.067 20.409 35.914 40.427 40.691l83.254-83.254h-16.756c-12.672 0-22.944-10.272-22.944-22.944z"
      enableBackground="new"
      opacity="0.1"
    />
    <path
      d="M361.582 130.343H176.747v40.152h164.759v164.759h14.065c2.018.228 4.029.343 6.011.343 1.982 0 3.993-.115 6.011-.343h14.065V150.419c0-11.088-8.989-20.076-20.076-20.076z"
      enableBackground="new"
      opacity="0.1"
    />
    <path fill="#FFF" d="M231.24 20.076H491.928V280.764H231.24z"/>
    <path
      fill="#C7CFE2"
      d="M491.925 0H231.239c-11.088 0-20.076 8.988-20.076 20.076V280.76c0 11.088 8.988 20.076 20.076 20.076h126.723c1.176.215 2.382.343 3.619.343s2.444-.128 3.619-.343h126.722c11.088 0 20.076-8.988 20.076-20.076V20.076C512.001 8.988 503.012 0 491.925 0zm-240.61 126.37h90.191v48.097h-90.191V126.37zm130.343 0h90.191v48.097h-90.191V126.37zm90.191-40.152h-90.191V40.152h90.191v46.066zM341.506 40.152v46.067h-90.191V40.152h90.191zm-90.191 174.467h90.191v46.067h-90.191v-46.067zm130.343 46.066v-46.067h90.191v46.067h-90.191z"
    />
    </symbol>
  </svg>
  <!-- /SVG UPLOAD XLSX -->
`;

svgWrap.innerHTML = svgData;
document.body.appendChild(svgWrap);
