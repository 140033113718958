import styled from 'styled-components';

export const Iframe = styled.iframe`
  width: 100%;
  height: 70vh;
  max-height: 100%;
`;

export const Tabela = styled.div`
  overflow-x: auto;
  table{
    width: 100%;
  }
  th{
    font-weight: 100;
    color: #3e3f5e;
    font-family: Rajdhani,sans-serif;
    line-height: 1em;
    padding: 0 20px 20px;
    white-space: nowrap;
  }
  td {
    padding: 20px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 8fr 2fr;
`;

export const Engagements = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 5fr 5fr;
`;

export const Nubers = styled.div`
text-align: center;
  .colors{
    width: 15px;
    height: 15px;
    margin: 0 auto;
    background-color: ${({ color }) => color};
  }
  p{
    color: #3e3f5e;
    font-family: Rajdhani,sans-serif;
    line-height: 1em;
    text-transform: uppercase;
  }
`;
