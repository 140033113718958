import { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import SectionHeaderProps from './SectionHeaderComponent.props'
import * as S from './style'

export default function SectionHeaderComponent ({ headerActions = [], pretitle, title }: SectionHeaderProps): ReactElement {
  return <S.Container>
    <S.HeaderInfo>
      <S.PreTitle>{pretitle}</S.PreTitle>
      <S.Title>{title}</S.Title>
    </S.HeaderInfo>

    <S.ActionsContainer>
      {headerActions.map(({ title, url }) => (
        <Link to={url}>{title}</Link>
      ))}
    </S.ActionsContainer>
  </S.Container>
}
