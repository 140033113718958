import { ChangeEvent, ReactElement } from 'react'
import SwitcherProps from './Switcher.props'
import * as S from './style'

export default function Switcher ({ checked, onChange }: SwitcherProps): ReactElement {
  const handleCheckboxChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    onChange(evt.target.checked, evt.target.value)
  }

  return <S.Label>
    <S.Input checked={checked} type="checkbox" onChange={handleCheckboxChange} />
    <span />
  </S.Label>
}
