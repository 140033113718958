import styled from 'styled-components'

export const ImgPreview = styled.img`
    width: 32px;
    height: 35px;
`

export const PostOpenContainer = styled.div`
    .post-open-cover {
        background-color: #3e3f5e;
    }
    .post-open-image {
        background-color: #615dfa;
    }
`

export const PostRelatedPreview = styled.div`
    max-width: 100% !important;
    padding: 0 100px !important;

    .post-preview-image {
        background-color: #615dfa;
    }
    .section-header-info {
        margin-left: 1rem;
    }
    .post-preview {
        padding-inline: 1rem;
    }

    @media screen and (max-width: 960px) {
        padding: 0 40px !important;
    }
`

export const BannerContainer = styled.div`
    overflow: hidden;
`

export const Banner = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`

export const PostCard = styled.div`
    width:85% !important;
    max-width: 1153px !important;
    position: relative;
    z-index: 999;
    .post-open-heading, .post-open-content {
        @media (max-width: 1365px) {
            padding: 0 5% !important;
        }
        
        h2 {
            @media (max-width: 1365px) {
                font-size: 2rem !important;
            }
        }
    }

    .post-open-content-body {
        width: 100% !important;
    }
`

export const Carousel = styled.div`
    overflow-x: scroll;
    padding-bottom: 0;

    &>div {
        display: flex;

        &>div {
            flex: 1 0 380px;
        }
    }
`
