import { ReactElement } from 'react'
import Card from '../../../../../../components/Card'
import HexagonComponent from '../../../../../../components/Hexagon'
import InputText from '../../../../../../components/Forms/InputText'

import * as S from './style'

import avatar from '../../../../../../assets/img/nick-grissom.png'

export default function ChatCard (): ReactElement {
  return <Card>
  <S.ChatContentContainer>
    <S.ChatHeaderContainer>
      <HexagonComponent className="chat-avatar" size="" src={avatar} />

      <S.ChatHeaderUserContainer>
        <p>Nick Grissom</p>

        <p>ONLINE</p>
      </S.ChatHeaderUserContainer>

      <S.DotsIcon>
        <svg className="post-settings-icon icon-more-dots">
          <use xlinkHref="#svg-more-dots"></use>
        </svg>
      </S.DotsIcon>
    </S.ChatHeaderContainer>

    <S.ChatMessagesContainer>
      <S.ChatMessageGroup>
        <img src={avatar} alt="" />
        <S.ChatMessageLeftContainer>

          <p>Hi Marina! It's been a long time!</p>

          <S.MessageTimestamp>Ontem às 8:36</S.MessageTimestamp>
        </S.ChatMessageLeftContainer>
      </S.ChatMessageGroup>

      <S.ChatMessageGroup>
        <S.ChatMessageRightContainer>
          <p>Hey Nick!</p>
        </S.ChatMessageRightContainer>

        <S.ChatMessageRightContainer>
          <p>You're right, it's been a really long time! I think the last time we saw was at Neko's party.</p>

          <S.MessageTimestamp>10:05</S.MessageTimestamp>
        </S.ChatMessageRightContainer>
      </S.ChatMessageGroup>

      <S.ChatMessageGroup>
        <img src={avatar} alt="" />

        <S.ChatMessageLeftContainer>
          <p>Yeah! I remember now! The stream launch party</p>
        </S.ChatMessageLeftContainer>

        <S.ChatMessageLeftContainer>
          <p>That reminds me that I wanted to ask you something</p>
        </S.ChatMessageLeftContainer>

        <S.ChatMessageLeftContainer>
          <p>Can you stream the new game?</p>
        </S.ChatMessageLeftContainer>

      </S.ChatMessageGroup>
    </S.ChatMessagesContainer>
  </S.ChatContentContainer>

  <S.ChatInputContainer>
    <InputText placeholder="Escreva uma mensagem...">
      <S.IconButton>
        <svg className="interactive-input-icon icon-camera">
          <use xlinkHref="#svg-camera"></use>
        </svg>
      </S.IconButton>
    </InputText>

    <S.SendMessageButton>
      <svg className="post-settings-icon icon-send-message">
        <use xlinkHref="#svg-send-message"></use>
      </svg>
    </S.SendMessageButton>
  </S.ChatInputContainer>
</Card>
}
