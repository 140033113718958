import { useCallback, useEffect, useState } from 'react';
import InputSelect from '../../../../components/Forms/InputSelect';
import api from '../../../../services/api';

export default function SeletorRegional ({ onChange }) {
  const [regionais, setRegionais] = useState();
  const setRegional = useCallback((regionalId) => {
    onChange(regionais.find(r => r.id === regionalId));
  }, [onChange, regionais]);
  const loadRegionais = useCallback(async () => {
    setRegionais();
    const response = await api.get('/regions');
    setRegionais(response.data.regions);
  }, []);
  useEffect(() => {
    loadRegionais();
  }, [loadRegionais]);
  return <InputSelect label="Selecione uma regional" onChange={(evt) => setRegional(evt)} large={true}>
    <option value="">Selecione uma regional</option>
    {regionais && regionais.map(r => <option value={r.id}>{r.name}</option>)}
  </InputSelect>;
}
