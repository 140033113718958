import styled from 'styled-components'

export const InterestsInfoContainer = styled.div`
  display: grid;
  gap: 1.75rem 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 124px;

  textarea {
    height: 100% !important;
    resize: none;
  }

  @media screen and (max-width: 1124px) {
    grid-template-columns: 1fr;
  }
`
