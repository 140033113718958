import { AxiosInstance } from 'axios'
import { CreatePolibrasProducts } from '../../domain/use-case/CreatePolibrasProducts'

export class ApiCreatePolibrasProducts implements CreatePolibrasProducts {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: CreatePolibrasProducts.Params): Promise<CreatePolibrasProducts.Result> {
    try {
      console.log(params)
      const response = await this.api.post('/polibras/product', params)
      return response
    } catch (error: any) {
      return error.response
    }
  }
}
