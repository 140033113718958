import axios from 'axios'
import { useEffect, useState } from 'react'
import PageLoader from '../../components/PageLoader'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import Template from "../../template"
import SectionConcepts from './SectionConcepts'
import SectionConditions from './SectionConditions'
import SectionPrize from './SectionPrize'
import SectionRules from './SectionRules'
import SectionTerms from './SectionTerms'
import SectionWaves from './SectionWaves'
import * as S from './style'

export const RegulamentoContent = () => {
  return <S.RegulamentoContainer className="widget-box">
    <SectionWaves />

    <SectionRules />

    <S.RegulamentoTitle>
      <h1>
        REGULAMENTO
        <br />
        CASA PROMODIGITAL 2023
      </h1>
    </S.RegulamentoTitle>

    <SectionConditions />

    <SectionConcepts />

    {/* <SectionCampaignFlow /> */}

    <SectionPrize />

    <SectionTerms />

  </S.RegulamentoContainer>
}

const States = {
  LOADING: 0,
  READY: 1,
  ERROR: 2
}

const handleDownloadProducts = async (fileLink) => {
  const response = await axios.get(fileLink, { responseType: 'arraybuffer' })
  const blob = new Blob([response.data])
  const anchor = document.createElement('a')
  const url = URL.createObjectURL(blob)
  anchor.href = url
  anchor.download = 'Tabela-de-pontos-completa.pdf'
  document.body.appendChild(anchor)
  anchor.click()
  setTimeout(function () {
    document.body.removeChild(anchor)
    window.URL.revokeObjectURL(url)
  }, 0)
}

window.handleDownloadProducts = handleDownloadProducts

function Regulamento () {
  const { user } = useAuth();
  const [state, setState] = useState({
    currentState: States.LOADING,
    content: null
  })

  useEffect(() => {
    (async () => {
      try {
        setState((state) => ({
          ...state,
          currentState: States.LOADING
        }))
        const response = await api.get('/regulation')
        setState((state) => ({
          currentState: States.READY,
          content: response.data.regulation.content
        }))
        return
      } catch (err) {
        setState((state) => ({
          ...state,
          currentState: States.ERROR,
        }))
      }
    })()
  }, [])

  if (state.currentState === States.LOADING) return <PageLoader />
  if (state.currentState === States.ERROR) return <Template>Ocorreu um erro, por favor tente novamente mais tarde</Template>
  if (state.currentState === States.READY) return <Template><div dangerouslySetInnerHTML={{ __html: state.content }}></div></Template>
}

export default Regulamento;
