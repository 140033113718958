import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import PageLoaderComponent from '../../../components/PageLoader'
import PostCard from '../../../components/PostCard'
import { useAuth } from '../../../contexts/AuthContext'
import { useCampaign } from '../../../contexts/CampaignContext'
import api from '../../../services/api'
import Template from '../../../template'
import * as S from './style'

function AdminBlog () {
  const { user, doLogout, userCan } = useAuth()
  const [posts, setPosts] = useState()
  const { campaignData } = useCampaign()

  const loadBlog = useCallback(async () => {
    try {
      const response = await api.get('/post')
      setPosts(response.data.posts)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          toast.error('O token expirou', { theme: 'colored' })
          doLogout()
        }
      }
    }
  }, [doLogout])

  useEffect(() => {
    loadBlog()
  }, [loadBlog])

  if (!user || !posts) return <PageLoaderComponent />

  return (
    <Template>
      <S.Grid>
        <S.HubContent className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <h2 className="section-title">Notícias {campaignData.title}</h2>
            </div>
          </div>

          <S.GridPosts>
            {userCan('post:create') && <S.NewPostContainer className="create-entity-box post-preview">
              <div className="create-entity-box-cover"></div>
              <div className="create-entity-box-avatar">
                <svg className="create-entity-box-avatar-icon icon-group">
                  <use href="#svg-group"></use>
                </svg>
              </div>
              <div className="create-entity-box-info">
                <p className="create-entity-box-title">Criar nova notícia</p>
                <p className="create-entity-box-text">Clique no botão para adicionar nova notícia</p>
                <Link to="/admin/blog/post" className="button primary full popup-manage-group-trigger">Criar nova notícia</Link>
              </div>
            </S.NewPostContainer>}
            {posts.map(post => <PostCard key={post.id} {...post} />)}
          </S.GridPosts>
        </S.HubContent>
      </S.Grid>

    </Template>
  )
}

export default AdminBlog
