import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../../../contexts/AuthContext';
import api from '../../../../../services/api';
import * as S from './style';

function ProfilePhotos () {
  const [modalState, setModalState] = useState('');
  const modalRef = useRef(null);
  const modalCotentRef = useRef(null);
  const [activePhoto, setActivePhoto] = useState();

  const { providerId } = useParams();
  const { userCan } = useAuth();
  const [loading, setLoading] = useState(true);
  const [currentPhotos, setCurrentPhotos] = useState();
  const [photosHistory, setPhotosHistory] = useState();

  const photoSlot = useCallback((slotIndex) => ({
    id: null,
    filePath: null,
    typeName: null,
    index: `Foto ${slotIndex}`,
    state: null,
    stateName: null,
    typeId: null
  }), []);

  const photoWithSlots = useMemo(() => {
    let photoSlots;
    if (userCan('photo:send')) {
      photoSlots = [photoSlot(1), photoSlot(2), photoSlot(3)];
    } else {
      photoSlots = [];
    }
    if (!currentPhotos) return photoSlot;
    const remaingPhotoSlots = photoSlots.slice(currentPhotos.length || 0, 3);
    return [...currentPhotos, ...remaingPhotoSlots];
  }, [currentPhotos, photoSlot, userCan]);

  const loadData = useCallback(async () => {
    setLoading(true);
    const response = await api.get(`/photos/${providerId || ''}`);
    setCurrentPhotos(response.data.currentPhotos);
    setPhotosHistory(response.data.photosHistory);
    setLoading(false);
  }, [providerId]);

  const handleClickOutside = useCallback((event) => {
    if (modalCotentRef.current && !modalCotentRef.current.contains(event.target)) {
      setModalState('closing');
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [modalCotentRef]);

  const handleOpenModal = useCallback((photo) => {
    setActivePhoto({ ...photo, validCode: false, sharpPhoto: false, exclusiveForTang: false });
    setModalState('opening');
    document.addEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const handleCloseModal = useCallback((callback) => {
    setModalState('closing');
    document.removeEventListener('mousedown', handleClickOutside);
    if (callback) {
      modalRef.current.addEventListener('animationend', () => {
        callback();
      }, { once: true });
    }
  }, [handleClickOutside]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loading) return <>Carregando....</>;
  return (
    <div className="account-hub-content">
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Minhas fotos</p>
          <h2 className="section-title">Informação das fotos</h2>
        </div>
      </div>

      <S.GridAlbums>
        <S.Disclaimer>
          <div>
            <h2>Pontos extras que serão considerados</h2>
            <p><strong>ILHA: </strong> Exposições feitas nos corredores entre as gôndolas. Podem ter 1 pallet ou mais.</p>
            <p><strong>Terminal de Gôndola: </strong> Exposições feitas nos corredores entre as gôndolas. Podem ter 1 pallet ou mais.</p>
          </div>

          <div>
            <h2>Pontos extras que NÃO serão considerados</h2>
            <p><strong>NÃO são considerados: </strong> Clip Strip/ Material em Carrinho/Orelha/ Cross Merchandising/ Papa Fila | display</p>
          </div>
        </S.Disclaimer>
        {photoWithSlots.length > 0
          ? photoWithSlots.map((p) => <S.Photo
            photo={p.filePath}
            type={p.typeName}
            stateName={p.stateName || 'Aguardando foto'}
            reason={p.reason}
            providerId={providerId}
            photoTypeId={p.typeId}
            index={p.index}
            month={p.month}
            createdAt={p.createdAt}
          />)
          : <>Seu distribuidor ainda não enviou nenhuma foto para essa loja</>}
      </S.GridAlbums>
      <S.GridPreview>
        <div className="section-header">
          <div className="section-header-info">
            <h2 className="section-title">Histórico de fotos</h2>
          </div>
        </div>
        <div>
          <S.GridAlbums>
            {photosHistory.map((p) => <S.PhotoContainer onClick={() => handleOpenModal(p)}>
              <S.Photo
                photo={p.filePath}
                type={p.typeName}
                stateName={p.stateName || 'Aguardando foto'}
                reason={p.reason}
                providerId={providerId}
                photoTypeId={p.typeId}
                month={p.month}
                createdAt={p.createdAt}
              />
            </S.PhotoContainer>)}
          </S.GridAlbums>
        </div>
      </S.GridPreview >

      <S.ContainerModal className={modalState} ref={modalRef}>
        <S.ContentLarge ref={modalCotentRef}>
          <div className="popup-close-button popup-picture-trigger" onClick={() => handleCloseModal()}>
            <svg className="popup-close-button-icon icon-cross">
              <use xlinkHref="#svg-cross"></use>
            </svg>
          </div>
          <S.Body>
            {activePhoto && <>
              <S.PhotoModal>
                <S.PhotoModalImage>
                  <S.PhotoModalImageContainer>
                    <S.PhotoLarge src={activePhoto.filePath} alt="Foto" />
                  </S.PhotoModalImageContainer>
                </S.PhotoModalImage>
              </S.PhotoModal>
            </>}
          </S.Body>
        </S.ContentLarge>
      </S.ContainerModal>
    </div>
  );
}

export default ProfilePhotos;
