import { FormEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ErrorComponent from '../../../components/Forms/ErrorComponent'
import InputSelect from '../../../components/Forms/InputSelect'
import InputText from '../../../components/Forms/InputText'
import ImageUpload from '../../../components/ImageUpload'
import PageLoaderComponent from '../../../components/PageLoader'
import RTEditor from '../../../components/RTEditor'
import { makeCreatePolibrasProducts } from '../../../main/helpers/makeCreatePolibrasProducts'
import { makeLoadPolibrasProductCategories } from '../../../main/helpers/makeLoadPolibrasProductCategories'
import Template from '../../../template'
import { CategoryProps, PolibrasNewProductError, PolibrasNewProductProps, PolibrasNewProductState } from './props'
import * as S from './style'

const createPolibrasProducts = makeCreatePolibrasProducts()
const loadPolibrasProductCategories = makeLoadPolibrasProductCategories()

function PolibrasNewProduct (): ReactElement {
  const [state, setState] = useState<PolibrasNewProductState>(PolibrasNewProductState.READY)
  const [categories, setCategories] = useState<CategoryProps[]>([])
  const [data, setData] = useState<PolibrasNewProductProps>({
    thumbs: []
  } as unknown as PolibrasNewProductProps)
  const [errors, setErrors] = useState<PolibrasNewProductError>({
    hasError: true,
    code: '',
    name: '',
    information: '',
    categoryId: ''
  } as unknown as PolibrasNewProductError)

  const navigate = useNavigate()

  const handleSubmit = useCallback(async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    const response = await createPolibrasProducts.handle(data)
    if (response.status === 200) {
      navigate(`/admin/produtos/${String(response.data.id)}`)
      return
    }

    switch (response.data.message) {
      case 'Missing param: name':
        toast.error('O nome é obrigatório', { theme: 'colored' })
        return
      case 'Missing param: code':
        toast.error('O código é obrigatório', { theme: 'colored' })
        return
      case 'Invalid param: details':
        toast.error('Os detalhes são inválidos', { theme: 'colored' })
        return
      case 'Missing param: information':
        toast.error('As informações são obrigatórias', { theme: 'colored' })
        return
      case 'Missing param: description':
        toast.error('A descrição é obrigatória', { theme: 'colored' })
        return
      case 'Value already exists: code':
        toast.error('Já existe um produto com esse código', { theme: 'colored' })
        return
      default:
        toast.error(response.data.message, { theme: 'colored' })
    }
  }, [data])

  const handleUpload = useCallback((imageData: any) => {
    setData(data => ({ ...data, thumbs: [...data.thumbs, imageData.image.path] }))
  }, [])

  const handleRemoveThumb = useCallback((index: number) => {
    const thumbs = data.thumbs
    thumbs.splice(index, 1)
    setData({ ...data, thumbs })
  }, [data])

  console.log(data)
  useEffect(() => {
    setErrors({
      hasError: false,
      code: '',
      name: '',
      information: '',
      categoryId: ''
    })

    if (!data.code) {
      setErrors(errors => ({
        ...errors,
        hasError: true,
        id: 'O campo código é obrigatório'
      }))
    }

    if (data.name === '') {
      setErrors(errors => ({
        ...errors,
        hasError: true,
        name: 'O campo nome é obrigatório'
      }))
    }

    if (data.categoryId === 0) {
      setErrors(errors => ({
        ...errors,
        hasError: true,
        categoryId: 'O campo categoria é obrigatório'
      }))
    }

    if (data.information === '<p><br></p>') {
      setErrors(errors => ({
        ...errors,
        hasError: true,
        information: 'O campo informações é obrigatório'
      }))
    }
  }, [data])

  useEffect(() => {
    (async () => {
      const response = await loadPolibrasProductCategories.handle()
      switch (response.status) {
        case 200:
          setCategories(response.data.categories)
          setState(PolibrasNewProductState.READY)
          return
        default:
          console.log(response.data)
      }
    })()
  }, [])

  if (state === PolibrasNewProductState.LOADING) return <PageLoaderComponent />

  return <Template>
    <S.Wrapper className="grid mobile-prefer-content">
      {state === PolibrasNewProductState.READY && <>
        <form onSubmit={handleSubmit} action="">
          <S.ThumbContainer>
            <S.ThumbWrapper>
              {data.thumbs.map((thumb, index) => <S.Thumb key={thumb}>
                <S.Remove type='button' className='primary button' onClick={() => handleRemoveThumb(index)}>&times;</S.Remove>
                <img src={thumb} alt="" />
              </S.Thumb>)}
            </S.ThumbWrapper>
            <ImageUpload title="Enviar thumbnail" text="110x110px tamanho minimo" onSend={handleUpload} />
          </S.ThumbContainer>
          <S.Row>
            <InputText label="Código" value={data.code} onChange={(code) => setData({ ...data, code })} error={errors.code} />
            <InputText label="Nome" value={data.name} onChange={(name) => setData({ ...data, name })} error={errors.name} />
            <InputSelect onChange={(value) => setData({ ...data, categoryId: Number.parseInt(value.toString()) })} error={errors.categoryId}>
              <option value={0}>Selecione uma categoria</option>
              {categories.map(category => <option value={category.id}>{category.name}</option>)}
            </InputSelect>
          </S.Row>
          <div>
            <S.EditorLabel>Informações</S.EditorLabel>
            <RTEditor onChange={(information: string) => setData({ ...data, information })} value={data.information} />
            <ErrorComponent>{errors.information}</ErrorComponent>
          </div>
          <button className='primary button' disabled={errors.hasError}>Salvar</button>
        </form>
      </>}
    </S.Wrapper>

  </Template >
}

export default PolibrasNewProduct
