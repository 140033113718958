import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import * as S from './styled';

function MenuButton ({ path, name, children, permission, title = true }) {
  const { userCan } = useAuth();
  const location = useLocation();
  if (permission && !userCan(permission)) return '';
  return <S.Li className={`menu-item ${location.pathname === path ? 'active' : ''}`}>
    <Link className="menu-item-link" to={path}>
      {children}
      {title && <S.DesktopTitle>
        {name}
      </S.DesktopTitle>}
      <S.Tooltip className="xm-tooltip">
        <p className="xm-tooltip-text">{name}</p>
      </S.Tooltip>
    </Link>
  </S.Li>;
}

export default MenuButton;
