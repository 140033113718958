import { useCallback, useEffect, useState } from 'react';
import InputSelect from '../../../../components/Forms/InputSelect';
import api from '../../../../services/api';

export default function SeletorGR ({ ga, onChange }) {
  const [ex, setEx] = useState();
  const loadEx = useCallback(async (params) => {
    setEx();
    const response = await api.get('/ex', { params });
    setEx(response.data.ex);
  }, []);
  useEffect(() => {
    if (!ga) return;
    const params = { ga };
    loadEx(params);
  }, [loadEx, ga]);
  if (!ga) {
    return <InputSelect label="Selecione um Executivo" onChange={(evt) => onChange(evt)} large={true} disabled={true}>
      <option value="">Aguardando GA...</option>
    </InputSelect>;
  }
  return <InputSelect label="Selecione um Executivo" onChange={(evt) => onChange(evt)} large={true}>
    <option value="">Selecione um Executivo</option>
    {ex && ex.map(r => <option value={r.name}>{r.name}</option>)}
  </InputSelect>;
}
