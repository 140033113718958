import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as S from './styled';

function PostCard ({ slug, createdAt, title, content, Banner, excerpt }) {
  const timestamp = useMemo(() => {
    return new Date(createdAt).toLocaleString('pt-BR');
  }, [createdAt]);
  return <div className="post-preview">
    <S.BannerFigure className="post-preview-image liquid">
      {Banner && <S.Banner src={Banner.path} alt="Banner" />}
    </S.BannerFigure>

    <S.CardCointainer className="post-preview-info fixed-height">
      <div className="post-preview-info-top">
        <p className="post-preview-timestamp">{timestamp}</p>

        <p className="post-preview-title">{title}</p>

        <p className="post-preview-text" dangerouslySetInnerHTML={{ __html: excerpt }}></p>
      </div>

      <div className="post-preview-info-bottom">
        <Link className="post-preview-link" to={`/blog/${slug}`}>Ler mais...</Link>
      </div>
    </S.CardCointainer>
  </div>;
}

export default PostCard;
