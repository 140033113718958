import styled from 'styled-components'

export const ChatMessageItemContainer = styled.div`
  border-left: 4px solid transparent;
  cursor: pointer;
  display: flex;
  padding: 1.25rem 1.75rem 1.25rem 1.5rem;
  pointer-events: all;
  gap: 12px;

  > div.chat-avatar {
    height: 2.5rem;
    width: 2.75rem;
  }

  &:hover, &.active {
    background-color: #EAEAF5;
    border-left-color: var(--secondary);
  }
`

export const ChatMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  width: 100%;

  p {
    font-size: 0.875rem;

    &:first-child {
      font-weight: 700;
    }

    &:last-child {
      margin-right: 32px;
      max-width: 260px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;


      @supports (-webkit-line-clamp: 2) {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  span {
    color: #8F91AC;
    font-size: 0.75rem;
    position: absolute;
    right: 0;
  }
`
