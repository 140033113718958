import { ReactElement } from 'react'
import AccountStatBoxGraph from './AccountStatBoxGraph'
import AccountStatBoxIcon from './AccountStatBoxIcon'
import AccountStatBoxTitle from './AccountStatBoxSubtitle'
import AccountStatBoxText from './AccountStatBoxText'
import DashboardCard from './DashboardCard'
import AccountStatBoxProps from './props'

function AccountStatBox ({ icon, title, children, subtitle, text, clearBg }: AccountStatBoxProps): ReactElement {
  return <DashboardCard>
    {(icon !== undefined) && <>
      <AccountStatBoxIcon clearBG={clearBg}>
        {(icon.match(/^(data||https?):/) !== null)
          ? <img src={icon} alt="" />
          : <svg className={`account-stat-box-icon ${icon}`}><use xlinkHref='#svg-friend'></use></svg>
        }
      </AccountStatBoxIcon>

      <AccountStatBoxTitle>
        {title}
      </AccountStatBoxTitle>
    </>}

    {icon === undefined && <AccountStatBoxGraph>
      {children}
    </AccountStatBoxGraph>}

    <AccountStatBoxTitle>
      {subtitle}
    </AccountStatBoxTitle>

    <AccountStatBoxText dangerouslySetInnerHTML={{ __html: text }} />
  </DashboardCard>
}

export default AccountStatBox
