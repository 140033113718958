export enum PolibrasProductsState {
  LOADING,
  ERROR,
  NO_CONTENT,
  READY
}

export interface PolibrasProductsData {
  products: any[]
}
