import { ReactElement } from 'react'
import Card from '../../../../../components/Card'
import InputSelect from '../../../../../components/Forms/InputSelect'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'

import * as S from './style'

export default function StoreDownloads (): ReactElement {
  const img = null

  return <>
    <SectionHeaderComponent
      pretitle="MINHA LOJA"
      title='Resgatar Vouchers'
    />

    <S.ContentContainer>
      <Card className="single-card">
        <S.FilterContainer>
          <InputSelect label="Filtrar">
            <option selected>Todas as Categorias</option>
          </InputSelect>

          <InputSelect label="Ordenar">
            <option selected>Data de Compra (Mais recentes primeiro)</option>
            <option>Data de Compra (Mais antigos primeiro)</option>
          </InputSelect>

          <button onClick={() => { }}>Filtrar Downloads!</button>
        </S.FilterContainer>
      </Card>

      <S.TableContainer>
        <S.SalesTable>
          <thead>
            <tr>
              <th>ITEM</th>
              <th>DATA</th>
              <th>LICENÇA</th>
              <th>PREÇO</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {Array.from(new Array(2)).map((el, i) => (
              <>
                <tr>
                  <td>
                    <S.ItemLink to="">
                      <figure>
                        {img !== null && <img alt="" src="" />}
                      </figure>
                    </S.ItemLink>

                    <S.ItemLinkContent>
                      <S.ItemLink to="">Gaming Coin Badges Pack</S.ItemLink>

                      <S.ItemLink to="">Illustrations</S.ItemLink>

                      <S.ItemLink to="">Nick Grissom</S.ItemLink>
                    </S.ItemLinkContent>
                  </td>
                  <td>Nov 15th, 2019</td>
                  <td>Regular</td>
                  <td>
                    <span>$</span>
                    {' '}
                    6.00
                  </td>
                  <td>
                    <S.ButtonsContainer>
                      <button>Resgatar</button>

                      <button>*****</button>
                    </S.ButtonsContainer>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </S.SalesTable>
      </S.TableContainer>
    </S.ContentContainer>
  </>
}
