import { ReactElement } from 'react'
import Card from '../../../../../../components/Card'
import * as S from './style'

import img1 from '../../../../../../assets/img/marketplace/items/13.jpg'

export default function TopSellers (): ReactElement {
  const img2 = null

  return <S.Container>
    <Card className="single-card">
      <S.TopSellersTitle>Top Sellers</S.TopSellersTitle>

      <S.TopSellersTable>
        <thead>
          <tr>
            <th>ITEM</th>
            <th>VENDAS</th>
            <th>VISUALIZAÇÕES</th>
          </tr>
        </thead>

        <tbody>
          {/* get data and map */}
          <tr>
            <td>
              <S.ItemLink to="">
                <figure>
                  {img2 !== null && <img alt="" src="" />}
                </figure>
              </S.ItemLink>
              <S.LinkContent>
                <S.ItemLink to="">Pixel Diamond Gaming Magazine</S.ItemLink>
                <S.ItemLink to="">HTML Templates</S.ItemLink>
              </S.LinkContent>
            </td>
            <td>130</td>
            <td>1750</td>
          </tr>
          <tr>
            <td>
              <S.ItemLink to="">
                <figure>
                  {img2 !== null && <img alt="" src="" />}
                </figure>
              </S.ItemLink>
              <S.LinkContent>
                <S.ItemLink to="">Twitch Stream UI Pack</S.ItemLink>
                <S.ItemLink to="">Stream Packs</S.ItemLink>
              </S.LinkContent>
            </td>
            <td>64</td>
            <td>1068</td>
          </tr>
          <tr>
            <td>
              <S.ItemLink to="">
                <figure>
                  {2 !== null && <img alt="" src={img1} />}
                </figure>
              </S.ItemLink>
              <S.LinkContent>
                <S.ItemLink to="">American Football Team Page</S.ItemLink>
                <S.ItemLink to="">HTML Templates</S.ItemLink>
              </S.LinkContent>
            </td>
            <td>22</td>
            <td>990</td>
          </tr>
        </tbody>
      </S.TopSellersTable>
    </Card>
  </S.Container>
}
