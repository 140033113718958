const categories = {
  profile: {
    description: 'Altere seu avatar e capa, aceite amigos, leia mensagens e muito mais!',
    links: [
      {
        linkTitle: 'Informações de Perfil',
        linkUrl: '',
        permission: ''
      },
      {
        linkTitle: 'Notificações',
        linkUrl: './newsfeed',
        permission: ''
      },
      {
        linkTitle: 'Menssagens',
        linkUrl: './messages',
        permission: 'message:manage'
      }
    ],
    svg: 'icon-profile',
    title: 'Meu Perfil',
    use: '#svg-profile'
  },
  account: {
    description: 'Altere configurações, defina notificações e reveja sua privacidade.',
    links: [
      {
        linkTitle: 'Alterar Senha',
        linkUrl: './account/password',
        permission: 'password:update'
      },
      {
        linkTitle: 'Configurações Gerais',
        linkUrl: './account/settings',
        permission: 'general-config:update'
      }
    ],
    svg: 'icon-settings',
    title: 'Conta',
    use: '#svg-settings'
  },
  store: {
    description: 'Revise sua conta, gerencie produtos, verifique estatísticas e muito mais!',
    links: [
      {
        linkTitle: 'Minha Conta',
        linkUrl: './store/account',
        permission: 'account:update'
      },
      {
        linkTitle: 'Demonstrativo de Resgates',
        linkUrl: './store/sales',
        permission: 'sells:update'
      },
      {
        linkTitle: 'Resgatar Vouchers',
        linkUrl: './store/downloads',
        permission: 'download:update'
      }
    ],
    svg: 'icon-store',
    title: 'Minha Loja',
    use: '#svg-store'
  }
}

export default categories
