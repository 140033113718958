import { ReactElement } from 'react'
import ProgressBar from '../ProgressBar'
import DashboardTableProps, { ContentType } from './props'

import * as S from './styled'

function TableCell ({ value }: { value: ContentType }): ReactElement {
  if (typeof value === 'object') {
    return <td><ProgressBar value={value.value} /></td>
  }

  return <td>{value}</td>
}

function DashboardTable ({ title, header, content, filter, onChange }: DashboardTableProps): ReactElement {
  return <S.Container>
    <S.Title>
      <p>{title}</p>

      {/* <S.FilterContainer>
        {(filter != null) && <>
          <select onChange={({ target: { value } }) => onChange(value)}>
            <option hidden value="">Selecione uma holding</option>
            {filter.values.map((f) => <option key={f.id} value={f.id}>{f.name}</option>)}
          </select>

          <S.FormSelectIcon>
            <use href="#svg-small-arrow"></use>
          </S.FormSelectIcon>
        </>}
      </S.FilterContainer> */}
    </S.Title>
    <S.Table>
      <thead>
        <tr>
          {header.map(h => <th key={h}>{h}</th>)}
        </tr>
      </thead>
      <tbody>
        {content.map((con, index) => <tr key={index}>{
          con.map((c, i) => <TableCell key={i} value={c} />)
        }</tr>)}
      </tbody>
    </S.Table>
  </S.Container>
}

export default DashboardTable
