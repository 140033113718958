import styled from "styled-components";

export const TermsSectionContainer = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  justify-content: center;
  padding: 2.875rem clamp(0rem, -2.6471rem + 11.7647vw, 11.5rem);
`

export const TermContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h2, ul li {
    color: var(--white);
    font-feature-settings: 'clig' off, 'liga' off;
  }

  h2 {
    font-family: MDLZBiteType, sans-serif;
    font-size: 45px;
    line-height: 3.4063rem;
    letter-spacing: -0.2188rem;
    text-transform: uppercase;
  }

  ul {
    padding-left: clamp(1rem, -1.3768rem + 10.1408vw, 7.75rem);

    li {
      font-family: Montserrat, sans-serif;
      font-size: 1.3125rem;
      line-height: 1.7188rem;
      list-style: disc;
    }
  }
`
