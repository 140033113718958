import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import BannerTitle from '../../assets/img/banner/accounthub-icon.png';
import BannerTitleComponent from '../../components/BannerTitle';
import PageLoaderComponent from '../../components/PageLoader';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import Template from '../../template';
import * as S from './style';

export default function StatusUsuarios() {
  const navigate = useNavigate();

  const [users, setUsers] = useState();
  const [filter] = useState('');

  const { userCan } = useAuth();

  const handleDownload = useCallback(async () => {
    const response = await api({
      url: '/user-status/export',
      method: 'GET',
      responseType: 'blob'
    });
    const href = URL.createObjectURL(response.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'user-status.xlsx'); // or any other extension
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  }, []);

  const loadUsers = useCallback(async (page) => {
    function handleLogout() {
      localStorage.clear();
      navigate('/');
    }
    try {
      const response = await api.get(`/user-status?page=${page}&filter=${filter}`);
      setUsers(response.data);
    } catch (err) {
      if (!err.response.data.message) {
        toast.error('Ocorreu um erro, por favor tente novamente mais tarde', { theme: 'colored' });
        return;
      }

      if (err.response.data.message === 'Token inválido') {
        handleLogout();
        toast.error(err.response.data.message, { theme: 'colored' });
        return;
      }

      toast.error(err.response.data.message, { theme: 'colored' });
    }
  }, [filter, navigate]);

  useEffect(() => {
    loadUsers(1);
  }, [loadUsers]);

  if (!users) return <PageLoaderComponent />;

  return (
    <Template>
      <BannerTitleComponent
        imgUrl={BannerTitle}
        title="Usuários"
        subTitle="Veja os status dos usuários cadastrados"
        imgAlt="newsfeed-icon"
      />

      <S.Tabela>
        <S.Wrapper className="grid mobile-prefer-content">
          {userCan('user-status:export') && <S.HeaderWrapper>
            <S.LinkButton onClick={handleDownload}>Exportar</S.LinkButton>
          </S.HeaderWrapper>}
          <div className="grid-column">
            <table>
              <tr>
                <th>Nome da Holding</th>
                <th>Ponto Focal</th>
                <th>Regional</th>
                <th>Status do cadastro</th>
              </tr>
              {users.map((user, index) => <tr key={index}>
                <td>{user.name}</td>
                <td>{user.focalPoint}</td>
                <td>{user.regionName}</td>
                <td>{user.status}</td>
              </tr>)}
            </table>
          </div>
        </S.Wrapper>
      </S.Tabela>
    </Template >
  );
}
