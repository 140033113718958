import styled, { keyframes } from 'styled-components';

const GrowAnimationFirst = keyframes`
  from {
    height: 1rem;
  }
  to {
    height: 18rem;
  }
`;

const GrowAnimationSecond = keyframes`
  from {
    height: 1rem;
  }
  to {
    height: 14.5rem;
  }
`;

const GrowAnimationThird = keyframes`
  from {
    height: 1rem;
  }
  to {
    height: 11.5rem;
  }
`;

export const RankingQuizContainer = styled.div`
  margin-top: 10rem;
  @media(min-width: 815px) {
    padding-inline: 10%;
  }
  .account-hub-content {
    position: relative;
  }
`;

export const SwitchModeButton = styled.div`
  p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 4rem;
    width: 45%;
    color: black;
    background-color: #fff;
    font-size: 2rem;
    font-weight: 400;
  }
  p.active {
    background-color: var(--secondary);
    color: #fff;
  }
  @media (max-width: 1280px) {
    margin-top: 14rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 12rem;
    p {
      margin-inline: auto;
      margin-top: 0.5rem;
      width: 90%;
      border-radius: 12px;
    }
  }
`;

export const RankingContainer = styled.div`
  padding: 4rem 3.75rem;
  margin-top: 3rem;
  @media(max-width: 768px) {
    padding: 2rem 0;
  }
`;

export const GraphicalRankingContainer = styled.div`
  grid-gap: 0;
  width: 100%;
  &&&.grid.grid-3-3-3 {
    grid-template-columns: repeat(3, minmax(0, 284px)) !important;
  }
  @media (max-width: 768px) {
    .user-short-description {
      padding-top: 2rem;
    }
  }
  `;

export const FirstPlace = styled.div`
  align-self: flex-end;
  div.user-short-description-title {
    p:first-of-type {
      margin-top: 1rem;
      font-size: 2rem;
      color: var(--gray);
    }
    p:last-of-type {
      margin-top: 0.5rem;
      font-size: 2rem;
      color: black;
    }
  }
  .label-position {
    position: absolute;
    display: flex;
    z-index: 99;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: 50%;
    margin-left: -1ch;
    margin-top: -1rem;
    background-color: #feb438;
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
  .step1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    background: linear-gradient(#feb438,80%,#fff);
    p {
      font-weight: 700;
      font-size: 7rem;
      color: white;
    }
    &.run-animation {
      animation: ${GrowAnimationFirst} 1s normal infinite ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
    &.static {
      height: 18rem;
    }
  }
  @media (max-width: 768px) {
    .user-avatar.medium {
      width: 80px;
      height: 92px;
      margin-left: -40px;
    }
    .step1 {
      p {
       font-size: 5rem;
      }
    }
    div.user-short-description-title {
      p:first-of-type {
        font-size: 1rem;
      }
      p:last-of-type {
        font-size: 1rem;
      }
    }
    .label-position {
      font-size: 1rem;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: -1ch;
    }
  }
  `;

export const SecondPlace = styled.div`
  align-self: flex-end;
  div.user-short-description-title {
    p:first-of-type {
      margin-top: 1rem;
      font-size: 2rem;
      color: var(--gray);
    }
    p:last-of-type {
      margin-top: 0.5rem;
      font-size: 2rem;
      color: black;
    }
  }
  .label-position {
    position: absolute;
    display: flex;
    z-index: 99;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: 50%;
    margin-left: -0.75ch;
    margin-top: -1rem;
    background-color: #b3d0f8;
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
  .step2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    background: linear-gradient(#b3d0f8,80%,#fff);
    p {
      font-weight: 700;
      font-size: 7rem;
      color: white;
    }
    &.run-animation {
      animation: ${GrowAnimationSecond} 1.5s normal infinite ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
    &.static {
      height: 14.5rem;
    }
  }
  @media (max-width: 768px) {
    .user-avatar.medium {
      width: 80px;
      height: 92px;
      margin-left: -40px;
    }
    .step2 {
      p {
       font-size: 5rem;
      }
    }
    div.user-short-description-title {
      p:first-of-type {
        font-size: 1rem;
      }
      p:last-of-type {
        font-size: 1rem;
      }
    }
    .label-position {
      font-size: 1rem;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: -1ch;
    }
  }
`;

export const ThirdPlace = styled.div`
  align-self: flex-end;
  div.user-short-description-title {
    p:first-of-type {
      margin-top: 1rem;
      font-size: 2rem;
      color: var(--gray);
    }
    p:last-of-type {
      margin-top: 0.5rem;
      font-size: 2rem;
      color: black;
    }
  }
  .label-position {
    position: absolute;
    display: flex;
    z-index: 99;
    justify-content: center;
    align-items: center;
    text-align: center;
    left: 50%;
    margin-left: -0.75ch;
    margin-top: -1rem;
    background-color: #47d5bf;
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }
  .step3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    background: linear-gradient(#47d5bf,80%,#fff);
    p {
      font-weight: 700;
      font-size: 7rem;
      color: white;
    }
    &.run-animation {
      animation: ${GrowAnimationThird} 2s normal infinite ease-in-out;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
    &.static {
      height: 11.5rem;
    }
  }
  @media (max-width: 768px) {
    .user-avatar.medium {
      width: 80px;
      height: 92px;
      margin-left: -40px;
    }
    .step3 {
      p {
       font-size: 5rem;
      }
    }
    div.user-short-description-title {
      p:first-of-type {
        font-size: 1rem;
      }
      p:last-of-type {
        font-size: 1rem;
      }
    }
    .label-position {
      font-size: 1rem;
      width: 1.25rem;
      height: 1.25rem;
      margin-left: -1ch;
    }
  }
`;

export const RankingList = styled.ol`
  margin-top: 5rem;
`;

export const RankingItem = styled.li`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  align-items: center;
  justify-content: space-between;
  .ranking-position {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
    width: 3.5rem;
    height: 3.5rem;
    padding: 0.15rem;
    background-color: #47d5bf;
    color: white;
  }
  .participant {
    font-weight: 700;
    font-size: 1.5rem;
  }
  .points {
    font-weight: 700;
    font-size: 1.5rem;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 5fr 1fr;
  }
`;

export const Center = styled.p`
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 3rem;
`;
