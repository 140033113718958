import { useMemo } from 'react'

export default function PaginationComponent ({ currentPage, totalPages, onChangePage }) {
  const pages = useMemo(() => {
    const maxPagesToShow = 5
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1)
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages)

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(1, endPage - maxPagesToShow + 1)
    }

    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)
  }, [currentPage, totalPages])

  if (totalPages <= 1) return null

  return (
    <div className="section-pager-bar" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <div className="section-pager" style={{ position: "initial" }}>
        {pages.map((page) => (
          <div key={page} className={`section-pager-item ${page === currentPage ? 'active' : ''}`}>
            <p className="section-pager-item-text" onClick={() => onChangePage(page)}>{page}</p>
          </div>
        ))}
      </div>
      <div className="section-pager-controls">
        <div
          className={`slider-control left ${currentPage === 1 && 'disabled'}`}
          onClick={() => onChangePage(1)}
        >
          <svg className="slider-control-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>
        <div
          className={`slider-control right ${currentPage === totalPages && 'disabled'}`}
          onClick={() => onChangePage(totalPages)}
        >
          <svg className="slider-control-icon icon-small-arrow">
            <use href="#svg-small-arrow"></use>
          </svg>
        </div>
      </div>
    </div>
  )
}