import { HTMLAttributes, ReactElement } from 'react'

import * as S from './styled'

function AccountStatBoxText ({ children, ...rest }: HTMLAttributes<HTMLDivElement>): ReactElement {
  return <S.Container {...rest}>
    {children}
  </S.Container>
}

export default AccountStatBoxText
