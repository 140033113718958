import { useMemo } from 'react';
import { useCampaign } from '../../../contexts/CampaignContext';
import { useDate } from '../../../hooks/useDate';
import Hexagon from '../../Hexagon';
import * as S from './style';

function Notice (props) {
  const { format } = useDate();
  const { campaignData } = useCampaign();
  const formattedDate = useMemo(() => {
    return format(props.createdAt);
  }, [props, format]);

  return <div className={`dropdown-box-list-item ${!props.isRead && 'unread'}`}>
    <div className="user-status notification">
      <a className="user-status-avatar" href="/">
        <div className="user-avatar small no-outline">
          <Hexagon src={props.sender.avatar?.path ? `${props.sender.avatar.path}` : campaignData.defaultAvatar} />
        </div>
      </a>
      <p className="user-status-title"><span className="bold">{props.sender.name}:</span> <S.MessageContainer className="ql-editor" dangerouslySetInnerHTML={{ __html: props.message }}></S.MessageContainer></p>
      <p className="user-status-timestamp">{formattedDate}</p>
    </div>
  </div>;
}

export default Notice;
