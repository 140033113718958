import styled from "styled-components";

export const ConceptsSectionContainer = styled.section`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0 4rem 3.75rem 8.25rem;


  @media screen and (max-width: 1366px) {
    grid-template-columns: 1fr;
    justify-content: center;
    padding-inline: 4rem;
  }

  > div:last-child {
    img {
      width: 118%;
    }

    @media screen and (max-width: 1366px) {
     display: none;
    }
  }
`

export const ConceptsCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  > h1 {
    color: #92FCFF;
    font-family: MDLZBiteType, sans-serif;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: clamp(3rem, 2.7134rem + 1.2739vw, 67.9232px);
    line-height: clamp(2rem, 1.5972rem + 1.7903vw, 3.75rem);
    letter-spacing: -0.2476rem;
  }

  > p {
    color: var(--white);
    font-family: Nunito, sans-serif;
    font-size: clamp(1rem, 0.9207rem + 0.3522vw, 1.3443rem);
    font-weight: 700;
    line-height: clamp(1.25rem, 1.0774rem + 0.7673vw, 2rem);
    margin-top: -1rem;
  }
`