import { ReactElement, useState } from 'react'
import InputSelect from '../../../../../../components/Forms/InputSelect'
import InputText from '../../../../../../components/Forms/InputText'

import * as S from './style'

const PROFESSIONAL_FIELD = {
  description: '',
  yearEnded: '',
  yearStarted: '',
  workField: ''
}

export default function ProfessionalInfo (): ReactElement {
  const [professionalFields, setProfessionalFields] = useState([PROFESSIONAL_FIELD])

  const handleProfessionalField = (): void => setProfessionalFields((prev) => [...prev, PROFESSIONAL_FIELD])

  return <S.ProfessionalContainer>
  {professionalFields.map((
    {
      description: workDescription,
      workField,
      yearEnded,
      yearStarted
    }, index) => (
      <S.ProfessionalContent key={index}>
        <InputText label="Formação ou Cargo" name="workField" value={workField} />

        <InputSelect label="Ano de início">
          <option disabled selected>Selecione um ano</option>
        </InputSelect>

        <InputSelect label="Ano de término">
          <option disabled selected>Selecione um ano</option>
        </InputSelect>

        <InputText
          as="textarea"
          label="Descrição"
          name="workDescription"
          value={workDescription}
        />
      </S.ProfessionalContent>
  ))}

  <S.AddFieldButton
    onClick={handleProfessionalField}
    type="button"
  >
    + Adicionar Novo Campo
  </S.AddFieldButton>
</S.ProfessionalContainer>
}
