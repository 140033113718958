import styled from 'styled-components';

export const ProgressContainer = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid #000;
  width: 90%;
  height: 20px;
  border-radius: 8px;
  margin-top: 16px;
`;

export const ProgressBar = styled.div`
  width: ${(props) => props.progressValue || 0}%;
  position:absolute;
  background-color:var(--primary);
  top: 0;
  bottom: 0;
`;
