export enum PolibrasEditProductState {
  LOADING,
  READY,
  ERROR,
  SUCCESS
}

export interface PolibrasEditProductProps {
  id: number
  code: string
  name: string
  description: string
  information: string
  categoryId: number
  details: Array<Record<string, string>>
  thumbs: string[]
}

export interface PolibrasEditProductError {
  hasError: boolean
  code: string
  name: string
  description: string
  information: string
  categoryId: string
  details: Array<Record<string, string>>
}
