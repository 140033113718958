import { ReactElement, useState } from 'react'
import EmailNotificationProps from './EmailNotification.props'
import Switcher from '../../../../../../components/Forms/Switcher'
import * as S from './style'

export default function EmailNotification ({ description, title }: EmailNotificationProps): ReactElement {
  const [isChecked, setIsChecked] = useState(false)

  return <S.SwitchOption>
    <S.SwitchTextContainer>
      <S.SwitchOptionTitle>{title}</S.SwitchOptionTitle>
      <S.SwitchOptionDescription>{description}</S.SwitchOptionDescription>
    </S.SwitchTextContainer>

    <Switcher checked={isChecked} onChange={setIsChecked} />
  </S.SwitchOption>
}
