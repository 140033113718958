import styled from 'styled-components'

export const ClubContainerBg = styled.div`
`

export const ClubContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 84px 0;
`

export const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-inline: 8px 4%;

  figure {
    width: fit-content;
  }
`

export const CashbackContainer = styled.div`
  margin-top: -12%;

  @media (max-width: 764px) {
    margin-top: -16%;
  }
`

export const TableContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-inline: 32px;
  position: relative;
  width: 100%;

  p {
    color: #103272;
    font-family: Inter, sans-serif;
    font-size: 52px;
    font-weight: 800;
    margin: 64px 0 24px;
    text-align: center;
    z-index: 1;
  }

  table {
    border-collapse: separate;
    border-spacing: 0;
    box-shadow: 10px 1px 20px 0 rgba(0, 0, 0, 0.33);
    font-family: "Open Sans", sans-serif;
    font-size: 18px;
    font-weight: 700;
    max-width: 56%;
    z-index: 1;

    thead {
      tr:first-child th:first-child {
        border-top-left-radius: 4px;
      }

      tr:first-child th:last-child {
        border-top-right-radius: 4px;
      }

      tr {
        background-color: #103272;
        height: 46px;

        th {
          border: 1px solid var(--white);
          color: var(--white);
          padding-inline: 16px;
          width: 50%;

          &:last-of-type {
            text-align: center;
          }
        }
      }
    }

    tbody {
      tr:last-child td:first-child {
        border-bottom-left-radius: 4px;
      }

      tr:last-child td:last-child {
        border-bottom-right-radius: 4px;
      }

      tr {
        border: 1px solid var(--white);
        height: 46px;

        th {
          background-color: rgba(0, 119, 181, 0.5);
          color: #103272;
          padding-inline: 16px;

          &:first-of-type {
            border-left: 1px solid var(--white);
          }

          &:last-of-type {
            border-right: 1px solid var(--white);
          }
        }

        td {
          background-color: rgba(255, 255, 255, 0.42);
          border: 1px solid var(--white);
          color: #0076B5;
          padding-inline: 16px;

          &:last-of-type {
            text-align: center;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    table {
      max-width: 64%;
    }
  }

  @media (max-width: 764px) {
    p {
      font-size: 20px;
      margin: 20px 0;
    }

    table {
      font-size: 8px;
      max-width: 72%;

      thead tr, tbody tr {
        height: 20px;
      }
    }

    figure {
      max-width: 32%;
    }
  }
`

export const BottomLeftPlane = styled.figure`
  left: 4%;
  position: absolute;
  top: 46%;

  img {
    max-width: 72%;
  }
`
export const BottomRightPlane = styled.figure`
  position: absolute;
  right: -2%;
  top: 68%;

  img {
    max-width: 72%;
  }
`
export const TopLeftPlane = styled.figure`
  left: 10%;
  position: absolute;
  top: 6%;

  img {
    max-width: 72%;
  }
`
export const TopRightPlane = styled.figure`
  position: absolute;
  right: 0;
  top: 2%;

  img {
    max-width: 72%;
  }
`
