import { isAxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InputSelect from '../../components/Forms/InputSelect';
import PageLoader from '../../components/PageLoader';
import { useAuth } from '../../contexts/AuthContext';
import { useWorksheetDate } from '../../hooks/useWorksheetDate';
import api from '../../services/api';
import Template from '../../template';
import RankingGeral from './RankingGeral';
import * as S from './style';

function RankingHomolog () {
  const [ranking, setRanking] = useState();
  const [stepId] = useState();
  const [channelId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [, setPeriod] = useState('');
  const [cargo, setCargo] = useState('gr');
  const { user, doLogout, userCan } = useAuth();
  const [channelName, setChannelName] = useState();
  const [roles, setRoles] = useState();
  const load = useWorksheetDate();
  const { worksheetId } = useParams();
  console.log(worksheetId);

  const loadPageData = useCallback(async () => {
    setIsLoading(true);
    try {
      const results = await api.get(`/ranking/${worksheetId}`, { params: { stepId: stepId || 1, channelId } });
      setRanking(results.data);
      setPeriod(await load(stepId || 1));
      setChannelName(results.data.channel.name);
      setRoles(results.data.roles);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          doLogout();
          return;
        }
      }
      toast.error('Ocorreu um erro inesperado, por favor tente novamente mais tarde!', { theme: 'colored' });
    }
    setIsLoading(false);
  }, [worksheetId, stepId, channelId, load, doLogout]);

  useEffect(() => {
    loadPageData();
  }, [loadPageData]);

  if (!ranking) return <PageLoader />;
  return <Template>

    <div className="grid medium-space">
      <div className="account-hub-content">

        <div className="grid-column">
          <div className="widget-box">
            <>
              <S.FiltrosCargo className={userCan('ranking-all:read') && 'large'}>
                <InputSelect label="" onChange={(cargo) => setCargo(cargo)} large={true}>
                  <option value="">Selecione um cargo</option>
                  {roles && Object.keys(roles).map(key => <option value={key}>{roles[key]}</option>)}
                </InputSelect>
              </S.FiltrosCargo>
              {!isLoading && <RankingGeral channel={channelName} onda={stepId} ranking={ranking[cargo].ranking} myPosition={ranking[cargo].myPosition} canal={user.canal} />}
              {isLoading && <S.Loading>Carregando...</S.Loading>}
            </>
          </div>

        </div>
      </div>
    </div>
  </Template>;
}

export default RankingHomolog;
