import { FormEvent, ReactElement, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ErrorComponent from '../../../components/Forms/ErrorComponent'
import InputSelect from '../../../components/Forms/InputSelect'
import InputText from '../../../components/Forms/InputText'
import ImageUpload from '../../../components/ImageUpload'
import PageLoaderComponent from '../../../components/PageLoader'
import RTEditor from '../../../components/RTEditor'
import { makeLoadPolibrasProduct } from '../../../main/helpers/makeLoadPolibrasProduct'
import { makeLoadPolibrasProductCategories } from '../../../main/helpers/makeLoadPolibrasProductCategories'
import { makeSavePolibrasProduct } from '../../../main/helpers/makeSavePolibrasProduct'
import Template from '../../../template'
import { CategoryProps } from '../PolibrasNewProduct/props'
import { PolibrasEditProductError, PolibrasEditProductProps, PolibrasEditProductState } from './props'
import * as S from './style'

const loadPolibrasProduct = makeLoadPolibrasProduct()
const savePolibrasProducts = makeSavePolibrasProduct()
const loadPolibrasProductCategories = makeLoadPolibrasProductCategories()

function PolibrasEditProduct (): ReactElement {
  const [state, setState] = useState<PolibrasEditProductState>(PolibrasEditProductState.READY)
  const [categories, setCategories] = useState<CategoryProps[]>([])
  const [data, setData] = useState<PolibrasEditProductProps>({ thumbs: [], details: [{ name: 'Detalhes', content: '' }] } as unknown as PolibrasEditProductProps)
  const [information, setInformation] = useState<string>('')
  const [errors, setErrors] = useState<PolibrasEditProductError>({
    hasError: true,
    name: '',
    information: '',
    categoryId: '',
    details: []
  } as unknown as PolibrasEditProductError)

  const { productId } = useParams()
  const navigate = useNavigate()

  const handleSubmit = useCallback(async (evt: FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    if (!productId) return
    const response = await savePolibrasProducts.handle(data, productId)
    if (response.status === 200) {
      navigate('/admin/produtos')
      return
    }

    switch (response.data.message) {
      case 'Missing param: name':
        toast.error('O nome é obrigatório', { theme: 'colored' })
        return
      case 'Missing param: code':
        toast.error('O código é obrigatório', { theme: 'colored' })
        return
      case 'Invalid param: details':
        toast.error('Os detalhes são inválidos', { theme: 'colored' })
        return
      case 'Missing param: information':
        toast.error('As informações são obrigatórias', { theme: 'colored' })
        return
      case 'Missing param: description':
        toast.error('A descrição é obrigatória', { theme: 'colored' })
        return
      case 'Value already exists: code':
        toast.error('Já existe um produto com esse código', { theme: 'colored' })
        return
      default:
        toast.error(response.data.message, { theme: 'colored' })
    }
  }, [data])

  const handleUpload = useCallback((imageData: any) => {
    setData(data => ({ ...data, thumbs: [...data.thumbs, imageData.image.path] }))
  }, [])

  const handleRemoveThumb = useCallback((index: number) => {
    const thumbs = data.thumbs
    thumbs.splice(index, 1)
    setData({ ...data, thumbs })
  }, [data])

  useEffect(() => {
    setErrors(errors => ({
      hasError: false,
      code: '',
      name: '',
      description: '',
      information: '',
      categoryId: '',
      details: []
    }))

    if (!data.code) {
      setErrors(errors => ({
        ...errors,
        hasError: true,
        code: 'O campo código é obrigatório'
      }))
    }

    if (data.name === '') {
      setErrors(errors => ({
        ...errors,
        hasError: true,
        name: 'O campo nome é obrigatório'
      }))
    }

    if (data.categoryId === 0) {
      setErrors(errors => ({
        ...errors,
        hasError: true,
        categoryId: 'O campo categoria é obrigatório'
      }))
    }

    if (data.information === '<p><br></p>') {
      setErrors(errors => ({
        ...errors,
        hasError: true,
        information: 'O campo informações é obrigatório'
      }))
    }
  }, [data, information])

  useEffect(() => {
    setData((data) => ({
      ...data,
      information
    }))
  }, [information])

  useEffect(() => {
    (async () => {
      const categoryResponse = await loadPolibrasProductCategories.handle()
      switch (categoryResponse.status) {
        case 200:
          setCategories(categoryResponse.data.categories)
      }

      const response = await loadPolibrasProduct.handle({ id: String(productId) })
      setData(response.data)
      setInformation(response.data.information)
      setState(PolibrasEditProductState.READY)
    })()
  }, [])

  if (state === PolibrasEditProductState.LOADING) return <PageLoaderComponent />

  return <Template>
    <S.Wrapper className="grid mobile-prefer-content">
      {state === PolibrasEditProductState.READY && <>
        <form onSubmit={handleSubmit} action="">
          <S.ThumbContainer>
            <S.ThumbWrapper>
              {data.thumbs.map((thumb, index) => <S.Thumb>
                <S.Remove type='button' className='primary button' onClick={() => handleRemoveThumb(index)}>&times;</S.Remove>
                <img src={thumb} alt="" />
              </S.Thumb>)}
            </S.ThumbWrapper>
            <ImageUpload title="Enviar thumbnail" text="110x110px tamanho minimo" onSend={handleUpload} />
          </S.ThumbContainer>
          <S.Row>
            <InputText label="Código" value={data.code} onChange={(code) => setData({ ...data, code })} error={errors.code} />
            <InputText label="Nome" value={data.name} onChange={(name) => setData({ ...data, name })} error={errors.name} />
            <InputSelect defaultValue={data.categoryId} onChange={(value) => setData({ ...data, categoryId: Number.parseInt(value.toString()) })} error={errors.categoryId}>
              <option value={0}>Selecione uma categoria</option>
              {categories.map(category => <option selected={category.id === data.categoryId} value={category.id}>{category.name}</option>)}
            </InputSelect>
          </S.Row>
          <div>
            <S.EditorLabel>Informações</S.EditorLabel>
            <RTEditor onChange={(value: string) => setInformation(value)} value={information} />
            <ErrorComponent>{errors.information}</ErrorComponent>
          </div>
          <button className='primary button' disabled={errors.hasError}>Salvar</button>
        </form>
      </>}
    </S.Wrapper>

  </Template >
}

export default PolibrasEditProduct
