import { ReactElement } from 'react'
import SocialButton from '..'
import googleIcon from '../../../assets/img/google.svg'

interface IButtonGoogle {
  userId?: string
}

function Google ({ userId }: IButtonGoogle): ReactElement {
  const queryParam = userId !== undefined ? `?userId=${userId}` : ''
  const loginUrl = `${String(process.env.REACT_APP_BASE_URL)}google/login${queryParam}`
  const icon = <img src={googleIcon} alt="Google" />
  return (
    <SocialButton
      clientLoginUrl={loginUrl}
      icon={icon}
      buttonName={'Acesse com o Google'}
    />
  )
}

export default Google
