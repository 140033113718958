import styled from 'styled-components';

export const HexagonContainer = styled.div`
  width: 40px;
  aspect-ratio: 1;
`;

export const GridNewsfeed = styled.div`
  .section-banner {
    display: none;
  }
  .content-grid {
    padding-top: 0;
  }
`;
