import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: .75rem;

  &>div {
    flex: 1;
  }
`;
