import styled from 'styled-components'

export const FilterContainer = styled.form`
  > div {
    align-items: center;
    gap: 1rem;
    max-width: 100%;
    padding: 1.5rem 1.75rem;

    > div:has(select) {
      height: 3rem;
    }

    @media screen and (min-width: 960px) {
      flex-direction: row;

      > div:has(select) {
      max-width: 300px;
     }
    }
  }
`

export const SearchButton = styled.button`
  background-color: var(--primary);
  border-radius: 0.625rem;
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 700;
  height: 3rem;
  line-height: 3rem;
  padding-inline: 1rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &:hover {
    background-color: var(--hover);
  }

  @media screen and (min-width: 481px) {
    max-width: 180px;
  }
`

export const CardsContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, 284px);
  justify-content: center;
  margin-top: 2rem;

  @media screen and (max-width: 460px) {
    grid-template-columns: 100%;
  }
`
