import { useMemo } from 'react';
import { useCampaign } from '../../contexts/CampaignContext';
import { useDate } from '../../hooks/useDate';
import HexagonComponent from '../Hexagon';

function UserStatus ({ user, createdAt }) {
  const { format } = useDate();
  const { campaignData } = useCampaign();
  const time = useMemo(() => {
    return format(createdAt);
  }, [createdAt, format]);
  return <div className="user-status">
    <span className="user-status-avatar">
      <div className="user-avatar small no-outline">
        <HexagonComponent src={user.avatar?.path ? `${user.avatar.path}` : campaignData?.defaultAvatar} />
      </div>
    </span>

    <p className="user-status-title medium"><span className="bold">{user.name}</span> postou <span className="bold">um aviso</span></p>
    <p className="user-status-text small">{time}</p>
  </div>;
}

export default UserStatus;
