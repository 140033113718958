import { ReactElement } from 'react'
import Card from '../../../../../../components/Card'

import * as S from './style'

export default function Statistics (): ReactElement {
  return <>
    <Card className="single-card">
      <S.Statistics className="main-stat">
        <S.BalanceContainer>
          <S.Icon>
            <svg className="icon-wallet">
              <use href="#svg-wallet"></use>
            </svg>
          </S.Icon>

          <S.StatContent>
            <S.StatTitle>
              250.32
              {' '}
              <span>PONTOS</span>
            </S.StatTitle>

            <S.StatDescription>
              SALDO DA CONTA
            </S.StatDescription>
          </S.StatContent>
        </S.BalanceContainer>

        <S.UserStatsContainer>
          <S.UserStat>
            <S.StatTitle>7</S.StatTitle>
            <S.StatDescription>ITENS</S.StatDescription>
          </S.UserStat>

          <S.Divider />

          <S.UserStat>
            <S.StatTitle>9</S.StatTitle>
            <S.StatDescription>AVALIAÇÕES</S.StatDescription>
          </S.UserStat>

          <S.Divider />

          <S.UserStat>
            <S.StatTitle>361</S.StatTitle>
            <S.StatDescription>VISUALIZAÇÕES</S.StatDescription>
          </S.UserStat>
        </S.UserStatsContainer>
      </S.Statistics>
    </Card>

    <S.GridContainer>
      <Card className="single-card">
        <S.Statistics>
          <S.Icon>
            <svg className="icon-item">
              <use href="#svg-item"></use>
            </svg>
          </S.Icon>

          <S.StatContent>
            <S.StatTitle>298</S.StatTitle>

            <S.StatDescription>
              TOTAL DE ITENS RESGATADOS
            </S.StatDescription>
          </S.StatContent>
        </S.Statistics>
      </Card>

      <Card className="single-card">
        <S.Statistics>
          <S.Icon>
            <svg className="icon-earnings">
              <use href="#svg-earnings"></use>
            </svg>
          </S.Icon>

          <S.StatContent>
            <S.StatTitle>
              1.925
              {' '}
              <span>PONTOS</span>
            </S.StatTitle>

            <S.StatDescription>
              PONTOS GASTOS
            </S.StatDescription>
          </S.StatContent>
        </S.Statistics>
      </Card>

      <Card className="single-card">
        <S.Statistics>
          <S.Icon>
            <svg className="icon-revenue">
              <use href="#svg-revenue"></use>
            </svg>
          </S.Icon>

          <S.StatContent>
            <S.StatTitle>
              2.203
              {' '}
              <span>PONTOS</span>
            </S.StatTitle>

            <S.StatDescription>
              PONTOS ADQUIRIDOS
            </S.StatDescription>
          </S.StatContent>
        </S.Statistics>
      </Card>
    </S.GridContainer>
  </>
}
