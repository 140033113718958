import { ReactElement, useEffect, useState } from 'react'
import bronzeBadgeLg from '../../assets/img/badge/bronze-b.png'
import CardBadge from '../../components/Card/CardBadge'
import PageLoaderComponent from '../../components/PageLoader'
import api from '../../services/api'
import Template from '../../template'

export default function Badges (): ReactElement {
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    api.get('/quest/my-quests', {
      params: {
        page: 1,
        type: 'badges'
      }
    }).then(result => {
      console.log(result.data)
      setData(result.data)
    })
  }, [])

  if (data == null) {
    return <PageLoaderComponent />
  }

  return (
    <Template>
      <div className="grid grid-3-3-3-3 top-space centered">
        {data.content.map((item: any): any => <CardBadge
          badgeSrcImg={bronzeBadgeLg}
          badgeText={item.quest.description}
          badgeTitle={item.quest.name}
          exp={item.quest.questRewards[0].value}
          key={item.quest.id}
          max={item.max}
          progress={item.progress}
          unlocked={item.unlocked}
        />)}
      </div>
    </Template>
  )
}
