import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 10px;
  color: #3e3f5e;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
  text-align: center;
`
