import { useEffect, useState, useMemo, MutableRefObject } from 'react'

export default function useOnScreen (ref: MutableRefObject<any>, data: any): boolean {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [])

  useEffect(() => {
    if (data == null) return
    if (ref.current == null) return

    observer.observe(ref.current)

    return () => observer.disconnect()
  }, [observer, ref, data])

  return isIntersecting
}
