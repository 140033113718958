import styled from 'styled-components'

export const AwesomeMenuIconSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 48px;
  pointer-events: none;
  transition: all .2s ease-in-out;
  color: #adafca;
`
