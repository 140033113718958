import styled from 'styled-components'

export const CardContainer = styled.div`
  background-color: var(--white);
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.06);
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  width: 100%;
`
