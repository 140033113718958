import styled from 'styled-components'

export const ProfessionalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.75rem;
`

export const ProfessionalContent = styled.div`
  display: grid;
  gap: 1.75rem 1rem;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 3rem;

  textarea {
    height: 100% !important;
    resize: none;
  }

  div:first-child {
    grid-area: 1 / 1 / 2 / 3;
  }

  div:last-child {
    grid-area: 2 / 1 / 4 / 5;
  }

  @media screen and (max-width: 1124px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 3rem) 7.75rem;

    div:first-child {
      grid-area: 1;
    }

    div:last-child {
      grid-area: 4;
    }
  }
`

export const AddFieldButton = styled.button`
  background-color: transparent;
  border: 1px solid #dedeea;
  box-shadow: none;
  color: #adafca;
  font-size: 0.75rem;
  line-height: 2.5rem;
  padding-inline: 1rem;
  margin-top: 0.75rem;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: fit-content;

  &:hover {
    background-color: #2e2e47;
    border-color: #2e2e47;
    color: var(--white);
  }
`
