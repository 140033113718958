import { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useCart } from '../../../contexts/CartContext'
import { useClickOutside } from '../../../hooks/useClickOutside'
import { currency } from '../../../utils/format'
import * as S from '../style'
import CartHeaderItem from './CartHeaderItem'

export default function CartComponent () {
  const { cart } = useCart()
  const [activeCart, setActiveCart] = useState(false)
  const [wrapperRef] = useClickOutside(() => setActiveCart(false))

  const subtotal = useCallback(() => {
    return 0
  }, [cart])

  const toggleCart = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveCart(a => !a)
  }, [])

  if (!cart) return <>...</>

  return <div className="action-list">
    <div className="action-list-item-wrap">
      <div className="action-list-item header-dropdown-trigger" onClick={toggleCart}>
        <svg className="icon-shopping-bag">
          <use href="#svg-shopping-bag"></use>
        </svg>
      </div>

      <div ref={wrapperRef}>
        <S.Dropbox active={activeCart} className="dropdown-box no-padding-bottom header-dropdown">
          {cart ? <>
            <div className="dropdown-box-header">
              <p className="dropdown-box-header-title">Carrinho <span className="highlighted">{cart.items.length}</span></p>
            </div>
            {cart.items.map(c => <CartHeaderItem {...c} />)}
            <div className="cart-preview-total">
              <p className="cart-preview-total-title">Total:</p>
              <p className="cart-preview-total-text">{currency(cart.total)}</p>
            </div>
            <div className="dropdown-box-actions">
              <div className="dropdown-box-action">
                <Link className="button secondary" to="/cart">Carrinho</Link>
              </div>
              <div className="dropdown-box-action">
                <Link className="button primary" to="/checkout">Finalizar pedido</Link>
              </div>
            </div>
          </> : <>Carregando...</>}
        </S.Dropbox>
      </div>
    </div>
  </div>
}