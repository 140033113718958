import * as S from './style';

/**
 *
 * @param {Object} props
 * @param {number} props.progress - A range from 1 to 10
 * @returns
 */
function ProgressBar (props) {
  return (
    <S.ProgressBar {...props} />
  );
}

export default ProgressBar;
