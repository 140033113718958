import Marketplace from '../../../Marketplace';
import { GridMarketPlace } from './style';

function ProfileMarketplace () {
  return (
  <GridMarketPlace>
    <Marketplace />
  </GridMarketPlace>
  );
}

export default ProfileMarketplace;
