import { useCallback, useEffect, useState } from 'react';
import InputSelect from '../Forms/InputSelect';
import api from '../../services/api';

function RoleSelectorComponent ({ value, onChange, ...props }) {
  const [roles, setRoles] = useState([]);

  const loadRoles = useCallback(async () => {
    const response = await api.get('/roles');
    setRoles(response.data.roles);
  }, []);

  const onChangeParse = useCallback((value) => {
    if (!value) return {};
    onChange(JSON.parse(value));
  }, [onChange]);

  useEffect(() => {
    loadRoles();
  }, [loadRoles]);

  return <InputSelect onChange={onChangeParse} label="Função" {...props}>
    <option value="" key="1">Selecione a função</option>
    {roles.length > 0 && roles.map(r => <option key={r.id} value={JSON.stringify(r)} selected={value === r.id}>{r.name}</option>)}
  </InputSelect>;
}

export default RoleSelectorComponent;
