import styled, { css } from 'styled-components'
import CardBadgeProps from './CardBadge.props'

export const CardBadge = styled.div<Pick<CardBadgeProps, 'unlocked'>>`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  justify-content: center;
  padding: 32px 28px;
  position: relative;
  text-align: center;

  ${({ unlocked }) => css`
    filter: ${(unlocked === false) && 'grayscale(1)'};
    color: ${(unlocked === false) && '#aeaeae'};
  `}
`

export const SmallBadge = styled.img`
  height: 35px;
  left: 28px;
  position: absolute;
  top: 32px;
  width: 32px;
`

export const Exp = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 200px;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.12);
  display: flex;
  height: 32px;
  padding-inline: 14px;
  position: absolute;
  right: -6px;
  top: 10px;

  p, span {
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0;
  }

  span {
    color: var(--secondary);
  }
`

export const Title = styled.p<Pick<CardBadgeProps, 'unlocked'>>`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 36px 0 16px;
  text-align: center;

  ${({ unlocked }) => css`
    filter: ${(unlocked === false) && 'grayscale(1)'};
    color: ${(unlocked === false) && '#aeaeae'};
  `}
`

export const Text = styled.p`
  font-size: 0.875rem;
  line-height: 1.4285714286em;
  font-weight: 500;
  margin: 0 24px;
  text-align: center;
  width: 180px;
`

export const ProgressContainer = styled.div`
  margin: 54px 12px 0;
  max-width: 204px;
  width: 100%;
`

export const ProgressStatus = styled.p`
  font-size: 0.75rem;
  font-weight: 700;
  margin: 20px 0 0;
  text-transform: uppercase;
`
