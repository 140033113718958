import { ReactElement } from 'react'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'
import ChatCard from './ChatCard'
import ChatMessagesMenu from './ChatMessagesMenu'

import * as S from './style'

export default function ProfileMessages (): ReactElement {
  return <>
    <SectionHeaderComponent pretitle="MEU PERFIL" title="Mensagens" />

    <S.ChatContainer>
      <ChatMessagesMenu />
      <ChatCard />
    </S.ChatContainer>
  </>
}
