import { Dispatch, PropsWithChildren, ReactElement, SetStateAction, createContext, useContext, useEffect, useState } from 'react'
import { Socket, io } from 'socket.io-client'
import { useAuth } from '../AuthContext'
import * as S from './style'

interface ServerToClientEvents {
  'enable-room': (data: string) => void
}

interface ClientToServerEvents {
  hello: () => void
}

interface MenuItem {
  name: string
  path: string
  permission: string
  children: ReactElement
}

interface DesktopMenuContextType {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  menuItens: MenuItem[]
}

const DesktopMenuContext = createContext<DesktopMenuContextType | null>(null)

export default function DesktopMenuProvider ({ children }: PropsWithChildren): ReactElement {
  const [open, setOpen] = useState(false)
  const { token } = useAuth()

  const [menuItens, setMenuItems] = useState<MenuItem[]>([
    {
      name: 'Admin',
      path: '/admin',
      permission: 'dashboard:read',
      children: (
        <svg className="menu-item-link-icon icon-info">
          <use href="#svg-info"></use>
        </svg>
      )
    },
    {
      name: 'Home',
      path: '/home',
      permission: '',
      children: (
        <svg className="menu-item-link-icon icon-info">
          <use href="#svg-info"></use>
        </svg>
      )
    },
    {
      name: 'Catálogo de prêmios',
      path: '/marketplace',
      permission: 'read:troca-pontos',
      children: (
        <svg className="menu-item-link-icon icon-marketplace">
          <use href="#svg-marketplace"></use>
        </svg>
      )
    },
    {
      name: 'Resultados',
      path: '/resultados',
      permission: 'read:result',
      children: (
        <svg className="menu-item-link-icon icon-overview">
          <use href="#svg-overview"></use>
        </svg>
      )
    },
    {
      name: 'Mecânica',
      path: '/mecanicas',
      permission: 'read:mecanicas',
      children: (
        <S.AwesomeMenuIconSmall>
          <i className="fas fa-cog"></i>
        </S.AwesomeMenuIconSmall>
      )
    },
    {
      name: 'Canal de notícias',
      path: '/newsfeed',
      permission: '',
      children: (
        <svg className="menu-item-link-icon icon-newsfeed">
          <use href="#svg-newsfeed"></use>
        </svg>
      )
    },
    {
      name: 'Blog',
      path: '/blog',
      permission: 'blog:read',
      children: (
        <svg className="menu-item-link-icon icon-newsfeed">
          <use href="#svg-newsfeed"></use>
        </svg>
      )
    },
    {
      name: 'Membros',
      path: '/members',
      permission: 'users:read',
      children: (
        <svg className="menu-item-link-icon icon-group">
          <use href="#svg-group"></use>
        </svg>
      )
    },
    {
      name: 'Ranking',
      path: '/ranking',
      permission: 'read:ranking',
      children: (
        <svg className="menu-item-link-icon icon-overview">
          <use href="#svg-overview"></use>
        </svg>
      )
    },
    {
      name: 'Quiz',
      path: '/quiz',
      permission: 'read:quiz',
      children: (
        <svg className="menu-item-link-icon icon-badges">
          <use href="#svg-badges"></use>
        </svg>
      )
    },
    {
      name: 'Ranking do Quiz',
      path: '/ranking-quiz',
      permission: 'read:ranking-quiz',
      children: (
        <svg className="menu-item-link-icon icon-badges">
          <use href="#svg-badges"></use>
        </svg>
      )
    },
    {
      name: 'Treinamentos',
      path: '/treinamentos',
      permission: 'read:training',
      children: (
        <svg className="menu-item-link-icon icon-events">
          <use href="#svg-events"></use>
        </svg>
      )
    },
    {
      name: 'Medalhas',
      path: '/badges',
      permission: 'read:badges',
      children: (
        <svg className="menu-item-link-icon icon-quests">
          <use href="#svg-quests"></use>
        </svg>
      )
    },
    {
      name: 'Missões',
      path: '/quests',
      permission: 'read:quest',
      children: (
        <svg className="menu-item-link-icon icon-quests">
          <use href="#svg-quests"></use>
        </svg>
      )
    }

  ])

  useEffect((): any => {
    const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
      String(process.env.REACT_APP_BASE_URL),
      {
        query: { token },
        transports: ['websocket']
      }
    )

    socket.on('enable-room', (data) => {
      const hasLiveMenu = menuItens.find((menu: MenuItem) => menu.name === 'Live')
      if (hasLiveMenu !== undefined) return
      const start = menuItens.slice(0, 3)
      const end = menuItens.slice(3)
      const json = JSON.parse(data)
      const room = String(json.guestUrl)
      const roomMatchs = room.match(/[^/]+$/)
      const roomId = roomMatchs !== null ? roomMatchs[0] : ''
      const newMenuItens = [
        ...start,
        {
          name: 'Live',
          path: `/live/${roomId}`,
          permission: 'read:live',
          children: (
            <svg className="menu-item-link-icon icon-marketplace">
              <use href="#svg-marketplace"></use>
            </svg>
          )
        },
        ...end
      ]
      setMenuItems(newMenuItens)
    })
    socket.on('connect', () => console.log('connected'))

    return () => socket.close()
  }, [])

  return (
    <DesktopMenuContext.Provider value={{ open, setOpen, menuItens }}>
      {children}
    </DesktopMenuContext.Provider>
  )
}

export function useMenu (): DesktopMenuContextType {
  const context = useContext(DesktopMenuContext)

  if (context == null) { throw new Error('useMenu must be used within a DesktopMenuProvider') }

  const { open, setOpen, menuItens } = context

  return { open, setOpen, menuItens }
}
