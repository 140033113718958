import styled from 'styled-components'

export const ChatContentContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const ChatHeaderContainer = styled.div`
  border-bottom: 1px solid #eaeaf5;
  display: flex;
  gap: 0.75rem;
  height: 5.438rem;
  padding: 1.25rem 1.75rem;
  position: relative;
  width: 100%;

  > div.chat-avatar {
    height: 2.5rem;
    width: 2.75rem;
  }
`

export const DotsIcon = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 1.875rem;
  justify-content: center;
  position: absolute;
  right: 0.875rem;
  top: 0.75rem;
  width: 2.5rem;

  svg {
    fill: #adafca;
    opacity: 0.4;
    transition: opacity 0.2s ease-in-out,fill 0.2s ease-in-out;

    &:hover {
      fill: #3e3f5e;
      opacity: 1;
    }
  }
`

export const ChatHeaderUserContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  > p {
    font-weight: 700;

    :first-child {
      font-size: 0.875rem;
      line-height: 1.4285714286em;
    }

    :last-child {
      background-color: #1df377;
      border-radius: 12.5rem;
      color: var(--white);
      font-size: 0.75rem;
      height: 1.25rem;
      line-height: 1.25rem;
      padding-inline: 0.5rem;
      text-transform: uppercase;
    }
  }
`

export const ChatMessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  padding: 2.25rem 1.75rem;
`

export const ChatMessageGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;

  > img {
    height: 1.625rem;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.5rem;
  }

  > div > p {
    &:not(.chat-message-timestamp) {
      border-radius: 0.625rem;
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.1428571429em;
      padding: 0.75rem;
      width: fit-content;
    }

    &.chat-message-timestamp {
      color: #adafca;
      font-size: 0.75rem;
      font-weight: 500;
      margin-top: 0.75rem;
    }
  }
`

export const ChatMessageLeftContainer = styled.div`
  display: flex;
  flex-flow: column;
  padding-inline: 2.25rem 1.625rem;
  margin-right: 4rem;

  > p {
    background-color: #f5f5fa;
    border-top-left-radius: 0 !important;
  }
`

export const ChatMessageRightContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: column;
  padding-inline: 2.25rem 1.625rem;
  margin-left: 4rem;

  > p {
    background-color: var(--primary);
    border-top-right-radius: 0 !important;
    color: var(--white);
  }
`

export const MessageTimestamp = styled.span`
  color: #adafca;
  font-size: 0.75rem;
  font-weight: 500;
  margin-top: 0.75rem;
`

export const ChatInputContainer = styled.div`
  border-radius: 10px;;
  box-shadow: 0 0 40px 0 rgba(94, 92, 154, 0.1);
  display: flex;
  gap: 1rem;
  padding: 1.5rem;
  transition: opacity 0.4s ease-in-out, visibility .4s ease-in-out, bottom 0.4s ease-in-out;

  @media screen and (max-width: 960px) {
    flex-direction: column;

    button {
      width: 100%;
    }
  }

  > div {
    width: 100%;

    input {
      padding-right: 4rem;
    }
  }
`

export const IconButton = styled.button`
  background-color: transparent;
  height: 3rem;

  svg {
    transition: fill .2s ease-in-out,opacity .2s ease-in-out;
  }

  &:hover {
    svg {
      fill: #3e3f5e;
      opacity: 1;
    }
  }
`

export const SendMessageButton = styled.button.attrs(() => {
  return { className: 'button secondary' }
})`
  background-color: var(--secondary) !important;
  padding-inline: 1.5rem;
  width: fit-content;

  svg {
    fill: var(--white);
  }

  &:hover {
    background-color: #1bc5d4 !important;
  }
`
