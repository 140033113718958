import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Template from '../../template';
import PageLoaderComponent from '../../components/PageLoader';
import api from '../../services/api';
import * as S from './style';
import { useAuth } from '../../contexts/AuthContext';

export default function UserTerms () {
  const [term, setTerm] = useState({});
  const { user, acceptedAt } = useAuth();

  useEffect(() => {
    const Data = async () => {
      try {
        const { data } = await api.get('term');
        setTerm(data);
      } catch (err) {
        toast.error(err.response.data.message, {
          theme: 'colored'
        });
      }
    };
    Data();
  }, [user]);

  if (!term) <PageLoaderComponent />;

  return <Template>
        <div style={{ marginTop: '8rem' }}></div>
        <S.PerfectScrollbar>
            <S.Body>
                <div className="text-center mb-5">
                    <h1>Termos e Condições</h1>
                </div>
                <p dangerouslySetInnerHTML={{ __html: term.content }}></p>
                <p className='accepted-at'><span className="label">Data do aceite:</span> <span>{new Date(acceptedAt).toLocaleString('pt-BR', {
                  day: 'numeric',
                  month: 'long',
                  weekday: 'long',
                  year: 'numeric',
                  hour: '2-digit',
                  minute: '2-digit'
                })}</span></p>
            </S.Body>
        </S.PerfectScrollbar>
    </Template>;
}
