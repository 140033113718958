import styled from 'styled-components';

export const SliderHome = styled.div`
overflow: hidden;
border-radius: 20px;
grid-column: span 2;
max-width: 1180px;
margin-top: 2rem;
@media (min-width: 1440px) {
  margin-inline: auto;
}
@media (max-width: 480px) {
  height: 100%;
}
@media (max-width: 1280px) {
  margin-top: 8rem;
}
@media (max-width: 768px) {
  margin-top: 0;
}
`;

export const Grid = styled.div`
margin-top: 100px;
grid-template-areas: "sidebar content";
grid-template-columns: 3fr 7fr;
display: grid;
grid-gap: 16px;
align-items: start;

@media (max-width: 1280px) {
  grid-template-columns: 1fr;
  grid-template-areas: none;
}
.account-hub-content {
  grid-column: span 2;
  max-width: 1180px;
  margin-inline: auto;
}
.hexagon-container {
  margin-inline: auto;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 124px;
  height: 124px;
  top: -60px;
  clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
  background-color: var(--white);
  .shape-outer {
    width: 85%;
    height: 85%;
  }
  span {
    width: 124px;
    height: 124px;
  }
}`;

export const NewPostContainer = styled.div`
  &&& svg.create-entity-box-avatar-icon {
    fill: #615dfa;
    width: 25px;
    height: 25px;
  }
  .create-entity-box-avatar {
    margin-top: 1rem;
    background-size: 100%;
  }
  .create-entity-box-info {
    margin-top: 3rem;
    .create-entity-box-title {
      font-size: 1.25rem;
    }
    .create-entity-box-text {
      font-size: 1rem;
      padding-inline: 25%;
      line-height: 1.5rem;
    }
    .button {
      margin-top: 30%;
      background: #615dfa;
    }
  }

`;

export const HubContent = styled.div`
  width: 100%;
`;

export const GridPosts = styled.div.attrs(() => ({
  className: 'grid grid-4-4-4 centered'
}))`
  &.grid.grid-4-4-4.centered {
    grid-template-columns: repeat(auto-fit,382px);
  }
  figure.post-preview-image {
    background: #615dfa;
  }
`;
