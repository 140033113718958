import { createContext, useContext, useState } from 'react';

const MenuContext = createContext();

export default function MenuProvider ({ children }) {
  const [activeMenuMobile, setActiveMenuMobile] = useState(true);
  const [menuItens] = useState([
    { name: 'Home', path: '/home', permission: '', children: <svg className="menu-item-link-icon icon-info"><use href="#svg-info"></use></svg> }
  ]);

  return (
        <MenuContext.Provider value={{ activeMenuMobile, setActiveMenuMobile, menuItens }}>
            {children}
        </MenuContext.Provider>
  );
};

export function useMenuActive () {
  const context = useContext(MenuContext);

  if (!context) throw new Error('useMenuActive must be used within a MenuProvider');

  const { activeMenuMobile, setActiveMenuMobile, menuItens } = context;

  return { activeMenuMobile, setActiveMenuMobile, menuItens };
}
