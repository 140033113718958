import useSWR, { KeyedMutator } from 'swr'
import api from '../services/api'

interface UseFetch {
  data: any
  error: any
  mutate: KeyedMutator<any>
}

export function useFetch (url: string): UseFetch {
  const { data, error, mutate } = useSWR(url, async (url: string) => {
    const response = await api.get(url)

    return response.data
  }, { refreshInterval: 60000 })

  return { data, error, mutate }
}
