import styled from 'styled-components';
import { Link as StyledLink } from 'react-router-dom';

export const Iframe = styled.iframe`
  width: 100%;
  height: 70vh;
  max-height: 100%;
`;

export const Tabela = styled.div`
  width: 70%;
  margin: 0 auto;
  table{
    width: 100%;
  }
  th{
    font-weight: 100;
    color: #3e3f5e;
    font-family: Rajdhani,sans-serif;
    line-height: 1em;
    font-weight: bold;
    font-size: 1rem;
  }

  @media (max-width: 1178px) {
    .avatar {
      display: none;
    }
  }

  @media (max-width: 1100px) {
    margin-top: 2rem;
    width: 100%;
    table {
      width: 100%;
    }
  }
`;

export const TabelaMinhaPos = styled(Tabela)`
  margin-top: 0;
`;

export const Grid = styled.div`
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 8fr 2fr;
`;

export const Engagements = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 5fr 5fr;
`;

export const Nubers = styled.div`
text-align: center;
  .colors{
    width: 15px;
    height: 15px;
    margin: 0 auto;
    background-color: ${({ color }) => color};
  }
  p{
    color: #3e3f5e;
    font-family: Rajdhani,sans-serif;
    line-height: 1em;
    text-transform: uppercase;
  }
`;

export const Link = styled(StyledLink)`
  &:hover {
    background-color: var(--secondary) !important;
  }
`;

export const HoldingName = styled(Link)`
  border-radius: 10px !important;
  height: auto !important;
  min-height: 50px;

  &:hover, &:focus, &:active {
    outline: 0 none;
    background-color: var(--secondary) !important;
  }
`;

export const Pergunta = styled.div`
  text-align: center;
  h4{
    margin-bottom: 20px;
  }
  button{
    border-radius: 0;
    border: 1px solid #dedeea;
    color: #3e3f5e;
    background-color: #fff;
    margin-bottom: 10px;
    height: 50px;
    :hover{
      color: #fff;
      background-color: #5753e4;
    }
    :focus{
      background-color: #5753e4;
      color: #fff;
    }
  }
  a{
    font-family: Rajdhani,sans-serif;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #dedeea;
    color: #3e3f5e;
    background-color: #fff;
    margin-bottom: 10px;
    height: 50px;
    :hover{
      color: #fff;
      background-color: #5753e4;
    }
    :focus{
      background-color: #5753e4;
      color: #fff;
    }
  }   
`;

export const Widget = styled.div.attrs(() => {
  return {
    className: 'widget-box'
  };
})`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Star = styled.img`
  max-width: 80px;
  width: auto;
  display: block;
  margin: 0;
`;

export const StarCell = styled.div`
  position: relative;
  color: var(--text-light);
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #3e3f5e;
  font-family: Rajdhani,sans-serif;
  line-height: 1em;
  min-width: 50px;
  aspect-ratio: 1;
  
  img {
    margin-left: 16px;
    margin: 0 auto;
    position: absolute;
    inset: 0;
    top: -16px;
    z-index: 2;
  }

  p {
    position: absolute;
    inset: 0;
    z-index: 3;
    font-size: 2rem;
    text-align: center;
    color: var(--white);
    text-shadow: 1px 1px 5px #000000;
    top: -17px;
    left: 5px;
  }

  small {
    position: relative;
    display: inline-block;
    font-size: 50%;
    text-shadow: 1px 1px 3px #000000;
    top: 10px;
  }
`;

export const PositionColumn = styled.td`
  @media (max-width: 681px) {
    img {
      display: none;
    }
    
    div {
      color: #000;
    }
  }
`;
export const Name = styled.td`
  font-size: 1rem;

  @media (max-width: 681px) {
    font-size: .75rem;
  }
`;

export const TabelaResultados = styled.table`
  width: 100%;
  margin-bottom: 48px;
  .name-column {
    width: 15.25%;
  }

  .meta-column, .result-column {
    width: 30%;
  }

  .progress-bar-column {
    width: auto;
  }

  .name-cell {
    font-weight: bold;
  }

  .name-cell, .meta-cell, .result-cell, .progress-bar-cell {
    padding: .5rem;
  }
`;

export const ResultContainer = styled.div`
  padding: 0 3rem;
  width: 100%;

  @media (max-width: 1100px) {
    padding: 0;
  }

  h5 {
    margin-bottom: 32px;
  }
`;

export const LeftTitle = styled.div`
  font-weight: bold;
  font-size: 1.125rem;
  text-align: left;
`;

export const TabelaPontoExtra = styled.table`
  margin-top: .5rem;
  width: 100%;

  .provider-header {
    border-top: 1px solid var(--primary);
  }

  th, td {
    text-align: center;
    padding: .5rem;
    width: 33.33%;
  }

  th:first-child, td:first-child {
    text-align: left;
  }
  th:last-child, td:last-child {
    text-align: right;
  }
`;
