import RankingImage from '../RankingImage';
import leaf from '../../../assets/img/folhas-sem-tang.png';
import * as S from './style';

const Row = ({ rowData }) => {
  return <tr>
    <td style={{ width: '10%' }}><RankingImage position={rowData.position} /></td>
    <td style={{ fontSize: '1rem', padding: '2.5rem 0' }}>
      <S.HoldingName>{rowData.name}</S.HoldingName>
    </td>
    <td style={{ width: '5%', paddingLeft: '2rem' }}>
      <S.StarCell>
        <p>
          <small>Pontos</small>
          <br />
          {rowData.pontosTotais}
        </p>
        <S.Star src={leaf} alt="Estrela" />
      </S.StarCell>
    </td>
  </tr>;
};

function RankingGeral ({ ranking, myPosition, pontosExtras, regionName }) {
  const resultados = {};
  return <div className="widget-box-content">
    <S.Pergunta>
      <h3>Sua posição</h3>

      <S.Widget>
        <S.TabelaMinhaPos>
          {myPosition && <table>
            {<Row rowData={myPosition} />}
          </table>}
        </S.TabelaMinhaPos>

        <S.ResultContainer>
          <h3>Resultado Sell In (unidade medida caixas)</h3>
        {resultados && <S.TabelaResultados>
            <thead>
              <tr>
                <th className="name-column">&nbsp;</th>
                <th className="meta-column">Meta</th>
                <th className="result-column">Resultado</th>
                <th className="progress-bar-column">Real x Meta</th>
              </tr>
            </thead>
            {myPosition && <tbody>
              <tr>
                <td className="name-cell">Etapa {myPosition.stepId}<br /><small>{myPosition.stepId === 1 ? 'P9 + P10' : 'P11 + P12'}</small></td>
                <td className="meta-cell">{myPosition.meta}</td>
                <td className="result-cell">{myPosition.resultado}</td>
                <td className="progress-bar-cell">{(myPosition.atingimento).toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%</td>
              </tr>
            </tbody>}
          </S.TabelaResultados>}{resultados && <>
            <h3>Resultado Pontos Extras</h3>
            <S.TabelaResultados>
              <thead>
                <tr>
                  <th className="result-column">MÉDIA PONTOS EXTRA POR LOJA</th>
                  <th className="result-column">PONTUAÇÃO PONTO EXTRA</th>
                  <th className="result-column">TOTAL PONTOS</th>
                </tr>
              </thead>
              {myPosition && <tbody>
                <tr>
                  <td className="result-cell">{myPosition.mediaPontoExtra}</td>
                  <td className="result-cell">{myPosition.pontoExtra}</td>
                  <td className="result-cell">{myPosition.pontosTotais}</td>
                </tr>
              </tbody>}
            </S.TabelaResultados>
          </>}

          <S.LeftTitle>Resultado Ponto Extra por loja</S.LeftTitle>
          <S.TabelaPontoExtra>
            {myPosition && <tr>
              <th colSpan="2">{myPosition.name}</th>
              <td>Total de pontos: {myPosition.pontoExtra}</td>
            </tr>}
            <tbody>
              {pontosExtras && pontosExtras.map(p => <tr>
                <th className="provider-header" colSpan="2">{p.name} ({p.cnpj})</th>
                <td className="provider-header">{p.pontos}</td>
              </tr>)}
            </tbody>
          </S.TabelaPontoExtra>
        </S.ResultContainer>

        <h3 style={{ width: '100%', marginTop: '32px' }}>Ranking Região {regionName} - TOP 10</h3>
        <S.Tabela>
          <table>
            {ranking && ranking.map(r => <Row rowData={r} />)}
          </table>
        </S.Tabela>
      </S.Widget>
    </S.Pergunta>
  </div>;
}

export default RankingGeral;
