import styled from 'styled-components'
import IT from '../../../components/Forms/InputText'

export const Container = styled.div``

export const InputRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
`

export const Button = styled.button.attrs(() => {
  return {
    className: 'button primary'
  }
})``

export const InputText = styled(IT)``
