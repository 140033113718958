import { ReactElement } from 'react'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'
import Payments from './Payments'
import Statistics from './Statistics'
import TopSellers from './TopSellers'

export default function StoreAccount (): ReactElement {
  return <>
    <SectionHeaderComponent pretitle="Minha Loja" title='Visão Geral' />
    <Statistics />

    <TopSellers />

    <SectionHeaderComponent pretitle="Minha Loja" title="Pagamentos" />

    <Payments />
  </>
}
