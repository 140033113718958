import CardQuiz from '../../components/CardQuiz';
import PageLoaderComponent from '../../components/PageLoader';
import { useFetch } from '../../hooks/useFetch';
import Template from '../../template';
import * as S from './style';

export default function Quiz () {
  const { data } = useFetch('/quiz');

  if (!data) return <PageLoaderComponent />;

  return (
    <Template>
      <div style={{ marginTop: '8rem' }}></div>
      {data.quizzes.length > 0 && <S.Grid>
        {data.quizzes.map((quiz, quizIndex) => <CardQuiz isNew={quizIndex === 0} template="trofeu" key={quiz.id} quiz={quiz}></CardQuiz>)}
      </S.Grid>}
      {data.quizzes.length === 0 && <p style={{ textAlign: 'center', marginTop: '64px', fontSize: '1rem' }}><strong>Em breve</strong></p>}
    </Template>
  );
}
