function ProfileStore () {
  return (
    <div className="account-hub-content">
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Minhas compras</p>
          <h2 className="section-title">Informação de minhas compras</h2>
        </div>
      </div>

      <div className="grid-column">
        <div className="grid grid-3-3-3 centered">
          <div className="user-preview small fixed-height">
            <p>Minhas compras</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileStore;
