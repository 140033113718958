import styled from 'styled-components';
import PhotoComponent from '../Perfil/tabs/ProfilePhotos/Photo';

export const Container = styled.div`
  background-color: #fff;
  border-radius: 1.25rem;
  padding: 1rem;
`;

export const FilterArea = styled.div`
  display: flex;
  gap: 1rem;
`;

export const PhotoList = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  width: 100%;
`;

export const PhotoContainer = styled.div`
  aspect-ratio: 1 / 1;
  display: block;
  flex: 0 1 33.33%;
  overflow: hidden;
`;

export const PhotoModal = styled.div`
  border-radius: .75rem;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 1140px;
`;

export const PhotoModalText = styled.div`
  flex: 1 30%;
  padding: 1rem;

  h4 {
    margin-bottom: 1rem;
  }
`;

export const PhotoModalImage = styled.div`
  aspect-ratio: 800 / 600;
  flex: 1 70%;
  overflow: hidden;
`;

export const Photo = styled(PhotoComponent)`
  display: block;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

export const PhotoModalImageContainer = styled.div`
  align-items: center;
  background-color: #333333;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`;
export const PhotoLarge = styled.img`
  display: block;
  object-position: center;
  object-fit: contain;
  min-height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
`;

export const ContainerModal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  pointer-events: none;
  z-index: 999;

  &:before {
    position: absolute;
    inset: 0;
    display: block;
    content: "";
    background-color: rgba(0, 0, 0, .5);
    backdrop-filter: blur(4px) grayscale(.5);
    transition: all 1s;
    opacity: 0;
  }

  &.opening {
    pointer-events: all;
    &:before {
      opacity: 1;
    }

    &>div {    
      animation-name: open;
      animation-play-state: running;
      animation-duration: 1.5s;
      top: 0;
      opacity: 1;
    }

  }

  &.closing {
    &:before {
      transition-delay: .5s;
    }

    &>div {
      animation-name: close;
      animation-play-state: running;
      animation-duration: 1s;
      top: -50%;
      opacity: 0;
    }
  }

  @keyframes open {
    0% {
      opacity: 0;
      top: -10%;
    }
    33% {
      opacity: 0;
      top: -10%;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes close {
    0% {
      opacity: 1;
      top: 0;
    }
    100% {
      opacity: 0;
      top: -10%;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 2;
  border-radius: 12px;
  padding: 24px 28px;
  background-color: var(--white);
  box-shadow: 0 0 40px 0 rgb(94 92 154 / 6%);
  animation-duration: 1s;
  top: -50%;
  opacity: 0;
  max-width: 50%;
  width: 400px;
  .popup-close-button {
    background-color: var(--primary);
  }
  .popup-close-button:hover {
    background-color: var(--hover);
  }

  @media (max-width: 768px) {
    max-width: 80%;
  }
  .form-row {
    p {
      margin-bottom: 1rem;
    }
  }
`;

export const ContentLarge = styled(Content)`
  max-width: 90%;
  width: auto;
  padding: 0;
`;

export const Header = styled.h4`
  margin-bottom: 16px;
`;

export const Body = styled.div``;

export const TextAreaContainer = styled.div`
  position: relative;
`;

export const TextAreaLabel = styled.label`
  position: absolute;
  top: 0;
  left: 1rem;
  background-color: var(--white);
  padding: .5rem;
  z-index: 2;
`;

export const TextArea = styled.textarea`
  position: relative;
  top: 1rem;
  left: 0rem;
  z-index: 1;
  margin-bottom: 1rem;
`;
