import styled from "styled-components";

export const WavesTimelineStepContainer = styled.div`
  flex-shrink: 0;

  :not(.active) {
    opacity: 0.25;
  }

  p {
    color: #000;
    font-size: clamp(1.5rem, 1.3849rem + 0.5115vw, 2rem);
    font-weight: 900;
    line-height: 1.75rem;
    text-align: center;
    text-shadow: 0px 2.528057098388672px 2.528057098388672px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;

    &, span {
      font-family: Montserrat, sans-serif;
    }

    span {
      display: block;
      white-space: nowrap;

      :first-child {
        color: #000;
        font-size: clamp(6rem, 4.9066rem + 4.8593vw, 10.75rem);

        ::before {
          content: "";
          padding: clamp(1rem, 0.8274rem + 0.7673vw, 1.75rem) 0;
        }
      }

      :last-child {
        color: #000;
        font-size: 20px;

        ::before {
          content: "";
          padding: clamp(1rem, 0.8274rem + 0.7673vw, 1.75rem) 0;
        }
      }

    }
  }

`