import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  align-items: flex-end;
  background-color: var(--white);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  z-index: 1000;
`

export const Table = styled.table`
  width: 100%;

  td, th {
    padding: 8px;
  }
`

export const IFrame = styled.iframe`
  height: 80vh;
  margin: 0 auto;
  max-height: 768px;
  max-width: 1366px;
  width: 80vw;
`

export const Button = styled(Link).attrs(() => {
  return {
    className: 'button primary'
  }
})`
  padding-left: 32px;
  padding-right: 32px;
  width: auto;

  &:hover {
    text-decoration: none;
  }
`

export const ActionButton = styled.button.attrs(() => {
  return {
    className: 'button primary'
  }
})`
  padding-left: 32px;
  padding-right: 32px;
  width: auto;
`
