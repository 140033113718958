import styled from 'styled-components';

export const BannerFigure = styled.figure`
  overflow: hidden;
`;

export const Banner = styled.img`
  object-fit: cover;
`;

export const CardCointainer = styled.div`
  position: relative;
  z-index: 2;
`;
