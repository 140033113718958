import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  > div {
    max-width: 100%;
  }
`

export const NotificationContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 22px 28px;

  > svg {
    margin-left: 2.5rem;
  }
`

export const NotificationContent = styled.div`
  align-items: center;
  display: flex;
  gap: 0.75rem;

  .avatar {
    max-width: 2.5rem;
  }
`

export const NotificationText = styled.p`
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
  margin-bottom: 0.25rem;
`

export const Timestamp = styled.p`
  color: #8f91ac;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
  margin: 0;
`

export const CustomLink = styled(Link)`
  font-weight: 600;
  line-height: 1rem;

  :first-child {
    color: var(--black);
    font-weight: 700;
  }
`
