import styled from 'styled-components'

export const Container = styled.div`
  position: relative;

  &.small label {
    color: var(--text-light);
    z-index: 999;
  }

  input:read-only {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &:has(input[type="date"])> svg {
    bottom: 0;
    margin: auto 0;
    pointer-events: none;
    position: absolute;
    right: 1rem;
    top: -8px;
  }
`
export const Input = styled.input`
  &[type="date"] {
    border: 1px solid #dedeea;
    border-radius: 12px;
    font-size: 0.875rem;
    font-weight: 700;
    min-width: 11.25rem;
    padding-left: 1rem;
    width: 100%;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
      margin-right: 1rem;
    }
  }

  &[value=""][type="date"] {
    color: transparent;
  }

  &[value=""][type="date"]:focus {
    color: var(--text);
  }
`
