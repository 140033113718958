import { AxiosInstance } from 'axios'
import { SavePolibrasProduct } from '../../domain/use-case/SavePolibrasProduct'

export class ApiSavePolibrasProduct implements SavePolibrasProduct {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: SavePolibrasProduct.Params): Promise<SavePolibrasProduct.Result> {
    try {
      const response = await this.api.put(`/polibras/product/${params.id}`, params)
      return response
    } catch (error: any) {
      return error.response
    }
  }
}
