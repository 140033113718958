import styled from 'styled-components'

export const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`

export const BadgesContent = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fit, 3.75rem);
`
export const Badge = styled.div`
  border: 2px dashed #eaeaf5;
  border-radius: 0.75rem;
  box-shadow: 3px 5px 20px 0 rgba(94,92,154, 0.1);
  cursor: pointer;
  height: 3.75rem;
`

export const BadgeInstructions = styled.p`
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.7142857143em;
  margin: 0;
`
