import { ReactElement } from 'react'
import Card from '../../../../../components/Card'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'
import { useAuth } from '../../../../../contexts/AuthContext'
import EmailNotification from './EmailNotification'
import PrivacySetting from './PrivacySetting'
import * as S from './style'

export default function AccountGeneralSettings (): ReactElement {
  const { userCan } = useAuth()
  return <>
    <SectionHeaderComponent pretitle='CONTA' title='Configurações Gerais' />

    <S.CardsContainer>
      <Card className="single-card">
        <S.SectionTitle>Notificações por Email</S.SectionTitle>

        <S.EmailNotificationsContent>
          <EmailNotification
            description="Você será notificado quando alguém comentar em suas postagens e/ou responder aos seus comentários"
            title="Comentários"
          />

          <EmailNotification
            description="Essas são notificações para quando alguém marca você em uma postagem ou imagem"
            title="Tags"
          />

          <EmailNotification
            description="Você receberá uma notificação quando alguém enviar uma solicitação de amizade"
            title="Solicitações de Amizade"
          />

          <EmailNotification
            description="Essas são notificações de atividades em grupos que você criou ou aos quais você faz parte"
            title="Grupos"
          />

          <EmailNotification
            description="Você será notificado sobre eventos que você criou ou adicionou ao seu calendário"
            title="Eventos"
          />

          <EmailNotification
            description="Essas são notificações para os itens do marketplace que você postou"
            title="Marketplace"
          />
        </S.EmailNotificationsContent>
      </Card>

      {userCan('privacy:manage') === true && <Card className="single-card">
        <S.SectionTitle>Configurações de Privacidade</S.SectionTitle>

        <S.PrivacySettingsContent>
          <PrivacySetting
            label="Quem pode ver seu perfil?"
            options={['Apenas Membros', 'Todos (Público)']}
          />

          <PrivacySetting
            label="Quem pode enviar pedido de amizade?"
            options={['Todos (Público)', 'Amigos de Amigos', 'Ninguém (Privado)']}
          />

          <PrivacySetting
            label="Quem pode ver suas conversas?"
            options={['Apenas Amigos', 'Todos (Público)']}
          />
        </S.PrivacySettingsContent>
      </Card>}
    </S.CardsContainer>
  </>
}
