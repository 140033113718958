import styled from 'styled-components'

export const OrdenedList = styled.ol`
  counter-reset: list;
`

export const ListItem = styled.li`
  :before {
    display: inline;
    content: counter(list, lower-alpha) ") ";
    counter-increment: list;
  }
`

export const WidgetBox = styled.div`
  p.widget-box-title {
    font-size: 1.5rem;
  }
  @media(max-width: 480px) {
    p.widget-box-title {
      font-size: 1.25rem;
    }
    .simple-accordion-title {
      font-size: 1.15rem !important;
    }
    .simple-accordion-text {
      font-size: 1rem !important;
    }
  }
`

export const Table = styled.table`
  border: 1px solid var(--gray);

  tr:has(th) {
    background-color: var(--primary);
  }

  th {
    color: var(--white);
    font-weight: 800;
  }

  td, th {
    border: 1px solid var(--gray);
    font-size: 16px;
    padding: 8px 16px;
    text-align: center;
  }
`
