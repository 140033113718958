import styled from 'styled-components';

export const Container = styled.div`
  transform: translatex(140px);
  max-width: calc(100% - 148px);
  width: 1184px;

  @media(max-width: 1580px) {
    transform: translatex(50px);
    max-width: calc(100% - 148px);
    width: 1184px;
    &.with-menu-desktop {
      transform: translatex(150px);
    }
  }
  @media (max-width: 960px) {
    &.with-menu-desktop {
      transform: translatex(0);
    }
    transform: translatex(0);
    max-width: calc(100% - 68px);
    margin: 0 34px;
  }
  @media (min-width: 1600px) {
    max-width: calc(100% - 386px);
    width: 1184px;
  }
  @media (min-width: 1366px) and (max-width: 1580px) {
    &.with-menu-desktop {
      padding: 112px 112px 200px;
    }
  }
`;
