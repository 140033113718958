import styled from 'styled-components'

export const ChatMessagesMenuContainer = styled.div`
  overflow-y: auto;
`

export const SearchMessageContainer = styled.div`
  padding: 1.5rem;
  border-radius: 10px;;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.1);
  transition: opacity .4s ease-in-out,visibility .4s ease-in-out,bottom .4s ease-in-out;

  > div {
    width: 100%;

    input {
      padding-right: 4rem;
    }
  }
`

export const IconButton = styled.button`
  background-color: transparent;
  height: 3rem;

  svg {
    fill: #adafca;
    transition: fill .2s ease-in-out,opacity .2s ease-in-out;
  }

  &:hover {
    svg {
      fill: #3e3f5e;
      opacity: 1;
    }
  }
`
