import axios from 'axios'
import { useCallback, useEffect, useMemo, useState } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { toast } from 'react-toastify'
import PageLoaderComponent from '../../components/PageLoader'
import PostCard from '../../components/PostCard'
import GoogleSocialButton from '../../components/SocialButton/Google'
import { useAuth } from '../../contexts/AuthContext'
import { useModal } from '../../contexts/ModalContext'
import api from '../../services/api'
import Template from '../../template'
import * as S from './style'
export default function Perfil (props) {
  const { user, doLogout, userCan } = useAuth()
  const [dashboardData, setDashboardData] = useState({})
  const [posts, setPosts] = useState()

  const loadDashboard = useCallback(async () => {
    try {
      const response = await api.get('/dashboard')
      setDashboardData(response.data)
      setPosts(response.data.posts)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response.data.message === 'Token inválido') {
          toast.error('O token expirou', { theme: 'colored' })
          doLogout()
        }
      }
    }
  }, [doLogout])

  const { openModal } = useModal()

  const isLoaded = useMemo(() => user && dashboardData, [dashboardData, user])

  const openGoogleModal = useCallback(() => {
    if (localStorage.getItem('modal-google')) return
    openModal({
      header: 'Associe sua conta Google!',
      body: <GoogleSocialButton userId={user.id} />
    })
    localStorage.setItem('modal-google', 1)
  }, [openModal, user])

  useEffect(() => {
    loadDashboard()
    openGoogleModal()

  }, [loadDashboard, openGoogleModal, openModal, user])

  if (!isLoaded) return <PageLoaderComponent />

  return (
    <S.HomeContainer>
      <Template>
        <div className="grid">
          <S.Grid>
            <S.GridFastAcccess className="grid medium-space">
              <S.FastLink
                className="product-category-box category-all results"
                to={`/resultados`}
              >
                <p className="product-category-box-title">Resultados</p>

                <p className="product-category-box-text">
                  Acesse seus resultados
                </p>
                <button type="button" className="product-category-link">
                  <span>Acesse</span>
                </button>
              </S.FastLink>

              <S.FastLink
                className="product-category-box category-all mecanica"
                to="/mecanicas"
              >

                <p className="product-category-box-title">Mecânica</p>

                <p className="product-category-box-text">Confira suas metas</p>
                <button type="button" className="product-category-link">
                  <span>Acesse</span>
                </button>
              </S.FastLink>

              <S.FastLink
                className="product-category-box category-all quiz"
                to="/faq"
              >
                <p className="product-category-box-title">Perguntas Frequentes</p>
                <p className="product-category-box-text">
                </p>
                <button type="button" className="product-category-link">
                  <span>Acesse</span>
                </button>
              </S.FastLink>

              <S.FastLink
                className="product-category-box category-all contact"
                to="/fale-conosco"
              >
                <p className="product-category-box-title">Fale conosco</p>
                <p className="product-category-box-text">
                  Envie uma dúvida ou sugestão
                </p>
                <button type="button" className="product-category-link">
                  <span className="text-gradient">Acesse</span>
                </button>
              </S.FastLink>
            </S.GridFastAcccess>

            {userCan('blog:read') && (
              <S.GridPosts>
                {posts &&
                  posts.map((post) => <PostCard key={post.id} {...post} />)}
              </S.GridPosts>
            )}
          </S.Grid>

          <S.HomeVideo
            autoPlay
            controls
            loop
            muted
            playsInline
            src="https://img.casadebraganca.com/polibras-incentivo/home-video/video-home.mp4"
          />
        </div>
      </Template>
    </S.HomeContainer>
  )
}
