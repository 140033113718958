import styled from 'styled-components'

export const Exp = styled.div`
  align-items: center;
  background-color: var(--white);
  border-radius: 200px;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.12);
  display: flex;
  height: 32px;
  padding-inline: 14px;
  position: absolute;
  right: -6px;
  top: 10px;

  p, span {
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0;
  }

  span {
    color: var(--secondary);
  }
`
