import { useCallback, useMemo, useEffect, useState, ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import Template from '../../template'
import PageLoaderComponent from '../../components/PageLoader'
import PostCard from '../../components/PostCard'
import api from '../../services/api'
import * as S from './style'

// to be revised
interface Post {
  Banner: { path: string }
  content: string | TrustedHTML
  createdAt: string
  excerpt: string | TrustedHTML
  id: number
  slug: string
  Tags: Array<{ id: number, title: string }>
  title: string
}

export default function Blog (): ReactElement {
  const [post, setPost] = useState<Post | null>(null)
  const [related, setRelated] = useState<Post[] | null>(null)
  const { slug } = useParams()

  const timestamp = useMemo(() => {
    if (post === null) return
    return new Date(post.createdAt).toLocaleString('pt-BR')
  }, [post])

  const loadPost = useCallback(async () => {
    const response = await api.get(`/post/${String(slug)}`)
    setPost(response.data.post)
    setRelated(response.data.relateds)
  }, [slug])

  useEffect(() => {
    loadPost()
    document.body.scrollTop = 500
    document.documentElement.scrollTop = 500
  }, [loadPost])

  if (post === null) return <PageLoaderComponent />

  return (
    <Template>
      <S.PostOpenContainer className="content-grid full">
        <article className="post-open">
          <S.BannerContainer className="post-open-cover liquid">
            <S.Banner src={post.Banner.path} alt="Banner" />
          </S.BannerContainer>
          <S.PostCard className="post-open-body">
            <div className="post-open-heading">
              <p className="post-open-timestamp"><span className="highlighted">{timestamp}</span></p>
              <h2 className="post-open-title">{post.title}</h2>
            </div>
            <div className="post-open-content">
              <div className="post-open-content-body">
                <p className="post-open-paragraph" dangerouslySetInnerHTML={{ __html: post.content }}></p>
                <div className="tag-list">
                  {post.Tags?.map(tag => <p key={tag.id} className="tag-item secondary">{tag.title}</p>)}
                </div>
              </div>
            </div>
            {(related !== null) && <S.PostRelatedPreview className="content-grid medium">
              <div className="section-header medium">
                <div className="section-header-info">
                  <p className="section-pretitle">Ver outras postagens</p>
                  <h2 className="section-title">Posts relacionados</h2>
                </div>
              </div>
              <S.Carousel>
                <div>
                  {related.map((post) => <PostCard key={post.id} {...post} />)}
                </div>
              </S.Carousel>
            </S.PostRelatedPreview>}
          </S.PostCard>
        </article>
      </S.PostOpenContainer>
    </Template>
  )
}
