import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageLoaderComponent from '../../components/PageLoader';
import PaginationComponent from '../../components/Pagination';
import { useAuth } from '../../contexts/AuthContext';
import { makeSearchProducts } from '../../main/factories/makeSearchProducts';
import Template from '../../template';
import { currency } from '../../utils/format';
import * as S from './styled';

const searchProducts = makeSearchProducts()

function Category () {
  const { departamentId } = useParams()
  const [categories, setCategories] = useState([])
  const [products, setProducts] = useState([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filter, setFilter] = useState({
    order: 1,
    minPrice: '',
    maxPrice: '',
    content: '',
    categories: [],
    departamentId,
    page: 1
  })

  const { userCan } = useAuth()

  const handleFilterByCategory = useCallback((id) => {
    const categories = [...filter.categories]
    if (categories.includes(id)) {
      setFilter((prev) => ({ ...prev, categories: categories.filter(cid => cid !== id) }))
      return
    }
    setFilter((prev) => ({ ...prev, categories: [...categories, id] }))
  }, [filter])

  const handleSearchProducts = useCallback(async (filter) => {
    handleSearchProductsBase({ ...filter, page: 1 })
  }, [])

  const handleSearchProductsBase = useCallback(async (filter) => {
    const response = await searchProducts.handle({ ...filter })
    setProducts(response.data.data)
    setCategories(response.data.categories)
    setPage(response.data.page)
    setTotalPages(response.data.totalPages)
  }, [])

  const handleChangePage = useCallback((page) => {
    setFilter((filter) => ({ ...filter, page: page }))
    console.log(filter)

    handleSearchProductsBase({ ...filter, page: page })
  }, [filter])


  useEffect(() => {
    (async () => {
      handleSearchProducts({
        order: 1,
        minPrice: '',
        maxPrice: '',
        content: '',
        categories: [],
        departamentId
      })
    })()
  }, [departamentId])

  if (!products) return <PageLoaderComponent />

  return (
    <Template>
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Buscar produtos</p>

          <h2 className="section-title">Filtro de produtos</h2>
        </div>

        <div className="section-header-actions">
          <Link className="section-header-subsection" to='/marketplace'>Marketplace</Link>
        </div>
      </div>

      <div className="section-filters-bar v4">
        <div className="section-filters-bar-actions">
          <form className="form">
            <div className="form-item split">
              <div className="form-input small">
                <label htmlFor="items-search"></label>
                <input type="text" id="items-search" name="items_search" value={filter.content} onChange={(e) => setFilter((prev) => ({ ...prev, content: e.target.value }))} />
              </div>

              <div className="button primary" onClick={() => handleSearchProducts(filter)}>
                <svg className="icon-magnifying-glass">
                  <use href="#svg-magnifying-glass"></use>
                </svg>
              </div>
            </div>
          </form>
        </div>

        {userCan('product-order') && <div className="section-filters-bar-actions">
          <form className="form">
            <div className="form-item split medium">
              <div className="form-select small">
                <label htmlFor="items-filter-order">Organizar por</label>
                <select id="items-filter-order" name="items_filter_order" onChange={(e) => setFilter((prev) => ({ ...prev, order: e.target.value }))}>
                  <option value={-1}>Decrescente</option>
                  <option value={1}>Crescente</option>
                </select>
                <svg className="form-select-icon icon-small-arrow">
                  <use href="#svg-small-arrow"></use>
                </svg>
              </div>

              <div className="button secondary" onClick={() => handleSearchProducts(filter)}>Aplicar filtros</div>
            </div>
          </form>
        </div>}
      </div>

      <S.MarketplaceGrid className="grid grid-3-9 small-space">
        <div className="marketplace-sidebar">
          <div className="sidebar-box">
            <p className="sidebar-box-title">Categorias</p>

            <div className="sidebar-box-items">
              {categories.map(c => (<div className="checkbox-line" key={c.id}>
                <div className="checkbox-wrap">
                  <input type="checkbox" id={c.id} name="category_all" value={c.id} onChange={() => handleFilterByCategory(c.id)} />
                  <div className="checkbox-box">
                    <svg className="icon-cross">
                      <use href="#svg-cross"></use>
                    </svg>
                  </div>
                  <label htmlFor={c.id}>{c.name}</label>
                </div>

                <p className="checkbox-line-text">&nbsp;</p>
              </div>))}
            </div>

            <p className="sidebar-box-title">Range de preço</p>

            <div className="sidebar-box-items small-space">
              <div className="form-item split">
                <div className="form-input small active always-active currency-decorated">
                  <label htmlFor="price-from">De</label>
                  <input type="text" id="price-from" name="price_from" value={filter.minPrice} onChange={(e) => setFilter((prev) => ({ ...prev, minPrice: e.target.value }))} />
                </div>

                <div className="form-input small active always-active currency-decorated">
                  <label htmlFor="price-to">Até</label>
                  <input type="text" id="price-to" name="price_to" value={filter.maxPrice} onChange={(e) => setFilter((prev) => ({ ...prev, maxPrice: e.target.value }))} />
                </div>
              </div>
            </div>

            <div className="button small primary" onClick={() => handleSearchProducts(filter)}>Aplicar filtro</div>
          </div>
        </div>

        <div className="marketplace-content">
          <div className="grid grid-3-3-3 centered">
            {products.map(p => <div className="product-preview" style={{ height: '100%' }} key={p.id}>
              <Link to={`/product/${p.id}`}>
                <S.Figure className="product-preview-image liquid">
                  {(p.skus.length > 0 && p.skus[0].images.length > 0) && <img src={p.skus[0].images[0].path} alt="item-01" />}
                </S.Figure>
              </Link>

              <div className="product-preview-info">
                <p className="text-sticker"><span className="highlighted"></span>{p.skus[0]?.price ? currency(p.skus[0]?.price) : ''}</p>

                <p className="product-preview-title"><Link to={`/product/${p.id}`}>{p.name}</Link></p>

              </div>

            </div>)}

          </div>
          <S.PaginationContainer>
            <PaginationComponent currentPage={page} totalPages={totalPages} onChangePage={handleChangePage} />
          </S.PaginationContainer>
        </div>
      </S.MarketplaceGrid>
    </Template >
  );
}

export default Category