import styled from 'styled-components'

export const ChatContainer = styled.div`
  display: flex;

  > div {
    height: 680px;
    justify-content: space-between;
    max-width: 100%;

    @media screen and (min-width: 961px) {
      &:first-child {
        border-right: 1px solid #eaeaf5;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    p {
      margin: 0;
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;
    gap: 1.75rem;
  }
`
