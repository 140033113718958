import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Tooltip = styled.div`
    visibility: hidden;
    display: none;
`;

export const Li = styled.li`
    &:hover {
        ${Tooltip} {
            visibility: visible;
            white-space: nowrap;
            position: absolute;
            z-index: 99999;
            left: 65px;
            top: 25%;
            margin-top: 0px;
            opacity: 1;
            transform: translate(-10px);
            transition: all 0.3s ease-in-out 0s;
        };
   }

   &.active i {
     color: #fff;
   }
`;

export const ShortDesc = styled.div`
   padding-top: 0;
   margin-top: -50px;

   p.points-description {
    text-align: center;
   }
`;

export const AwesomeMenuIconSmall = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 48px;
  pointer-events: none;
  transition: all .2s ease-in-out;
  color: #adafca;
`;

export const AwesomeMenuIconSmallMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 48px;
  pointer-events: none;
  transition: all .2s ease-in-out;
  color: #adafca;
`;

export const AwesomeMenuIcon = styled.div`
  color: #adafca;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:hover {
    color: #3e3f5e;
  }

  i.active {
    color: #fff;
  }
`;

export const AwesomeLink = styled(Link)`
  &:hover div {
    color: var(--button-primary);
  }
`;

export const AwesomeAnchor = styled.a`
  &:hover div {
    color: var(--button-primary);
  }
`;

export const DownloadButton = styled.button`
  display: block;
  padding: 8px 0;
  font-size: .875rem;
  font-weight: 700;
  transition: padding .2s ease-in-out,color .2s ease-in-out;
  background-color: transparent;
  text-align: left;
  color: #3e3f5e;
  font-family: Rajdhani,sans-serif;
  line-height: 1em;
  margin-left: 30px;

  &:focus {
    outline: none;
  }

  &:hover {
    padding-left: 4px;
    color: #00c7d9;
  }
`;

export const AvatarContainer = styled.div.attrs(() => {
  return { className: 'js-avatar-container' };
})`
  width: 100px;
`;

export const SidebarContainer = styled.div`
  nav.sidebar {
    z-index: 0;
  }
  ul.menu {
    height: calc(100% + 140px);
    padding-bottom: 10rem;
  }
  ul.menu.small {
    padding-bottom: 10rem;
    overflow-y: scroll;
    height: 80%;
  }
  a.menu-item-link {
    color: var(--black);
    min-width: 48px;
    text-decoration: none;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;
