import { createRef, useState } from 'react';
import api from '../../../services/api';
import { ProgressBar, ProgressContainer } from './styles';

export default function ImageUpload (props) {
  const [status, setStatus] = useState('awaiting');
  const [progress, setProgress] = useState('awaiting');
  const { title, text, onSend } = props;
  let fileInputRef = createRef();

  const callForUpload = () => {
    if (status === 'awaiting') fileInputRef.click();
  };

  const handleUpload = async (e) => {
    const [file] = e.target.files;
    if (!file) return;
    const formData = new FormData();
    formData.append('image', file);
    setStatus('sending');
    const response = await api.post('/upload', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: (e) => {
        const progress = Math.round((e.loaded * 100) / e.total);
        setProgress(progress);
      }
    });

    console.log(response);
    if (onSend) onSend(response.data);
    setStatus('completed');
    setTimeout(() => setStatus('awaiting'), 10000);
  };

  const statusList = {
    awaiting: (
      <>
        <div style={{ width: '0px', height: '0px', overflow: 'hidden' }}>
          <input
            type="file"
            id="input"
            ref={(fileInput) => {
              fileInputRef = fileInput;
            }}
            onChange={handleUpload}
          />
        </div>
        <svg className="upload-box-icon icon-members">
          <use href="#svg-members"></use>
        </svg>

        <p className="upload-box-title">{title}</p>

        <p className="upload-box-text">{text}</p>
      </>
    ),
    sending: (
      <>
        <p>Enviando: {progress}%</p>
        <ProgressContainer>
          <ProgressBar progressValue={progress} />
        </ProgressContainer>
      </>
    ),
    completed: <>Sua imagem foi enviada com sucesso!</>
  };

  return (
    <div className="upload-box" onClick={callForUpload}>
      {statusList[status]}
    </div>
  );
}
