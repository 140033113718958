import { Link } from 'react-router-dom'
import { ReactElement } from 'react'
import Banner from '../Banner'
import Card from '..'
import CardTreinamentosProps from './CardTreinamentos.props'

import * as S from './style'

export default function CardTreinamentos ({
  avatar,
  category,
  categoryUrl,
  thumbnailTitle,
  thumbnailUrlSrc,
  title
}: CardTreinamentosProps): ReactElement {
  return <Card>
    <S.CardTreinamentos>
      <Banner>
        <S.Thumbnail title={thumbnailTitle} src={thumbnailUrlSrc} />
      </Banner>

      <S.AvatarContainer>
        <S.Avatar src={avatar} alt="Avatar" />
      </S.AvatarContainer>

      <S.ContentContainer>
        <S.Title to={'/treinamentos/' + categoryUrl}>{title}</S.Title>

        {/* creator @ */}
        {/* <p className="stream-box-text"><a href="/treinamentos/construtor-de-vendas" target="_blank">@ManuelNeto</a></p> */}

        <S.Category>Categoria: <Link to={'/treinamentos/' + categoryUrl} target="_blank">{category}</Link></S.Category>
      </S.ContentContainer>

      <S.MetaContainer>
        <a className="user-avatar small no-outline" href="#anchor">
          <div className="user-avatar-content">
            <div className="hexagon-image-30-32" data-src={avatar}></div>
          </div>

          <div className="user-avatar-progress">
            <div className="hexagon-progress-40-44"></div>
          </div>

          <div className="user-avatar-progress-border">
            <div className="hexagon-border-40-44"></div>
          </div>

          <div className="user-avatar-badge">
            <div className="user-avatar-badge-border">
              <div className="hexagon-22-24"></div>
            </div>

            <div className="user-avatar-badge-content">
              <div className="hexagon-dark-16-18"></div>
            </div>

            <p className="user-avatar-badge-text">24</p>
          </div>
        </a>
      </S.MetaContainer>
    </S.CardTreinamentos>
  </Card>
}
