import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CargoComponent from '../../components/CargoComponent';
import ChannelComponent from '../../components/ChannelComponent';
import InputText from '../../components/Forms/InputText';
import PageLoaderComponent from '../../components/PageLoader';
import RoleSelectorComponent from '../../components/RoleSelectorComponent';
import { useFetch } from '../../hooks/useFetch';
import api from '../../services/api';
import Template from '../../template';
import { MaskDocument, MaskPhone } from '../../utils/mask';
import * as S from './style';

// Component
import { useParams } from 'react-router-dom';
import { useCampaign } from '../../contexts/CampaignContext';

export default function EditUser () {
  const [user, setUser] = useState({});
  const [, setRole] = useState({});
  const [, setHolding] = useState({});
  const [address, setAddress] = useState({});
  const [button, setButton] = useState({
    name: 'Carregando dados',
    isDisabled: true
  });

  const { userId } = useParams();
  const { campaignData } = useCampaign();

  const { data } = useFetch(`/user/${userId}`);

  const handleUserSave = useCallback(async (e) => {
    e.preventDefault();
    setButton({
      name: 'Salvando dados',
      isDisabled: true
    });
    try {
      await api.put(`/user/${user.id}`, user);
      toast.success('Os dados foram salvos', { theme: 'colored' });
    } catch (e) { }
    setButton({
      name: 'Salvar dados',
      isDisabled: false
    });
  }, [user]);

  const handleApprove = useCallback(async (evt) => {
    evt.preventDefault();
    try {
      await api.patch(`/user/${user.id}/approve`);
      toast.success('Usuário aprovado com sucesso!', { theme: 'colored' });
    } catch (err) {
      toast.error('Ocorreu um erro e não foi possivel aprovar o usuário', { theme: 'colored' });
    }
  }, [user]);

  useEffect(() => {
    if (!data) return;
    setUser(data);
    setRole(data.role);
    setHolding(data.holding);
    setAddress(data.address || {});
    setButton({
      name: 'Salvar dados',
      isDisabled: false
    });
  }, [campaignData, data]);

  if (!user) return <PageLoaderComponent />;

  return (
    <Template>

      <div className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Meu perfil</p>

              <h2 className="section-title">Informação de perfil</h2>
            </div>
          </div>

          <div className="grid-column">

            <div className="widget-box">
              <p className="widget-box-title">Sobre seu perfil</p>

              <div className="widget-box-content">

                <form className="form" action="" method="POST" onSubmit={handleUserSave}>
                  <div className="form-row split">
                    <div className="form-item">
                      <RoleSelectorComponent value={user.role?.id} onChange={(role) => { setUser({ ...user, roleId: role.id }); setRole(role); }} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="repLegal" label="Responsavel Legal" id="repLegal" value={user.repLegal} onChange={(value) => setUser({ ...user, repLegal: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="cdgHolding" label="Cód. Cliente" id="cdgHolding" value={user.cdgHolding} onChange={(value) => setUser({ ...user, cdgHolding: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="razaoSoc" label="Razão Social" id="razaoSoc" value={user.razaoSoc} onChange={(value) => setUser({ ...user, razaoSoc: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="repComercial" label="Representante Comercial" id="repComercial" value={user.repComercial} onChange={(value) => setUser({ ...user, repComercial: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="name" label="Nome fantasia" id="name" value={user.name} onChange={(value) => setUser({ ...user, name: value })} />
                    </div>

                    <div className="form-item">
                      <InputText name="email" label="Email Corporativo" id="email" value={user.email} onChange={(value) => setUser({ ...user, email: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cellphone" label="Telefone Corporativo" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: MaskPhone(value) })} maxLength="15" />
                    </div>
                    <div className="form-item">
                      <InputText name="document" label="CNPJ" id="document" value={user.document} onChange={(value) => setUser({ ...user, document: MaskDocument(value) })} maxLength="18" />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="estado" label="Estado" id="estado" value={user.address?.estado} onChange={(value) => setUser({ ...user, address: { ...user.address, estado: value } })} />
                    </div>
                    <div className="form-item">
                      <InputText name="cidade" label="Cidade" id="cidade" value={user.address?.cidade} onChange={(value) => setUser({ ...user, address: { ...user.address, cidade: value } })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <ChannelComponent name="channelId" label="Canal" id="channelId" value={user.channelId} onChange={(value) => setUser({ ...user, channelId: value })} />
                    </div>
                    <div className="form-item">
                      <CargoComponent name="cargo" label="Cargo" id="cargo" value={user.cargo} onChange={(value) => setUser({ ...user, cargo: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    {!user.approved && <div className="form-item">
                      <button className="button primary add-field-button" onClick={handleApprove}>Aprovar usuário</button>
                    </div>}
                    <div className="form-item">
                      <S.SubmitButton className="button primary add-field-button" type="submit" disabled={button.isDisabled}>{button.name}</S.SubmitButton>
                    </div>

                  </div>

                </form>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Template >
  );
}
