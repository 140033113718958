import styled, { css } from 'styled-components';

const sizes = {
  small: css`
    height: 6px;
    border-radius: 3px;
  `,
  large: css`
    height: 12px;
    border-radius: 6px;
  `
};

export const ProgressBar = styled.div.attrs((props) => ({
  style: {
    width: (props.progress * 10) > 100 ? '100%' : `${props.progress * 10}%`
  }
}))`
  width: 100%;
  ${({ size }) => sizes[size || 'small']}
  background-color: #e7e8ee;
  position: relative;
  overflow: hidden;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    display: block;
    border-radius: 3px;
    width: ${({ current, max }) => current * 100 / max}%;
    content: "";
    background: var(--primary);
    background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
    transition: width 2s ease;
  }
`;
