import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1180px;
  margin: 0 auto;
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
  width: auto;
`;

export const ClubImage = styled.img`
  width: 100%;
`

export const DownloadButton = styled.button`
  min-width: 168px;
  width: auto;
  margin-top: 0;
  box-shadow: 4px 7px 12px 0 rgba(35,210,226,.2);
  display: inline-block;
  height: 48px;
  border-radius: 10px;
  color: var(--white);
  font-size: .875rem;
  font-weight: 700;
  text-align: center;
  line-height: 48px;
  background-color: var(--primary);
  cursor: pointer;
  transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
  box-shadow: 3px 5px 10px 0 rgba(62,63,94,.2);
  `

export const DownloadText = styled.p`
  margin-top: 32px;
  width: 100%;
  font-size: 1.25rem;
`
