import { ReactElement, useCallback } from 'react'
import { Link } from 'react-router-dom'
import Card from '..'
import Banner from '../Banner'
import CardMarketplaceProps from './CardMarketplace.props'

import * as S from './style'

export default function CardMarketplace ({
  category,
  description,
  itemCode,
  skus,
  title
}: CardMarketplaceProps): ReactElement {
  const longDesc = useCallback((longDescription: string) => {
    if (longDescription === undefined) return ''
    return longDescription.length < 70 ? `${longDescription.replace(/&lt;/g, '<').replace(/&gt;/g, '<')}` : `${longDescription.replace(/&lt;/g, '<').replace(/&gt;/g, '<').substring(0, 80)}...`
  }, [])

  return <Card>
    <S.CardContainer>
      <Link to={`/product/${String(itemCode)}`}>
        <Banner>
          {skus[0].images[0] !== undefined && <img src={skus[0].images[0].path} alt={title} />}
        </Banner>
      </Link>

      <S.CardContentContainer>
        <S.PriceContainer>
          <p>
            <span>$</span>
            {' '}
            {Number(skus[0].price).toFixed(2).toString().replace(/\./g, ',')}
          </p>
        </S.PriceContainer>

        <S.TitleContainer>
          <Link to={`/product/${String(itemCode)}`}>{title}</Link>

          <S.CategoryLinkContainer>
            <Link to="">{category}</Link>
          </S.CategoryLinkContainer>
        </S.TitleContainer>

        <S.DescriptionContainer dangerouslySetInnerHTML={{ __html: longDesc(description) }} />
      </S.CardContentContainer>
    </S.CardContainer>

    <S.CardFooter />
  </Card>
}
