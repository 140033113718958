import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  gap: 1rem;
  max-width: 884px;
  width: 100%;

  .single-card {
    max-width: 100%;
    padding: 32px 28px;
  }

  > div:first-child {
    margin-top: 0;
  }
`
