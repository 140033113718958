import { ReactElement } from 'react'
import ChatMessageItem from './ChatMessageItem'
import Card from '../../../../../../components/Card'
import InputText from '../../../../../../components/Forms/InputText'

import * as S from './style'

export default function ChatMessagesMenu (): ReactElement {
  return <Card>
    <S.ChatMessagesMenuContainer>
      <ChatMessageItem
        message="Great! Then we'll meet with them at the party..."
        timestamp="Há 2 horas"
        userName="Bearded Wonder"
      />
      <ChatMessageItem
        message="Awesome! I'll see you there!"
        timestamp="Há 54 minutos"
        userName="Neko Bebop"
      />
      <ChatMessageItem
        message="Can you stream the new game?"
        timestamp="Há 2 horas"
        userName="Nick Grissom"
      />
      <ChatMessageItem
        message="I'm sending you the latest news of the release..."
        timestamp="Há 16 horas"
        userName="Sarah Diamond"
      />
      <ChatMessageItem
        message="Can you stream the new game?"
        timestamp="Há 2 horas"
        userName="Matt Parker"
      />
      <ChatMessageItem
        message="Can you stream the new game?"
        timestamp="Há 2 horas"
        userName="Sandra Strange"
      />
      <ChatMessageItem
        message="Great! Then we'll meet with them at the party..."
        timestamp="Há 7 dias"
        userName="James Murdock"
      />
    </S.ChatMessagesMenuContainer>

    <S.SearchMessageContainer>
      <InputText placeholder="Pesquisar Mensagens...">
        <S.IconButton>
          <svg className="interactive-input-icon icon-magnifying-glass">
            <use xlinkHref="#svg-magnifying-glass"></use>
          </svg>
        </S.IconButton>
      </InputText>
    </S.SearchMessageContainer>
  </Card>
}
