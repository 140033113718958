import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import Checkbox from '../../components/Forms/Checkbox';
import PageLoaderComponent from '../../components/PageLoader';
import Template from '../../template';
import * as S from './style';
import RTEditor from '../../components/RTEditor';
import { useModal } from '../../contexts/ModalContext';

// const allCanais = [
//   'VJ',
//   'C&C',
//   'MERCHANDISING',
//   'GKA',
//   'LMT',
//   'FOOD+DIA+FARMA',
//   'Admin',
//   'SAC',
//   'XX',
//   'ATC',
//   'PRD',
//   'TD',
//   'COM',
//   'HIB',
// ];

function AdminNotice () {
  const token = localStorage.getItem('token');
  const { openModal } = useModal();
  const [selectedCanais, setSelectedCanais] = useState([]);
  const [message, setMessage] = useState([]);
  const [allCanais, setAllCanais] = useState();
  const [saving, setSaving] = useState(false);

  const addCanalToSelected = useCallback((channelName) => {
    setSelectedCanais([...selectedCanais, channelName]);
  }, [selectedCanais]);

  const removeCanalToSelected = useCallback((channelName) => {
    const selected = selectedCanais.filter(r => r !== channelName);
    setSelectedCanais(selected);
  }, [selectedCanais]);

  const handleSelection = useCallback((checked, value) => {
    if (checked) {
      return addCanalToSelected(value);
    }
    return removeCanalToSelected(value);
  }, [addCanalToSelected, removeCanalToSelected]);

  const handleSend = useCallback(async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setSaving(true);
    const data = {
      canal: selectedCanais,
      message
    };
    await api.post('notice', data, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    openModal({ body: <div className='form-row'>A mensagem foi enviada!</div>, header: 'Mensagem enviada' });
    setSaving(false);
  }, [message, openModal, selectedCanais, token]);

  const loadCanais = useCallback(async () => {
    const response = await api.get('/channels');
    setAllCanais(response.data);
  }, []);

  useEffect(() => {
    loadCanais();
  }, [loadCanais]);

  if (!allCanais) return <PageLoaderComponent />;

  return <Template>
    <S.NoticeContainer className="account-hub-content">
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Notificações</p>

          <h2 className="section-title">Envie mensagens para seus usuários</h2>
        </div>
      </div>

      <div className="grid-column">
        <div className="widget-box">
          <p className="widget-box-title">Cargos</p>

          <div className="widget-box-content">
            <form className="form" action="" method="POST" onSubmit={handleSend}>
              <S.RoleGrid>
                {allCanais && allCanais.map(canal => (
                  <Checkbox
                    key={canal.id}
                    name={canal.name}
                    label={canal.id}
                    text={canal.name}
                    onChange={handleSelection}
                    checked={selectedCanais.includes(canal.id.toString())}
                  />))}
              </S.RoleGrid>
              <div className="form-row split">
                <div className="form-item">
                  <S.TextAreaContainer>
                    <S.TextAreaLabel>Mensagem</S.TextAreaLabel>
                    <RTEditor onChange={(text) => setMessage(text)} />
                  </S.TextAreaContainer>
                </div>
              </div>

              <div className="form-row split">
                <div className="form-item">
                  <button
                    type="submit"
                    className="button primary add-field-button"
                    disabled={saving}
                  >{saving ? 'Salvando...' : 'Salvar'}</button>
                </div>

              </div>

            </form>
          </div>
        </div>

      </div>
    </S.NoticeContainer>
  </Template>;
}

export default AdminNotice;
