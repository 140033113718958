import styled from 'styled-components';

import bg from '../../assets/img/quiz/bg-trofeu.png';

export const BannerContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 28.57%;
`;

export const Banner = styled.div`
    position: absolute;
    inset: 0;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-size: 85%;
    background-position: center right 32%;
    margin: 0 auto;
    margin-bottom: 50px;
    display: flex;


    h2{
        color: #fff;
        text-transform: uppercase;
        position: relative;
        margin-left: 32px;
        line-height: 2,502rem;
        font-size: 2.78rem;

        span{
            font-size: 136%;
        }
    }

  @media (max-width: 932px) {
    h2 {
      font-size: 30px;
    }
  }
`;

export const Text = styled.div`
  display: flex;
  align-items: center;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  margin-top: 64px;
    
  @media (max-width: 1128px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 932px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
