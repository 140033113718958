import { ReactElement } from 'react'
import InputText from '../../../../../../components/Forms/InputText'

import * as S from './style'

export default function InterestsInfo (): ReactElement {
  return <S.InterestsInfoContainer>
    <InputText as="textarea" label="Séries de TV Favoritas" name="favTv" />

    <InputText as="textarea" label="Bandas / Artistas Musicais Favoritos" name="favMusic" />

    <InputText as="textarea" label="Filmes Favoritos" name="favFilms" />

    <InputText as="textarea" label="Livros Favoritos" name="favBooks" />

    <InputText as="textarea" label="Jogos Favoritos" name="favGames" />

    <InputText as="textarea" label="Meus Hobbies" name="favHobbies" />
  </S.InterestsInfoContainer>
}
