import { ReactElement, useCallback, useEffect, useState } from 'react'
import bgImg1 from '../../assets/img/marketplace/category/01.png'
import bgImg2 from '../../assets/img/marketplace/category/02.png'
import bgImg3 from '../../assets/img/marketplace/category/03.png'
import bgImg4 from '../../assets/img/marketplace/category/04.png'
import bgImg5 from '../../assets/img/marketplace/category/05.png'
import CardMarketplace from '../../components/Card/CardMarketplace'
import InputTextWithButton from '../../components/Forms/InputTextWithButton'
import PaginationComponent from '../../components/Pagination'
import SectionHeaderComponent from '../../components/SectionHeaderComponent'
import api from '../../services/api'
import Template from '../../template'
import CategoryCard from './CategordyCard'
import * as S from './styled'

interface ProductData {
  id: number
  category: { name: string }
  cnpj: string
  code: number
  description: string
  name: string
  sellerId: number
  sellerName: string
  brandCode: number
  brandName: string
  fotoPequena: string
  fotoMedia: string
  fotoGrande: string
  palavraChave: string
  maisVendidos: number
  skus: Array<{ preco: number, images: any[] }>
  images: Array<{ path: string }>
}

enum State {
  LOADING,
  READY
}

export default function Marketplace (): ReactElement {
  const [state, setState] = useState<State>(State.LOADING)
  const [cards, setCards] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [filter, setFilter] = useState('')

  const loadPage = useCallback(async (page: number) => {
    const response = await api.get('/products', { params: { filter, page } })
    setPage(page)
    setCards(response.data.data)
    setTotalPages(response.data.totalPages)
    setState(State.READY)
  }, [filter])

  useEffect(() => {
    (async () => {
      const response = await api.get('/products', { params: { filter, page: 1 } })
      setCards(response.data.data)
      setTotalPages(response.data.totalPages)
      setState(State.READY)
    })()
  }, [filter])

  return (
    <Template>
      <SectionHeaderComponent
        pretitle="Pesquise o que você quiser!"
        title="Categorias"
      />

      <S.GridContainer>
        <CategoryCard
          bgImgSrc={bgImg1}
          description="Navegue por todos os itens"
          buttonTitle='Mais itens'
          title="Todos os itens"
          to="/"
        />

        <CategoryCard
          bgImgSrc={bgImg5}
          description=""
          buttonTitle='Mais itens'
          title="Utilidades Domésticas"
          to="/utilidades-domesticas"
        />

        <CategoryCard
          bgImgSrc={bgImg2}
          description="A melhor seleção"
          buttonTitle='Mais itens'
          title="Casa e Cozinha"
          to="/casa-e-cozinha"
        />

        <CategoryCard
          bgImgSrc={bgImg3}
          description="Produtos Digitais"
          buttonTitle='Mais itens'
          title="Gift cards"
          to="/produtos-digitais"
        />

        <CategoryCard
          bgImgSrc={bgImg4}
          description="Notebooks, videogames..."
          buttonTitle='Mais itens'
          title="Eletrônicos"
          to="/eletronicos"
        />
      </S.GridContainer>

      <S.FilterContainer>
        <InputTextWithButton
          label="Pesquisar produto"
          onClick={setFilter}
          value={filter}
        />
      </S.FilterContainer>

      <SectionHeaderComponent
        headerActions={[{ title: 'Mostrar todos os itens', url: '/marketplace-category' }]}
        pretitle="Veja as novidades!"
        title="Todos os itens"
      />

      <S.GridContainer>
        {state === State.LOADING && <>Carregando produtos...</>}
        {state === State.READY && cards.map(({ id, category, code, description, name, skus }: ProductData) => {
          return <CardMarketplace
            key={code}
            itemCode={id}
            category={category.name}
            description={description}
            title={name}
            skus={skus}
          />
        })}
      </S.GridContainer>
      <S.PaginationContainer>
        <PaginationComponent totalPages={totalPages} currentPage={page} onChangePage={async (page: any) => await loadPage(page)} />
      </S.PaginationContainer>
    </Template >)
}
