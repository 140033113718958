import styled from 'styled-components'

export const Container = styled.div`
  flex: 1 calc(50% - 8px);
`

export const FilterContainer = styled.div`
  position: relative;

  select {
    cursor: pointer;
    font-size: 0.75rem;
    padding-inline: 1.25rem 2.75rem;
    width: 100%;
  }
`

export const FormSelectIcon = styled.svg`
  fill: var(--dark);
  transform: rotate(90deg);
  position: absolute;
  top: 0.625rem;
  right: 1.25rem;
  pointer-events: none;
  width: 0.375rem;
  height: 0.5rem;
`

export const Title = styled.div.attrs(() => {
  return {
    className: 'js-widget-box-title'
  }
})`
  align-items: center;
  display: flex;
  justify-content: space-between;
  p {
    color: #212529;
    font-size: 1rem;
    font-weight: 700;
  }
`

export const Table = styled.table`
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;

  thead, tbody {
    th, td {
      padding: 14px;
      text-align: center;

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
`
