import styled from 'styled-components'

export const PaymentAccordion = styled.section`
  display: grid;
  gap: 0.75rem;
  padding-top: 1.75rem;
  position: relative;

  &, * {
    cursor: pointer;
  }
`

export const PaymentAccordionLabel = styled.p`
  align-items: center;
  display: flex;
  font-weight: 700;
  gap: 0.75rem;
  line-height: 1rem;
  margin: 0;
  text-transform: capitalize;
`

export const PaymentAccordionInput = styled.input`
  appearance: none;
  background-color: var(--white);
  border: 1px solid #dedeea;
  border-radius: 50%;
  height: 1.375rem;
  pointer-events: none;
  width: 1.375rem;
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;

  :checked {
    border: 6px solid #23d2e2;
  }
`

export const PaymentAccordionDescriprion = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0;
`

export const PaymentAccordionContent = styled.div`
  display: none;
  overflow: hidden;

  &.accordion-open {
    display: block;
  }
`
