import { useCallback, useEffect, useState } from 'react';
import InputSelect from '../../../../../components/Forms/InputSelect';
import ImageDropzone from '../../../../../components/ImageDropzone';
import { useAuth } from '../../../../../contexts/AuthContext';
import api from '../../../../../services/api';
import * as S from './style';

function Photo ({ photo, type, index, stateName, reason, providerId, photoTypeId, month, createdAt }) {
  const { userCan } = useAuth();

  const [photoState, setPhotoState] = useState();
  const [photoType, setPhotoType] = useState();
  const [photoTypeName, setPhotoTypeName] = useState('');
  const [currentStateName, setCurrentStateName] = useState(false);
  const [showSaveButton, setShowSaveButton] = useState(false);

  const handleChangePhoto = useCallback((img) => {
    setShowSaveButton(true);
    setPhotoState(img.path);
  }, []);

  const handleSave = useCallback(async () => {
    await api.post('/photos', {
      filePath: photoState,
      providerId,
      photoTypeId: photoType
    });
    setShowSaveButton(false);
    setCurrentStateName('Aguardando avaliação');
  }, [photoState, photoType, providerId]);

  const handleChangePhotoType = useCallback((typeJSON) => {
    const type = JSON.parse(typeJSON);
    setPhotoType(type.id);
    setPhotoTypeName(type.name);
  }, []);

  useEffect(() => {
    setPhotoState(photo);
    setCurrentStateName(stateName);
    setPhotoType(photoTypeId);
    setPhotoTypeName(type);
  }, [photo, photoTypeId, stateName, type]);

  return <>{photoState && <div className="album-preview">
    <figure className="album-preview-image liquid" />
    {!userCan('photo:approve') && <div
      className="album-preview-info"
      style={{ background: `rgba(0, 0, 0, 0) url("${photoState}") no-repeat scroll center center / cover` }}
      onClick={() => { }}
    >
      <p className="album-preview-title">{photoTypeName}</p>

      {month && <S.DateContainer>
        <span style={{ fontWeight: 'bold' }}>{month}</span><br />{new Date(createdAt).toLocaleString('pt-br')}
      </S.DateContainer>}

      <p className="album-preview-text">
        {currentStateName}
        {reason && <><br />Motivo: {reason}</>}
      </p>
    </div>}

    {userCan('photo:approve') && <div
      className="album-preview-info"
      style={{ background: `rgba(0, 0, 0, 0) url("${photoState}") no-repeat scroll center center / cover` }}
    >
      <p className="album-preview-title">{photoTypeName}</p>

      {month && <S.DateContainer>
        <span style={{ fontWeight: 'bold' }}>{month}</span><br />{new Date(createdAt).toLocaleString('pt-br')}
      </S.DateContainer>}

      <p className="album-preview-text">
        {currentStateName}
        {reason && <><br />Motivo: {reason}</>}
      </p>
      {showSaveButton && <>
        <InputSelect onChange={handleChangePhotoType}>
          <option value="">Selecione o tipo</option>
          <option value={JSON.stringify({ id: 1, name: 'Ilha' })}>Ilha</option>
          <option value={JSON.stringify({ id: 2, name: 'Terminal de Gôndola' })}>Terminal de Gôndola</option>
        </InputSelect>
        <S.Button className='button primary' onClick={handleSave}>Salvar</S.Button>
      </>}
    </div>}
  </div>}

    {userCan('photo:send') && !photoState && <S.DropzoneContainer className="album-preview">
      <ImageDropzone typeName={index} onDrop={handleChangePhoto} />
    </S.DropzoneContainer>}
  </>;
}

export default Photo;
