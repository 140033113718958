import styled from 'styled-components'

export const Grid = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-top: 2rem;

  > div {
    flex: 1 calc((100% - 1rem) / 2);
    width: auto;

    @media (max-width: 1113px) {
      flex: 1 100%;
    }
  }
`
