import styled from "styled-components"

export const Figure = styled.figure`
  align-items: center;
  display: flex;
  justify-content: center;
  img {
    height: auto;
    max-height: 100%;
    max-width: auto;
    width: auto;
  }
`

export const MarketplaceGrid = styled.div`
  @media screen and (max-width: 1365px) {
    grid-template-columns: 23.9% 74.6% !important;
  }

  @media screen and (max-width: 860px) {
    grid-template-columns: auto !important;
  }
`

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
