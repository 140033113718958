import Template from '../../template';

export default function Quests () {
  return (
        <Template>
            <div className="grid">
                <div className="content-grid">
                    <div className="section-header">
                        <div className="section-header-info">
                            <p className="section-title">Essa página ainda não está disponível para você, mas em breve estará!</p>
                        </div>
                    </div>
                </div>
            </div>
        </Template>
  );
}
