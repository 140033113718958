import styled from 'styled-components';

export const RoleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const DatePickerLabel = styled.span`
  padding-left: 0.25rem;
  color: #3e3f5e;
  font-weight: 700;
  font-size: 0.75rem;
`;

export const TextAreaContainer = styled.div`
  position: relative;
`;

export const TextAreaLabel = styled.label`
  position: absolute;
  top: 0;
  left: 1rem;
  background-color: #fff;
  padding: .5rem;
  z-index: 2;
`;

export const TextArea = styled.textarea`
  position: relative;
  top: 1rem;
  left: 0rem;
  z-index: 1;
`;

export const NewAlternatives = styled.div`
  display: flex;
  justify-content: flex-end;
  max-width: 100%;
  .form-row.split {
    flex-wrap: wrap;
    .form-item {
      flex: 1;
      flex-basis: 18%;
      flex-grow: 0.30;
      margin-top: 0.5rem;
    }
  }
`;

export const NewQuestion = styled.div`
  input {
    margin-bottom: 1rem;
  }
  .question-button-container {
    display: flex;
    gap: 1rem;
    justify-content: center;
  }
  button {
    margin-top: 1rem;
    padding: 1rem;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    &.correct {
      background-color: var(--green);
    }
    &.danger {
      background-color: var(--red);
    }
    &.remove {
      width: 15%;
    }
  }
`;

export const AlternativeBox = styled.div`
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    gap: 0.5rem;
    button {
      font-size: 0.75rem;
      margin-top: 0;
      width: min-content;
      max-width: 4.5rem;
      max-height: 1rem;
    }
  }
`;
