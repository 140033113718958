import { AxiosInstance } from 'axios'
import { LoadPolibrasProduct } from '../../domain/use-case/LoadPolibrasProduct'
import { LoadPolibrasProducts } from '../../domain/use-case/LoadPolibrasProducts'

export class ApiLoadPolibrasProduct implements LoadPolibrasProduct {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: LoadPolibrasProduct.Params): Promise<LoadPolibrasProducts.Result> {
    try {
      const response = await this.api.get(`/polibras/product/${params.id}`)
      return response
    } catch (error: any) {
      return error.response
    }
  }
}
