import { ReactElement, createRef, useState } from 'react'
import api from '../../services/api'
import { ImageUploadProps } from './props'
import * as S from './styles'

function ImageUpload ({ title, text, onSend }: ImageUploadProps): ReactElement {
  const [status, setStatus] = useState('awaiting')
  const [progress, setProgress] = useState<number>(0)
  const fileInputRef = createRef<HTMLInputElement>()

  const callForUpload = (): void => {
    if (!fileInputRef.current) return
    if (status === 'awaiting') fileInputRef.current.click()
  }

  const handleUpload = async (e: any): Promise<void> => {
    const files: FileList = e.target.files

    if (!files) return

    setStatus('sending')

    for (const file of files) {
      const formData = new FormData()

      formData.append('image', file)

      const response = await api.post('/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (e) => {
          const progress = Math.round((e.loaded * 100) / e.total)
          setProgress(progress)
        }
      })

      if (onSend) onSend(response.data)
    }

    setStatus('completed')
    setTimeout(() => setStatus('awaiting'), 2000)
  }

  const statusList: Record<string, any> = {
    awaiting: (
      <>
        <div style={{ width: '0px', height: '0px', overflow: 'hidden' }}>
          <input
            type="file"
            id="input"
            ref={fileInputRef}
            onChange={handleUpload}
            multiple
          />
        </div>
        <svg className="upload-box-icon icon-members">
          <use href="#svg-members"></use>
        </svg>

        <p className="upload-box-title">{title}</p>

        <p className="upload-box-text">{text}</p>
      </>
    ),
    sending: (
      <>
        <p>Enviando: {progress}%</p>
        <S.ProgressContainer>
          <S.ProgressBar progressValue={progress} />
        </S.ProgressContainer>
      </>
    ),
    completed: <>Sua imagem foi enviada com sucesso!</>
  }

  return (
    <div className="upload-box" onClick={callForUpload}>
      {statusList[status]}
    </div>
  )
}

export default ImageUpload
