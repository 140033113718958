import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 calc(50% - 8px);
`;

export const Title = styled.div.attrs(() => {
  return {
    className: 'js-widget-box-title'
  };
})`
  font-size: 1rem;
  font-weight: 700;
`;

export const Table = styled.table`
  font-size: .75rem;
  font-weight: 700;
  text-transform: uppercase;
  width: 100%;

  thead, tbody {
    th, td {
      padding: 14px;
      text-align: center;
      
      &:first-child {
        padding-left: 0;
        text-align: left;
      }
      
      &:last-child {
        padding-right: 0;
        text-align: right;
      }
    }
  }
`;
