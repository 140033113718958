import { useCallback, useEffect, useState } from 'react';
import { useAuth } from '../../../../contexts/AuthContext';
import api from '../../../../services/api';
import * as S from './style';

function ProfilePhotos () {
  const [loading, setLoading] = useState(true);
  const [providers, setProviders] = useState([]);

  const { userCan } = useAuth();

  const handleUpdateHolding = useCallback(async (holdingId) => {
    setLoading(true);
    const response = await api.get(`/distributor/providers/${holdingId}`);
    setProviders(response.data);
    setLoading(false);
  }, []);

  const loadData = useCallback(async () => {
    setLoading(true);
    const response = await api.get('/providers');
    setProviders(response.data.providers);
    setLoading(false);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  if (loading) return <>Carregando....</>;
  return (
    <div className="account-hub-content">
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Minhas fotos</p>
          <h2 className="section-title">Informação das fotos</h2>
        </div>
      </div>

      <S.Tabela>
        <S.Wrapper className="grid mobile-prefer-content">
          <div className="grid-column">
            {providers.length > 0
              ? <table>
                <tr>
                  <th>Nome</th>
                  <th>CNPJ</th>
                  <th>&nbsp;</th>
                </tr>
                {providers.map(provider => <tr>
                  <td>{provider.name}</td>
                  <td>{provider.cnpj}</td>
                  <S.ActionContainer>
                    <S.Edit to={`/perfil/photos/${provider.id}`}>
                      <i className="fas fa-edit"></i>
                    </S.Edit>
                  </S.ActionContainer>
                </tr>)}
              </table>
              : <S.Center>Você não tem nenhuma loja ainda, que tal adicionar uma agora?</S.Center>}
          </div>
        </S.Wrapper>
      </S.Tabela>
    </div>
  );
}

export default ProfilePhotos;
