import styled from 'styled-components';

export const Tooltip = styled.div`
    visibility: hidden;
    display: none;
`;

export const Li = styled.li`
&:hover {
    ${Tooltip} {
        visibility: visible;
        white-space: nowrap;
        position: absolute;
        z-index: 99999;
        left: 65px;
        top: 25%;
        margin-top: 0px;
        opacity: 1;
        transform: translate(-10px);
        transition: all 0.3s ease-in-out 0s;
    };
}

&.active i {
 color: #fff;
}
`;

export const DesktopTitle = styled.div``;
