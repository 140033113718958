import styled from 'styled-components'

export const GridContainer = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, 284px);
  justify-content: center;

  @media screen and (max-width: 460px) {
    grid-template-columns: 100%;
  }
  `

export const FilterContainer = styled.div`
  margin: 0 auto;
  margin-top: 64px;
  max-width: 800px;
`

export const PaginationContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
