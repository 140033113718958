import { ReactElement, useCallback, useEffect, useState } from 'react'
import LinkButton from '../../../components/Forms/LinkButton'
import PageLoaderComponent from '../../../components/PageLoader'
import PaginationComponent from '../../../components/Pagination'
import { makeDeletePolibrasProduct } from '../../../main/helpers/makeDeletePolibrasProduct'
import { makeLoadPolibrasProducts } from '../../../main/helpers/makeLoadPolibrasProducts'
import Template from '../../../template'
import { PolibrasProductsData, PolibrasProductsState } from './props'
import * as S from './style'

const loadPolibrasProducts = makeLoadPolibrasProducts()
const deletePolibrasProduct = makeDeletePolibrasProduct()

function PolibrasProducts (): ReactElement {
  const [state, setState] = useState<PolibrasProductsState>(PolibrasProductsState.LOADING)
  const [data, setData] = useState<PolibrasProductsData>({} as unknown as PolibrasProductsData)

  const handleDelete = useCallback(async (id: string) => {
    await deletePolibrasProduct.handle({ id })
    const newData = { ...data }
    newData.products = data.products.filter(product => {
      return product.id !== id
    })
    setData(newData)
  }, [data])

  useEffect(() => {
    (async () => {
      const response = await loadPolibrasProducts.handle()
      setData(response.data)
      setState(PolibrasProductsState.READY)
    })()
  }, [])

  if (state === PolibrasProductsState.LOADING) return <PageLoaderComponent />

  return <Template>
    <S.Tabela>
      <S.Wrapper className="grid mobile-prefer-content">
        <S.HeaderWrapper>
          <LinkButton to="/admin/produtos/novo" >Adicionar novo</LinkButton>
        </S.HeaderWrapper>
        {state === PolibrasProductsState.READY && <div className="grid-column">
          <table>
            <tr>
              <th>Thumbnail</th>
              <th>Nome</th>
              <th>Descrição</th>
              <th>&nbsp;</th>
            </tr>
            {data.products.map(product => <tr>
              <td>&nbsp;</td>
              <td>{product.name}</td>
              <td>{product.description}</td>
              <td>
                <S.Edit to={`/admin/produtos/${String(product.id)}`}><i className="fas fa-edit"></i></S.Edit>
                <S.Exclude onClick={async () => await handleDelete(product.id)}><i className="fas fa-trash-alt"></i></S.Exclude>
              </td>
            </tr>)}
          </table>
        </div>}
      </S.Wrapper>
    </S.Tabela>

    <PaginationComponent totalPages={1} currentPage={1} onChangePage={(page: number) => console.log(page)} />

  </Template >
}

export default PolibrasProducts
