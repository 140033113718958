/**
 * Manipulate dates
 *
 * @hooks
 */
export function useDate (): { format: (date: string) => string } {
  /**
   * Format date as `d/m/y h:m`
   *
   * @param {string} date - Date as string
   * @returns Formatted date
   */
  function format (date: string): string {
    const dateObject = new Date(Date.parse(date) - 3 * 60 * 60 * 1000) // new Date(props);
    const day = `0${dateObject.getDate()}`
    const month = `0${dateObject.getMonth() + 1}`
    const hours = `0${dateObject.getHours()}`
    const minutes = `0${dateObject.getMinutes()}`
    return `${day.substring(day.length - 2)}/${month.substring(month.length - 2)}/${dateObject.getFullYear()} ${hours.substring(hours.length - 2)}:${minutes.substring(minutes.length - 2)}`
  }

  return {
    format
  }
}
