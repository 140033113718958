import { Link as RRDLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div.attrs(() => {
  return {
    className: 'js-ranking-selector-container'
  };
})`
  max-width: 1180px;
  margin: 0 auto;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
  width: auto;
`;

export const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  position: relative;

  &.ativo {
    &::after {
      background-color: #008800;
      border-radius: 4px 0 0 4px;
      color: #ffffff;
      content: "Ativa";
      display: block;
      font-weight: bold;
      padding: 4px 4px 4px 8px;
      position: absolute;
      right: 0;
      top: 15px;
    }
  }

  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Link = styled(RRDLink).attrs(() => {
  return {
    className: 'button primary'
  };
})`
  width: 100%;
`;
