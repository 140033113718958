import { ReactElement, useState } from 'react'
import Accordion from '../../components/Accordion'
import Template from '../../template'
import * as S from './style'

function Faq (): ReactElement {
  const [activeAccordion, setActiveAccordion] = useState(-1)

  const handleOnOpen = (newIndex: number): void => setActiveAccordion(newIndex)

  return <Template>
    <div style={{ marginTop: '8rem' }}></div>

    <div className="section-header">
      <div className="section-header-info">
        <p className="section-pretitle"></p>
        <h2 className="section-title">{''}</h2>
      </div>
    </div>

    <S.WidgetBox className="widget-box">

      <p className="widget-box-title">
        Confira abaixo as perguntas frequentes e caso permaneça com alguma dúvida, entre em contato através de um chamado no campo ao final dela.
      </p>

      <div className="widget-box-content">
        <div className="simple-accordion-list">

          <Accordion
            index={1}
            question="1. Como faço para participar?"
            isOpen={activeAccordion === 1}
            onOpen={handleOnOpen}
          >
            Para participar, basta aderir à campanha, através do site: <a href="https://www.clubpolibras.com.br">www.clubpolibras.com.br</a> e aceitar os termos do regulamento. O aceite é requisito obrigatório para a participação na campanha, assim como complemento dos dados cadastrais.
          </Accordion>

          <Accordion
            index={2}
            question="2. Qual o período da campanha e o prazo de resgate de premiação?"
            isOpen={activeAccordion === 2}
            onOpen={handleOnOpen}
          >
            A campanha terá duração de 12 meses, iniciando em [04/03/2024] e encerrando em [04/03/2025]. Os prêmios deverão ser resgatados até o dia 04/06/2025
          </Accordion>

          <Accordion
            index={3}
            question="3. Quais os produtos participantes?"
            isOpen={activeAccordion === 3}
            onOpen={handleOnOpen}
          >
            Todos os produtos do nosso Catálogo Polibras, incluindo Cricut.
          </Accordion>

          <Accordion
            index={4}
            question="4. Qual a premiação da campanha?"
            isOpen={activeAccordion === 4}
            onOpen={handleOnOpen}
          >
            O cliente poderá usar os pontos acumulados para realizar a compra de produtos Polibras e toda Linha Cricut ou se preferir, os pontos podem ser trocados por produtos dos parceiros aderentes à Campanha Club Polibras.
          </Accordion>

          <Accordion
            index={5}
            question="5. Como faço para resgatar os prêmios?"
            isOpen={activeAccordion === 5}
            onOpen={handleOnOpen}
          >
            Os usuários que optarem pelo resgate de um Giftcard Polibras deverão entrar em contato com nosso SAC, para validar o código e efetivar a bonificação. O SAC estará disponível para auxiliar no processo de validação;
            <br/>
            <br/>
            O primeiro resgate pode acontecer após 3 meses cadastrado no “Club Polibras”.
            <br/>

            <S.Table>
              <thead>
                <tr>
                  <th>PONTOS</th>
                  <th>VALOR</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>100 PONTOS</td>
                  <td>R$ 15,00</td>
                </tr>

                <tr>
                  <td>10 PONTOS</td>
                  <td>R$ 1,50</td>
                </tr>

                <tr>
                  <td>5 PONTOS</td>
                  <td>R$ 0,75</td>
                </tr>

                <tr>
                  <td>2 PONTOS</td>
                  <td>R$ 0,30</td>
                </tr>
              </tbody>
            </S.Table>
          </Accordion>

          <Accordion
            index={6}
            question="6. Como será a divulgação do resultado?"
            isOpen={activeAccordion === 6}
            onOpen={handleOnOpen}
          >
            O resultado da Campanha estará disponível aos participantes através do site <a href="https://www.clubpolibras.com.br">www.clubpolibras.com.br</a>
          </Accordion>

          <Accordion
            index={7}
            question="7. Quem eu devo acionar caso seja identificado erro no cálculo da  apuração?"
            isOpen={activeAccordion === 7}
            onOpen={handleOnOpen}
          >
            Entre em contato com o SAC, via e-mail <a href="mailto:contato@clubpolibras.com.br">contato@clubpolibras.com.br</a> ou WhastApp: (11) 95695-8316
          </Accordion>

          <Accordion
            index={8}
            question="8. Ainda tenho dúvidas, com quem posso falar?"
            isOpen={activeAccordion === 8}
            onOpen={handleOnOpen}
          >
            As dúvidas sobre a Campanha poderão ser esclarecidas junto ao SAC, por meio do e-mail: <a href="mailto:contato@clubpolibras.com.br">contato@clubpolibras.com.br</a>,  WhatsApp: (11) 95695-8316 e via Fale Conosco, dentro da plataforma da Campanha. O horário de atendimento é de segunda à sexta-feira (exceto feriados), das 09h00 às 17h00.
          </Accordion>

        </div>
      </div>
    </S.WidgetBox>
  </Template>
}

export default Faq
