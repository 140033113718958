import React, { ChangeEvent, ReactElement } from 'react'
import ErrorComponent from '../ErrorComponent'
import * as S from './styled'

interface CheckboxProps {
  checked: boolean
  name?: string
  label?: string
  text?: React.ReactNode
  onChange: (checked: boolean, value: string) => void
  error?: string
}

/**
 * Adiciona um checkbox
 * @param (bool) checked
 * @param (string) name
 * @param (ReactNode) text
 */
function Checkbox ({
  checked,
  name,
  label,
  text,
  onChange,
  error
}: CheckboxProps): ReactElement {
  const handleCheckboxChange = (evt: ChangeEvent<HTMLInputElement>): void => {
    onChange(evt.target.checked, evt.target.value)
  }

  return (
    <S.Container>
      <div>
        <S.Checkbox
          type="checkbox"
          name={name}
          id={name}
          checked={checked}
          value={label}
          onChange={handleCheckboxChange}
        />
        <S.Label htmlFor={name}>{text}</S.Label>
      </div>
      <ErrorComponent>{error}</ErrorComponent>
    </S.Container>
  )
}

export default Checkbox
