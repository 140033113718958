import { ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import Card from '../../../../../components/Card'
import InputText from '../../../../../components/Forms/InputText'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'

import * as S from './style'

interface TimePeriod {
  from: string
  to: string
}

const currentDate = new Date().toISOString().substring(0, 10)

export default function StoreSalesStatement (): ReactElement {
  const [timePeriod, setTimePeriod] = useState<TimePeriod>({ from: currentDate, to: currentDate })

  return <>
    <SectionHeaderComponent
      pretitle="MINHA LOJA"
      title="Histórico de Resgates"
    />

    <S.ContentContainer>
      <Card>
        <S.DateInputsContainer>
          <InputText
            name="fromDate"
            label="De"
            value={timePeriod.from}
            onChange={(from) => setTimePeriod({ ...timePeriod, from })}
            type="date"
          >
            <svg className="form-input-icon icon-events">
              <use xlinkHref="#svg-events"></use>
            </svg>
          </InputText>

          <InputText
            name="toDate"
            label="Até"
            value={timePeriod.to}
            onChange={(to) => setTimePeriod({ ...timePeriod, to })}
            type="date"
          >
            <svg className="form-input-icon icon-events">
              <use xlinkHref="#svg-events"></use>
            </svg>
          </InputText>

          <S.SearchButton onClick={() => { }}>
            <svg className="icon-magnifying-glass">
              <use xlinkHref="#svg-magnifying-glass"></use>
            </svg>
          </S.SearchButton>
        </S.DateInputsContainer>

        <S.DownloadButton onClick={() => { }}>Baixar Demonstrativo</S.DownloadButton>
      </Card>

      <S.TableContainer>
        <S.SalesTable>
          <thead>
            <tr>
              <th>DATA</th>
              <th>ITEM</th>
              <th>TIPO</th>
              <th>CÓDIGO</th>
              <th>PREÇO</th>
              <th>DESCONTO</th>
              <th>FATURAMENTO</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>Nov 15th, 2019</td>
              <td>
                <Link to="">Pixel Diamond Gaming Magazine</Link>
              </td>
              <td>Venda</td>
              <td>VK1287</td>
              <td>$26</td>
              <td>50%</td>
              <td>$13</td>
              <td>
                <S.IconContainer>
                  <svg className="percentage-diff-icon icon-minus-small">
                    <use xlinkHref="#svg-minus-small"></use>
                  </svg>
                </S.IconContainer>
              </td>
            </tr>
            {
              Array.from(new Array(11)).map((_, i) => (
                <tr key={i}>
                  <td>Nov 15th, 2019</td>
                  <td>
                    <Link to="">Pixel Diamond Gaming Magazine</Link>
                  </td>
                  <td>Venda</td>
                  <td>VK1287</td>
                  <td>$26</td>
                  <td>50%</td>
                  <td>$13</td>
                  <td>
                    <S.IconContainer>
                      <svg className="percentage-diff-icon icon-plus-small">
                        <use xlinkHref="#svg-plus-small"></use>
                      </svg>
                    </S.IconContainer>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </S.SalesTable>
      </S.TableContainer>
    </S.ContentContainer>
  </>
}
