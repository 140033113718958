export enum PolibrasNewProductState {
  LOADING,
  READY,
  ERROR,
  SUCCESS
}

export interface PolibrasNewProductProps {
  code: string
  name: string
  information: string
  categoryId: number
  thumbs: string[]
}

export interface PolibrasNewProductError {
  hasError: boolean
  code: string
  name: string
  information: string
  categoryId: string
}

export interface CategoryProps {
  id: number
  name: string
}
