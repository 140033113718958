import { AxiosInstance } from 'axios'
import { DeletePolibrasProduct } from '../../domain/use-case/DeletePolibrasProduct'

export class ApiDeletePolibrasProduct implements DeletePolibrasProduct {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: DeletePolibrasProduct.Params): Promise<DeletePolibrasProduct.Result> {
    try {
      const response = await this.api.delete(`/polibras/product/${params.id}`)
      return response
    } catch (error: any) {
      return error.response
    }
  }
}
