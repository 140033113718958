import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin: 1.5rem auto 0 auto;
  max-width: 1180px;
  width: 100%;

  > div {
    padding: 32px 28px 100px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
    position: relative;

    @media (max-width: 768px) {
      flex: 1 100%;
    }
  }
`
