import { ReactElement, useCallback } from 'react'
import CargoComponent from '../../../../../../components/CargoComponent'
import InputText from '../../../../../../components/Forms/InputText'
import { User as UserModel } from '../../../../../Perfil'
import * as S from './style'

interface User {
  setUser: (user: UserModel) => void
  user: UserModel
}

export default function PersonalInfo ({ user, setUser }: User): ReactElement {
  const setAddress = useCallback((address: any) => {
    setUser({ ...user, address })
  }, [user])
  return <S.PersonalInfoContainer>
    <InputText label="Nome de perfil" name="name" value={user.name} onChange={(name: string) => setUser({ ...user, name })} />

    <InputText label="Email" name="email" value={user.email} onChange={(email: string) => setUser({ ...user, email })} />

    <InputText label="Data de nascimento" type="date" name="birthday" value={user.birthday} onChange={(birthday: string) => setUser({ ...user, birthday })} />

    <InputText label="CNPJ" name="cpf" value={user.document} onChange={(document: string) => setUser({ ...user, document })} />

    <InputText label="Telefone" name="cellphone" value={user.cellphone} onChange={(cellphone: string) => setUser({ ...user, cellphone })} />

    <CargoComponent value={user.cargo} onChange={(cargo: string) => setUser({ ...user, cargo })} />

    <InputText label="CEP" name="cep" value={user.address?.cep} onChange={(cep: string) => setAddress({ ...user.address, cep })} />

    <InputText label="Bairro" name="bairro" value={user.address?.bairro} onChange={(bairro: string) => setAddress({ ...user.address, bairro })} />

    <InputText label="Cidade" name="cidade" value={user.address?.cidade} onChange={(cidade: string) => setAddress({ ...user.address, cidade })} />

    <InputText label="Estado" name="estado" value={user.address?.estado} onChange={(estado: string) => setAddress({ ...user.address, estado })} />

    <InputText label="Logradouro" name="logradouro" value={user.address?.logradouro} onChange={(logradouro: string) => setAddress({ ...user.address, logradouro })} />

    <InputText label="Numero" name="numero" value={user.address?.numero} onChange={(numero: string) => setAddress({ ...user.address, numero })} />

    <InputText label="Referência" name="referencia" value={user.address?.referencia} onChange={(referencia: string) => setAddress({ ...user.address, referencia })} />

    <InputText label="Complemento" name="complemento" value={user.address?.complemento} onChange={(complemento: string) => setAddress({ ...user.address, complemento })} />

  </S.PersonalInfoContainer>
}
