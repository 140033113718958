import styled from 'styled-components';

export const MessageContainer = styled.span`
  display: block;
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;
