import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../contexts/AuthContext';
import api from '../../../services/api';
import Template from '../../../template';
import * as S from './style';

function RankingSelectorComponent () {
  const navigate = useNavigate();
  const { userCan } = useAuth();
  const [worksheets, setWorksheets] = useState();

  const loadWorksheets = useCallback(async () => {
    const response = await api.get('/worksheets');
    setWorksheets(response.data.worksheets);
  }, []);

  useEffect(() => {
    loadWorksheets();
  }, [loadWorksheets]);

  if (!userCan('ranking-all:read')) {
    navigate('/ranking');
    return <>Você não pode acessar essa área</>;
  }

  if (!worksheets) return <>...</>;

  return (
    <>
      <Template>
        <S.Container>
          {worksheets.map(c => <S.Card className={c.active ? 'ativo' : ''}>
            <p><strong>Planilha:</strong> {c.name}</p>
            <p><strong>Canal: {c.channel.name}</strong></p>
            <p>Periodo: {c.period}</p>
            <p>Tipo: {c.type}</p>
            <S.Link to={`/ranking/${c.id}`}>Ver ranking</S.Link>
          </S.Card>)}
        </S.Container>
      </Template >
    </>
  );
}

export default RankingSelectorComponent;
