import * as S from './styled';

function SocialButton ({ clientLoginUrl, icon, buttonName }) {
  return <S.Container href={clientLoginUrl}>
    <div>
      {icon}
      {buttonName}
    </div>
  </S.Container>;
}

export default SocialButton;
