import { ReactElement } from 'react'
import PrivacySettingProps from './PrivacySetting.props'
import * as S from './style'

export default function PrivacySetting ({ options, label }: PrivacySettingProps): ReactElement {
  return <S.PrivacySettingContainer>
    <S.PrivacySettingLabel>{label}
      <select>
        {options.map((option) => <option key={option} value={option}>{option}</option>)}
      </select>
      <svg className="form-select-icon icon-small-arrow">
        <use href="#svg-small-arrow"></use>
      </svg>
    </S.PrivacySettingLabel>
  </S.PrivacySettingContainer>
}
