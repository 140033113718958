import { ReactElement } from 'react'
import Card from '../../../../../components/Card'
import HexagonComponent from '../../../../../components/Hexagon'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'

import * as S from './style'

import avatar from '../../../../../assets/img/avatar/01-social.png'

export default function ProfileNotifications (): ReactElement {
  // get data

  const headerActions = [
    {
      title: 'Marcar Tudo como Lido',
      url: ''
    },
    {
      title: 'Configurações',
      url: ''
    }
  ]

  return <>
    <SectionHeaderComponent headerActions={headerActions} pretitle="MEU PERFIL" title="Notificações" />

    <S.Container>
      <Card>
        <S.NotificationContainer>

          <S.NotificationContent>
            <HexagonComponent size="small" src={avatar} className="avatar" />

            <div>
              <S.NotificationText>
                <S.CustomLink to="">Nick Grissom</S.CustomLink>
                {' '}
                deixou um comentário na sua
                {' '}
                <S.CustomLink to="">atualização de status</S.CustomLink>
              </S.NotificationText>

              <S.Timestamp>2 minutos atrás</S.Timestamp>
            </div>
          </S.NotificationContent>

          <svg className="icon-comment">
            <use href="#svg-comment"></use>
          </svg>

        </S.NotificationContainer>
      </Card>

      <Card>
        <S.NotificationContainer>

          <S.NotificationContent>
            <HexagonComponent size="small" src={avatar} className="avatar" />

            <div>
              <S.NotificationText>
                <S.CustomLink to="">Nick Grissom</S.CustomLink>
                {' '}
                deixou um comentário na sua
                {' '}
                <S.CustomLink to="">atualização de status</S.CustomLink>
              </S.NotificationText>

              <S.Timestamp>2 minutos atrás</S.Timestamp>
            </div>
          </S.NotificationContent>

          <svg className="icon-comment">
            <use href="#svg-comment"></use>
          </svg>

        </S.NotificationContainer>
      </Card>

      {/* add loading */}
    </S.Container>
</>
}
