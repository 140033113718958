import { ReactElement, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import AccordionSidebarMenuProps from './AccordionSidebarMenu.props'
import categories from './categories'
import * as S from './style'

export default function AccordionSideBarMenu ({ category, index, isOpen = false, onOpen }: AccordionSidebarMenuProps): ReactElement {
  const handleOpen = useCallback(() => {
    if (!isOpen) {
      onOpen(index)
      return
    }

    onOpen(undefined)
  }, [isOpen, index, onOpen])

  const { userCan } = useAuth()

  return <S.MenuAccordion className="simple-accordion" onClick={handleOpen}>
    <svg className={categories[category].svg}>
      <use href={categories[category].use} />
    </svg>
    <S.MenuAccordionHeader className="simple-accordion-header accordion-trigger">
      <S.MenuAccordionTitle>{categories[category].title}</S.MenuAccordionTitle>
      <S.MenuAccordionDescriprion>{categories[category].description}</S.MenuAccordionDescriprion>
      <div className="simple-accordion-icon">
        {
          !isOpen
            ? <svg className="icon-plus-small">
              <use href="#svg-plus-small" />
            </svg>
            : <svg className="icon-minus-small">
              <use href="#svg-minus-small" />
            </svg>
        }
      </div>
    </S.MenuAccordionHeader>

    <S.MenuAccordionContent
      className={`simple-accordion-content simple-accordion-text simple-accordion-text ${isOpen ? 'accordion-open' : ''}`}
    >
      <ul>
        {categories[category].links.map(({ permission, linkTitle, linkUrl }) => {
          if (permission !== '' && userCan(permission) === false) return <></>
          return <li key={linkTitle}>
            <Link to={linkUrl}>{linkTitle}</Link>
          </li>
        })}
      </ul>
    </S.MenuAccordionContent>
  </S.MenuAccordion>
}
