import {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import api from '../../services/api'
import { useAuth } from '../AuthContext'

const defaultValue = {}

const CartContext = createContext(defaultValue)

interface CartProps {
  sku: {
    code: number
    Product: {
      name: string
    }
  }
  amount: number
}

interface CartProviderProps {
  children: ReactNode
}

export default function CartProvider ({ children }: CartProviderProps): ReactElement {
  const [cart, setCart] = useState<CartProps[]>()
  const { user } = useAuth()

  const addToCart = useCallback(async (skuCode: number, amount: number) => {
    const response = await api.patch('/cart', { skuCode, amount })
    setCart(response.data)
  }, [cart])

  const removeFromCart = useCallback((skuCode: number) => { }, [cart])

  const updateAmount = useCallback((skuCode: number, amount: number) => { }, [cart])

  useEffect(() => {
    (async () => {
      const response = await api.get('/cart')
      setCart(response.data.cart)
    })()
  }, [user])

  return (
    <CartContext.Provider
      value={{ addToCart, updateAmount, removeFromCart, cart }}
    >
      {children}
    </CartContext.Provider>
  )
}

export function useCart (): any {
  const context = useContext(CartContext)

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!context) throw new Error('useCart must be used within a CartProvider')

  return context
}
