import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 16px;

  form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`

export const ActionContainer = styled.td`
  display: flex;
`

export const Premmiar = styled.button`
  display: inline-flex;
  background-color: var(--success);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  aspect-ratio: 1;
  width: 32px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  &:hover {
    color: var(--white);
  }
`

export const Edit = styled(Link)`
  display: inline-flex;
  background-color: var(--secondary);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  aspect-ratio: 1;
  width: 32px;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  &:hover {
    color: var(--white);
  }
`

export const Exclude = styled.button`
  display: inline-flex;
  background-color: var(--danger);
  color: var(--white);
  padding: 8px;
  border-radius: 4px;
  aspect-ratio: 1;
  width: 32px;
  font-size: 14px;
  justify-content: center;
  align-items: center;

  &:hover {
    color: var(--white);
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    flex: 1;
    margin-right: 16px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    .form-input {
      margin-right: 0;
      width: 100%;
      max-width: 30rem;
      margin-bottom: 1rem;
    }
  }
`

export const ThumbContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 4fr 1fr;
`

export const ThumbWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  gap: 16px;
  justify-content: center;
`

export const Thumb = styled.div`
  position: relative;
  width: 200px;

  & > img {
    display: block;
    height: auto;
    max-width: 100%;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  & > div {
    flex: 1 100%;
  }
`

export const Remove = styled.button`
  position: absolute;
  right: 0;
  top: 0;
`

export const EditorLabel = styled.label`
  color: var(--text-light);
  font-weight: 700;
  margin: 0;
`
