import { ReactElement } from 'react'
import HexagonComponent from '../../../../../../../components/Hexagon'
import ChatMessageItemProps from './ChatMessageItem.props'

import * as S from './style'

import avatarImg from '../../../../../../../assets/img/nick-grissom.png'

export default function ChatMessageItem ({
  avatar = avatarImg,
  message,
  timestamp,
  userName
}: ChatMessageItemProps): ReactElement {
  return <S.ChatMessageItemContainer>
    <HexagonComponent className="chat-avatar" size="" src={avatar} />

    <S.ChatMessageContent>
      <p>{userName}</p>

      <span>{timestamp}</span>

      <p>{message}</p>
    </S.ChatMessageContent>
  </S.ChatMessageItemContainer>
}
