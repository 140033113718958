import { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import Template from '../../template'

import * as S from './style'

export default function Live (): ReactElement {
  const { liveId } = useParams()
  return <Template>
    <S.LiveContainer>
      <iframe src={`https://meetings.vonage.com/incentivo-casa-promodigital/${String(liveId)}`} title="Embedded Meeting" allow="camera;microphone"></iframe>
    </S.LiveContainer>
  </Template>
}
