import styled from 'styled-components';
import coverExample from '../../../../assets/img/cover/29.jpg';

export const GridGroups = styled.div.attrs(() => ({
  className: 'grid grid-4-4-4 centered'
}))`
  figure.user-preview-cover {
    background: rgba(0, 0, 0, 0) url("${coverExample}") no-repeat scroll center center / cover
  }
  &&& {
  .user-avatar-content {
    top: -10px;
    left: 0;
    .hexagon-groups {
      width: 9rem;
      height: 9rem;
      .shape-outer {
        background: #fff;
        margin-inline: auto;
      }
    }
  }
  }
`;
