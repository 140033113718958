import { useContext, useState, useCallback, useRef, ReactElement, PropsWithChildren, createContext } from 'react'
import * as S from './styled'

type ModalSize = 'small' | 'medium' | 'large'

interface OpenModalProps {
  header: string
  body: ReactElement
  size?: ModalSize
}

interface LockedModalContextType {
  openModal: (props: OpenModalProps) => void
  closeModal: (callback?: any) => void
}

const ModalContext = createContext<LockedModalContextType | null>(null)

export default function LockedModalProvider ({ children }: PropsWithChildren): ReactElement {
  const [modalState, setModalState] = useState('')
  const [header, setHeader] = useState('')
  const [body, setBody] = useState<ReactElement | null>(null)
  const [size, setSize] = useState<ModalSize>('small')
  const modalRef = useRef<HTMLDivElement>(null)
  const modalCotentRef = useRef<HTMLDivElement>(null)

  const closeModal = useCallback((callback: any) => {
    setModalState('closing')
    if (callback !== null && modalRef.current !== null) {
      modalRef.current.addEventListener('animationend', () => {
        callback()
      }, { once: true })
    }
  }, [])

  const openModal = useCallback(({ header, body, size = 'small' }: OpenModalProps) => {
    setModalState('opening')
    setHeader(header)
    setBody(body)
    setSize(size)
  }, [])

  return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            <S.Container className={modalState} ref={modalRef}>
                <S.Content ref={modalCotentRef} data-size={size}>
                    <S.Header>{header}</S.Header>
                    <S.Body>{body}</S.Body>
                </S.Content>
            </S.Container>
        </ModalContext.Provider>
  )
};

export function useLockedModal (): LockedModalContextType {
  const context = useContext(ModalContext)

  if (context == null) throw new Error('useLockedModal must be used within a LockedMenuProvider')

  const { openModal, closeModal } = context

  return { openModal, closeModal }
}
