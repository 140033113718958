import styled from 'styled-components'

export const AvatarBannerContainer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, 284px);
  justify-content: center;
`

export const AvatarPreview = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  cursor: default;
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  transition: transform .2s ease-in-out,box-shadow .25s ease-in-out;

  .avatar {
    max-width: 5.25rem;
    width: 100%;
  }
`
