import axios from 'axios'
import { ComponentType, ReactElement, useCallback, useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { toast } from 'react-toastify'
import PageLoaderComponent from '../../components/PageLoader'
import api from '../../services/api'
import * as S from './style'

enum PublicTermsState {
  LOADING,
  READY
}

interface PublicTermsAttributes {
  currentState: PublicTermsState
  content?: string
}

export default function PublicTerms (): ReactElement {
  const CustomPerfectScrollbar = PerfectScrollbar as ComponentType<ReactElement['props']>
  const [state, setState] = useState<PublicTermsAttributes>({
    currentState: PublicTermsState.LOADING
  })

  const showContent = useCallback((regulation: any) => {
    return <S.Dungerous dangerouslySetInnerHTML={{ __html: regulation }}></S.Dungerous>
  }, [])

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get('term')
        setState((state) => ({ ...state, currentState: PublicTermsState.READY, content: data.content }))
      } catch (err) {
        if (axios.isAxiosError(err)) {
          toast.error(err?.response?.data.message, {
            theme: 'colored'
          })
          return
        }
        toast.error('Ocorreu um erro inesperado', {
          theme: 'colored'
        })
      }
    })()
  }, [])

  if (state.currentState === PublicTermsState.LOADING) return <PageLoaderComponent />

  return <S.Container className="landing">
    <CustomPerfectScrollbar>
      <S.Body>
        <div className="text-center mb-5">
          <h1>Termos e Condições</h1>
        </div>

        {showContent(state.content)}
      </S.Body>
    </CustomPerfectScrollbar>
  </S.Container>
}
