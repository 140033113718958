import React from 'react';
// Styles
import * as S from './style';

export default function HexagonComponent ({ src, size, className }) {
  return <S.Hexagon size={size} className={className}>
        <div className="shape-outer solid-primary">
            <div className="shape-inner">
                <img src={src} alt="Avatar" />
            </div>
        </div>
    </S.Hexagon>;
}
