import { ReactElement } from 'react'
import CardBadgeProps from './CardBadge.props'
import Card from '..'
import Exp from '../Exp'
import { Progress } from '../../Progress'
import * as S from './style'

export default function CardBadge ({
  badgeSrcImg,
  badgeText,
  badgeTitle,
  exp,
  max,
  progress,
  unlocked = false
}: CardBadgeProps): ReactElement {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const status = unlocked ? 'unlocked' : 'blocked'

  return <Card>
      <S.CardBadge unlocked={unlocked}>
        <Exp exp={exp} />

        <S.SmallBadge alt="badge" src={badgeSrcImg} />

        <img alt="badge" src={badgeSrcImg} />

        <S.Title unlocked={unlocked}>{badgeTitle}</S.Title>

        <S.Text>{badgeText}</S.Text>

        <S.ProgressContainer>
          {/* no gradient for Line progress */}
          <Progress color="#615dfa" progress={progress} width={4} />

          <S.ProgressStatus>
            {
              (max !== null && max !== undefined)
                ? String(progress) + '/' + String(max)
                : String(status) + '!'
            }
          </S.ProgressStatus>

        </S.ProgressContainer>
      </S.CardBadge>
    </Card>
}
