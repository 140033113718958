import { ReactElement } from 'react'
import background from './assets/point_table.jpg'
import * as S from './styled'

export default function MecanicaClub (): ReactElement {
  return <S.ClubContainerBg>
    <figure>
      <img src={background} alt="" />
    </figure>
  </S.ClubContainerBg>
}
