import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 50px;
  @media (max-width: 48rem) {
    padding: 0.5rem;
  }
  & > img {
    display: none;
    position: absolute;
    max-width: 200px;
    left: 0;
    top: 0;
    z-index: 1;
  }
  @media (max-width: 960px) {
    & > img {
      display: block;
      position: absolute;
      max-width: 150px;
      left: 50%;
      margin-left: -75px;
      top: 0;
    }
  }
  & > div.scrollbar-container {
    width: 85%;
    z-index: 2;
    @media (max-width: 480px) {
      width: 95%;
    }
  }
`

export const Body = styled.div`
  width: 100%;
  padding: 2.5rem;
  border-radius: 12px;
  background-color: var(--white);
  box-shadow: 0 0 60px 0 rgba(94, 92, 154, 0.12);
  position: relative;

  p {
    line-height: 1.5rem;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  div.form-item {
    width: 49%;
  }
  @media (max-width: 960px) {
    margin-top: 160px;
  }
  @media (max-width: 768px) {
    padding: 1.5rem;
    div.form-item {
      width: 49%;
      button {
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.25rem;
        &:first-of-type {
          margin-right: 0.25rem;
        }
      }
    }
    h1 {
      font-size: 2rem;
    }
  }
`

export const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 16px;
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 70vh;
  max-height: 100%;
  &.desk-visible {
    display: none;
  }

  &.mobile-visible {
    display: none;
  }

  @media (min-width: 1068px) {
    &.desk-visible {
      display: block;
    }
  }

  @media (max-width: 1068px) {
    &.mobile-visible {
      display: block;
    }
  }
`

export const Dungerous = styled.div`
  img {
    display: block;
    max-width: 100%;
    width: auto;
  }
`
