import { AxiosInstance } from 'axios'
import { LoadPolibrasProducts } from '../../domain/use-case/LoadPolibrasProducts'

export class ApiLoadPolibrasProducts implements LoadPolibrasProducts {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (): Promise<LoadPolibrasProducts.Result> {
    try {
      const response = await this.api.get('/polibras/products')
      return response
    } catch (error: any) {
      return error.response
    }
  }
}
