import { AxiosError, AxiosInstance } from 'axios'
import { SearchProducts } from '../../domain/use-case/SearchProducts'

export class ApiSearchProducts implements SearchProducts {
  private readonly api: AxiosInstance

  constructor (api: AxiosInstance) {
    this.api = api
  }

  async handle (params: SearchProducts.Params): Promise<SearchProducts.Result> {
    try {
      const response = await this.api.get('/products/category', {
        params: {
          order: params.order,
          minPrice: params.minPrice,
          maxPrice: params.maxPrice,
          content: params.content,
          categories: JSON.stringify(params.categories),
          departamentId: params.departamentId,
          page: params.page
        }
      })
      return response
    } catch (err) {
      const axiosError = err as AxiosError
      if (axiosError.response) {
        return axiosError.response
      }

      if (axiosError.request) {
        return {
          data: axiosError.request,
          status: 0
        }
      }

      return {
        data: axiosError.message,
        status: 0
      }
    }
  }
}
