import styled from 'styled-components';
import Scrollbar from 'react-perfect-scrollbar';
import 'react-toastify/dist/ReactToastify.css';

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 50px;
    min-height: 100vh;
`;

export const Body = styled.div`
    width: 100%;
    padding: 5rem;
    border-radius: 12px;
    background-color: var(--white);
    box-shadow: 0 0 60px 0 rgba(94,92,154,.12);
    position: relative;
    .accepted-at {
        display: block;
        border-radius: 0.75rem;
        border: 1px solid var(--secondary);
        margin-top: 1rem;
        color: black;
        font-weight: 700;
        overflow: hidden;
        .label {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 3rem;
            width: 10rem;
            background-color: var(--secondary);
            color: var(--white);
        }
        span:not(.label) {
            color: var(--secondary);
            width: 20rem;
            background-color: var(--white);
            font-weight: 700;
            text-transform: capitalize;
            margin-left: 1rem;
        }
    }
    p {
        line-height: 1.5rem;
    }
    @media (max-width: 900px) {
        h1 {
            font-size: 1.25rem;
        }
        padding: 2rem;
        .accepted-at {
            display: flex;
            flex-direction: column;
            padding: 0.25rem;
            .label {
                font-size: 0.75rem;
                border-top-left-radius: 12px;
                width: 6rem;
                height: 1.5rem;
            }
            span:not(.label) {
                width: 100%;
                font-size: 0.75rem;
                margin-left: 0.25rem;
            }
        }
    }
`;

export const PerfectScrollbar = styled(Scrollbar)`
    margin-top: 5%;
    height: 90%;
`;
