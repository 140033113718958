import { useEffect, useMemo, useState } from 'react';
import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  Legend,
  Tooltip,
  PieController,
  ArcElement
} from 'chart.js';
import * as S from './styled';

Chart.register(
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  Legend,
  Tooltip,
  BarController,
  BarElement,
  PieController,
  ArcElement
);

function DashboardChart ({ chartData }) {
  const [, setMyChart] = useState();
  const randomName = useMemo(() => `id-${Math.random().toString(36).substring(7)}`, []);

  useEffect(() => {
    setMyChart((myChart) => {
      try {
        if (myChart) myChart.destroy();
        return new Chart(randomName, chartData);
      } catch (err) {
        console.log(err);
      }
    });
  }, [chartData, randomName]);

  return <S.Container>
    <S.Canvas id={randomName} width={497} height={432}></S.Canvas>
  </S.Container>;
}

export default DashboardChart;
