import { PropsWithChildren, ReactElement, createContext, useContext, useEffect, useState } from 'react'

import { staticCampaignData } from './static'

interface Regulamentos {
  equipe: string
  url: string
  date: string
}

interface CampaignContextType {
  campaignData: {
    title: string
    logo: string
    imgBackground: string
    campaignVideo: string
    email: string
    companyName: string
    privacyLink: string
    dataPrivacyLink: string
    defaultAvatar: string
    homeBanners: string[]
    regulamentos: Regulamentos[]
  }
}

const CampaignContext = createContext<CampaignContextType | null>(null)

export default function CampaignProvider ({ children }: PropsWithChildren): ReactElement {
  const [campaignData, setCampaignData] = useState(staticCampaignData)

  useEffect(() => {
    const loadCampaignData = async (): Promise<void> => {
      setCampaignData(staticCampaignData)
    }
    loadCampaignData()
  }, [])

  return (
    <CampaignContext.Provider value={{ campaignData }}>
      {children}
    </CampaignContext.Provider>
  )
};

export function useCampaign (): CampaignContextType {
  const context = useContext(CampaignContext)

  if (context === null) throw new Error('useAuth must be used within a AuthProvider')

  const { campaignData } = context

  return { campaignData }
}
