import axios from 'axios';
import { useCallback } from 'react';
import Template from '../../template';
import MecanicaClub from './MecanicaClub';
import * as S from './style';

function Mecanicas () {
  const handleDownloadProducts = useCallback(async (fileLink) => {
    const response = await axios.get(fileLink, { responseType: 'arraybuffer' })
    const blob = new Blob([response.data])
    const anchor = document.createElement('a')
    const url = URL.createObjectURL(blob)
    anchor.href = url
    anchor.download = 'Tabela-de-pontos-completa.pdf'
    document.body.appendChild(anchor)
    anchor.click()
    setTimeout(function () {
      document.body.removeChild(anchor)
      window.URL.revokeObjectURL(url)
    }, 0)
  }, [])

  return (
    <>
      <Template>
        <S.Container>
          <MecanicaClub />
        </S.Container>
        <S.DownloadText>Baixe aqui a tabela com todos os produtos participantes da campanha e a sua respectiva pontuação:</S.DownloadText>
        <S.DownloadButton class="button primary" onClick={() => handleDownloadProducts('https://img.casadebraganca.com/polibras-incentivo/regulamento/Tabela%20de%20Pontos%20Completa%20Setembro24.pdf')}>Baixar</S.DownloadButton>
      </Template >
    </>
  );
}

export default Mecanicas;
