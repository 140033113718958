import { ReactElement, useState } from 'react'
import { useAuth } from '../../contexts/AuthContext'
import AccordionSideBarMenu from '../Accordion/AccordionSidebarMenu'
import Card from '../Card'
import { ProfileNavProps } from './props'
import * as S from './style'

export default function ProfileNav (porps: ProfileNavProps): ReactElement {
  const [activeAccordion, setActiveAccordion] = useState<number | undefined>(undefined)
  const { userCan } = useAuth()

  return <S.Container>
    <Card>
      <AccordionSideBarMenu
        category='profile'
        index={0}
        isOpen={activeAccordion === 0}
        onOpen={(newIndex) => setActiveAccordion(newIndex)}
      />
      <AccordionSideBarMenu
        category='account'
        index={1}
        isOpen={activeAccordion === 1}
        onOpen={(newIndex) => setActiveAccordion(newIndex)}
      />
      {Boolean(userCan('read:store')) && <AccordionSideBarMenu
        category='store'
        index={3}
        isOpen={activeAccordion === 3}
        onOpen={(newIndex) => setActiveAccordion(newIndex)}
      />}

      <S.ButtonsContainer>
        <button className='button secondary' onClick={porps.updateUser}>Salvar Alterações!</button>

        <button className='button white small-space' onClick={porps.resetForm}>Descartar Tudo</button>
      </S.ButtonsContainer>
    </Card>
  </S.Container>
}
