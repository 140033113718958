import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import InputSelect from '../../components/Forms/InputSelect'
import PageLoader from '../../components/PageLoader'
import { useAuth } from '../../contexts/AuthContext'
import api from '../../services/api'
import Template from '../../template'
import { User } from '../Perfil'
import AccountStatBox from './AccountStatBox'
import star from './assets/star.png'
import DashboardChart from './DashboardChart'
import DashboardTable from './DashboardTable'
import { Filter, Params, PeriodFilter } from './props'
import * as S from './styled'

enum PageState {
  LOADING,
  READY,
  NO_CONTENT
}

const zeroFill = (value: number): string => {
  const newValue = `0${value}`
  return newValue.substring(newValue.length - 2, newValue.length)
}

const monthList = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]

function Resultados (): ReactElement {
  const { userId } = useParams()
  const { user } = useAuth() as { user: User }
  const [state, setState] = useState<PageState>(PageState.LOADING)
  const [data, setData] = useState<any[]>([])
  const [params, setParams] = useState<Params>({ stepId: 1, month: '' })

  const periodFilterOptions = useMemo((): PeriodFilter[] => {
    const currentDate = new Date('2024-03-01 03:00:00')
    const endDate = new Date()
    const response: PeriodFilter[] = []
    while (currentDate.getTime() < endDate.getTime()) {
      response.push({
        id: `${currentDate.getFullYear()}-${zeroFill(currentDate.getMonth() + 1)}`,
        name: `${monthList[currentDate.getMonth()]} / ${currentDate.getFullYear()}`
      })
      currentDate.setMonth(currentDate.getMonth() + 1)
    }
    return response
  }, [])

  const loadDashboardData = useCallback(async (params: Params) => {
    setState(PageState.LOADING)
    if (userId !== undefined) {
      const response = await api.get(`/result/${userId}`, { params })
      setData(response.data.content)
      if (response.data.content.length === 0) {
        setState(PageState.NO_CONTENT)
      } else {
        setState(PageState.READY)
      }
      return
    }
    const response = await api.get(`/result/${user.id}`, { params })
    setData(response.data.content)
    if (response.data.content.length === 0) {
      setState(PageState.NO_CONTENT)
    } else {
      setState(PageState.READY)
    }
  }, [userId, user])

  const handleChangeFilter = useCallback((filter: Filter, value: string) => {
    setParams((params) => ({ ...params, ...{ [filter.attribute]: value } }))
  }, [])

  const componentMap = (component: any, index: number): any => {
    switch (component.component) {
      case 'group':
        return <S.Group>{component.content.map(componentMap)}</S.Group>
      case 'stats':
        if (component.subtitle === 'Posição') return <></>

        return <AccountStatBox {...component} key={index}>
          {(component.icon === null || component.icon === undefined) && <S.Graph value={component.title} />}
        </AccountStatBox>
      case 'table':
        if (String(component.title).toLocaleLowerCase().includes('holding')) return <></>
        return <DashboardTable
          {...component}
          onChange={(value: string) => handleChangeFilter(component.filter, value)}
          key={index}
        />
      case 'chart':
        if (component.type === 'pie') return <></>

        return <DashboardChart chartData={component} key={index} />
      case 'break':
        return <S.Break key={index} />
      default:
        return ''
    }
  }

  useEffect(() => {
    loadDashboardData(params)
  }, [loadDashboardData, params])

  if (state === PageState.LOADING) return <PageLoader />

  return <Template>
    <S.Container>
      <S.FilterContainer>
        <InputSelect
          defaultValue=""
          label="Filtrar por mês"
          onChange={(month) => setParams((prev) => ({ ...prev, month: month as string }))}
        >
          <option selected={params.month === ''} value="">Todos os meses</option>

          {periodFilterOptions.map((selectedPeriod) => <option
            selected={selectedPeriod.id === params.month}
            key={selectedPeriod.id}
            value={selectedPeriod.id}
          >
            {selectedPeriod.name}
          </option>
          )}
        </InputSelect>
      </S.FilterContainer>

      {state === PageState.READY && data.map(componentMap)}

      {state === PageState.NO_CONTENT && <S.Disclaimer>
        <S.Image src={star} alt="Estrela" />

        Ainda não temos resultado para você!
      </S.Disclaimer>
      }
    </S.Container >
  </Template >
}

export default Resultados
