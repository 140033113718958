import { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Chart,
  BarController,
  BarElement,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  Legend,
  Tooltip,
  PieController,
  ArcElement,
  ChartTypeRegistry
} from 'chart.js'

import * as S from './styled'

Chart.register(
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  CategoryScale,
  Legend,
  Tooltip,
  BarController,
  BarElement,
  PieController,
  ArcElement
)

interface DashboardChartProps {
  chartData: {
    type: keyof ChartTypeRegistry
    title: string
    data: {
      labels: string[]
      datasets: [
        {
          label: string
          data: number[]
          backgroundColor: string[]
        }
      ]
    }
  }
}

function DashboardChart ({ chartData }: DashboardChartProps): ReactElement {
  const [, setMyChart] = useState<Chart>()
  const randomName = useMemo(() => `id-${Math.random().toString(36).substring(7)}`, [])

  useEffect(() => {
    setMyChart((myChart) => {
      try {
        if (myChart !== undefined) myChart.destroy()
        return new Chart(randomName, chartData)
      } catch (err) {
        console.log(err)
      }
    })
  }, [chartData, randomName])

  return <S.Container>
    {chartData.title !== '' && <S.Title>
      <p>{chartData.title}</p>
    </S.Title>}
    <S.Canvas id={randomName} width={497} height={432}></S.Canvas>
  </S.Container>
}

export default DashboardChart
