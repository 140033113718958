import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkButton = styled(Link)`
  min-width: 168px;
  width: 300px;
  margin-top: 0;
  float: right;
  background-color: var(--button-secondary);
  box-shadow: 4px 7px 12px 0 rgba(35,210,226,.2);
  display: block;
  height: 48px;
  border-radius: 10px;
  color: var(--white);
  font-size: .875rem;
  font-weight: 700;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  transition: background-color .2s ease-in-out,color .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out;
  box-shadow: 3px 5px 10px 0 rgba(62,63,94,.2);
  /* Fix position at admin/usuarios */
  justify-self: end;
`;
