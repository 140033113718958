import styled from 'styled-components';

export const Container = styled.div.attrs(() => {
  return {
    className: 'icon'
  };
})`
  align-items: center;
  background-color: #615dfa;
  border-radius: 50%;
  display: flex;
  height: 48px;
  justify-content: center;
  margin: 0 auto;
  width: 48px;

  svg {
    fill: #ffffff;
  }
`;
