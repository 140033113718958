import styled from "styled-components";

export const PrizeSectionContainer = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.75rem clamp(1rem, -0.9277rem + 8.5678vw, 9.375rem);

  img {
    width: 100%;
  }
`

export const PrizeCardContainer = styled.div`
  align-items: center;
  background: #FF9F0F;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #E1E4ED;
  display: flex;
  padding: 3rem 6.75rem 2.25rem 3rem;

  @media screen and (max-width: 1366px) {
    flex-direction: column;
    gap: 1.5rem;
    padding-right: 3rem;
  }
`

export const PrizeCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  h1, ul li {
    color: var(--white);
    font-feature-settings: 'clig' off, 'liga' off;
  }

  h1 {
    font-family: MDLZBiteType, sans-serif;
    font-size: clamp(3rem, 2.5684rem + 1.9182vw, 4.875rem);
    line-height: 3.4063rem;
    letter-spacing: -0.2188rem;
    white-space: nowrap;
  }

  ul {
    @media screen and (max-width: 1366px) {
      padding-left: 1.5rem;
    }
  }

  > ul li {
    font-family: Montserrat, sans-serif;
    font-size: clamp(1rem, 0.8849rem + 0.5115vw, 1.5rem);
    font-weight: 700;
    line-height: clamp(1.5rem, 1.2482rem + 1.1192vw, 2.594rem);
    list-style: disc;
  }
`
