import styled from 'styled-components'
export const Header = styled.h4`
  margin-top: 15px;
  max-width: 470px;
`

export const Body = styled.div`
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  gap: 4px;
`

export const ButtonClose = styled.button`
  display: flex;
  position: absolute;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background-color: var(--hover);
  :hover {
    background-color: var(--hover);
    color: var(--primary);
    transition: 0.3s ease;
  }
`
