import AccountStatBoxGraph from './AccountStatBoxGraph';
import AccountStatBoxIcon from './AccountStatBoxIcon';
import AccountStatBoxTitle from './AccountStatBoxSubtitle';
import AccountStatBoxText from './AccountStatBoxText';
import DashboardCard from './DashboardCard';

function AccountStatBox({ icon, title, children, subtitle, text }) {
  return <DashboardCard>
    {icon && <>
      <AccountStatBoxIcon>
        <svg className={`account-stat-box-icon ${icon}`}><use xlinkHref='#svg-friend'></use></svg>
      </AccountStatBoxIcon>

      <AccountStatBoxTitle>
        {title}
      </AccountStatBoxTitle>
    </>}

    {!icon && <AccountStatBoxGraph>
      {children}
    </AccountStatBoxGraph>}

    <AccountStatBoxTitle>
      {subtitle}
    </AccountStatBoxTitle>

    <AccountStatBoxText>
      {text}
    </AccountStatBoxText>
  </DashboardCard>;
}

export default AccountStatBox;
