import { useCallback, useEffect, useState } from 'react';
import InputSelect from '../../../components/Forms/InputSelect';
import Grid from './Grid';
import AccountStatBox from './AccountStatBox';
import DashboardTable from './DashboardTable';
import DashboardChart from './DashboardChart';
import PageLoader from '../../../components/PageLoader';
import Template from '../../../template';
import api from '../../../services/api';
import * as S from './styled';

function Dashboard () {
  const [data, setData] = useState();
  const [params, setParams] = useState();

  const loadDashboardData = useCallback(async () => {
    const response = await api.get('/admin/dashboard', { params });
    setData(response.data.content);
  }, [params]);

  useEffect(() => {
    loadDashboardData();
  }, [loadDashboardData]);

  if (!data) return <PageLoader />;

  return <Template>
    <Grid>
      <S.FilterContainer>
        <InputSelect label="Selecione uma etapa" onChange={(stepId) => setParams({ ...params, stepId })} large={true}>
          <option value="1">Etapa 1</option>
          <option value="2">Etapa 2</option>
        </InputSelect>
        <button type="button" className="primary button" onClick={loadDashboardData}>Filtrar</button>
      </S.FilterContainer>
    </Grid>
    <Grid>
      {data && data.map(d => {
        console.log(d);
        switch (d.component) {
          case 'stats':
            return <AccountStatBox {...d}>
              {!d.icon && <S.Graph value={d.title} />}
            </AccountStatBox>;
          case 'table':
            return <DashboardTable {...d} />;
          case 'chart':
            return <DashboardChart chartData={d} />;
          default:
            return '';
        }
      })}

      {/* <AccountStatBox {...data[1]} />
      <AccountStatBox  {...data[2]}>

      </AccountStatBox>
      <AccountStatBox  {...data[3]}>
        {!data[2].icon && <S.Graph value={data[2].title} />}
      </AccountStatBox>
      <DashboardTable {...data[4]} />
      <DashboardChart chartData={data[5]} />
      <DashboardChart chartData={data[6]} /> */}
    </Grid>
  </Template>;
}

export default Dashboard;
