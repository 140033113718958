import styled from 'styled-components'

export const PersonalInfoContainer = styled.div`
  display: grid;
  gap: 1.75rem 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 3rem;

  > div:has(textarea) {
    grid-area: 2 / 1 / 4 / 2;
  }

  textarea {
    height: 100%;
    min-height: 124px;
    resize: none;
  }

  @media screen and (max-width: 1124px) {
    grid-template-columns: 1fr;
  }
`
