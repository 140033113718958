import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Container = styled.section`
  .single-card {
    display: grid;
    gap: 1rem;
  }
`

export const TopSellersTitle = styled.p`
  font-weight: 700;
  line-height: 1rem;
  margin: 0;
`

export const TopSellersTable = styled.table`
  * {
    color: #3e3f5e;
    font-weight: 700;
    line-height: 1em;
  }

  thead {
    border-bottom: 1px solid #eaeaf5;
    height: 2.5rem;

    tr {
      font-size: 0.75rem;
      text-transform: uppercase;

      th {
        :not(:first-child) {
          padding-inline: 1.5rem;
          text-align: center;
        }

        :last-child {
          padding-right: 0;
        }
      }
    }
  }

  tbody {
    font-size: 0.875rem;

    tr {
      :first-child {
        td {
          padding-top: 0.688rem;
        }
      }

      td {
        text-align: center;

        :first-child {
          align-items: center;
          display: flex;
          gap: 1rem;
          height: 4.125rem;
          text-align: start;
        }
      }
    }
  }
`

export const ItemLink = styled(Link)`
  text-decoration: none !important;

  > figure, img {
    border-radius: 0.75rem;
    height: 2.75rem;
    margin: 0;
    width: 4.25rem;
  }
`
export const LinkContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;

  > a:last-child {
    font-size: 0.75rem;
    padding-left: 0.875rem;
    position: relative;

    ::before {
      border: 2px solid #3ad2fe;
      border-radius: 50%;
      content: "";
      height: 8px;
      left: 0;
      position: absolute;
      top: 1px;
      width: 8px;
    }
  }
`
