export function hexToRgb (color) {
  let hex = color[0] === '#' ? color.slice(1) : color;
  let c;
  if (hex.length !== 6) {
    hex = ((() => {
      const result = [];
      for (c of Array.from(hex)) {
        result.push(`${c}${c}`);
      }
      return result;
    })()).join('');
  }
  const colorStr = hex.match(/#?(.{2})(.{2})(.{2})/).slice(1);
  const rgb = colorStr.map(col => parseInt(col, 16));
  rgb.push(1);
  return rgb;
}

export function rgbToHsl (rgb) {
  const r = rgb[0] / 255;
  const g = rgb[1] / 255;
  const b = rgb[2] / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const diff = max - min;
  const add = max + min;

  const hue =
    min === max
      ? 0
      : r === max
        ? (((60 * (g - b)) / diff) + 360) % 360
        : g === max
          ? ((60 * (b - r)) / diff) + 120
          : ((60 * (r - g)) / diff) + 240;

  const lum = 0.5 * add;

  const sat =
    lum === 0
      ? 0
      : lum === 1
        ? 1
        : lum <= 0.5
          ? diff / add
          : diff / (2 - add);

  const h = Math.round(hue);
  const s = Math.round(sat * 100);
  const l = Math.round(lum * 100);

  return [h, s, l];
}

/**
 *
 * @param {string} start a css variable name
 * @param {string} end a css variable name
 * @param {number} percent a percent value 0-100 near end
 * @returns {string} a hsl string in format 'hsl(hue, saturation, light)'
 * @example
 *
 * const tenPercentNextSecondary = getTransitionColorByProperty(
 *  "--primary",
 *  "--secondary",
 *  10
 * );
 *
 * styled.css`
 *  background-color: ${() => tenPercentNextSecondary}
 * `
 *
 */

export function getTransitionColorByVariableName (start, end, percent) {
  const startHSLColor = rgbToHsl(hexToRgb(
    getComputedStyle(document.documentElement)
      .getPropertyValue(start)
  ));
  const endHSLColor = rgbToHsl(hexToRgb(
    getComputedStyle(document.documentElement)
      .getPropertyValue(end)
  ));

  const [startH, startS, startL] = startHSLColor;
  const [endH, endS, endL] = endHSLColor;

  const ratio = percent / 100;
  const transitionFactor = {
    h: (endH - startH) * ratio,
    s: (endS - startS) * ratio,
    l: (endL - startL) * ratio
  };
  const h = startH + transitionFactor.h;
  const s = startS + transitionFactor.s;
  const l = startL + transitionFactor.l;
  return `hsl(${h},${s}%,${l}%)`;
}
