import { ReactElement } from 'react'
import BannerProps from './Banner.props'

import * as S from './style'

function Banner ({ text, link }: BannerProps): ReactElement {
  return <S.Banner>
  <S.IconContainer>
    <S.Icon alt='Download' src="https://cdn-icons-png.flaticon.com/512/724/724933.png" />
  </S.IconContainer>

  <S.BannerTitle>{text}</S.BannerTitle>

  <S.BannerLink href={link} download />
</S.Banner>
}

export default Banner
