import styled from 'styled-components';

export const Button = styled.div`
  button{
    border-radius: 10px;
    background-color: var(--primary);
    color: var(--white);
    border: 1px solid #dedeea;
    color: var(--white);
    margin-bottom: 10px;
    height: 50px;
    :hover{
      background-color: var(--secondary);
    }
  }
`;
