import { useCallback } from 'react'
import api from '../services/api'

const text = [
  'Resultados parciais',
  'Fechamento parcial',
  'Fechamento'
]

export function useWorksheetDate (): (stepId?: number) => Promise<string> {
  const load = useCallback(async (stepId = 1) => {
    const response = await api.get('/data', { params: { stepId } })
    const newDate = new Date(response.data.data)
    const type = text[response.data.type]

    return `${type} etapa ${stepId} - Atualizado em ${newDate.getDate()}/${newDate.getMonth() + 1}`
  }, [])

  return load
}
