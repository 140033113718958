import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const CardTreinamentos = styled.div`
  position: relative;

  figure {
    :first-child {
      height: 10rem;
    }
  }

  :hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    > :last-child {
      opacity: 1;
      transform: translate(0);
      visibility: visible;
    }
  }
`

export const Thumbnail = styled.img`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  object-fit: cover;
  object-position: center;
`

export const AvatarContainer = styled.figure`
  border-radius: 50%;
  border: 4px solid #fff;
  left: 1.5rem;
  margin: 0;
  position: absolute;
  top: 8.625rem;
  width: 2rem;
  z-index: 3;
`

export const Avatar = styled.img`
  border-radius: 50%;
`

export const ContentContainer = styled.div`
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  padding: 24px 1.75rem;
  transition: border-radius 0.2s ease-in-out;
  z-index: 2;
`

export const Title = styled(Link)`
  color: #3e3f5e;
  font-size: 0.875rem;
  font-weight: 700;
`

export const Category = styled.p`
  color: #3e3f5e;
  font-size: 0.75rem;
  font-weight: 600;
`

export const MetaContainer = styled.div`
  align-items: center;
  background-color: #fcfcfd;
  border-top: 1px solid #eaeaf5;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  bottom: -77px;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  display: flex;
  justify-content: center;
  height: 77px;
  position: absolute;
  left: 0;
  opacity: 0;
  transform: translateY(-20px);
  transition: transform 0.3s ease-in-out,opacity 0.3s ease-in-out,visibility 0.3s ease-in-out;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
`
