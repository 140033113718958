import { ComponentType, ReactElement, useCallback, useEffect, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import ImageUpload from '../../../components/Forms/ImageUpload'
import PageLoaderComponent from '../../../components/PageLoader'
import { UserUpdateModal } from '../../../components/UserUpdateForm'
import { UserUpdateHoldingModal } from '../../../components/UserUpdateHoldingForm'
import { UserUpdatePasswordForm } from '../../../components/UserUpdatePasswordForm'
import { useAuth } from '../../../contexts/AuthContext'
import { useCampaign } from '../../../contexts/CampaignContext'
import { useLockedModal } from '../../../contexts/LockedModalContext'
import { useModal } from '../../../contexts/ModalContext'
import api from '../../../services/api'
import * as S from './style'

interface RegType {
  regulation: string
  id: number
}

interface imgType {
  image: {
    path: string
    id: string
  }
}

export default function Activation (): ReactElement {
  const CustomPerfectScrollbar = PerfectScrollbar as ComponentType<ReactElement['props']>
  const { token } = useParams()
  const navigate = useNavigate()
  const [reg, setReg] = useState<RegType>()
  const { campaignData } = useCampaign()
  const { userCan, setAvatar } = useAuth()
  const { openModal, closeModal } = useModal()
  const { openModal: openLockedModal, closeModal: closeLocked } = useLockedModal()

  const handleConfirmTerm = useCallback(async () => {
    closeLocked()
    try {
      const response = await api.post(
        `/activate/${String(token?.replace('token=', ''))}`
      )
      toast.success(response.data.message, {
        theme: 'colored'
      })

      localStorage.setItem('token', response.data.token)
      localStorage.setItem('user', JSON.stringify(response.data.user))
      localStorage.setItem('acceptedAt', (new Date()).toISOString())

      navigate('/home')
    } catch (err: any) {
      console.log(err)
      toast.error(err.response.data?.message, {
        theme: 'colored'
      })
    }
  }, [closeLocked, navigate, token])
  const handleAvatarUpload = useCallback(
    async (img: imgType) => {
      try {
        const response = await api.patch('/me/avatar', {
          avatarId: img.image.id
        })
        setAvatar(response.data.avatar.path)
        handleConfirmTerm()
      } catch (e) { }
    },
    [handleConfirmTerm, setAvatar]
  )

  const handleRejectTerm = useCallback(async () => {
    try {
      const response = await api.get(
        `/activate/${String(token?.replace('token=', ''))}/reject`
      )
      toast.success(response.data.message, {
        theme: 'colored'
      })
      closeModal()
      navigate('/')
    } catch (err: any) {
      toast.error(err.response.data.message, {
        theme: 'colored'
      })
    }
  }, [closeModal, navigate, token])

  const openImageUploadModal = useCallback(() => {
    closeLocked(() => {
      openLockedModal({
        header: 'Atualize seu avatar',
        size: 'small',
        body: (
          <ImageUpload
            title="Trocar Avatar"
            text="110x110px tamanho minimo"
            onSend={handleAvatarUpload}
            error={'avatarError'}
          />
        )
      })
    })
  }, [closeLocked, handleAvatarUpload, openLockedModal])

  const openUpdateUserModal = useCallback(async () => {
    closeLocked(() => {
      openLockedModal({
        header: 'Confirme seus dados',
        size: 'large',
        body: <UserUpdateModal onSave={handleConfirmTerm} />
      })
    })
  }, [closeModal, handleConfirmTerm, openLockedModal])

  const openUpdateUserLockedModal = useCallback(async () => {
    closeLocked(() => {
      openLockedModal({
        header: 'Confirme seus dados',
        size: 'large',
        body: <UserUpdateModal onSave={openImageUploadModal} />
      })
    })
  }, [closeLocked, openImageUploadModal, openLockedModal])

  const openUpdateHoldingModal = useCallback(() => {
    if (userCan('holdings:update', true) === false) {
      openUpdateUserModal()
      return
    }
    closeLocked(() => {
      openLockedModal({
        header: 'Atualize os dados da sua rede',
        body: <UserUpdateHoldingModal onSave={openUpdateUserLockedModal} />
      })
    })
  }, [
    userCan,
    closeModal,
    openUpdateUserModal,
    openLockedModal,
    openUpdateUserLockedModal
  ])

  const openUpdatePasswordModal = useCallback(() => {
    closeLocked(() => {
      openLockedModal({
        header: 'Atualize sua senha',
        body: <UserUpdatePasswordForm onSave={openUpdateHoldingModal} />,
        size: 'medium'
      })
    })
  }, [closeModal, openLockedModal, openUpdateHoldingModal])

  const openAcceptModal = useCallback(() => {
    openLockedModal({
      header: 'Você tem certeza?',
      body: (
        <>
          <p>
            Você tem certeza que deseja participar da campanha{' '}
            {campaignData.title}?
          </p>
          <S.ButtonContainer>
            <button className="button secondary" onClick={() => closeLocked()}>
              Voltar
            </button>
            <button
              className="button secondary"
              onClick={openUpdatePasswordModal}
            >
              Aceitar
            </button>
          </S.ButtonContainer>
        </>
      )
    })
  }, [openLockedModal, campaignData, openUpdatePasswordModal, closeModal])

  const openRejectModal = useCallback(() => {
    openModal({
      header: 'Você tem certeza?',
      body: (
        <>
          <p>
            Você tem certeza que NÃO deseja participar da campanha{' '}
            {campaignData.title}?
          </p>
          <S.ButtonContainer>
            <button className="button secondary" onClick={handleRejectTerm}>
              Recusar
            </button>
            <button className="button secondary" onClick={() => closeModal()}>
              Voltar
            </button>
          </S.ButtonContainer>
        </>
      )
    })
  }, [openModal, campaignData, handleRejectTerm, closeModal])

  const showContent = useCallback((regulation: any) => {
    if (regulation === null) return 'Nenhum regulamento está disponivel'
    if (regulation.content.match(/\.pdf$/) !== null) {
      return <S.Iframe src={regulation.content} />
    }

    return (
      <S.Dungerous dangerouslySetInnerHTML={{ __html: regulation.content }}></S.Dungerous>
    )
  }, [])

  useEffect(() => {
    const Data = async (): Promise<void> => {
      try {
        const result = await api.get('regulation')
        setReg(result.data)
      } catch (err: any) {
        toast.error(err.response.data.message, {
          theme: 'colored'
        })
        // navigate("/");
      }
    }
    Data()
  }, [token])

  if (reg === null || reg === undefined) return <PageLoaderComponent />

  return (
    <S.Container className="landing">
      <CustomPerfectScrollbar>
        <S.Body>
          <div className="text-center mb-5">
            <h1>Regulamento</h1>
          </div>

          {showContent(reg.regulation)}

          <div
            className="form-row"
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <div className="form-item">
              <button
                className="button medium secondary mt-5"
                onClick={openRejectModal}
              >
                Recuso o regulamento
              </button>
            </div>

            <div className="form-item">
              <button
                className="button medium secondary mt-5"
                onClick={openAcceptModal}
              >
                Aceito o regulamento
              </button>
            </div>
          </div>
        </S.Body>
      </CustomPerfectScrollbar>
    </S.Container>
  )
}
