import styled from 'styled-components';
import statBoxBackground1 from '../../../../../assets/img/graph/stat/07.png';
import statBoxBackground2 from '../../../../../assets/img/graph/stat/08.png';
import statBoxBackground3 from '../../../../../assets/img/graph/stat/09.png';
import statBoxBackground4 from '../../../../../assets/img/graph/stat/10.png';

export const Container = styled.div`
  background-color: #fff;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 12px;
  box-shadow: 0 0 40px 0 rgba(94,92,154,.06);
  flex: 1 21%;
  overflow: hidden;
  padding: 32px 28px;
  position: relative;

  &:nth-child(4n+1) {
    background-image: url(${statBoxBackground1});
    .icon {
      background-color: #615dfa;
    }
  }
  &:nth-child(4n+2) {
    background-image: url(${statBoxBackground2});
    .icon {
      background-color: #4f8dff;
    }
  }
  &:nth-child(4n+3) {
    background-image: url(${statBoxBackground3});
    .icon {
      background-color: #3ad2fe;
    }
  }
  &:nth-child(4n) {
    background-image: url(${statBoxBackground4});
    .icon {
      background-color: #23d2e2;
    }
  }
`;
