import styled from 'styled-components'

export const SwitchOption = styled.section`
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
`

export const SwitchTextContainer = styled.section``

export const SwitchOptionTitle = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  margin: 0;
`

export const SwitchOptionDescription = styled.p`
  color: #8f91ac;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.4285714286em;
  margin: 0.25rem 0 0;
`
