import { useEffect, useState } from 'react'
import GoldMedalBadgePreview from '../../../../assets/img/badge/bronze-b.png'
import PageLoaderComponent from '../../../../components/PageLoader'
import api from '../../../../services/api'

function ProfileBadges () {
  const [data, setData] = useState(null)

  useEffect(() => {
    api.get('/quest/my-quests', {
      params: {
        page: 1,
        type: 'badges'
      }
    }).then(result => {
      setData(result.data)
    })
  }, [])

  if (data == null) {
    return <PageLoaderComponent />
  }

  return (
    <div className="account-hub-content">
      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Minhas Badges</p>
          <h2 className="section-title">Informação de badges</h2>
        </div>
      </div>

      <div className="grid-column">
        <div className="grid grid-4-4-4 centered">
          {data.content.map((c) => (
            <div className="badge-item-preview">
              <img className="badge-item-preview-image" src={GoldMedalBadgePreview} alt="badge-gold-b" />

              <div className="badge-item-preview-info">
                <p className="badge-item-preview-title">{c.quest.name}</p>

                <p className="badge-item-preview-text">{c.quest.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProfileBadges;
