import styled from 'styled-components'

export const Container = styled.div`
  > div {
    max-width: 100%;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.75rem;

  button:first-child {
    background-color: var(--secondary) !important;
  }
`
