import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import InputText from '../../../components/Forms/InputText';
import PageLoaderComponent from '../../../components/PageLoader';
import { useCart } from '../../../contexts/CartContext';
import { useFetch } from '../../../hooks/useFetch';
import Template from '../../../template';
import { currency } from '../../../utils/format';

export default function Product () {
  const [currentSku, setCurrentSku] = useState();
  const [amount, setAmount] = useState();
  const { cod } = useParams();

  const { addToCart } = useCart()

  // Request API
  const { data } = useFetch(`products/${cod}`);

  const name = useMemo(() => {
    return data?.name.substr(0, 42) + '...';
  }, [data]);

  const menorPreco = useCallback(() => {
    const valor = data.skus.reduce((acc, item) => Math.min(item.price, acc), Infinity);
    return currency(valor);
  }, [data]);

  useEffect(() => {
    if (!data) return;
    setCurrentSku(data.skus[0].code);
    setAmount(1);
  }, [data]);

  if (!data) {
    return <PageLoaderComponent />;
  }

  console.log(data)

  return (
    <Template>

      <div className="section-header">
        <div className="section-header-info">
          <p className="section-pretitle">Produto</p>

          <h2 className="section-title" style={{ maxWidth: '800px' }}>{data.name}</h2>
        </div>

        <div className="section-header-actions">
          <Link className="section-header-subsection" to="/marketplace">Marketplace</Link>

          <p className="section-header-subsection">{name}</p>
        </div>
      </div>

      <div className="grid grid-9-3">
        <div className="marketplace-content grid-column">
          <div style={{ backgroundColor: '#fff', borderRadius: '12px' }}>
            {data.skus.map(sku => <img
              src={sku.images[0].path}
              alt={sku.images[0].path}
              style={{ margin: '0 auto', display: 'block', maxHeight: '500px' }}
            />)}
          </div>

          <div className="tab-box">
            <div className="tab-box-options">
              <div className="tab-box-option active">
                <p className="tab-box-option-title">Descrição</p>
              </div>
            </div>

            <div className="tab-box-items">
              <div className="tab-box-item">
                <div className="tab-box-item-content">
                  <p className="tab-box-item-paragraph" dangerouslySetInnerHTML={{ __html: data.description.replace(/&lt;/g, '<').replace(/&gt;/g, '>') }}></p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="marketplace-sidebar">
          <div className="sidebar-box">
            <div className="sidebar-box-items">
              <p className="price-title big">
                <span className="currency"></span>
                {menorPreco()}
              </p>

              <br />

              <InputText id="amount" label="Quantidade" name="quantidade" value={amount} onChange={(amount) => setAmount(Number.parseInt(amount))} large />

              {data.skus.length > 1 && (
                <div className="form-select small">
                  <label htmlFor="items-filter-category">Variações</label>
                  <select
                    id="items-filter-category"
                    name="items_filter_category"
                    value={currentSku}
                  >
                    {data.skus.map(s => <option value={s.code}>{s.model}</option>)}
                  </select>
                  <svg className="form-select-icon icon-small-arrow">
                    <use href="#svg-small-arrow"></use>
                  </svg>
                </div>
              )}

              <button
                className="button primary"
                onClick={() => {
                  addToCart(data.skus[0].code, amount)
                }}
              >
                Adicione ao carrinho!
              </button>
            </div>

            <p className="sidebar-box-title medium-space">Detalhes do item</p>

            <div className="sidebar-box-items">
              <div className="information-line-list">

                {data.category.length && <div className="information-line">
                  <p className="information-line-title">Categoria</p>

                  <p className="information-line-text">
                    <Link to={`/category/${data.category.id}`}>{data.category.name}</Link>
                  </p>
                </div>}

                <div className="information-line">
                  <p className="information-line-title">Fabricante</p>

                  <p className="information-line-text">
                    <span className="bold">{data.brandName}</span>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </Template>
  );
}
