import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import HexagonComponent from '../Hexagon';
import ProgressBar from '../ProgressBar';
import * as S from './style';

const Row = ({ rowData }) => {
  const indiceAcertos = rowData.ratio;
  return <tr>
    <td style={{ fontSize: '1rem', maxWidth: '120px' }}>
      <HexagonComponent size="large" src={`${rowData.user.avatar?.path}`} />
      {rowData.user.name}
    </td>
    <td>{rowData.points}</td>
    <td>{rowData.posicao}</td>
    <td>
      <ProgressBar current={indiceAcertos} max={100} />
      &nbsp;{indiceAcertos}%
    </td>
  </tr>;
};

function QuizRankComponent ({ id }) {
  const [ranking, setRanking] = useState();

  const loadRank = useCallback(async () => {
    const response = await api.get(`quiz/rank/${id}`);
    setRanking(response.data.rank);
  }, [id]);

  useEffect(() => {
    loadRank();
  }, [loadRank]);

  if (!ranking) return 'carregando...';

  return <div className="widget-box">
    <p style={{ fontSize: '24px', padding: '2% 0%' }} className="widget-box-title">Top 5 do quiz</p>

    <S.Tabela>
      <table>
        <tr>
          <th>Jogador</th>
          <th>Pontuação</th>
          <th>Posição</th>
          <th>Taxa de acerto</th>
        </tr>
        {ranking.map(r => <Row rowData={r} />)}
      </table>
    </S.Tabela>
  </div>;
}

export default QuizRankComponent;
