import { ReactElement, useMemo } from 'react'
import CategoryCardProps from './CategoryCard.props'

import * as S from './style'

export default function CategoryCard ({ bgImgSrc, description, title, to = '', buttonTitle }: CategoryCardProps): ReactElement {
  const path = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!to) {
      return '/marketplace'
    }
    return `/marketplace-category${String(to)}`
  }, [to])
  return <S.CategoryCardContainer bgImgSrc={bgImgSrc} to={path}>
    <S.CategoryCardContent>
      <p>{title}</p>

      <p>{description}</p>
    </S.CategoryCardContent>

    {<S.ButtonTitle>{buttonTitle}</S.ButtonTitle>}
  </S.CategoryCardContainer>
}
