import { useCallback, useEffect, useState } from 'react';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import Template from '../../template';
import BannerTitleComponent from '../../components/BannerTitle';
import PaginationComponent from '../../components/Pagination';
import LinkButton from '../../components/Forms/LinkButton';
import InputSelect from '../../components/Forms/InputSelect';
import BannerTitle from '../../assets/img/banner/accounthub-icon.png';
import api from '../../services/api';
import PageLoaderComponent from '../../components/PageLoader';
import { useModal } from '../../contexts/ModalContext';
import { useAuth } from '../../contexts/AuthContext';
import * as S from './style';

export default function Providers () {
  const [providers, setProviders] = useState();
  const [holdings, setHoldings] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { openModal, closeModal } = useModal();
  const { userCan } = useAuth();

  const loadProviders = useCallback(async (page) => {
    try {
      const response = await api.get('/providers');
      const { providers, currentPage, totalPages } = response.data;
      setProviders(providers);
      setCurrentPage(currentPage);
      setTotalPages(totalPages);
      const holdingsResponse = await api.get('/holdings');
      const { holdings } = holdingsResponse.data;
      setHoldings(holdings);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.status !== 500) {
          toast.error(err.response.data, { theme: 'colored' });
          return;
        }
      }
      toast.error('Ocorreu um erro no servidor.', { theme: 'colored' });
    }
  }, []);

  const handleDelete = useCallback(async (providerId) => {
    try {
      await api.delete(`/providers/${providerId}`);
      console.log(providers);
      const newProviders = providers.filter(p => p.id !== providerId);
      setProviders([...newProviders]);
      toast.success('A loja foi excluido com sucesso!', { theme: 'colored' });
      closeModal();
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.status !== 500) {
          toast.error(err.response.data, { theme: 'colored' });
          return;
        }
      }
      toast.error('Ocorreu um erro no servidor.', { theme: 'colored' });
    }
  }, [closeModal, providers]);

  const handleDeleteModal = useCallback(async (providerId) => {
    openModal({
      body: <>
          Essa ação é irreversivel. Quer mesmo continuar?
          <S.ButtonContainer>
            <S.DangerButton className="button" onClick={() => handleDelete(providerId)}>Sim</S.DangerButton>
            <S.SuccessButton className="button" onClick={closeModal}>Não</S.SuccessButton>
          </S.ButtonContainer>
        </>
    });
  }, [closeModal, handleDelete, openModal]);

  const handleLoadProviders = useCallback(async (holdingId) => {
    try {
      const response = await api.get(`/distributor/providers/${holdingId}`);
      setProviders(response.data);
      setCurrentPage(1);
      setTotalPages(1);
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response.status !== 500) {
          toast.error(err.response.data, { theme: 'colored' });
          return;
        }
      }
      toast.error('Ocorreu um erro no servidor.', { theme: 'colored' });
    }
  }, []);

  useEffect(() => {
    loadProviders(1);
  }, [loadProviders]);

  if (!providers) return <PageLoaderComponent />;

  return (
        <Template>
            <BannerTitleComponent
                imgUrl={BannerTitle}
                title="Lojas"
                subTitle="Cadastre todas as lojas que pertencem a sua rede para participar da campanha"
                imgAlt="newsfeed-icon"
            />
            <div className="section-header">
              <div className="section-header-info">
                <p className="section-pretitle">Suas lojas</p>

                <h2 className="section-title">Cadastre todas as lojas que pertencem a sua rede para participar da campanha</h2>
              </div>
            </div>
            <S.Tabela>
              <S.Wrapper className="grid mobile-prefer-content">
                <S.HeaderWrapper>
                  {userCan('all-providers:read') && <InputSelect onChange={handleLoadProviders}>
                    <option value="">Selecione uma REDE</option>
                    {holdings && holdings.map(h => <option value={h.id}>{h.name}</option>)}
                  </InputSelect>}
                  {userCan('providers:create') && <LinkButton to="/providers/new" >Adicionar nova loja</LinkButton>}
                </S.HeaderWrapper>
                <div className="grid-column">
                  {providers.length > 0
                    ? <table>
                    <tr>
                      <th>Nome</th>
                      <th>CNPJ</th>
                      <th>&nbsp;</th>
                    </tr>
                    {providers.map(provider => <tr>
                      <td>{provider.name}</td>
                      <td>{provider.cnpj}</td>
                      <S.ActionContainer>
                        {userCan('providers:update') && <S.Edit to={`/providers/${provider.id}`}>
                          <i className="fas fa-edit"></i>
                        </S.Edit>}
                        {userCan('providers:delete') && <S.Exclude type="button" onClick={() => handleDeleteModal(provider.id)}>
                          <i className="fas fa-trash-alt"></i>
                        </S.Exclude>}
                      </S.ActionContainer>
                    </tr>)}
                  </table>
                    : <S.Center>Você não tem nenhuma loja ainda, que tal adicionar uma agora?</S.Center>}
                </div>
              </S.Wrapper>
            </S.Tabela>

            <PaginationComponent totalPages={totalPages} currentPage={currentPage} onChagePage={(page) => console.log(page)} />
        </Template >
  );
}
