import styled from 'styled-components'

export const PrivacySettingContainer = styled.section`
`

export const PrivacySettingLabel = styled.label`
  align-items: center;
  display: grid;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin: 0;
  position: relative;

  select {
    border: 1px solid #dedeea;
    color: #3e3f5e;
    cursor: pointer;
    font-size: 0.875rem;
    height: 3rem;
    padding-inline: 1.25rem;
    transition: border-color 0.2s ease-in-out;

    :focus-visible {
      outline: -webkit-focus-ring-color auto 1px;
    }
  }

  svg {
    fill: #3e3f5e;
    height: 0.5rem;
    pointer-events: none;
    position: absolute;
    right: 1.25rem;
    top: 1.25rem;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    width: 0.375rem;
  }
`
