import styled from 'styled-components';

export const AvisoCookiesHolder = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
`;

export const AvisoCookies = styled.div`
  padding: 1rem;
  border-radius: 1rem 1rem 0 0;
  background-color: var(--white);
  max-width: 90%;
  margin: 0 auto;
  pointer-events: all;
  text-align:center;
`;

export const Button = styled.button`
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  background-color: var(--primary);
  color: var(--white);
  min-height: 48px;
  border-radius: 12px;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  margin-top: 32px;

  &:hover {
    background-color: var(--hover);
  }
`;
