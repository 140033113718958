import styled from 'styled-components'

export const ManageItemsContainer = styled.section`
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, 284px);

  @media screen and (max-width: 1365px) {
    justify-content: center;
  }

  > div.store-item {
    max-width: 100%;
  }
`

export const ItemCover = styled.figure`
  height: 11.25rem;
  margin: 0;
  position: relative;

  img {
   border-top-left-radius: 0.75rem;
   border-top-right-radius: 0.75rem;
  }
  img.item-icon-frame {
    height: 6.875rem;
    width: 6.25rem;
  }

  img.item-icon-frame, svg {
    position: absolute;
    inset: 0;
    margin: auto;
  }
`

export const ItemContentContainer = styled.div`
  display: grid;
  gap: 2.25rem;
  padding: 1.75rem;
  position: relative;

  button {
    background-color: transparent;
    border: 1px solid #dedeea;
    border-radius: 0.625rem;
    color: #adafca;
    font-size: 0.875rem;
    height: 3rem;
    line-height: 3rem;
    text-transform: capitalize;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

    :hover {
      color: #fff;
      background-color: #2e2e47;
      border-color: #2e2e47;
    }

    &.store-item-button {
      background-color: #23d2e2;
      border: none;
      box-shadow: 4px 7px 12px 0 rgba(35, 210, 226, 0.2);
      color: var(--white);

      :hover {
        background-color: #1bc5d4;
      }
    }
  }
`

export const PriceContainer = styled.div`
  align-items: center;
  background-color: #fff;
  border-radius: 12.5rem;
  box-shadow: 3px 5px 20px 0 rgba(94, 92, 154, 0.12);
  display: flex;
  height: 2rem;
  padding: 0 0.875rem;
  position: absolute;
  right: 0.875rem;
  top: -0.875rem;

  > p {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 2rem;
    margin: 0;

    span {
      color: #00c7d9;
    }
  }
`

export const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;

  > p {
    font-weight: 700;
    margin: 0;

    :last-child {
      color: #adafca;
      font-size: 0.75rem;
      padding-left: 0.875rem;
      position: relative;

      ::before {
        border: 2px solid #3ad2fe;
        border-radius: 50%;
        content: "";
        height: 8px;
        left: 0;
        position: absolute;
        top: 1px;
        width: 8px;
      }

      &.create-item-card::before {
        border: 2px solid #adafca;
      }
    }
  }
`
