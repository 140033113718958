import { ReactElement, useCallback, useEffect, useState } from 'react'
import PageLoaderComponent from '../../components/PageLoader'
import api from '../../services/api'
import Template from '../../template'
import * as S from './styled'

interface LiveProps {
  name: string
  hostUrl: string
  enableAt: Date
  disableAt: Date
}

interface AdminRoomState {
  isLoading: boolean
  lives: LiveProps[] | null
  activeLive?: string
}

function AdminRoom (): ReactElement {
  const [state, setState] = useState<AdminRoomState>({
    isLoading: true,
    lives: null
  })

  useEffect(() => {
    (async () => {
      const response = await api.get('/meeting/rooms')
      setState((state: AdminRoomState) => ({
        ...state,
        isLoading: false,
        lives: response.data.map((live: LiveProps) => ({
          ...live,
          enableAt: new Date(live.enableAt),
          disableAt: new Date(live.disableAt)
        }))
      }))
    })()
  }, [])

  const handleOpenRoom = useCallback(async (activeLive: string) => {
    await api.get('/meeting/room')
    setState((state: AdminRoomState) => ({ ...state, activeLive }))
  }, [])

  const handleCloseRoom = useCallback(async () => {
    setState((state: AdminRoomState) => ({ ...state, activeLive: undefined }))
  }, [])

  if (state.isLoading) return <PageLoaderComponent />
  return <Template>
    <div style={{ marginTop: '10rem' }}></div>
    {state.activeLive === undefined
      ? <S.Container>
        <S.Button to="/admin/room/new">Criar live</S.Button>
        <S.Table>
          <thead>
            <tr>
              <th>Nome da Live</th>
              <th>Habilitada em</th>
              <th>Desabilitada em</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {state.lives?.map((live: LiveProps) => <tr>
              <td>{live.name}</td>
              <td>{live.enableAt.toLocaleString('pt-br', { dateStyle: 'short', timeStyle: 'short' })}</td>
              <td>{live.disableAt.toLocaleString('pt-br', { dateStyle: 'short', timeStyle: 'short' })}</td>
              <td><button className="button primary" onClick={async () => await handleOpenRoom(live.hostUrl)}>Iniciar a live</button></td>
            </tr>)}
          </tbody>
        </S.Table>
      </S.Container>
      : <S.Container>
        <S.ActionButton onClick={handleCloseRoom}>Fechar a live</S.ActionButton>
        <S.IFrame src={state.activeLive}></S.IFrame>
      </S.Container>}
  </Template>
}

export default AdminRoom
