import { HTMLAttributes, ReactElement } from 'react'
import * as S from './styled'

function Grid ({ children, ...rest }: HTMLAttributes<HTMLDivElement>): ReactElement {
  return <S.Container {...rest}>
    {children}
  </S.Container>
}

export default Grid
