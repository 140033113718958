import styled from 'styled-components';
import previewPostImg from '../../../../assets/img/cover/19.jpg';

export const GridPosts = styled.div.attrs(() => ({
  className: 'grid grid-4-4-4 centered'
}))`
  figure.post-preview-image {
    background: rgba(0, 0, 0, 0) url("${previewPostImg}") no-repeat scroll center center / cover
  }
`;
