import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CargoComponent from '../../components/CargoComponent';
import ChannelComponent from '../../components/ChannelComponent';
import InputText from '../../components/Forms/InputText';
import PageLoaderComponent from '../../components/PageLoader';
import RoleSelectorComponent from '../../components/RoleSelectorComponent';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import Template from '../../template';
import { MaskDocument, MaskPhone } from '../../utils/mask';
import * as S from './style';

// Component

export default function AddNewUser (props) {
  const { doLogout, userCan } = useAuth();
  const [user, setUser] = useState({});
  const [distributor, setDistributor] = useState({});
  const [hasDistributor, setHasDistributor] = useState(false);
  const [button, setButton] = useState({
    name: 'Carregando dados',
    isDisabled: true
  });

  const handleUserCreate = useCallback(async (e) => {
    e.preventDefault();
    setButton({
      name: 'Salvando dados',
      isDisabled: true
    });

    try {
      console.log(user)
      await api.post('/user', { ...user });
      toast.success('Os dados foram salvos', { theme: 'colored' });
    } catch (e) {
      if (!e.response.data.message) {
        toast.error('Ocorreu um erro, por favor tente novamente mais tarde', { theme: 'colored' });
        return;
      }

      if (e.response.data.message === 'Token inválido') {
        doLogout();
        toast.error(e.response.data.message, { theme: 'colored' });
        return;
      }

      toast.error(e.response.data.message, { theme: 'colored' });
    }
    setButton({
      name: 'Salvar dados',
      isDisabled: false
    });
  }, [doLogout, user]);

  const handleOpenHoldingModal = useCallback(() => {
  }, []);

  const handleRoleChange = useCallback((role) => {
    setUser({ ...user, roleId: role.id });
    setHasDistributor(!!role.distributor);
  }, [user]);

  useEffect(() => {
    setButton({
      name: 'Salvar dados',
      isDisabled: false
    });
  }, []);

  if (!user) return <PageLoaderComponent />;

  return (
    <Template>
      <S.Grid className="grid medium-space">
        <div className="account-hub-content">
          <div className="section-header">
            <div className="section-header-info">
              <p className="section-pretitle">Novo usuário</p>

              <h2 className="section-title">Informação de usuário</h2>
            </div>
          </div>

          <div className="grid-column">

            <div className="widget-box">
              <p className="widget-box-title">Sobre o novo usuário</p>

              <div className="widget-box-content">
                <form className="form" action="" method="POST" onSubmit={handleUserCreate}>
                  <div className="form-row split">
                    <div className="form-item">
                      <RoleSelectorComponent value={user.roleId} onChange={handleRoleChange} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="repLegal" label="Responsavel Legal" id="repLegal" value={user.repLegal} onChange={(value) => setUser({ ...user, repLegal: value })} />
                    </div>
                    <div className="form-item">
                      <InputText name="cdgHolding" label="Cód. Cliente" id="cdgHolding" value={user.cdgHolding} onChange={(value) => setUser({ ...user, cdgHolding: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="razaoSoc" label="Razão Social" id="razaoSoc" value={user.razaoSoc} onChange={(value) => setUser({ ...user, razaoSoc: value })} required />
                    </div>
                    <div className="form-item">
                      <InputText name="repComercial" label="Representante Comercial" id="repComercial" value={user.repComercial} onChange={(value) => setUser({ ...user, repComercial: value })} required />
                    </div>
                  </div>
                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="name" label="Nome fantasia" id="name" value={user.name} onChange={(value) => setUser({ ...user, name: value })} />
                    </div>

                    <div className="form-item">
                      <InputText name="email" label="Email Corporativo" id="email" value={user.email} onChange={(value) => setUser({ ...user, email: value })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="cellphone" label="Telefone Corporativo" id="cellphone" value={user.cellphone} onChange={(value) => setUser({ ...user, cellphone: MaskPhone(value) })} maxLength="15" />
                    </div>
                    <div className="form-item">
                      <InputText name="document" label="CNPJ" id="document" value={user.document} onChange={(value) => setUser({ ...user, document: MaskDocument(value) })} maxLength="18" />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <InputText name="estado" label="Estado" id="estado" value={user.address?.estado} onChange={(value) => setUser({ ...user, address: { ...user.address, estado: value } })} />
                    </div>
                    <div className="form-item">
                      <InputText name="cidade" label="Cidade" id="cidade" value={user.address?.cidade} onChange={(value) => setUser({ ...user, address: { ...user.address, cidade: value } })} />
                    </div>
                  </div>

                  <div className="form-row split">
                    <div className="form-item">
                      <ChannelComponent name="channelId" label="Canal" id="channelId" value={user.channelId} onChange={(value) => setUser({ ...user, channelId: value })} />
                    </div>
                    <div className="form-item">
                      <CargoComponent name="cargo" label="Cargo" id="cargo" value={user.cargo} onChange={(value) => setUser({ ...user, cargo: value })} />
                    </div>
                  </div>

                  {hasDistributor && <>
                    <p className="widget-box-title">Escolha o distribuidor</p>

                    <div className="widget-box-content">
                      <div className="form-row split">
                        <div className="form-item">
                          <InputText name="name" label="Nome" id="name" value={distributor.name} onChange={(value) => setDistributor({ ...distributor, name: value })} required />
                        </div>

                        <div className="form-item">
                          <InputText name="email" label="CNPJ" id="email" value={distributor.email} onChange={(value) => setDistributor({ ...distributor, email: value })} />
                        </div>
                      </div>
                    </div>
                  </>
                  }

                  <div className="form-row split">
                    {userCan("select:holdings") && <div className="form-item">
                      <S.SubmitButton className="button secondary add-field-button" type="button" onClick={handleOpenHoldingModal}>Selecionar as holdings</S.SubmitButton>
                    </div>}
                    <div className="form-item">
                      <S.SubmitButton className="button primary add-field-button" type="submit" disabled={button.isDisabled}>{button.name}</S.SubmitButton>
                    </div>

                  </div>

                </form>

                <div className="widget-box-content">
                </div>
              </div>
            </div>

          </div>
        </div>
      </S.Grid>
    </Template >
  );
}
