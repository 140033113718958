import { ReactElement } from 'react'
import Template from '../../template'
import BannerTitleComponent from '../../components/BannerTitle'
import BannerTitle from '../../assets/img/banner/quests-icon.png'
import Banner from './Banner'

import * as S from './style'

function Downloads (): ReactElement {
  return <Template>
    <BannerTitleComponent
      imgUrl={BannerTitle}
      title="Downloads"
      subTitle="Faça download dos nossos arquivos!"
      imgAlt="quests-icon"
      variant="default"
      avatar=""
      username=""
    />

    <S.Grid>
      <Banner text="Banner png para o site (Desktop)" link="https://img.casadebraganca.com/Tang/Pack/Tang_BannerSite_Desktop.png" />
      <Banner text="Banner psd para o site (Desktop)" link="https://img.casadebraganca.com/Tang/Pack/Tang_BannerSite_Desktop.psd" />
      <Banner text="Banner png para o site (Mobile)" link="https://img.casadebraganca.com/Tang/Pack/Tang_BannerSite_Mobile.png" />
      <Banner text="Banner psd para o site (Mobile)" link="https://img.casadebraganca.com/Tang/Pack/Tang_BannerSite_Mobile.psd" />
      <Banner text="Box em png" link="https://img.casadebraganca.com/Tang/Pack/Tang_Box.png" />
      <Banner text="Box em psd" link="https://img.casadebraganca.com/Tang/Pack/Tang_Box.psd" />
      <Banner text="Core lamina F em png" link="https://img.casadebraganca.com/Tang/Pack/Tang_Core_Lamina_F.png" />
      <Banner text="Core lamina F em psd" link="https://img.casadebraganca.com/Tang/Pack/Tang_Core_Lamina_F.psd" />
      <Banner text="Core lamina V em png" link="https://img.casadebraganca.com/Tang/Pack/Tang_Core_Lamina_V.png" />
      <Banner text="Core lamina V em psd" link="https://img.casadebraganca.com/Tang/Pack/Tang_Core_Lamina_V.psd" />
      <Banner text="Feed em png" link="https://img.casadebraganca.com/Tang/Pack/Tang_Feed.png" />
      <Banner text="Feed em psd" link="https://img.casadebraganca.com/Tang/Pack/Tang_Feed.psd" />
      <Banner text="Storie" link="https://img.casadebraganca.com/Tang/Pack/Tang_Storie.png" />
      <Banner text="WhatsApp" link="https://img.casadebraganca.com/Tang/Pack/Tang_Whats.png" />
    </S.Grid>

  </Template >
}

export default Downloads
