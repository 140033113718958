import { AxiosError } from 'axios'
import { FormEvent, ReactElement, useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import Card from '../../../../../components/Card'
import InputPassword from '../../../../../components/Forms/InputPassword'
import SectionHeaderComponent from '../../../../../components/SectionHeaderComponent'
import schema from '../../../../../config/passwordUpdateFormScheme.json'
import { FormValidator } from '../../../../../domain/FormValidator'
import api from '../../../../../services/api'
import * as S from './style'

interface PasswordFormData {
  newPassword: string
  password: string
  passwordConfirmation: string
}

const formValidator = new FormValidator(schema)

export default function AccountChangePassword (): ReactElement {
  const [errors, setErrors] = useState<any>()
  const [password, setPassword] = useState<PasswordFormData>({
    newPassword: '',
    password: '',
    passwordConfirmation: ''
  })

  // review submit handling
  const handleSave = useCallback(
    async (evt: FormEvent<HTMLButtonElement>) => {
      evt.preventDefault()
      if (!formValidator.validate(password)) {
        setErrors(formValidator.errors)
        return
      }
      try {
        const response = await api.patch('/me/password', password)
        toast.success(response.data.message, {
          theme: 'colored'
        })
      } catch (error: any) {
        const err = error as AxiosError
        if (err !== null && err?.response?.status !== 500) {
          toast.error(err?.response?.data.message, { theme: 'colored' })
          return
        }
        toast.error('Ocorreu um erro interno no servidor.', {
          theme: 'colored'
        })
      }
    },
    [password]
  )

  return <>
    <SectionHeaderComponent pretitle='CONTA' title='Alterar Senha' />

    <Card className="single-card">
      <S.PasswordContainer>
        <InputPassword
          name="password"
          label="Confirme sua Senha Atual"
          value={password.password !== null ? password.password : ''}
          onChange={(currentPassword) =>
            setPassword({ ...password, password: currentPassword })
          }
          error={errors?.password}
        />

        <InputPassword
          name="newPassword"
          label="Nova Senha"
          value={password.newPassword !== null ? password.newPassword : ''}
          onChange={(newPassword) => setPassword({ ...password, newPassword })}
          error={errors?.newPassword}
        />

        <InputPassword
          name="passwordConfirmation"
          label="Confirmação sua Nova Senha"
          value={password.passwordConfirmation !== null ? password.passwordConfirmation : ''}
          onChange={(passwordConfirmation) =>
            setPassword({ ...password, passwordConfirmation })
          }
          error={errors?.passwordConfirmation}
        />

        <S.ButtonsContainer>
          <button onClick={handleSave} className='button primary'>
            Alterar Senha Agora!
          </button>
        </S.ButtonsContainer>
      </S.PasswordContainer>
    </Card>
  </>
}
