import styled from 'styled-components'

export const MenuAccordion = styled.div`
  border-bottom: 1px solid #eaeaf5;
  padding: 1.75rem 1.625rem 0 3.75rem;
  position: relative;

  :last-child {
    border: none;
  }

  > svg {
    left: 1.75rem;
    position: absolute;
    top: 1.75rem;
  }
  `

export const MenuAccordionHeader = styled.div`
  cursor: pointer;
  padding:  0 0 2rem 0;

  > div.simple-accordion-icon .icon-minus-small {
    display: block !important;
  }
`

export const MenuAccordionTitle = styled.p`
  font-weight: 700;
  line-height: 1rem;
  margin: 0;
`

export const MenuAccordionDescriprion = styled.p`
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0.25rem 0 0;
`

export const MenuAccordionContent = styled.div`
  border-top: 1px solid #eaeaf5;
  display: none;
  overflow: hidden;
  padding: 0.75rem 0 0.75rem;

  &.accordion-open {
    display: block;
  }

  * {
    transition: all 0.3s ease-in-out;
  }

  ul {
    cursor: pointer;
    font-weight: 700;
    line-height: 2.125rem;
    margin: 0;

    li:hover {
      transform: translate(4px);

      a {
        text-decoration: none;
      }
    }
  }
`
